<template>
    <div :class="`mail-container mail-${rowId}`" @mouseover="hover = true" @mouseleave="hover = false">
        <DxPopover
            :wrapper-attr="tooltipAttributes"
            :target="`.mail-${rowId}`"
            :position="'top'"
            ;shading="false"
            :visible="hover"
            :width="300"
            :close-on-outside-click="false"
        >
            <div class="mail-detail-container">
                <div class="mail-details">
                    <div class="mail-subject">{{ mailSubject }}</div>
                    <div class="mail-content">{{ mailContent }}</div>
                </div>
            </div>
        </DxPopover>
    </div>
</template>

<script>
import { DxPopover } from 'devextreme-vue/popover';

export default {
  name: 'RowMailTemplate',
  components: {
    // DxTooltip,
    DxPopover,
  },
  props: {
    templateData: {
        type: Object,
        default: () => ({}),
    },
  },
  data() {
      return {
          tooltipAttributes: { class: 'mail-detail' },
          hover: false
      }
  },
  computed: {
    realData() { return this.templateData.data },
    mailSubject() { return this.realData.subject },
    mailContent() { return this.realData.mail },
    rowId() { return this.realData.id },
  },
}
</script>

<style lang="scss" scoped>
.mail-container {
    &:before {
        content: url(/images/mail.svg);
    }
}

.mail-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;   
    text-align: left;
    padding: 25px;

    .mail-details {
        font-family: "LatoWeb";
        font-size: 17px;        
        
        .mail-subject {
            font-weight: bold;
        }

        .mail-content {
            margin-top: 15px;
            font-family: "LatoWeb";
            font-size: 16px;  
            word-wrap: break-word;
            max-width: 250px;
        }
    }

    
}
</style>