<template>
    <div :class="dropZoneClass" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop" >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DropZone',
    props: {
        fullParent: {
            type: Boolean,
            default: () => (false)
        }
    },
    data() {
        return {
            isDropZoneActive: false
        }
    },
    computed: {
        dropZoneClass() {
            return {
                "dropzone-container": true,
                "dropzone-container-full-parent": this.fullParent,
                "dropzone-container-active": this.isDropZoneActive   
            }
        }
    },
    methods: {
        onDragOver(event) {
            event.preventDefault();
            this.isDropZoneActive = true
            this.$emit('dragover', event)
        },
        onDragLeave() {
            this.isDropZoneActive = false
            this.$emit('dragleave')
        },
        onDrop(event) {
            event.preventDefault();
            this.isDropZoneActive = false
            this.$emit('drop', event)
            this.$emit('dropped-files',event.dataTransfer.files)
            // console.log('dropped-files',event.dataTransfer.files)
        },
    },
}
</script>

// <style lang="scss" scoped>
// .dropzone-container {
//     border: 1px dotted transparent; 

//     &.dropzone-container-full-parent {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//     }

//     &-active {
//         border: 2px dotted white; 
//         background-color: rgba(255, 255, 255, 0.9);
//         z-index: 5;        
//     }
// }
// </style>