<template>
    <div class="logo" @click="homePage">
        <img :src="`/images/logo${logoColor}`" />
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: () => ('white')
        },
        height: {
            default: () => ("")
        }
    },
    methods: {
        homePage() {
            this.$router.push({ name: 'home' })
        }
    },
    computed: {
        logoColor() {
            return this.color === 'white' ? `.png` : `-${this.color}.png`
        } 
    }
}
</script>

<style lang="scss" scoped>
.logo {
    cursor: pointer;
}
</style>
