<template>
    <div class="file-progress">
        <div class="progress" />
        {{ progress }}%
    </div>
</template>

<script>
export default {
    props: ['progress']   
}
</script>

<style lang="scss" scoped>
.progress {
    content: url(/images/file.svg);
}
</style>