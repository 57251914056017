<template>
  <Common class="authentication-form">
    <template v-slot:header>
      <Header />
    </template>
    <slot/>
  </Common>
</template>

<script>
import Common from "./common";
import Header from './default/header'

export default {
  components: {
    Common,
    Header,    
  },
};
</script>

<style lang="scss" scoped>
</style>
