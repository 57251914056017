<template>
  <form class="send-file-form" @submit.prevent="onSubmit">
    <slot />
    <dx-form
      :form-data="formData"
      :disabled="loading"
      :show-colon-after-label="showCol"
      :label-mode="labelMode"
      :label-location="labelLocation"
    >
      <dx-item
        data-field="subject"
        editor-type="dxTextBox"
        :label="{ text: $i18n.t('file-uploader.form.subject') }"
        :editor-options="{ stylingMode: 'filled' }"
      >
        <dx-required-rule :message="$i18n.t('file-uploader.form.validation.subject')" />
      </dx-item>
      <dx-item
        data-field="email"
        editor-type="dxTextBox"
        :label="{ text: $i18n.t('file-uploader.form.receiver') }"
        :editor-options="{ stylingMode: 'filled', mode: 'email' }"
      >
        <dx-required-rule :message="$i18n.t('file-uploader.form.validation.receiver')" />
        <dx-email-rule :message="$i18n.t('file-uploader.form.validation.receiver')" />
      </dx-item>

      <!-- <dx-item
        ___data-field="email"
        editor-type="dxTagBox"
        :label="{ text: $i18n.t('file-uploader.form.receiver') }"
        :editor-options="emailOptions"
      >
      </dx-item> -->

      <dx-item
        data-field="messageHtml"
        editor-type="dxTextArea"
        :label="{ text: $i18n.t('file-uploader.form.message') }"
        :editor-options="{ stylingMode: 'filled', height: 140 }"
      >
      </dx-item>
      
      <dx-button-item>
        <dx-button-options
          :disabled="remainFileSize<0"
          width="100%"
          type="normal"
          icon="/images/send.svg"
          :element-attr="{ class: 'send-button' }"
          :text="$i18n.t(`file-uploader.form.button-send`)"
          :use-submit-behavior="true"
        />
      </dx-button-item>
    </dx-form>
  </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";
import 'devextreme-vue/tag-box'; 
import 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import { SENDING_STATUTES } from '../../SDK'

export default {
  components: {
    DxForm,
    DxEmailRule,
    DxRequiredRule,
    DxItem,
    DxButtonItem,
    DxButtonOptions,
  },
  props: {
    showCol: {
      type: Boolean,
      default: () => (false)
    },
    labelMode: {
      type: String,
      default: () => ('floating')
    },
    labelLocation: {
      type: String,
      default: () => ('left')
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        email: []
      },
      emailOptions: { 
        items: [],
        acceptCustomValue: true,
        // onCustomItemCreating: (e) => {
        //   const newValue = e.text;
        //   console.log("customItemCreating",newValue)
        // }
      },
    }
  },
  computed: {
    ...mapState(['limits']),
    ...mapState('sendings', ['files','envelope', 'sendings']),
    ...mapGetters('sendings', ['GET_CURRENT_FILE_SIZE_MONTH', 'GET_CURRENT_SENDING_FILE_SIZE', 'GET_REMAIN_FILE_SIZE']),
    ...mapGetters('authentication-basic', ['GET_USER']),
    maxFileSize() {
      return this.limits && this.limits.max_file_size_upload
    },
    maxFileSizePerMonth() {
      return this.limits && this.limits.max_file_size_per_month
    },
    // displayMaxFileSizePerMonth() {
    //   return sizes.formatBytes(this.maxFileSizePerMonth)
    // },
    currentFileSizeMonth() {
      return this.GET_CURRENT_FILE_SIZE_MONTH                
    },
    // displayCurrentFileSizeMonth() {
    //   return sizes.formatBytes(this.currentFileSizeMonth)
    // },
    currentSendingTotalSize() {
        return this.GET_CURRENT_SENDING_FILE_SIZE
    },
    // displayCurrentSendingTotalSize() {
    //     return this.currentSendingTotalSize ? sizes.formatBytes(this.currentSendingTotalSize) : "Inconnu"
    // },
    remainFileSize() {
      return this.GET_REMAIN_FILE_SIZE
    },
    // displayRemainFileSize() {
    //     return this.remainFileSize ? `${this.remainFileSize < 0 ? '-' : ''}${sizes.formatBytes(Math.abs(this.remainFileSize))}` : "Inconnu"
    // },
    maxFileRetention() {
      return this.limits.max_file_retention
    }
  },
  methods: {
    ...mapActions('sendings', ['POST_ENVELOPE']),
    async onSubmit(event) {
      event.preventDefault();
      if (!(this.files && this.files.length > 0)) {
        notify(this.$i18n.t('sendings.nofile-to-upload'), 'error', 5000);
        return
      }

      const sendigEnvelope = {
        "send_duration": this.maxFileRetention,
        "sender_uuid": this.GET_USER && this.GET_USER.uuid,
        "recipients": [{
          email: this.formData.email.toLowerCase()
        }],
        "files": [...this.files],
        "subject": this.formData.subject,
        "message": this.formData.messageHtml,
        "status": SENDING_STATUTES.CREATED
      }
      
      const responseSendingEnvelope = await this.POST_ENVELOPE(sendigEnvelope)

      // console.log("responseSendingEnvelope",responseSendingEnvelope)

      if (!responseSendingEnvelope.success) notify(responseSendingEnvelope.message, 'error', 5000)
    }
  }
};
</script>

<style lang="scss" scoped>
.send-file-form {
  ::v-deep {
    .dx-form {
      display: flex;
      justify-content: flex-start;
      width: 85%;
      margin: auto;
      
      &:before {
        content: url(/images/write-mail.svg);
        margin-right: 32px;
      }
    }
  }
}

::v-deep {
 .dx-item-content{
    width: 100% !important;

    .dx-field-button-item{

      width: 100% !important;
    
      .send-button {
        width: 100% !important;
        background-color: #262626;
        color: white;

          .dx-button-content{
          padding: 20px !important;
        }

        &:hover{
          background-color: #1a1a1a;
        }
      }
    }
  }

  .dx-layout-manager.dx-widget {
      width: 80%;
  }

  // .dx-label *{
  //     font-family: "LatoWeblight";
  //     color: #a4a4a4;
  // }

   .dx-textarea{
            textarea{
                color: #ffffffd6;
                font-size: 16px !important;
                font-family: LatoWeb !important;
            }
    }

    .dx-box{


        .dx-item.dx-box-item{

              .dx-texteditor.dx-editor-filled{
                  background-color: rgba(0,0,0,.04);
                  border-top: 1px solid #bcbcbc52;
                  border-left: 1px solid #bcbcbc52;
                  border-right: 1px solid #bcbcbc52;
                  border-bottom: 0px;
                  border-radius: 0px;
                  background: transparent;
                  
                  &.dx-state-hover{
                    background: #3e3e3e85;   
                  }

                  &.dx-state-focused{
                    background: #4d4d4d7a;    
                  }

                  &:after{
                   border-bottom:0px solid rgba(0,0,0,.42);
                  }
              }

              &:nth-child(3){

                 .dx-texteditor.dx-editor-filled:last-child{
                    border-bottom: 1px solid #bcbcbc52;
                    margin-bottom: 35px;
                  }

              }


              .dx-invalid-message-content{
                background-color: #363637;
                padding: 6px 15px;
                position: relative;
                left: 1px !important;
              }

        

        }

        .dx-texteditor-label{
          text-transform: uppercase;

          span{
            font-size:12px;
            font-family: "LatoWeb";
            color: #717171;
          }
          
        }

        .dx-field-item-required .dx-label > span::after{
         color: rgba(130, 130, 130, 0.6);
          }

    }


   .dx-flex-layout.dx-field-item{
     padding-top: 0px !important;
   }

}


</style>