import i18n from "./i18n"

const navMenu = () => ([
  {
    text: i18n.t('menu.send-new-file'),
    path: "/send",
    class: "menu-send",
  }
  , {
    text: i18n.t('menu.sended'),
    path: '/sendings',
    class: 'menu-all-send',
  }, {
    text: i18n.t('menu.received'),
    path: '/receiving',
    class: 'menu-all-receive',
  },
  // {
  //   text: i18n.t('menu.payment'),
  //   path: '/payment',
  //   class: 'menu-payment',
  // }, 
  // {
  //   text: i18n.t('menu.settings'),
  //   path: '/settings',
  //   class: 'menu-settings',
  // }, 
  {
    text: i18n.t('menu.profile'),
    path: '/profile',
    class: 'menu-account',
  }])

export { navMenu }

export default navMenu();
