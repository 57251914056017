<template>
    <div :class="cssClass">
        <DxScrollView
            :height="180"
            :scroll-by-content="true"
            :scroll-by-thumb="true"
            show-scrollbar="onScroll"
        >
            <div class="detail-container">
                <DxDataGrid
                    class="sendings-detail-grid"
                    :data-source="calculateStatutes"
                    :show-borders="false"
                    :show-column-headers="false"
                    :show-column-lines="false"
                    :show-row-lines="false"
                >
                    <DxColumn data-field="recipients" width="750" cell-template="recipientsTemplate" />
                    <DxColumn data-field="statutes" cell-template="statutesTemplate" />
                    <template #recipientsTemplate="{ data }">
                        <div class="detail detail-recipients">
                            <div class="mail">{{ data.data.mail }}</div>
                        </div>
                    </template>
                    <template #statutesTemplate="{ data }">
                        <div class="detail detail-files">
                            <RowDetailFileTemplate v-for="file in data.data.files" :key="`file-${data.data.id}-${file.id}`" :recipient-id="data.data.id" :file="file" />
                        </div>
                    </template>
                </DxDataGrid>
            </div>
        </DxScrollView>
    </div>
</template>

<script>
import RowDetailFileTemplate from '@/components/sendings/RowDetailFileTemplate'
import { DxScrollView } from 'devextreme-vue/scroll-view';
import {
  DxDataGrid,
  DxColumn,
} from 'devextreme-vue/data-grid'
import { areFilesDownloadedByStatutesAndRecipients } from '@/utils';

export default {
    name: "RowDetailTemplate",
    components: {
        RowDetailFileTemplate,
        DxScrollView,
        DxDataGrid,
        DxColumn,
    },
    props: {
        templateData: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        cssClass() {
            return {
                "sendings-detail-container": true,
                "sendings-detail-expired": this.expired,
                "sendings-detail-downloaded": !this.expired && this.downloaded
            }
        },
        downloaded() {
            return this.templateData.data.downloaded
        },
        expired() {
            return this.templateData.data.expired
        },
        files() {
            return this.templateData.data.files
        },
        recipients() {
            return this.templateData.data.recipients
        },
        statutes() {
            return this.templateData.data.statutes
        },
        calculateStatutes() {
            const calculateStatutes = areFilesDownloadedByStatutesAndRecipients(this.files, this.statutes, this.recipients)
            // console.log("calculateStatutes",calculateStatutes)
            return calculateStatutes
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .dx-datagrid  {
    background-color: transparent;

    .dx-data-row {
        border-bottom: none;
    }
}
.detail {
    display: flex;
    align-items: center;

    // height: 50px;
    width: 100%;
    // padding: 5px;

    &.detail-recipients {
        justify-content: center;
        margin-left: 150px;
        .mail {
            width: 200px;
        }
    }

    &.detail-files {
        justify-content: left;
        margin-left: 8px;
        .file {
            height: 20px;
            margin-right: 10px;

            &-not-downloaded {
                width: 28px;
                height: 0px;
                border-top: solid 1px #707070;
            }
            
            &-downloaded {
                width: 28px;
                height: 6px;
                border-radius: 14px;
                border: none;
                background-color: #00f885;

                // & span {
                //     display: none;
                // }

                // &:hover {
                //    position: relative; 

                //    & span {
                //     border: #666 2px dotted;
                //     padding: 5px 20px 5px 5px;
                //     display: block;
                //     z-index: 100;
                //     background: #e3e3e3;
                //     left: 0px;
                //     margin: 15px;
                //     width: 300px;
                //     position: absolute;
                //     top: 15px;
                //     text-decoration: none;
                //    }
                // }
            }
        }
    }
}
</style>