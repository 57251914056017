<template>
  <default-layout class="administration-container" :card="card">
    <DxMenu
      :data-source="menu"
      :show-first-submenu-mode="{ name: 'onClick', delay: { hide:300, show:50 } }"
      orientation="horizontal"
      :hide-submenu-on-mouse-leave="true"
      display-expr="name"
    />
    <div class="dx-card content">
      <users v-if="selectedMenu===ADMINCOMPONENTS.USERS"/>
      <locale v-if="selectedMenu===ADMINCOMPONENTS.LOCALE"/>
    </div>
  </default-layout>
</template>

<script>
import { ADMINCOMPONENTS } from '../utils'
import defaultLayout from '../layouts/default'
import DxMenu from 'devextreme-vue/menu';

import module_i18n from '../i18n'
import Users from '../components/users.vue';
import Locale from '../components/locale.vue';

export default {
  name: 'Administration',
  props: {
    card: {
      type: Boolean,
      default: () => (false)
    }
  },
  components: {
    defaultLayout,
    DxMenu,
    Users,
    Locale,
  },
  computed: {
    i18n() {
        return this.$i18n ? this.$i18n : this.module_i18n
    },
  },
  methods: {
    menuClick(e) {
      this.selectedMenu = e.itemData.name
    }
  },
  data() {
      return {
        module_i18n,
        ADMINCOMPONENTS,
        selectedMenu: ADMINCOMPONENTS.USERS,
        menu: [{
          id: '1',
          name: ADMINCOMPONENTS.USERS,
          onClick: this.menuClick
        },{
          id: '2',
          name: ADMINCOMPONENTS.LOCALE,
          onClick: this.menuClick
        }]
      }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dx-menu.dx-menu-base {
  margin-bottom: 30px;
  background-color: white;

  .dx-item-content {
    color: black;
    text-transform: uppercase;
    text-decoration: underline;
  } 
}
</style>