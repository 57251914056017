import layouts from './layouts'
import slots from './slots'
// import forms from './forms'

export { slots }
// export { forms }
export { layouts }

export const modulesOptions = {
    "authentication-basic": {
      "default": {
        components: {
          layout: layouts.defaultLayout
        }
      },
      "create-account": {
        components: {
          layout: layouts.defaultLayout,
        },
        props: {
          content: {
            // formItems: forms.createAccountForm
            slots: slots.createAccountForm
          }
        }
      },
      "login": {
        components: {
          layout: layouts.defaultLayout
        },
        props: {
          content: {
            showRemember: false,
            // slots: slots.loginForm
          }
        }
      },
      "reset-password": {
        components: {
          layout: layouts.defaultLayout,
        },
        // props: {
        //   content: {
        //     slots: slots.resetPasswordForm
        //   }
        // }
      },
      "profile": {
        components: {
          layout: layouts.appLayout,
        },
        props: {
          content: {
            type: 1, // CREATE
            slots: slots.createAccountForm
          }
        }
      },
    }
}

export default { 
  layouts, 
  slots, 
  // forms, 
  modulesOptions 
}