<template>
  <dx-form
    @initialized="onFormInitialized"
    :form-data="forwardFormData"
    :disabled="loading"
    :show-colon-after-label="showColonAfterLabel"
    :label-mode="labelMode"
    :label-location="labelLocation"
    @fieldDataChanged="onFormValueChanged"
  >
     <DxTabbedItem v-if="!isCreateMode">
        <DxTabPanelOptions
            :defer-rendering="false"
        />
          <DxTab :title="t('create-account.info')">
            <slot name="info" />
            <slot name="buttonSubmit" />
          </DxTab>
          <DxTab key="tab-addresses" :title="t('create-account.addresses')">
            <slot name="address" />
            <slot name="buttonSubmit" />
          </DxTab>
          <DxTab key="tab-subscription" :title="t('create-account.subscription')" v-if="isSubscriptionEnabled">
            <slot name="subscription" />          
          </DxTab>
    </DxTabbedItem>

    <slot name="info" v-else />

    <slot v-if="isCreateMode" name="buttonSubmit" />

    <!-- <dx-button-item>
      <dx-button-options
        width="100%"
        type="normal"
        template="createAccount"
        :use-submit-behavior="true"
      >
      </dx-button-options>
    </dx-button-item> 
    -->
    <template #createAccount>
      <div>
        <span class="dx-button-text">
            <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">{{ t('authentication-basic.create-account.submit') }}</span>
        </span>
      </div>
    </template>

    <template #changePassword>
      <div>
        <span class="dx-button-text">
            <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">{{ t('authentication-basic.common.change-password') }}</span>
        </span>
      </div>
    </template>
    <template #logOut>
      <div>
        <span class="dx-button-text">
            <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">{{ t('authentication-basic.logout.button') }}</span>
        </span>
      </div>
    </template>

    <dx-item v-if="isCreateMode">
      <template #default>
        <div class="login-link">
          {{t('authentication-basic.create-account.have_account')}}? <router-link class="arrow-to-right" to="/login">{{t('authentication-basic.common.signin')}}</router-link>
          <!-- <div>TEST : <router-link to="/create-account-confirm?createState=1">CONFIRM</router-link> / <router-link to="/create-account-confirm?createState=2">ERROR</router-link></div> -->
        </div>
      </template>
    </dx-item>
    
  </dx-form>
</template>

<script>
import { formTypes } from '@/modules'
import { i18n } from '@/modules/authentication-basic'
import DxForm, {
  DxItem,
  // DxButtonItem,
  // DxButtonOptions,
  ///////////////////
  DxTabbedItem,
  DxTabPanelOptions,
  DxTab,
  ///////////////////
  // DxCustomRule,
  // DxRequiredRule,
  // DxEmailRule
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import Config from '@/config.json'

export default {
    components: {
        DxForm,
        DxItem,
        // DxButtonItem,
        // DxButtonOptions,
        ///////////////////
        DxTabbedItem,
        DxTabPanelOptions,
        DxTab,
        ///////////////////
        // DxRequiredRule,
        // DxCustomRule,
        // DxEmailRule,
        DxLoadIndicator,
    },
    props: ['type','formData', 'loading', 'confirmPassword', "showColonAfterLabel", "labelMode", "labelLocation", "onFormValueChanged"],
    data() {
      return {
        i18n,
        selectedTab: null
      }
    },
    computed: {
      isCreateMode() {
        return this.type === formTypes.CREATE
      },
      forwardFormData: {
        get() {
          return this.formData
        },
        set(value) {
          this.$emit('update:form-data',{...value})
        }
      },
      isSubscriptionEnabled() {
        return Config.subscription;
      }
    },
    methods: {
      onFormInitialized(e) {
        this.$emit('initialized', e)
      },
      test(e) {
        this.onFormValueChanged(e)
      },
      t(value) {
        return this.i18n.t(value) == value ? this.$i18n.t(value) : this.i18n.t(value)
      },
      // confirmPassword(e) {
      //   return e.value === this.formData.password;
      // }
    },
}
</script>