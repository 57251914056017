const axios = require('axios')
axios.interceptors.response.use(response => {
    // rafraîchit le jeton d'accès si nécessaire
    // refreshAccessToken(response.headers)
    console.log("------------------------------------------------ AXIOS RESPONSE", response)
    return response
}, error => {
    // rafraîchit le jeton d'accès si nécessaire
    // refreshAccessToken(error.response.headers)
    console.log("------------------------------------------------ AXIOS ERROR", error.response)
    return Promise.reject(error)
})

export { axios }