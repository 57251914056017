import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
const getPersistState = ()=>{
    if (process.env.NODE_ENV !== 'production') {
        return  {
            getItem: key => localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null,
            setItem: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
            removeItem: key => localStorage.removeItem(key)
        }
    }
    else {
        return  {
            getItem: key => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: key => ls.remove(key)
        }
    }
}

const securedLocalStorage = getPersistState()

export { securedLocalStorage,  getPersistState }

export default securedLocalStorage