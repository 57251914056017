<template>
    <div class="current-subscription-left">
        <div class="subscription-title">{{ i18n.t('subscription.title') }}</div>
        <div class="subscription-current-option">{{ current && current.option && current.option != '' ? i18n.t(`subscription.options.${current.option}`) : '' }}</div>
        <div class="subscription-current-date subscription-current-startdate">{{ i18n.t(`subscription.startdate`) }} {{ startDate }}</div>
        <div class="subscription-current-date subscription-current-renewdate">
            <span v-if="current.renewDate">{{ i18n.t(`subscription.renewdate`) }} {{ renewDate }}</span>
            <span v-else>&nbsp;</span>
        </div>
    </div>
</template>

<script>
import module_i18n from '../i18n'

export default {
    props: {
        current: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            module_i18n,
        }
    },
    computed: {
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
        startDate() {
            return this.$moment(this.current.startDate).format('YYYY/MM/DD')
            // return this.$moment(this.current.startDate).format('DD-MM-YYYY')
        },
        renewDate() {
            return this.$moment(this.current.renewDate).format('YYYY/MM/DD')
            // return this.$moment(this.current.renewDate).format('DD-MM-YYYY')
        },  
    }
}
</script>