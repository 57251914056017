export const list = [
    {
     "code": "AFG",
     "finance": {
      "currencies": [
       {
        "code": "AFN",
        "name": "Afghan afghani",
        "symbol": "؋"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/afg.svg",
     "geo": {
      "area": 652230,
      "borders": [
       "IRN",
       "PAK",
       "TKM",
       "UZB",
       "TJK",
       "CHN"
      ],
      "capital": "Kabul",
      "latlng": [
       33,
       65
      ],
      "population": 27657145,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+04:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".af"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ps",
        "iso639_2": "pus",
        "name": "Pashto",
        "nativeName": "پښتو"
       },
       {
        "iso639_1": "uz",
        "iso639_2": "uzb",
        "name": "Uzbek",
        "nativeName": "Oʻzbek"
       },
       {
        "iso639_1": "tk",
        "iso639_2": "tuk",
        "name": "Turkmen",
        "nativeName": "Türkmen"
       }
      ],
      "translations": {
       "br": "Afeganistão",
       "de": "Afghanistan",
       "es": "Afganistán",
       "fa": "افغانستان",
       "fr": "Afghanistan",
       "hr": "Afganistan",
       "it": "Afghanistan",
       "ja": "アフガニスタン",
       "nl": "Afghanistan",
       "pt": "Afeganistão"
      }
     },
     "name": "Afghanistan",
     "phoneprefix": [
      93
     ]
    },
    {
     "code": "ALA",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ala.svg",
     "geo": {
      "area": 1580,
      "borders": [],
      "capital": "Mariehamn",
      "latlng": [
       60.116667,
       19.9
      ],
      "population": 28875,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ax"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sv",
        "iso639_2": "swe",
        "name": "Swedish",
        "nativeName": "svenska"
       }
      ],
      "translations": {
       "br": "Ilhas de Aland",
       "de": "Åland",
       "es": "Alandia",
       "fa": "جزایر الند",
       "fr": "Åland",
       "hr": "Ålandski otoci",
       "it": "Isole Aland",
       "ja": "オーランド諸島",
       "nl": "Ålandeilanden",
       "pt": "Ilhas de Aland"
      }
     },
     "name": "Åland",
     "phoneprefix": [
      358
     ]
    },
    {
     "code": "ALB",
     "finance": {
      "currencies": [
       {
        "code": "ALL",
        "name": "Albanian lek",
        "symbol": "L"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/alb.svg",
     "geo": {
      "area": 28748,
      "borders": [
       "MNE",
       "GRC",
       "MKD",
       "KOS"
      ],
      "capital": "Tirana",
      "latlng": [
       41,
       20
      ],
      "population": 2886026,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".al"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sq",
        "iso639_2": "sqi",
        "name": "Albanian",
        "nativeName": "Shqip"
       }
      ],
      "translations": {
       "br": "Albânia",
       "de": "Albanien",
       "es": "Albania",
       "fa": "آلبانی",
       "fr": "Albanie",
       "hr": "Albanija",
       "it": "Albania",
       "ja": "アルバニア",
       "nl": "Albanië",
       "pt": "Albânia"
      }
     },
     "name": "Albanie",
     "phoneprefix": [
      355
     ]
    },
    {
     "code": "DZA",
     "finance": {
      "currencies": [
       {
        "code": "DZD",
        "name": "Algerian dinar",
        "symbol": "د.ج"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/dza.svg",
     "geo": {
      "area": 2381741,
      "borders": [
       "TUN",
       "LBY",
       "NER",
       "ESH",
       "MRT",
       "MLI",
       "MAR"
      ],
      "capital": "Algiers",
      "latlng": [
       28,
       3
      ],
      "population": 40400000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".dz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Argélia",
       "de": "Algerien",
       "es": "Argelia",
       "fa": "الجزایر",
       "fr": "Algérie",
       "hr": "Alžir",
       "it": "Algeria",
       "ja": "アルジェリア",
       "nl": "Algerije",
       "pt": "Argélia"
      }
     },
     "name": "Algérie",
     "phoneprefix": [
      213
     ]
    },
    {
     "code": "ASM",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United State Dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/asm.svg",
     "geo": {
      "area": 199,
      "borders": [],
      "capital": "Pago Pago",
      "latlng": [
       -14.33333333,
       -170
      ],
      "population": 57100,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC-11:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".as"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "sm",
        "iso639_2": "smo",
        "name": "Samoan",
        "nativeName": "gagana fa'a Samoa"
       }
      ],
      "translations": {
       "br": "Samoa Americana",
       "de": "Amerikanisch-Samoa",
       "es": "Samoa Americana",
       "fa": "ساموآی آمریکا",
       "fr": "Samoa américaines",
       "hr": "Američka Samoa",
       "it": "Samoa Americane",
       "ja": "アメリカ領サモア",
       "nl": "Amerikaans Samoa",
       "pt": "Samoa Americana"
      }
     },
     "name": "Samoa américaines",
     "phoneprefix": [
      1684
     ]
    },
    {
     "code": "AND",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/and.svg",
     "geo": {
      "area": 468,
      "borders": [
       "FRA",
       "ESP"
      ],
      "capital": "Andorra la Vella",
      "latlng": [
       42.5,
       1.5
      ],
      "population": 78014,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ad"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ca",
        "iso639_2": "cat",
        "name": "Catalan",
        "nativeName": "català"
       }
      ],
      "translations": {
       "br": "Andorra",
       "de": "Andorra",
       "es": "Andorra",
       "fa": "آندورا",
       "fr": "Andorre",
       "hr": "Andora",
       "it": "Andorra",
       "ja": "アンドラ",
       "nl": "Andorra",
       "pt": "Andorra"
      }
     },
     "name": "Andorre",
     "phoneprefix": [
      376
     ]
    },
    {
     "code": "AGO",
     "finance": {
      "currencies": [
       {
        "code": "AOA",
        "name": "Angolan kwanza",
        "symbol": "Kz"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ago.svg",
     "geo": {
      "area": 1246700,
      "borders": [
       "COG",
       "COD",
       "ZMB",
       "NAM"
      ],
      "capital": "Luanda",
      "latlng": [
       -12.5,
       18.5
      ],
      "population": 25868000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ao"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Angola",
       "de": "Angola",
       "es": "Angola",
       "fa": "آنگولا",
       "fr": "Angola",
       "hr": "Angola",
       "it": "Angola",
       "ja": "アンゴラ",
       "nl": "Angola",
       "pt": "Angola"
      }
     },
     "name": "Angola",
     "phoneprefix": [
      244
     ]
    },
    {
     "code": "AIA",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/aia.svg",
     "geo": {
      "area": 91,
      "borders": [],
      "capital": "The Valley",
      "latlng": [
       18.25,
       -63.16666666
      ],
      "population": 13452,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ai"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Anguila",
       "de": "Anguilla",
       "es": "Anguilla",
       "fa": "آنگویلا",
       "fr": "Anguilla",
       "hr": "Angvila",
       "it": "Anguilla",
       "ja": "アンギラ",
       "nl": "Anguilla",
       "pt": "Anguila"
      }
     },
     "name": "Anguilla",
     "phoneprefix": [
      1264
     ]
    },
    {
     "code": "ATA",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       },
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ata.svg",
     "geo": {
      "area": 14000000,
      "borders": [],
      "capital": "",
      "latlng": [
       -74.65,
       4.48
      ],
      "population": 1000,
      "region": "Polar",
      "regionalBlocs": [],
      "subregion": "",
      "timezones": [
       "UTC-03:00",
       "UTC+03:00",
       "UTC+05:00",
       "UTC+06:00",
       "UTC+07:00",
       "UTC+08:00",
       "UTC+10:00",
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".aq"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Antártida",
       "de": "Antarktika",
       "es": "Antártida",
       "fa": "جنوبگان",
       "fr": "Antarctique",
       "hr": "Antarktika",
       "it": "Antartide",
       "ja": "南極大陸",
       "nl": "Antarctica",
       "pt": "Antárctida"
      }
     },
     "name": "Antarctique",
     "phoneprefix": [
      672
     ]
    },
    {
     "code": "ATG",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/atg.svg",
     "geo": {
      "area": 442,
      "borders": [],
      "capital": "Saint John's",
      "latlng": [
       17.05,
       -61.8
      ],
      "population": 86295,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ag"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Antígua e Barbuda",
       "de": "Antigua und Barbuda",
       "es": "Antigua y Barbuda",
       "fa": "آنتیگوا و باربودا",
       "fr": "Antigua-et-Barbuda",
       "hr": "Antigva i Barbuda",
       "it": "Antigua e Barbuda",
       "ja": "アンティグア・バーブーダ",
       "nl": "Antigua en Barbuda",
       "pt": "Antígua e Barbuda"
      }
     },
     "name": "Antigua-et-Barbuda",
     "phoneprefix": [
      1268
     ]
    },
    {
     "code": "ARG",
     "finance": {
      "currencies": [
       {
        "code": "ARS",
        "name": "Argentine peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/arg.svg",
     "geo": {
      "area": 2780400,
      "borders": [
       "BOL",
       "BRA",
       "CHL",
       "PRY",
       "URY"
      ],
      "capital": "Buenos Aires",
      "latlng": [
       -34,
       -64
      ],
      "population": 43590400,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ar"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       },
       {
        "iso639_1": "gn",
        "iso639_2": "grn",
        "name": "Guaraní",
        "nativeName": "Avañe'ẽ"
       }
      ],
      "translations": {
       "br": "Argentina",
       "de": "Argentinien",
       "es": "Argentina",
       "fa": "آرژانتین",
       "fr": "Argentine",
       "hr": "Argentina",
       "it": "Argentina",
       "ja": "アルゼンチン",
       "nl": "Argentinië",
       "pt": "Argentina"
      }
     },
     "name": "Argentine",
     "phoneprefix": [
      54
     ]
    },
    {
     "code": "ARM",
     "finance": {
      "currencies": [
       {
        "code": "AMD",
        "name": "Armenian dram",
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/arm.svg",
     "geo": {
      "area": 29743,
      "borders": [
       "AZE",
       "GEO",
       "IRN",
       "TUR"
      ],
      "capital": "Yerevan",
      "latlng": [
       40,
       45
      ],
      "population": 2994400,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "EEU",
        "name": "Eurasian Economic Union",
        "otherAcronyms": [
         "EAEU"
        ],
        "otherNames": []
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".am"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "hy",
        "iso639_2": "hye",
        "name": "Armenian",
        "nativeName": "Հայերեն"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Armênia",
       "de": "Armenien",
       "es": "Armenia",
       "fa": "ارمنستان",
       "fr": "Arménie",
       "hr": "Armenija",
       "it": "Armenia",
       "ja": "アルメニア",
       "nl": "Armenië",
       "pt": "Arménia"
      }
     },
     "name": "Arménie",
     "phoneprefix": [
      374
     ]
    },
    {
     "code": "ABW",
     "finance": {
      "currencies": [
       {
        "code": "AWG",
        "name": "Aruban florin",
        "symbol": "ƒ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/abw.svg",
     "geo": {
      "area": 180,
      "borders": [],
      "capital": "Oranjestad",
      "latlng": [
       12.5,
       -69.96666666
      ],
      "population": 107394,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".aw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       },
       {
        "iso639_1": "pa",
        "iso639_2": "pan",
        "name": "(Eastern) Punjabi",
        "nativeName": "ਪੰਜਾਬੀ"
       }
      ],
      "translations": {
       "br": "Aruba",
       "de": "Aruba",
       "es": "Aruba",
       "fa": "آروبا",
       "fr": "Aruba",
       "hr": "Aruba",
       "it": "Aruba",
       "ja": "アルバ",
       "nl": "Aruba",
       "pt": "Aruba"
      }
     },
     "name": "Aruba",
     "phoneprefix": [
      297
     ]
    },
    {
     "code": "AUS",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/aus.svg",
     "geo": {
      "area": 7692024,
      "borders": [],
      "capital": "Canberra",
      "latlng": [
       -27,
       133
      ],
      "population": 24117360,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Australia and New Zealand",
      "timezones": [
       "UTC+05:00",
       "UTC+06:30",
       "UTC+07:00",
       "UTC+08:00",
       "UTC+09:30",
       "UTC+10:00",
       "UTC+10:30",
       "UTC+11:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".au"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Austrália",
       "de": "Australien",
       "es": "Australia",
       "fa": "استرالیا",
       "fr": "Australie",
       "hr": "Australija",
       "it": "Australia",
       "ja": "オーストラリア",
       "nl": "Australië",
       "pt": "Austrália"
      }
     },
     "name": "Australie",
     "phoneprefix": [
      61
     ]
    },
    {
     "code": "AUT",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/aut.svg",
     "geo": {
      "area": 83871,
      "borders": [
       "CZE",
       "DEU",
       "HUN",
       "ITA",
       "LIE",
       "SVK",
       "SVN",
       "CHE"
      ],
      "capital": "Vienna",
      "latlng": [
       47.33333333,
       13.33333333
      ],
      "population": 8725931,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".at"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       }
      ],
      "translations": {
       "br": "áustria",
       "de": "Österreich",
       "es": "Austria",
       "fa": "اتریش",
       "fr": "Autriche",
       "hr": "Austrija",
       "it": "Austria",
       "ja": "オーストリア",
       "nl": "Oostenrijk",
       "pt": "áustria"
      }
     },
     "name": "Autriche",
     "phoneprefix": [
      43
     ]
    },
    {
     "code": "AZE",
     "finance": {
      "currencies": [
       {
        "code": "AZN",
        "name": "Azerbaijani manat",
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/aze.svg",
     "geo": {
      "area": 86600,
      "borders": [
       "ARM",
       "GEO",
       "IRN",
       "RUS",
       "TUR"
      ],
      "capital": "Baku",
      "latlng": [
       40.5,
       47.5
      ],
      "population": 9730500,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".az"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "az",
        "iso639_2": "aze",
        "name": "Azerbaijani",
        "nativeName": "azərbaycan dili"
       }
      ],
      "translations": {
       "br": "Azerbaijão",
       "de": "Aserbaidschan",
       "es": "Azerbaiyán",
       "fa": "آذربایجان",
       "fr": "Azerbaïdjan",
       "hr": "Azerbajdžan",
       "it": "Azerbaijan",
       "ja": "アゼルバイジャン",
       "nl": "Azerbeidzjan",
       "pt": "Azerbaijão"
      }
     },
     "name": "Azerbaïdjan",
     "phoneprefix": [
      994
     ]
    },
    {
     "code": "BHS",
     "finance": {
      "currencies": [
       {
        "code": "BSD",
        "name": "Bahamian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bhs.svg",
     "geo": {
      "area": 13943,
      "borders": [],
      "capital": "Nassau",
      "latlng": [
       24.25,
       -76
      ],
      "population": 378040,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bs"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Bahamas",
       "de": "Bahamas",
       "es": "Bahamas",
       "fa": "باهاما",
       "fr": "Bahamas",
       "hr": "Bahami",
       "it": "Bahamas",
       "ja": "バハマ",
       "nl": "Bahama’s",
       "pt": "Baamas"
      }
     },
     "name": "Bahamas",
     "phoneprefix": [
      1242
     ]
    },
    {
     "code": "BHR",
     "finance": {
      "currencies": [
       {
        "code": "BHD",
        "name": "Bahraini dinar",
        "symbol": ".د.ب"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bhr.svg",
     "geo": {
      "area": 765,
      "borders": [],
      "capital": "Manama",
      "latlng": [
       26,
       50.55
      ],
      "population": 1404900,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bh"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Bahrein",
       "de": "Bahrain",
       "es": "Bahrein",
       "fa": "بحرین",
       "fr": "Bahreïn",
       "hr": "Bahrein",
       "it": "Bahrein",
       "ja": "バーレーン",
       "nl": "Bahrein",
       "pt": "Barém"
      }
     },
     "name": "Bahreïn",
     "phoneprefix": [
      973
     ]
    },
    {
     "code": "BGD",
     "finance": {
      "currencies": [
       {
        "code": "BDT",
        "name": "Bangladeshi taka",
        "symbol": "৳"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bgd.svg",
     "geo": {
      "area": 147570,
      "borders": [
       "MMR",
       "IND"
      ],
      "capital": "Dhaka",
      "latlng": [
       24,
       90
      ],
      "population": 161006790,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bd"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "bn",
        "iso639_2": "ben",
        "name": "Bengali",
        "nativeName": "বাংলা"
       }
      ],
      "translations": {
       "br": "Bangladesh",
       "de": "Bangladesch",
       "es": "Bangladesh",
       "fa": "بنگلادش",
       "fr": "Bangladesh",
       "hr": "Bangladeš",
       "it": "Bangladesh",
       "ja": "バングラデシュ",
       "nl": "Bangladesh",
       "pt": "Bangladeche"
      }
     },
     "name": "Bangladesh",
     "phoneprefix": [
      880
     ]
    },
    {
     "code": "BRB",
     "finance": {
      "currencies": [
       {
        "code": "BBD",
        "name": "Barbadian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/brb.svg",
     "geo": {
      "area": 430,
      "borders": [],
      "capital": "Bridgetown",
      "latlng": [
       13.16666666,
       -59.53333333
      ],
      "population": 285000,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bb"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Barbados",
       "de": "Barbados",
       "es": "Barbados",
       "fa": "باربادوس",
       "fr": "Barbade",
       "hr": "Barbados",
       "it": "Barbados",
       "ja": "バルバドス",
       "nl": "Barbados",
       "pt": "Barbados"
      }
     },
     "name": "Barbade",
     "phoneprefix": [
      1246
     ]
    },
    {
     "code": "BLR",
     "finance": {
      "currencies": [
       {
        "code": "BYN",
        "name": "New Belarusian ruble",
        "symbol": "Br"
       },
       {
        "code": "BYR",
        "name": "Old Belarusian ruble",
        "symbol": "Br"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/blr.svg",
     "geo": {
      "area": 207600,
      "borders": [
       "LVA",
       "LTU",
       "POL",
       "RUS",
       "UKR"
      ],
      "capital": "Minsk",
      "latlng": [
       53,
       28
      ],
      "population": 9498700,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EEU",
        "name": "Eurasian Economic Union",
        "otherAcronyms": [
         "EAEU"
        ],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".by"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "be",
        "iso639_2": "bel",
        "name": "Belarusian",
        "nativeName": "беларуская мова"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Bielorrússia",
       "de": "Weißrussland",
       "es": "Bielorrusia",
       "fa": "بلاروس",
       "fr": "Biélorussie",
       "hr": "Bjelorusija",
       "it": "Bielorussia",
       "ja": "ベラルーシ",
       "nl": "Wit-Rusland",
       "pt": "Bielorrússia"
      }
     },
     "name": "Biélorussie",
     "phoneprefix": [
      375
     ]
    },
    {
     "code": "BEL",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bel.svg",
     "geo": {
      "area": 30528,
      "borders": [
       "FRA",
       "DEU",
       "LUX",
       "NLD"
      ],
      "capital": "Brussels",
      "latlng": [
       50.83333333,
       4
      ],
      "population": 11319511,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".be"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       }
      ],
      "translations": {
       "br": "Bélgica",
       "de": "Belgien",
       "es": "Bélgica",
       "fa": "بلژیک",
       "fr": "Belgique",
       "hr": "Belgija",
       "it": "Belgio",
       "ja": "ベルギー",
       "nl": "België",
       "pt": "Bélgica"
      }
     },
     "name": "Belgique",
     "phoneprefix": [
      32
     ]
    },
    {
     "code": "BLZ",
     "finance": {
      "currencies": [
       {
        "code": "BZD",
        "name": "Belize dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/blz.svg",
     "geo": {
      "area": 22966,
      "borders": [
       "GTM",
       "MEX"
      ],
      "capital": "Belmopan",
      "latlng": [
       17.25,
       -88.75
      ],
      "population": 370300,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       },
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Belize",
       "de": "Belize",
       "es": "Belice",
       "fa": "بلیز",
       "fr": "Belize",
       "hr": "Belize",
       "it": "Belize",
       "ja": "ベリーズ",
       "nl": "Belize",
       "pt": "Belize"
      }
     },
     "name": "Belize",
     "phoneprefix": [
      501
     ]
    },
    {
     "code": "BEN",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ben.svg",
     "geo": {
      "area": 112622,
      "borders": [
       "BFA",
       "NER",
       "NGA",
       "TGO"
      ],
      "capital": "Porto-Novo",
      "latlng": [
       9.5,
       2.25
      ],
      "population": 10653654,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bj"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Benin",
       "de": "Benin",
       "es": "Benín",
       "fa": "بنین",
       "fr": "Bénin",
       "hr": "Benin",
       "it": "Benin",
       "ja": "ベナン",
       "nl": "Benin",
       "pt": "Benim"
      }
     },
     "name": "Bénin",
     "phoneprefix": [
      229
     ]
    },
    {
     "code": "BMU",
     "finance": {
      "currencies": [
       {
        "code": "BMD",
        "name": "Bermudian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bmu.svg",
     "geo": {
      "area": 54,
      "borders": [],
      "capital": "Hamilton",
      "latlng": [
       32.33333333,
       -64.75
      ],
      "population": 61954,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Northern America",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Bermudas",
       "de": "Bermuda",
       "es": "Bermudas",
       "fa": "برمودا",
       "fr": "Bermudes",
       "hr": "Bermudi",
       "it": "Bermuda",
       "ja": "バミューダ",
       "nl": "Bermuda",
       "pt": "Bermudas"
      }
     },
     "name": "Bermudes",
     "phoneprefix": [
      1441
     ]
    },
    {
     "code": "BTN",
     "finance": {
      "currencies": [
       {
        "code": "BTN",
        "name": "Bhutanese ngultrum",
        "symbol": "Nu."
       },
       {
        "code": "INR",
        "name": "Indian rupee",
        "symbol": "₹"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/btn.svg",
     "geo": {
      "area": 38394,
      "borders": [
       "CHN",
       "IND"
      ],
      "capital": "Thimphu",
      "latlng": [
       27.5,
       90.5
      ],
      "population": 775620,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "dz",
        "iso639_2": "dzo",
        "name": "Dzongkha",
        "nativeName": "རྫོང་ཁ"
       }
      ],
      "translations": {
       "br": "Butão",
       "de": "Bhutan",
       "es": "Bután",
       "fa": "بوتان",
       "fr": "Bhoutan",
       "hr": "Butan",
       "it": "Bhutan",
       "ja": "ブータン",
       "nl": "Bhutan",
       "pt": "Butão"
      }
     },
     "name": "Bhoutan",
     "phoneprefix": [
      975
     ]
    },
    {
     "code": "BOL",
     "finance": {
      "currencies": [
       {
        "code": "BOB",
        "name": "Bolivian boliviano",
        "symbol": "Bs."
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bol.svg",
     "geo": {
      "area": 1098581,
      "borders": [
       "ARG",
       "BRA",
       "CHL",
       "PRY",
       "PER"
      ],
      "capital": "Sucre",
      "latlng": [
       -17,
       -65
      ],
      "population": 10985059,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bo"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       },
       {
        "iso639_1": "ay",
        "iso639_2": "aym",
        "name": "Aymara",
        "nativeName": "aymar aru"
       },
       {
        "iso639_1": "qu",
        "iso639_2": "que",
        "name": "Quechua",
        "nativeName": "Runa Simi"
       }
      ],
      "translations": {
       "br": "Bolívia",
       "de": "Bolivien",
       "es": "Bolivia",
       "fa": "بولیوی",
       "fr": "Bolivie",
       "hr": "Bolivija",
       "it": "Bolivia",
       "ja": "ボリビア多民族国",
       "nl": "Bolivia",
       "pt": "Bolívia"
      }
     },
     "name": "Bolivie",
     "phoneprefix": [
      591
     ]
    },
    {
     "code": "BES",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bes.svg",
     "geo": {
      "area": 294,
      "borders": [],
      "capital": "Kralendijk",
      "latlng": [
       12.15,
       -68.266667
      ],
      "population": 17408,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".an",
       ".nl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       }
      ],
      "translations": {
       "br": "Bonaire",
       "de": "Bonaire, Sint Eustatius und Saba",
       "es": null,
       "fa": "بونیر",
       "fr": "Bonaire, Saint-Eustache et Saba",
       "hr": null,
       "it": "Bonaire, Saint-Eustache e Saba",
       "ja": null,
       "nl": null,
       "pt": "Bonaire"
      }
     },
     "name": "Bonaire, Saint-Eustache et Saba",
     "phoneprefix": [
      5997
     ]
    },
    {
     "code": "BIH",
     "finance": {
      "currencies": [
       {
        "code": "BAM",
        "name": "Bosnia and Herzegovina convertible mark",
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bih.svg",
     "geo": {
      "area": 51209,
      "borders": [
       "HRV",
       "MNE",
       "SRB"
      ],
      "capital": "Sarajevo",
      "latlng": [
       44,
       18
      ],
      "population": 3531159,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ba"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "bs",
        "iso639_2": "bos",
        "name": "Bosnian",
        "nativeName": "bosanski jezik"
       },
       {
        "iso639_1": "hr",
        "iso639_2": "hrv",
        "name": "Croatian",
        "nativeName": "hrvatski jezik"
       },
       {
        "iso639_1": "sr",
        "iso639_2": "srp",
        "name": "Serbian",
        "nativeName": "српски језик"
       }
      ],
      "translations": {
       "br": "Bósnia e Herzegovina",
       "de": "Bosnien und Herzegowina",
       "es": "Bosnia y Herzegovina",
       "fa": "بوسنی و هرزگوین",
       "fr": "Bosnie-Herzégovine",
       "hr": "Bosna i Hercegovina",
       "it": "Bosnia ed Erzegovina",
       "ja": "ボスニア・ヘルツェゴビナ",
       "nl": "Bosnië en Herzegovina",
       "pt": "Bósnia e Herzegovina"
      }
     },
     "name": "Bosnie-Herzégovine",
     "phoneprefix": [
      387
     ]
    },
    {
     "code": "BWA",
     "finance": {
      "currencies": [
       {
        "code": "BWP",
        "name": "Botswana pula",
        "symbol": "P"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bwa.svg",
     "geo": {
      "area": 582000,
      "borders": [
       "NAM",
       "ZAF",
       "ZMB",
       "ZWE"
      ],
      "capital": "Gaborone",
      "latlng": [
       -22,
       24
      ],
      "population": 2141206,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Southern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "tn",
        "iso639_2": "tsn",
        "name": "Tswana",
        "nativeName": "Setswana"
       }
      ],
      "translations": {
       "br": "Botsuana",
       "de": "Botswana",
       "es": "Botswana",
       "fa": "بوتسوانا",
       "fr": "Botswana",
       "hr": "Bocvana",
       "it": "Botswana",
       "ja": "ボツワナ",
       "nl": "Botswana",
       "pt": "Botsuana"
      }
     },
     "name": "Botswana",
     "phoneprefix": [
      267
     ]
    },
    {
     "code": "BVT",
     "finance": {
      "currencies": [
       {
        "code": "NOK",
        "name": "Norwegian krone",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bvt.svg",
     "geo": {
      "area": 49,
      "borders": [],
      "capital": "",
      "latlng": [
       -54.43333333,
       3.4
      ],
      "population": 0,
      "region": "",
      "regionalBlocs": [],
      "subregion": "",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bv"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "no",
        "iso639_2": "nor",
        "name": "Norwegian",
        "nativeName": "Norsk"
       },
       {
        "iso639_1": "nb",
        "iso639_2": "nob",
        "name": "Norwegian Bokmål",
        "nativeName": "Norsk bokmål"
       },
       {
        "iso639_1": "nn",
        "iso639_2": "nno",
        "name": "Norwegian Nynorsk",
        "nativeName": "Norsk nynorsk"
       }
      ],
      "translations": {
       "br": "Ilha Bouvet",
       "de": "Bouvetinsel",
       "es": "Isla Bouvet",
       "fa": "جزیره بووه",
       "fr": "Île Bouvet",
       "hr": "Otok Bouvet",
       "it": "Isola Bouvet",
       "ja": "ブーベ島",
       "nl": "Bouveteiland",
       "pt": "Ilha Bouvet"
      }
     },
     "name": "Île Bouvet",
     "phoneprefix": [
      0
     ]
    },
    {
     "code": "BRA",
     "finance": {
      "currencies": [
       {
        "code": "BRL",
        "name": "Brazilian real",
        "symbol": "R$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bra.svg",
     "geo": {
      "area": 8515767,
      "borders": [
       "ARG",
       "BOL",
       "COL",
       "GUF",
       "GUY",
       "PRY",
       "PER",
       "SUR",
       "URY",
       "VEN"
      ],
      "capital": "Brasília",
      "latlng": [
       -10,
       -55
      ],
      "population": 206135893,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-05:00",
       "UTC-04:00",
       "UTC-03:00",
       "UTC-02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".br"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Brasil",
       "de": "Brasilien",
       "es": "Brasil",
       "fa": "برزیل",
       "fr": "Brésil",
       "hr": "Brazil",
       "it": "Brasile",
       "ja": "ブラジル",
       "nl": "Brazilië",
       "pt": "Brasil"
      }
     },
     "name": "Brésil",
     "phoneprefix": [
      55
     ]
    },
    {
     "code": "IOT",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/iot.svg",
     "geo": {
      "area": 60,
      "borders": [],
      "capital": "Diego Garcia",
      "latlng": [
       -6,
       71.5
      ],
      "population": 3000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".io"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Território Britânico do Oceano íÍdico",
       "de": "Britisches Territorium im Indischen Ozean",
       "es": "Territorio Británico del Océano Índico",
       "fa": "قلمرو بریتانیا در اقیانوس هند",
       "fr": "Territoire britannique de l'océan Indien",
       "hr": "Britanski Indijskooceanski teritorij",
       "it": "Territorio britannico dell'oceano indiano",
       "ja": "イギリス領インド洋地域",
       "nl": "Britse Gebieden in de Indische Oceaan",
       "pt": "Território Britânico do Oceano Índico"
      }
     },
     "name": "Territoire britannique de l'océan Indien",
     "phoneprefix": [
      246
     ]
    },
    {
     "code": "UMI",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States Dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/umi.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "",
      "latlng": [],
      "population": 300,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Northern America",
      "timezones": [
       "UTC-11:00",
       "UTC-10:00",
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".us"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Menores Distantes dos Estados Unidos",
       "de": "Kleinere Inselbesitzungen der Vereinigten Staaten",
       "es": "Islas Ultramarinas Menores de Estados Unidos",
       "fa": "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
       "fr": "Îles mineures éloignées des États-Unis",
       "hr": "Mali udaljeni otoci SAD-a",
       "it": "Isole minori esterne degli Stati Uniti d'America",
       "ja": "合衆国領有小離島",
       "nl": "Kleine afgelegen eilanden van de Verenigde Staten",
       "pt": "Ilhas Menores Distantes dos Estados Unidos"
      }
     },
     "name": "Îles mineures éloignées des États-Unis",
     "phoneprefix": [
      0
     ]
    },
    {
     "code": "VGB",
     "finance": {
      "currencies": [
       {
        "code": null,
        "name": "[D]",
        "symbol": "$"
       },
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/vgb.svg",
     "geo": {
      "area": 151,
      "borders": [],
      "capital": "Road Town",
      "latlng": [
       18.431383,
       -64.62305
      ],
      "population": 28514,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".vg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Virgens Britânicas",
       "de": "Britische Jungferninseln",
       "es": "Islas Vírgenes del Reino Unido",
       "fa": "جزایر ویرجین بریتانیا",
       "fr": "Îles Vierges britanniques",
       "hr": "Britanski Djevičanski Otoci",
       "it": "Isole Vergini Britanniche",
       "ja": "イギリス領ヴァージン諸島",
       "nl": "Britse Maagdeneilanden",
       "pt": "Ilhas Virgens Britânicas"
      }
     },
     "name": "Îles Vierges britanniques",
     "phoneprefix": [
      1284
     ]
    },
    {
     "code": "VIR",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/vir.svg",
     "geo": {
      "area": 346.36,
      "borders": [],
      "capital": "Charlotte Amalie",
      "latlng": [
       18.34,
       -64.93
      ],
      "population": 114743,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".vi"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Virgens Americanas",
       "de": "Amerikanische Jungferninseln",
       "es": "Islas Vírgenes de los Estados Unidos",
       "fa": "جزایر ویرجین آمریکا",
       "fr": "Îles Vierges des États-Unis",
       "hr": null,
       "it": "Isole Vergini americane",
       "ja": "アメリカ領ヴァージン諸島",
       "nl": "Verenigde Staten Maagdeneilanden",
       "pt": "Ilhas Virgens Americanas"
      }
     },
     "name": "Îles Vierges des États-Unis",
     "phoneprefix": [
      0
     ]
    },
    {
     "code": "BRN",
     "finance": {
      "currencies": [
       {
        "code": "BND",
        "name": "Brunei dollar",
        "symbol": "$"
       },
       {
        "code": "SGD",
        "name": "Singapore dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/brn.svg",
     "geo": {
      "area": 5765,
      "borders": [
       "MYS"
      ],
      "capital": "Bandar Seri Begawan",
      "latlng": [
       4.5,
       114.66666666
      ],
      "population": 411900,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ms",
        "iso639_2": "msa",
        "name": "Malay",
        "nativeName": "bahasa Melayu"
       }
      ],
      "translations": {
       "br": "Brunei",
       "de": "Brunei",
       "es": "Brunei",
       "fa": "برونئی",
       "fr": "Brunei",
       "hr": "Brunej",
       "it": "Brunei",
       "ja": "ブルネイ・ダルサラーム",
       "nl": "Brunei",
       "pt": "Brunei"
      }
     },
     "name": "Brunei",
     "phoneprefix": [
      673
     ]
    },
    {
     "code": "BGR",
     "finance": {
      "currencies": [
       {
        "code": "BGN",
        "name": "Bulgarian lev",
        "symbol": "лв"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bgr.svg",
     "geo": {
      "area": 110879,
      "borders": [
       "GRC",
       "MKD",
       "ROU",
       "SRB",
       "TUR"
      ],
      "capital": "Sofia",
      "latlng": [
       43,
       25
      ],
      "population": 7153784,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "bg",
        "iso639_2": "bul",
        "name": "Bulgarian",
        "nativeName": "български език"
       }
      ],
      "translations": {
       "br": "Bulgária",
       "de": "Bulgarien",
       "es": "Bulgaria",
       "fa": "بلغارستان",
       "fr": "Bulgarie",
       "hr": "Bugarska",
       "it": "Bulgaria",
       "ja": "ブルガリア",
       "nl": "Bulgarije",
       "pt": "Bulgária"
      }
     },
     "name": "Bulgarie",
     "phoneprefix": [
      359
     ]
    },
    {
     "code": "BFA",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bfa.svg",
     "geo": {
      "area": 272967,
      "borders": [
       "BEN",
       "CIV",
       "GHA",
       "MLI",
       "NER",
       "TGO"
      ],
      "capital": "Ouagadougou",
      "latlng": [
       13,
       -2
      ],
      "population": 19034397,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ff",
        "iso639_2": "ful",
        "name": "Fula",
        "nativeName": "Fulfulde"
       }
      ],
      "translations": {
       "br": "Burkina Faso",
       "de": "Burkina Faso",
       "es": "Burkina Faso",
       "fa": "بورکینافاسو",
       "fr": "Burkina Faso",
       "hr": "Burkina Faso",
       "it": "Burkina Faso",
       "ja": "ブルキナファソ",
       "nl": "Burkina Faso",
       "pt": "Burquina Faso"
      }
     },
     "name": "Burkina Faso",
     "phoneprefix": [
      226
     ]
    },
    {
     "code": "BDI",
     "finance": {
      "currencies": [
       {
        "code": "BIF",
        "name": "Burundian franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/bdi.svg",
     "geo": {
      "area": 27834,
      "borders": [
       "COD",
       "RWA",
       "TZA"
      ],
      "capital": "Bujumbura",
      "latlng": [
       -3.5,
       30
      ],
      "population": 10114505,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bi"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "rn",
        "iso639_2": "run",
        "name": "Kirundi",
        "nativeName": "Ikirundi"
       }
      ],
      "translations": {
       "br": "Burundi",
       "de": "Burundi",
       "es": "Burundi",
       "fa": "بوروندی",
       "fr": "Burundi",
       "hr": "Burundi",
       "it": "Burundi",
       "ja": "ブルンジ",
       "nl": "Burundi",
       "pt": "Burúndi"
      }
     },
     "name": "Burundi",
     "phoneprefix": [
      257
     ]
    },
    {
     "code": "KHM",
     "finance": {
      "currencies": [
       {
        "code": "KHR",
        "name": "Cambodian riel",
        "symbol": "៛"
       },
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/khm.svg",
     "geo": {
      "area": 181035,
      "borders": [
       "LAO",
       "THA",
       "VNM"
      ],
      "capital": "Phnom Penh",
      "latlng": [
       13,
       105
      ],
      "population": 15626444,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+07:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kh"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "km",
        "iso639_2": "khm",
        "name": "Khmer",
        "nativeName": "ខ្មែរ"
       }
      ],
      "translations": {
       "br": "Camboja",
       "de": "Kambodscha",
       "es": "Camboya",
       "fa": "کامبوج",
       "fr": "Cambodge",
       "hr": "Kambodža",
       "it": "Cambogia",
       "ja": "カンボジア",
       "nl": "Cambodja",
       "pt": "Camboja"
      }
     },
     "name": "Cambodge",
     "phoneprefix": [
      855
     ]
    },
    {
     "code": "CMR",
     "finance": {
      "currencies": [
       {
        "code": "XAF",
        "name": "Central African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cmr.svg",
     "geo": {
      "area": 475442,
      "borders": [
       "CAF",
       "TCD",
       "COG",
       "GNQ",
       "GAB",
       "NGA"
      ],
      "capital": "Yaoundé",
      "latlng": [
       6,
       12
      ],
      "population": 22709892,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Camarões",
       "de": "Kamerun",
       "es": "Camerún",
       "fa": "کامرون",
       "fr": "Cameroun",
       "hr": "Kamerun",
       "it": "Camerun",
       "ja": "カメルーン",
       "nl": "Kameroen",
       "pt": "Camarões"
      }
     },
     "name": "Cameroun",
     "phoneprefix": [
      237
     ]
    },
    {
     "code": "CAN",
     "finance": {
      "currencies": [
       {
        "code": "CAD",
        "name": "Canadian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/can.svg",
     "geo": {
      "area": 9984670,
      "borders": [
       "USA"
      ],
      "capital": "Ottawa",
      "latlng": [
       60,
       -95
      ],
      "population": 36155487,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "NAFTA",
        "name": "North American Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": [
         "Tratado de Libre Comercio de América del Norte",
         "Accord de Libre-échange Nord-Américain"
        ]
       }
      ],
      "subregion": "Northern America",
      "timezones": [
       "UTC-08:00",
       "UTC-07:00",
       "UTC-06:00",
       "UTC-05:00",
       "UTC-04:00",
       "UTC-03:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ca"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Canadá",
       "de": "Kanada",
       "es": "Canadá",
       "fa": "کانادا",
       "fr": "Canada",
       "hr": "Kanada",
       "it": "Canada",
       "ja": "カナダ",
       "nl": "Canada",
       "pt": "Canadá"
      }
     },
     "name": "Canada",
     "phoneprefix": [
      1
     ]
    },
    {
     "code": "CPV",
     "finance": {
      "currencies": [
       {
        "code": "CVE",
        "name": "Cape Verdean escudo",
        "symbol": "Esc"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cpv.svg",
     "geo": {
      "area": 4033,
      "borders": [],
      "capital": "Praia",
      "latlng": [
       16,
       -24
      ],
      "population": 531239,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC-01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cv"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Cabo Verde",
       "de": "Kap Verde",
       "es": "Cabo Verde",
       "fa": "کیپ ورد",
       "fr": "Cap Vert",
       "hr": "Zelenortska Republika",
       "it": "Capo Verde",
       "ja": "カーボベルデ",
       "nl": "Kaapverdië",
       "pt": "Cabo Verde"
      }
     },
     "name": "Cap Vert",
     "phoneprefix": [
      238
     ]
    },
    {
     "code": "CYM",
     "finance": {
      "currencies": [
       {
        "code": "KYD",
        "name": "Cayman Islands dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cym.svg",
     "geo": {
      "area": 264,
      "borders": [],
      "capital": "George Town",
      "latlng": [
       19.5,
       -80.5
      ],
      "population": 58238,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ky"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Cayman",
       "de": "Kaimaninseln",
       "es": "Islas Caimán",
       "fa": "جزایر کیمن",
       "fr": "Îles Caïmans",
       "hr": "Kajmanski otoci",
       "it": "Isole Cayman",
       "ja": "ケイマン諸島",
       "nl": "Caymaneilanden",
       "pt": "Ilhas Caimão"
      }
     },
     "name": "Îles Caïmans",
     "phoneprefix": [
      1345
     ]
    },
    {
     "code": "CAF",
     "finance": {
      "currencies": [
       {
        "code": "XAF",
        "name": "Central African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/caf.svg",
     "geo": {
      "area": 622984,
      "borders": [
       "CMR",
       "TCD",
       "COD",
       "COG",
       "SSD",
       "SDN"
      ],
      "capital": "Bangui",
      "latlng": [
       7,
       21
      ],
      "population": 4998000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "sg",
        "iso639_2": "sag",
        "name": "Sango",
        "nativeName": "yângâ tî sängö"
       }
      ],
      "translations": {
       "br": "República Centro-Africana",
       "de": "Zentralafrikanische Republik",
       "es": "República Centroafricana",
       "fa": "جمهوری آفریقای مرکزی",
       "fr": "République centrafricaine",
       "hr": "Srednjoafrička Republika",
       "it": "Repubblica Centrafricana",
       "ja": "中央アフリカ共和国",
       "nl": "Centraal-Afrikaanse Republiek",
       "pt": "República Centro-Africana"
      }
     },
     "name": "République centrafricaine",
     "phoneprefix": [
      236
     ]
    },
    {
     "code": "TCD",
     "finance": {
      "currencies": [
       {
        "code": "XAF",
        "name": "Central African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tcd.svg",
     "geo": {
      "area": 1284000,
      "borders": [
       "CMR",
       "CAF",
       "LBY",
       "NER",
       "NGA",
       "SSD"
      ],
      "capital": "N'Djamena",
      "latlng": [
       15,
       19
      ],
      "population": 14497000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".td"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Chade",
       "de": "Tschad",
       "es": "Chad",
       "fa": "چاد",
       "fr": "Tchad",
       "hr": "Čad",
       "it": "Ciad",
       "ja": "チャド",
       "nl": "Tsjaad",
       "pt": "Chade"
      }
     },
     "name": "Tchad",
     "phoneprefix": [
      235
     ]
    },
    {
     "code": "CHL",
     "finance": {
      "currencies": [
       {
        "code": "CLP",
        "name": "Chilean peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/chl.svg",
     "geo": {
      "area": 756102,
      "borders": [
       "ARG",
       "BOL",
       "PER"
      ],
      "capital": "Santiago",
      "latlng": [
       -30,
       -71
      ],
      "population": 18191900,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "PA",
        "name": "Pacific Alliance",
        "otherAcronyms": [],
        "otherNames": [
         "Alianza del Pacífico"
        ]
       },
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-06:00",
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Chile",
       "de": "Chile",
       "es": "Chile",
       "fa": "شیلی",
       "fr": "Chili",
       "hr": "Čile",
       "it": "Cile",
       "ja": "チリ",
       "nl": "Chili",
       "pt": "Chile"
      }
     },
     "name": "Chili",
     "phoneprefix": [
      56
     ]
    },
    {
     "code": "CHN",
     "finance": {
      "currencies": [
       {
        "code": "CNY",
        "name": "Chinese yuan",
        "symbol": "¥"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/chn.svg",
     "geo": {
      "area": 9640011,
      "borders": [
       "AFG",
       "BTN",
       "MMR",
       "HKG",
       "IND",
       "KAZ",
       "PRK",
       "KGZ",
       "LAO",
       "MAC",
       "MNG",
       "PAK",
       "RUS",
       "TJK",
       "VNM"
      ],
      "capital": "Beijing",
      "latlng": [
       35,
       105
      ],
      "population": 1377422166,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "zh",
        "iso639_2": "zho",
        "name": "Chinese",
        "nativeName": "中文 (Zhōngwén)"
       }
      ],
      "translations": {
       "br": "China",
       "de": "China",
       "es": "China",
       "fa": "چین",
       "fr": "Chine",
       "hr": "Kina",
       "it": "Cina",
       "ja": "中国",
       "nl": "China",
       "pt": "China"
      }
     },
     "name": "Chine",
     "phoneprefix": [
      86
     ]
    },
    {
     "code": "CXR",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cxr.svg",
     "geo": {
      "area": 135,
      "borders": [],
      "capital": "Flying Fish Cove",
      "latlng": [
       -10.5,
       105.66666666
      ],
      "population": 2072,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Australia and New Zealand",
      "timezones": [
       "UTC+07:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cx"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilha Christmas",
       "de": "Weihnachtsinsel",
       "es": "Isla de Navidad",
       "fa": "جزیره کریسمس",
       "fr": "Île Christmas",
       "hr": "Božićni otok",
       "it": "Isola di Natale",
       "ja": "クリスマス島",
       "nl": "Christmaseiland",
       "pt": "Ilha do Natal"
      }
     },
     "name": "Île Christmas",
     "phoneprefix": [
      61
     ]
    },
    {
     "code": "CCK",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cck.svg",
     "geo": {
      "area": 14,
      "borders": [],
      "capital": "West Island",
      "latlng": [
       -12.5,
       96.83333333
      ],
      "population": 550,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Australia and New Zealand",
      "timezones": [
       "UTC+06:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Cocos",
       "de": "Kokosinseln",
       "es": "Islas Cocos o Islas Keeling",
       "fa": "جزایر کوکوس",
       "fr": "Îles Cocos",
       "hr": "Kokosovi Otoci",
       "it": "Isole Cocos e Keeling",
       "ja": "ココス（キーリング）諸島",
       "nl": "Cocoseilanden",
       "pt": "Ilhas dos Cocos"
      }
     },
     "name": "Îles Cocos",
     "phoneprefix": [
      61
     ]
    },
    {
     "code": "COL",
     "finance": {
      "currencies": [
       {
        "code": "COP",
        "name": "Colombian peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/col.svg",
     "geo": {
      "area": 1141748,
      "borders": [
       "BRA",
       "ECU",
       "PAN",
       "PER",
       "VEN"
      ],
      "capital": "Bogotá",
      "latlng": [
       4,
       -72
      ],
      "population": 48759958,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "PA",
        "name": "Pacific Alliance",
        "otherAcronyms": [],
        "otherNames": [
         "Alianza del Pacífico"
        ]
       },
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".co"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Colômbia",
       "de": "Kolumbien",
       "es": "Colombia",
       "fa": "کلمبیا",
       "fr": "Colombie",
       "hr": "Kolumbija",
       "it": "Colombia",
       "ja": "コロンビア",
       "nl": "Colombia",
       "pt": "Colômbia"
      }
     },
     "name": "Colombie",
     "phoneprefix": [
      57
     ]
    },
    {
     "code": "COM",
     "finance": {
      "currencies": [
       {
        "code": "KMF",
        "name": "Comorian franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/com.svg",
     "geo": {
      "area": 1862,
      "borders": [],
      "capital": "Moroni",
      "latlng": [
       -12.16666666,
       44.25
      ],
      "population": 806153,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".km"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Comores",
       "de": "Union der Komoren",
       "es": "Comoras",
       "fa": "کومور",
       "fr": "Comores",
       "hr": "Komori",
       "it": "Comore",
       "ja": "コモロ",
       "nl": "Comoren",
       "pt": "Comores"
      }
     },
     "name": "Comores",
     "phoneprefix": [
      269
     ]
    },
    {
     "code": "COG",
     "finance": {
      "currencies": [
       {
        "code": "XAF",
        "name": "Central African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cog.svg",
     "geo": {
      "area": 342000,
      "borders": [
       "AGO",
       "CMR",
       "CAF",
       "COD",
       "GAB"
      ],
      "capital": "Brazzaville",
      "latlng": [
       -1,
       15
      ],
      "population": 4741000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ln",
        "iso639_2": "lin",
        "name": "Lingala",
        "nativeName": "Lingála"
       }
      ],
      "translations": {
       "br": "Congo",
       "de": "Kongo",
       "es": "Congo",
       "fa": "کنگو",
       "fr": "Congo",
       "hr": "Kongo",
       "it": "Congo",
       "ja": "コンゴ共和国",
       "nl": "Congo [Republiek]",
       "pt": "Congo"
      }
     },
     "name": "Congo",
     "phoneprefix": [
      242
     ]
    },
    {
     "code": "COD",
     "finance": {
      "currencies": [
       {
        "code": "CDF",
        "name": "Congolese franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cod.svg",
     "geo": {
      "area": 2344858,
      "borders": [
       "AGO",
       "BDI",
       "CAF",
       "COG",
       "RWA",
       "SSD",
       "TZA",
       "UGA",
       "ZMB"
      ],
      "capital": "Kinshasa",
      "latlng": [
       0,
       25
      ],
      "population": 85026000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00",
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cd"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ln",
        "iso639_2": "lin",
        "name": "Lingala",
        "nativeName": "Lingála"
       },
       {
        "iso639_1": "kg",
        "iso639_2": "kon",
        "name": "Kongo",
        "nativeName": "Kikongo"
       },
       {
        "iso639_1": "sw",
        "iso639_2": "swa",
        "name": "Swahili",
        "nativeName": "Kiswahili"
       },
       {
        "iso639_1": "lu",
        "iso639_2": "lub",
        "name": "Luba-Katanga",
        "nativeName": "Tshiluba"
       }
      ],
      "translations": {
       "br": "RD Congo",
       "de": "Kongo (Dem. Rep.)",
       "es": "Congo (Rep. Dem.)",
       "fa": "جمهوری کنگو",
       "fr": "Congo (Rép. dém.)",
       "hr": "Kongo, Demokratska Republika",
       "it": "Congo (Rep. Dem.)",
       "ja": "コンゴ民主共和国",
       "nl": "Congo [DRC]",
       "pt": "RD Congo"
      }
     },
     "name": "Congo (Rép. dém.)",
     "phoneprefix": [
      243
     ]
    },
    {
     "code": "COK",
     "finance": {
      "currencies": [
       {
        "code": "NZD",
        "name": "New Zealand dollar",
        "symbol": "$"
       },
       {
        "code": "CKD",
        "name": "Cook Islands dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cok.svg",
     "geo": {
      "area": 236,
      "borders": [],
      "capital": "Avarua",
      "latlng": [
       -21.23333333,
       -159.76666666
      ],
      "population": 18100,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC-10:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ck"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Cook",
       "de": "Cookinseln",
       "es": "Islas Cook",
       "fa": "جزایر کوک",
       "fr": "Îles Cook",
       "hr": "Cookovo Otočje",
       "it": "Isole Cook",
       "ja": "クック諸島",
       "nl": "Cookeilanden",
       "pt": "Ilhas Cook"
      }
     },
     "name": "Îles Cook",
     "phoneprefix": [
      682
     ]
    },
    {
     "code": "CRI",
     "finance": {
      "currencies": [
       {
        "code": "CRC",
        "name": "Costa Rican colón",
        "symbol": "₡"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cri.svg",
     "geo": {
      "area": 51100,
      "borders": [
       "NIC",
       "PAN"
      ],
      "capital": "San José",
      "latlng": [
       10,
       -84
      ],
      "population": 4890379,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Costa Rica",
       "de": "Costa Rica",
       "es": "Costa Rica",
       "fa": "کاستاریکا",
       "fr": "Costa Rica",
       "hr": "Kostarika",
       "it": "Costa Rica",
       "ja": "コスタリカ",
       "nl": "Costa Rica",
       "pt": "Costa Rica"
      }
     },
     "name": "Costa Rica",
     "phoneprefix": [
      506
     ]
    },
    {
     "code": "HRV",
     "finance": {
      "currencies": [
       {
        "code": "HRK",
        "name": "Croatian kuna",
        "symbol": "kn"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/hrv.svg",
     "geo": {
      "area": 56594,
      "borders": [
       "BIH",
       "HUN",
       "MNE",
       "SRB",
       "SVN"
      ],
      "capital": "Zagreb",
      "latlng": [
       45.16666666,
       15.5
      ],
      "population": 4190669,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".hr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "hr",
        "iso639_2": "hrv",
        "name": "Croatian",
        "nativeName": "hrvatski jezik"
       }
      ],
      "translations": {
       "br": "Croácia",
       "de": "Kroatien",
       "es": "Croacia",
       "fa": "کرواسی",
       "fr": "Croatie",
       "hr": "Hrvatska",
       "it": "Croazia",
       "ja": "クロアチア",
       "nl": "Kroatië",
       "pt": "Croácia"
      }
     },
     "name": "Croatie",
     "phoneprefix": [
      385
     ]
    },
    {
     "code": "CUB",
     "finance": {
      "currencies": [
       {
        "code": "CUC",
        "name": "Cuban convertible peso",
        "symbol": "$"
       },
       {
        "code": "CUP",
        "name": "Cuban peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cub.svg",
     "geo": {
      "area": 109884,
      "borders": [],
      "capital": "Havana",
      "latlng": [
       21.5,
       -80
      ],
      "population": 11239004,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Cuba",
       "de": "Kuba",
       "es": "Cuba",
       "fa": "کوبا",
       "fr": "Cuba",
       "hr": "Kuba",
       "it": "Cuba",
       "ja": "キューバ",
       "nl": "Cuba",
       "pt": "Cuba"
      }
     },
     "name": "Cuba",
     "phoneprefix": [
      53
     ]
    },
    {
     "code": "CUW",
     "finance": {
      "currencies": [
       {
        "code": "ANG",
        "name": "Netherlands Antillean guilder",
        "symbol": "ƒ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cuw.svg",
     "geo": {
      "area": 444,
      "borders": [],
      "capital": "Willemstad",
      "latlng": [
       12.116667,
       -68.933333
      ],
      "population": 154843,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       },
       {
        "iso639_1": "pa",
        "iso639_2": "pan",
        "name": "(Eastern) Punjabi",
        "nativeName": "ਪੰਜਾਬੀ"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Curaçao",
       "de": "Curaçao",
       "es": null,
       "fa": "کوراسائو",
       "fr": "Curaçao",
       "hr": null,
       "it": "Curaçao",
       "ja": null,
       "nl": "Curaçao",
       "pt": "Curaçao"
      }
     },
     "name": "Curaçao",
     "phoneprefix": [
      599
     ]
    },
    {
     "code": "CYP",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cyp.svg",
     "geo": {
      "area": 9251,
      "borders": [
       "GBR"
      ],
      "capital": "Nicosia",
      "latlng": [
       35,
       33
      ],
      "population": 847000,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cy"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "el",
        "iso639_2": "ell",
        "name": "Greek (modern)",
        "nativeName": "ελληνικά"
       },
       {
        "iso639_1": "tr",
        "iso639_2": "tur",
        "name": "Turkish",
        "nativeName": "Türkçe"
       },
       {
        "iso639_1": "hy",
        "iso639_2": "hye",
        "name": "Armenian",
        "nativeName": "Հայերեն"
       }
      ],
      "translations": {
       "br": "Chipre",
       "de": "Zypern",
       "es": "Chipre",
       "fa": "قبرس",
       "fr": "Chypre",
       "hr": "Cipar",
       "it": "Cipro",
       "ja": "キプロス",
       "nl": "Cyprus",
       "pt": "Chipre"
      }
     },
     "name": "Chypre",
     "phoneprefix": [
      357
     ]
    },
    {
     "code": "CZE",
     "finance": {
      "currencies": [
       {
        "code": "CZK",
        "name": "Czech koruna",
        "symbol": "Kč"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/cze.svg",
     "geo": {
      "area": 78865,
      "borders": [
       "AUT",
       "DEU",
       "POL",
       "SVK"
      ],
      "capital": "Prague",
      "latlng": [
       49.75,
       15.5
      ],
      "population": 10558524,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".cz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "cs",
        "iso639_2": "ces",
        "name": "Czech",
        "nativeName": "čeština"
       },
       {
        "iso639_1": "sk",
        "iso639_2": "slk",
        "name": "Slovak",
        "nativeName": "slovenčina"
       }
      ],
      "translations": {
       "br": "República Tcheca",
       "de": "Tschechische Republik",
       "es": "República Checa",
       "fa": "جمهوری چک",
       "fr": "République tchèque",
       "hr": "Češka",
       "it": "Repubblica Ceca",
       "ja": "チェコ",
       "nl": "Tsjechië",
       "pt": "República Checa"
      }
     },
     "name": "République tchèque",
     "phoneprefix": [
      420
     ]
    },
    {
     "code": "DNK",
     "finance": {
      "currencies": [
       {
        "code": "DKK",
        "name": "Danish krone",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/dnk.svg",
     "geo": {
      "area": 43094,
      "borders": [
       "DEU"
      ],
      "capital": "Copenhagen",
      "latlng": [
       56,
       10
      ],
      "population": 5717014,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC-04:00",
       "UTC-03:00",
       "UTC-01:00",
       "UTC",
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".dk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "da",
        "iso639_2": "dan",
        "name": "Danish",
        "nativeName": "dansk"
       }
      ],
      "translations": {
       "br": "Dinamarca",
       "de": "Dänemark",
       "es": "Dinamarca",
       "fa": "دانمارک",
       "fr": "Danemark",
       "hr": "Danska",
       "it": "Danimarca",
       "ja": "デンマーク",
       "nl": "Denemarken",
       "pt": "Dinamarca"
      }
     },
     "name": "Danemark",
     "phoneprefix": [
      45
     ]
    },
    {
     "code": "DJI",
     "finance": {
      "currencies": [
       {
        "code": "DJF",
        "name": "Djiboutian franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/dji.svg",
     "geo": {
      "area": 23200,
      "borders": [
       "ERI",
       "ETH",
       "SOM"
      ],
      "capital": "Djibouti",
      "latlng": [
       11.5,
       43
      ],
      "population": 900000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".dj"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Djibuti",
       "de": "Dschibuti",
       "es": "Yibuti",
       "fa": "جیبوتی",
       "fr": "Djibouti",
       "hr": "Džibuti",
       "it": "Gibuti",
       "ja": "ジブチ",
       "nl": "Djibouti",
       "pt": "Djibuti"
      }
     },
     "name": "Djibouti",
     "phoneprefix": [
      253
     ]
    },
    {
     "code": "DMA",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/dma.svg",
     "geo": {
      "area": 751,
      "borders": [],
      "capital": "Roseau",
      "latlng": [
       15.41666666,
       -61.33333333
      ],
      "population": 71293,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".dm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Dominica",
       "de": "Dominica",
       "es": "Dominica",
       "fa": "دومینیکا",
       "fr": "Dominique",
       "hr": "Dominika",
       "it": "Dominica",
       "ja": "ドミニカ国",
       "nl": "Dominica",
       "pt": "Dominica"
      }
     },
     "name": "Dominique",
     "phoneprefix": [
      1767
     ]
    },
    {
     "code": "DOM",
     "finance": {
      "currencies": [
       {
        "code": "DOP",
        "name": "Dominican peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/dom.svg",
     "geo": {
      "area": 48671,
      "borders": [
       "HTI"
      ],
      "capital": "Santo Domingo",
      "latlng": [
       19,
       -70.66666666
      ],
      "population": 10075045,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       },
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".do"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "República Dominicana",
       "de": "Dominikanische Republik",
       "es": "República Dominicana",
       "fa": "جمهوری دومینیکن",
       "fr": "République dominicaine",
       "hr": "Dominikanska Republika",
       "it": "Repubblica Dominicana",
       "ja": "ドミニカ共和国",
       "nl": "Dominicaanse Republiek",
       "pt": "República Dominicana"
      }
     },
     "name": "République dominicaine",
     "phoneprefix": [
      1809,
      1829,
      1849
     ]
    },
    {
     "code": "ECU",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ecu.svg",
     "geo": {
      "area": 276841,
      "borders": [
       "COL",
       "PER"
      ],
      "capital": "Quito",
      "latlng": [
       -2,
       -77.5
      ],
      "population": 16545799,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-06:00",
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ec"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Equador",
       "de": "Ecuador",
       "es": "Ecuador",
       "fa": "اکوادور",
       "fr": "Équateur",
       "hr": "Ekvador",
       "it": "Ecuador",
       "ja": "エクアドル",
       "nl": "Ecuador",
       "pt": "Equador"
      }
     },
     "name": "Équateur",
     "phoneprefix": [
      593
     ]
    },
    {
     "code": "EGY",
     "finance": {
      "currencies": [
       {
        "code": "EGP",
        "name": "Egyptian pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/egy.svg",
     "geo": {
      "area": 1002450,
      "borders": [
       "ISR",
       "LBY",
       "SDN"
      ],
      "capital": "Cairo",
      "latlng": [
       27,
       30
      ],
      "population": 91290000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".eg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Egito",
       "de": "Ägypten",
       "es": "Egipto",
       "fa": "مصر",
       "fr": "Égypte",
       "hr": "Egipat",
       "it": "Egitto",
       "ja": "エジプト",
       "nl": "Egypte",
       "pt": "Egipto"
      }
     },
     "name": "Égypte",
     "phoneprefix": [
      20
     ]
    },
    {
     "code": "SLV",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/slv.svg",
     "geo": {
      "area": 21041,
      "borders": [
       "GTM",
       "HND"
      ],
      "capital": "San Salvador",
      "latlng": [
       13.83333333,
       -88.91666666
      ],
      "population": 6520675,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sv"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "El Salvador",
       "de": "El Salvador",
       "es": "El Salvador",
       "fa": "السالوادور",
       "fr": "Salvador",
       "hr": "Salvador",
       "it": "El Salvador",
       "ja": "エルサルバドル",
       "nl": "El Salvador",
       "pt": "El Salvador"
      }
     },
     "name": "Salvador",
     "phoneprefix": [
      503
     ]
    },
    {
     "code": "GNQ",
     "finance": {
      "currencies": [
       {
        "code": "XAF",
        "name": "Central African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gnq.svg",
     "geo": {
      "area": 28051,
      "borders": [
       "CMR",
       "GAB"
      ],
      "capital": "Malabo",
      "latlng": [
       2,
       10
      ],
      "population": 1222442,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gq"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Guiné Equatorial",
       "de": "Äquatorial-Guinea",
       "es": "Guinea Ecuatorial",
       "fa": "گینه استوایی",
       "fr": "Guinée-Équatoriale",
       "hr": "Ekvatorijalna Gvineja",
       "it": "Guinea Equatoriale",
       "ja": "赤道ギニア",
       "nl": "Equatoriaal-Guinea",
       "pt": "Guiné Equatorial"
      }
     },
     "name": "Guinée-Équatoriale",
     "phoneprefix": [
      240
     ]
    },
    {
     "code": "ERI",
     "finance": {
      "currencies": [
       {
        "code": "ERN",
        "name": "Eritrean nakfa",
        "symbol": "Nfk"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/eri.svg",
     "geo": {
      "area": 117600,
      "borders": [
       "DJI",
       "ETH",
       "SDN"
      ],
      "capital": "Asmara",
      "latlng": [
       15,
       39
      ],
      "population": 5352000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".er"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ti",
        "iso639_2": "tir",
        "name": "Tigrinya",
        "nativeName": "ትግርኛ"
       },
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Eritreia",
       "de": "Eritrea",
       "es": "Eritrea",
       "fa": "اریتره",
       "fr": "Érythrée",
       "hr": "Eritreja",
       "it": "Eritrea",
       "ja": "エリトリア",
       "nl": "Eritrea",
       "pt": "Eritreia"
      }
     },
     "name": "Érythrée",
     "phoneprefix": [
      291
     ]
    },
    {
     "code": "EST",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/est.svg",
     "geo": {
      "area": 45227,
      "borders": [
       "LVA",
       "RUS"
      ],
      "capital": "Tallinn",
      "latlng": [
       59,
       26
      ],
      "population": 1315944,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ee"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "et",
        "iso639_2": "est",
        "name": "Estonian",
        "nativeName": "eesti"
       }
      ],
      "translations": {
       "br": "Estônia",
       "de": "Estland",
       "es": "Estonia",
       "fa": "استونی",
       "fr": "Estonie",
       "hr": "Estonija",
       "it": "Estonia",
       "ja": "エストニア",
       "nl": "Estland",
       "pt": "Estónia"
      }
     },
     "name": "Estonie",
     "phoneprefix": [
      372
     ]
    },
    {
     "code": "ETH",
     "finance": {
      "currencies": [
       {
        "code": "ETB",
        "name": "Ethiopian birr",
        "symbol": "Br"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/eth.svg",
     "geo": {
      "area": 1104300,
      "borders": [
       "DJI",
       "ERI",
       "KEN",
       "SOM",
       "SSD",
       "SDN"
      ],
      "capital": "Addis Ababa",
      "latlng": [
       8,
       38
      ],
      "population": 92206005,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".et"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "am",
        "iso639_2": "amh",
        "name": "Amharic",
        "nativeName": "አማርኛ"
       }
      ],
      "translations": {
       "br": "Etiópia",
       "de": "Äthiopien",
       "es": "Etiopía",
       "fa": "اتیوپی",
       "fr": "Éthiopie",
       "hr": "Etiopija",
       "it": "Etiopia",
       "ja": "エチオピア",
       "nl": "Ethiopië",
       "pt": "Etiópia"
      }
     },
     "name": "Éthiopie",
     "phoneprefix": [
      251
     ]
    },
    {
     "code": "FLK",
     "finance": {
      "currencies": [
       {
        "code": "FKP",
        "name": "Falkland Islands pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/flk.svg",
     "geo": {
      "area": 12173,
      "borders": [],
      "capital": "Stanley",
      "latlng": [
       -51.75,
       -59
      ],
      "population": 2563,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".fk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Malvinas",
       "de": "Falklandinseln",
       "es": "Islas Malvinas",
       "fa": "جزایر فالکلند",
       "fr": "Îles Malouines",
       "hr": "Falklandski Otoci",
       "it": "Isole Falkland o Isole Malvine",
       "ja": "フォークランド（マルビナス）諸島",
       "nl": "Falklandeilanden [Islas Malvinas]",
       "pt": "Ilhas Falkland"
      }
     },
     "name": "Îles Malouines",
     "phoneprefix": [
      500
     ]
    },
    {
     "code": "FRO",
     "finance": {
      "currencies": [
       {
        "code": "DKK",
        "name": "Danish krone",
        "symbol": "kr"
       },
       {
        "code": "(none)",
        "name": "Faroese króna",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/fro.svg",
     "geo": {
      "area": 1393,
      "borders": [],
      "capital": "Tórshavn",
      "latlng": [
       62,
       -7
      ],
      "population": 49376,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".fo"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fo",
        "iso639_2": "fao",
        "name": "Faroese",
        "nativeName": "føroyskt"
       }
      ],
      "translations": {
       "br": "Ilhas Faroé",
       "de": "Färöer-Inseln",
       "es": "Islas Faroe",
       "fa": "جزایر فارو",
       "fr": "Îles Féroé",
       "hr": "Farski Otoci",
       "it": "Isole Far Oer",
       "ja": "フェロー諸島",
       "nl": "Faeröer",
       "pt": "Ilhas Faroé"
      }
     },
     "name": "Îles Féroé",
     "phoneprefix": [
      298
     ]
    },
    {
     "code": "FJI",
     "finance": {
      "currencies": [
       {
        "code": "FJD",
        "name": "Fijian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/fji.svg",
     "geo": {
      "area": 18272,
      "borders": [],
      "capital": "Suva",
      "latlng": [
       -18,
       175
      ],
      "population": 867000,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Melanesia",
      "timezones": [
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".fj"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fj",
        "iso639_2": "fij",
        "name": "Fijian",
        "nativeName": "vosa Vakaviti"
       },
       {
        "iso639_1": "hi",
        "iso639_2": "hin",
        "name": "Hindi",
        "nativeName": "हिन्दी"
       },
       {
        "iso639_1": "ur",
        "iso639_2": "urd",
        "name": "Urdu",
        "nativeName": "اردو"
       }
      ],
      "translations": {
       "br": "Fiji",
       "de": "Fidschi",
       "es": "Fiyi",
       "fa": "فیجی",
       "fr": "Fidji",
       "hr": "Fiđi",
       "it": "Figi",
       "ja": "フィジー",
       "nl": "Fiji",
       "pt": "Fiji"
      }
     },
     "name": "Fidji",
     "phoneprefix": [
      679
     ]
    },
    {
     "code": "FIN",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/fin.svg",
     "geo": {
      "area": 338424,
      "borders": [
       "NOR",
       "SWE",
       "RUS"
      ],
      "capital": "Helsinki",
      "latlng": [
       64,
       26
      ],
      "population": 5491817,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".fi"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fi",
        "iso639_2": "fin",
        "name": "Finnish",
        "nativeName": "suomi"
       },
       {
        "iso639_1": "sv",
        "iso639_2": "swe",
        "name": "Swedish",
        "nativeName": "svenska"
       }
      ],
      "translations": {
       "br": "Finlândia",
       "de": "Finnland",
       "es": "Finlandia",
       "fa": "فنلاند",
       "fr": "Finlande",
       "hr": "Finska",
       "it": "Finlandia",
       "ja": "フィンランド",
       "nl": "Finland",
       "pt": "Finlândia"
      }
     },
     "name": "Finlande",
     "phoneprefix": [
      358
     ]
    },
    {
     "code": "FRA",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/fra.svg",
     "geo": {
      "area": 640679,
      "borders": [
       "AND",
       "BEL",
       "DEU",
       "ITA",
       "LUX",
       "MCO",
       "ESP",
       "CHE"
      ],
      "capital": "Paris",
      "latlng": [
       46,
       2
      ],
      "population": 66710000,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC-10:00",
       "UTC-09:30",
       "UTC-09:00",
       "UTC-08:00",
       "UTC-04:00",
       "UTC-03:00",
       "UTC+01:00",
       "UTC+03:00",
       "UTC+04:00",
       "UTC+05:00",
       "UTC+11:00",
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".fr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "França",
       "de": "Frankreich",
       "es": "Francia",
       "fa": "فرانسه",
       "fr": "France",
       "hr": "Francuska",
       "it": "Francia",
       "ja": "フランス",
       "nl": "Frankrijk",
       "pt": "França"
      }
     },
     "name": "France",
     "phoneprefix": [
      33
     ]
    },
    {
     "code": "GUF",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/guf.svg",
     "geo": {
      "area": null,
      "borders": [
       "BRA",
       "SUR"
      ],
      "capital": "Cayenne",
      "latlng": [
       4,
       -53
      ],
      "population": 254541,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       },
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Guiana Francesa",
       "de": "Französisch Guyana",
       "es": "Guayana Francesa",
       "fa": "گویان فرانسه",
       "fr": "Guayane",
       "hr": "Francuska Gvajana",
       "it": "Guyana francese",
       "ja": "フランス領ギアナ",
       "nl": "Frans-Guyana",
       "pt": "Guiana Francesa"
      }
     },
     "name": "Guayane",
     "phoneprefix": [
      594
     ]
    },
    {
     "code": "PYF",
     "finance": {
      "currencies": [
       {
        "code": "XPF",
        "name": "CFP franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pyf.svg",
     "geo": {
      "area": 4167,
      "borders": [],
      "capital": "Papeetē",
      "latlng": [
       -15,
       -140
      ],
      "population": 271800,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC-10:00",
       "UTC-09:30",
       "UTC-09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Polinésia Francesa",
       "de": "Französisch-Polynesien",
       "es": "Polinesia Francesa",
       "fa": "پلی‌نزی فرانسه",
       "fr": "Polynésie française",
       "hr": "Francuska Polinezija",
       "it": "Polinesia Francese",
       "ja": "フランス領ポリネシア",
       "nl": "Frans-Polynesië",
       "pt": "Polinésia Francesa"
      }
     },
     "name": "Polynésie française",
     "phoneprefix": [
      689
     ]
    },
    {
     "code": "ATF",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/atf.svg",
     "geo": {
      "area": 7747,
      "borders": [],
      "capital": "Port-aux-Français",
      "latlng": [
       -49.25,
       69.167
      ],
      "population": 140,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Southern Africa",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Terras Austrais e Antárticas Francesas",
       "de": "Französische Süd- und Antarktisgebiete",
       "es": "Tierras Australes y Antárticas Francesas",
       "fa": "سرزمین‌های جنوبی و جنوبگانی فرانسه",
       "fr": "Terres australes et antarctiques françaises",
       "hr": "Francuski južni i antarktički teritoriji",
       "it": "Territori Francesi del Sud",
       "ja": "フランス領南方・南極地域",
       "nl": "Franse Gebieden in de zuidelijke Indische Oceaan",
       "pt": "Terras Austrais e Antárticas Francesas"
      }
     },
     "name": "Terres australes et antarctiques françaises",
     "phoneprefix": [
      0
     ]
    },
    {
     "code": "GAB",
     "finance": {
      "currencies": [
       {
        "code": "XAF",
        "name": "Central African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gab.svg",
     "geo": {
      "area": 267668,
      "borders": [
       "CMR",
       "COG",
       "GNQ"
      ],
      "capital": "Libreville",
      "latlng": [
       -1,
       11.75
      ],
      "population": 1802278,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ga"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Gabão",
       "de": "Gabun",
       "es": "Gabón",
       "fa": "گابن",
       "fr": "Gabon",
       "hr": "Gabon",
       "it": "Gabon",
       "ja": "ガボン",
       "nl": "Gabon",
       "pt": "Gabão"
      }
     },
     "name": "Gabon",
     "phoneprefix": [
      241
     ]
    },
    {
     "code": "GMB",
     "finance": {
      "currencies": [
       {
        "code": "GMD",
        "name": "Gambian dalasi",
        "symbol": "D"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gmb.svg",
     "geo": {
      "area": 11295,
      "borders": [
       "SEN"
      ],
      "capital": "Banjul",
      "latlng": [
       13.46666666,
       -16.56666666
      ],
      "population": 1882450,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Gâmbia",
       "de": "Gambia",
       "es": "Gambia",
       "fa": "گامبیا",
       "fr": "Gambie",
       "hr": "Gambija",
       "it": "Gambia",
       "ja": "ガンビア",
       "nl": "Gambia",
       "pt": "Gâmbia"
      }
     },
     "name": "Gambie",
     "phoneprefix": [
      220
     ]
    },
    {
     "code": "GEO",
     "finance": {
      "currencies": [
       {
        "code": "GEL",
        "name": "Georgian Lari",
        "symbol": "ლ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/geo.svg",
     "geo": {
      "area": 69700,
      "borders": [
       "ARM",
       "AZE",
       "RUS",
       "TUR"
      ],
      "capital": "Tbilisi",
      "latlng": [
       42,
       43.5
      ],
      "population": 3720400,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Western Asia",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ge"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ka",
        "iso639_2": "kat",
        "name": "Georgian",
        "nativeName": "ქართული"
       }
      ],
      "translations": {
       "br": "Geórgia",
       "de": "Georgien",
       "es": "Georgia",
       "fa": "گرجستان",
       "fr": "Géorgie",
       "hr": "Gruzija",
       "it": "Georgia",
       "ja": "グルジア",
       "nl": "Georgië",
       "pt": "Geórgia"
      }
     },
     "name": "Géorgie",
     "phoneprefix": [
      995
     ]
    },
    {
     "code": "DEU",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/deu.svg",
     "geo": {
      "area": 357114,
      "borders": [
       "AUT",
       "BEL",
       "CZE",
       "DNK",
       "FRA",
       "LUX",
       "NLD",
       "POL",
       "CHE"
      ],
      "capital": "Berlin",
      "latlng": [
       51,
       9
      ],
      "population": 81770900,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".de"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       }
      ],
      "translations": {
       "br": "Alemanha",
       "de": "Deutschland",
       "es": "Alemania",
       "fa": "آلمان",
       "fr": "Allemagne",
       "hr": "Njemačka",
       "it": "Germania",
       "ja": "ドイツ",
       "nl": "Duitsland",
       "pt": "Alemanha"
      }
     },
     "name": "Allemagne",
     "phoneprefix": [
      49
     ]
    },
    {
     "code": "GHA",
     "finance": {
      "currencies": [
       {
        "code": "GHS",
        "name": "Ghanaian cedi",
        "symbol": "₵"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gha.svg",
     "geo": {
      "area": 238533,
      "borders": [
       "BFA",
       "CIV",
       "TGO"
      ],
      "capital": "Accra",
      "latlng": [
       8,
       -2
      ],
      "population": 27670174,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gh"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Gana",
       "de": "Ghana",
       "es": "Ghana",
       "fa": "غنا",
       "fr": "Ghana",
       "hr": "Gana",
       "it": "Ghana",
       "ja": "ガーナ",
       "nl": "Ghana",
       "pt": "Gana"
      }
     },
     "name": "Ghana",
     "phoneprefix": [
      233
     ]
    },
    {
     "code": "GIB",
     "finance": {
      "currencies": [
       {
        "code": "GIP",
        "name": "Gibraltar pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gib.svg",
     "geo": {
      "area": 6,
      "borders": [
       "ESP"
      ],
      "capital": "Gibraltar",
      "latlng": [
       36.13333333,
       -5.35
      ],
      "population": 33140,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gi"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Gibraltar",
       "de": "Gibraltar",
       "es": "Gibraltar",
       "fa": "جبل‌طارق",
       "fr": "Gibraltar",
       "hr": "Gibraltar",
       "it": "Gibilterra",
       "ja": "ジブラルタル",
       "nl": "Gibraltar",
       "pt": "Gibraltar"
      }
     },
     "name": "Gibraltar",
     "phoneprefix": [
      350
     ]
    },
    {
     "code": "GRC",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/grc.svg",
     "geo": {
      "area": 131990,
      "borders": [
       "ALB",
       "BGR",
       "TUR",
       "MKD"
      ],
      "capital": "Athens",
      "latlng": [
       39,
       22
      ],
      "population": 10858018,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "el",
        "iso639_2": "ell",
        "name": "Greek (modern)",
        "nativeName": "ελληνικά"
       }
      ],
      "translations": {
       "br": "Grécia",
       "de": "Griechenland",
       "es": "Grecia",
       "fa": "یونان",
       "fr": "Grèce",
       "hr": "Grčka",
       "it": "Grecia",
       "ja": "ギリシャ",
       "nl": "Griekenland",
       "pt": "Grécia"
      }
     },
     "name": "Grèce",
     "phoneprefix": [
      30
     ]
    },
    {
     "code": "GRL",
     "finance": {
      "currencies": [
       {
        "code": "DKK",
        "name": "Danish krone",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/grl.svg",
     "geo": {
      "area": 2166086,
      "borders": [],
      "capital": "Nuuk",
      "latlng": [
       72,
       -40
      ],
      "population": 55847,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Northern America",
      "timezones": [
       "UTC-04:00",
       "UTC-03:00",
       "UTC-01:00",
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "kl",
        "iso639_2": "kal",
        "name": "Kalaallisut",
        "nativeName": "kalaallisut"
       }
      ],
      "translations": {
       "br": "Groelândia",
       "de": "Grönland",
       "es": "Groenlandia",
       "fa": "گرینلند",
       "fr": "Groenland",
       "hr": "Grenland",
       "it": "Groenlandia",
       "ja": "グリーンランド",
       "nl": "Groenland",
       "pt": "Gronelândia"
      }
     },
     "name": "Groenland",
     "phoneprefix": [
      299
     ]
    },
    {
     "code": "GRD",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/grd.svg",
     "geo": {
      "area": 344,
      "borders": [],
      "capital": "St. George's",
      "latlng": [
       12.11666666,
       -61.66666666
      ],
      "population": 103328,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gd"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Granada",
       "de": "Grenada",
       "es": "Grenada",
       "fa": "گرنادا",
       "fr": "Grenade",
       "hr": "Grenada",
       "it": "Grenada",
       "ja": "グレナダ",
       "nl": "Grenada",
       "pt": "Granada"
      }
     },
     "name": "Grenade",
     "phoneprefix": [
      1473
     ]
    },
    {
     "code": "GLP",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/glp.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "Basse-Terre",
      "latlng": [
       16.25,
       -61.583333
      ],
      "population": 400132,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gp"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Guadalupe",
       "de": "Guadeloupe",
       "es": "Guadalupe",
       "fa": "جزیره گوادلوپ",
       "fr": "Guadeloupe",
       "hr": "Gvadalupa",
       "it": "Guadeloupa",
       "ja": "グアドループ",
       "nl": "Guadeloupe",
       "pt": "Guadalupe"
      }
     },
     "name": "Guadeloupe",
     "phoneprefix": [
      590
     ]
    },
    {
     "code": "GUM",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gum.svg",
     "geo": {
      "area": 549,
      "borders": [],
      "capital": "Hagåtña",
      "latlng": [
       13.46666666,
       144.78333333
      ],
      "population": 184200,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+10:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ch",
        "iso639_2": "cha",
        "name": "Chamorro",
        "nativeName": "Chamoru"
       },
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Guam",
       "de": "Guam",
       "es": "Guam",
       "fa": "گوام",
       "fr": "Guam",
       "hr": "Guam",
       "it": "Guam",
       "ja": "グアム",
       "nl": "Guam",
       "pt": "Guame"
      }
     },
     "name": "Guam",
     "phoneprefix": [
      1671
     ]
    },
    {
     "code": "GTM",
     "finance": {
      "currencies": [
       {
        "code": "GTQ",
        "name": "Guatemalan quetzal",
        "symbol": "Q"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gtm.svg",
     "geo": {
      "area": 108889,
      "borders": [
       "BLZ",
       "SLV",
       "HND",
       "MEX"
      ],
      "capital": "Guatemala City",
      "latlng": [
       15.5,
       -90.25
      ],
      "population": 16176133,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Guatemala",
       "de": "Guatemala",
       "es": "Guatemala",
       "fa": "گواتمالا",
       "fr": "Guatemala",
       "hr": "Gvatemala",
       "it": "Guatemala",
       "ja": "グアテマラ",
       "nl": "Guatemala",
       "pt": "Guatemala"
      }
     },
     "name": "Guatemala",
     "phoneprefix": [
      502
     ]
    },
    {
     "code": "GGY",
     "finance": {
      "currencies": [
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       },
       {
        "code": "(none)",
        "name": "Guernsey pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ggy.svg",
     "geo": {
      "area": 78,
      "borders": [],
      "capital": "St. Peter Port",
      "latlng": [
       49.46666666,
       -2.58333333
      ],
      "population": 62999,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Guernsey",
       "de": "Guernsey",
       "es": "Guernsey",
       "fa": "گرنزی",
       "fr": "Guernesey",
       "hr": "Guernsey",
       "it": "Guernsey",
       "ja": "ガーンジー",
       "nl": "Guernsey",
       "pt": "Guernsey"
      }
     },
     "name": "Guernesey",
     "phoneprefix": [
      44
     ]
    },
    {
     "code": "GIN",
     "finance": {
      "currencies": [
       {
        "code": "GNF",
        "name": "Guinean franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gin.svg",
     "geo": {
      "area": 245857,
      "borders": [
       "CIV",
       "GNB",
       "LBR",
       "MLI",
       "SEN",
       "SLE"
      ],
      "capital": "Conakry",
      "latlng": [
       11,
       -10
      ],
      "population": 12947000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ff",
        "iso639_2": "ful",
        "name": "Fula",
        "nativeName": "Fulfulde"
       }
      ],
      "translations": {
       "br": "Guiné",
       "de": "Guinea",
       "es": "Guinea",
       "fa": "گینه",
       "fr": "Guinée",
       "hr": "Gvineja",
       "it": "Guinea",
       "ja": "ギニア",
       "nl": "Guinee",
       "pt": "Guiné"
      }
     },
     "name": "Guinée",
     "phoneprefix": [
      224
     ]
    },
    {
     "code": "GNB",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gnb.svg",
     "geo": {
      "area": 36125,
      "borders": [
       "GIN",
       "SEN"
      ],
      "capital": "Bissau",
      "latlng": [
       12,
       -15
      ],
      "population": 1547777,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Guiné-Bissau",
       "de": "Guinea-Bissau",
       "es": "Guinea-Bisáu",
       "fa": "گینه بیسائو",
       "fr": "Guinée-Bissau",
       "hr": "Gvineja Bisau",
       "it": "Guinea-Bissau",
       "ja": "ギニアビサウ",
       "nl": "Guinee-Bissau",
       "pt": "Guiné-Bissau"
      }
     },
     "name": "Guinée-Bissau",
     "phoneprefix": [
      245
     ]
    },
    {
     "code": "GUY",
     "finance": {
      "currencies": [
       {
        "code": "GYD",
        "name": "Guyanese dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/guy.svg",
     "geo": {
      "area": 214969,
      "borders": [
       "BRA",
       "SUR",
       "VEN"
      ],
      "capital": "Georgetown",
      "latlng": [
       5,
       -59
      ],
      "population": 746900,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       },
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gy"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Guiana",
       "de": "Guyana",
       "es": "Guyana",
       "fa": "گویان",
       "fr": "Guyane",
       "hr": "Gvajana",
       "it": "Guyana",
       "ja": "ガイアナ",
       "nl": "Guyana",
       "pt": "Guiana"
      }
     },
     "name": "Guyane",
     "phoneprefix": [
      592
     ]
    },
    {
     "code": "HTI",
     "finance": {
      "currencies": [
       {
        "code": "HTG",
        "name": "Haitian gourde",
        "symbol": "G"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/hti.svg",
     "geo": {
      "area": 27750,
      "borders": [
       "DOM"
      ],
      "capital": "Port-au-Prince",
      "latlng": [
       19,
       -72.41666666
      ],
      "population": 11078033,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ht"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "ht",
        "iso639_2": "hat",
        "name": "Haitian",
        "nativeName": "Kreyòl ayisyen"
       }
      ],
      "translations": {
       "br": "Haiti",
       "de": "Haiti",
       "es": "Haiti",
       "fa": "هائیتی",
       "fr": "Haïti",
       "hr": "Haiti",
       "it": "Haiti",
       "ja": "ハイチ",
       "nl": "Haïti",
       "pt": "Haiti"
      }
     },
     "name": "Haïti",
     "phoneprefix": [
      509
     ]
    },
    {
     "code": "HMD",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/hmd.svg",
     "geo": {
      "area": 412,
      "borders": [],
      "capital": "",
      "latlng": [
       -53.1,
       72.51666666
      ],
      "population": 0,
      "region": "",
      "regionalBlocs": [],
      "subregion": "",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".hm",
       ".aq"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilha Heard e Ilhas McDonald",
       "de": "Heard und die McDonaldinseln",
       "es": "Islas Heard y McDonald",
       "fa": "جزیره هرد و جزایر مک‌دونالد",
       "fr": "Îles Heard-et-MacDonald",
       "hr": "Otok Heard i otočje McDonald",
       "it": "Isole Heard e McDonald",
       "ja": "ハード島とマクドナルド諸島",
       "nl": "Heard- en McDonaldeilanden",
       "pt": "Ilha Heard e Ilhas McDonald"
      }
     },
     "name": "Îles Heard-et-MacDonald",
     "phoneprefix": [
      0
     ]
    },
    {
     "code": "VAT",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/vat.svg",
     "geo": {
      "area": 0.44,
      "borders": [
       "ITA"
      ],
      "capital": "Rome",
      "latlng": [
       41.9,
       12.45
      ],
      "population": 451,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".va"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "la",
        "iso639_2": "lat",
        "name": "Latin",
        "nativeName": "latine"
       },
       {
        "iso639_1": "it",
        "iso639_2": "ita",
        "name": "Italian",
        "nativeName": "Italiano"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       }
      ],
      "translations": {
       "br": "Vaticano",
       "de": "Heiliger Stuhl",
       "es": "Santa Sede",
       "fa": "سریر مقدس",
       "fr": "voir Saint",
       "hr": "Sveta Stolica",
       "it": "Santa Sede",
       "ja": "聖座",
       "nl": "Heilige Stoel",
       "pt": "Vaticano"
      }
     },
     "name": "voir Saint",
     "phoneprefix": [
      379
     ]
    },
    {
     "code": "HND",
     "finance": {
      "currencies": [
       {
        "code": "HNL",
        "name": "Honduran lempira",
        "symbol": "L"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/hnd.svg",
     "geo": {
      "area": 112492,
      "borders": [
       "GTM",
       "SLV",
       "NIC"
      ],
      "capital": "Tegucigalpa",
      "latlng": [
       15,
       -86.5
      ],
      "population": 8576532,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".hn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Honduras",
       "de": "Honduras",
       "es": "Honduras",
       "fa": "هندوراس",
       "fr": "Honduras",
       "hr": "Honduras",
       "it": "Honduras",
       "ja": "ホンジュラス",
       "nl": "Honduras",
       "pt": "Honduras"
      }
     },
     "name": "Honduras",
     "phoneprefix": [
      504
     ]
    },
    {
     "code": "HKG",
     "finance": {
      "currencies": [
       {
        "code": "HKD",
        "name": "Hong Kong dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/hkg.svg",
     "geo": {
      "area": 1104,
      "borders": [
       "CHN"
      ],
      "capital": "City of Victoria",
      "latlng": [
       22.25,
       114.16666666
      ],
      "population": 7324300,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".hk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "zh",
        "iso639_2": "zho",
        "name": "Chinese",
        "nativeName": "中文 (Zhōngwén)"
       }
      ],
      "translations": {
       "br": "Hong Kong",
       "de": "Hong Kong",
       "es": "Hong Kong",
       "fa": "هنگ‌کنگ",
       "fr": "Hong Kong",
       "hr": "Hong Kong",
       "it": "Hong Kong",
       "ja": "香港",
       "nl": "Hongkong",
       "pt": "Hong Kong"
      }
     },
     "name": "Hong Kong",
     "phoneprefix": [
      852
     ]
    },
    {
     "code": "HUN",
     "finance": {
      "currencies": [
       {
        "code": "HUF",
        "name": "Hungarian forint",
        "symbol": "Ft"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/hun.svg",
     "geo": {
      "area": 93028,
      "borders": [
       "AUT",
       "HRV",
       "ROU",
       "SRB",
       "SVK",
       "SVN",
       "UKR"
      ],
      "capital": "Budapest",
      "latlng": [
       47,
       20
      ],
      "population": 9823000,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".hu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "hu",
        "iso639_2": "hun",
        "name": "Hungarian",
        "nativeName": "magyar"
       }
      ],
      "translations": {
       "br": "Hungria",
       "de": "Ungarn",
       "es": "Hungría",
       "fa": "مجارستان",
       "fr": "Hongrie",
       "hr": "Mađarska",
       "it": "Ungheria",
       "ja": "ハンガリー",
       "nl": "Hongarije",
       "pt": "Hungria"
      }
     },
     "name": "Hongrie",
     "phoneprefix": [
      36
     ]
    },
    {
     "code": "ISL",
     "finance": {
      "currencies": [
       {
        "code": "ISK",
        "name": "Icelandic króna",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/isl.svg",
     "geo": {
      "area": 103000,
      "borders": [],
      "capital": "Reykjavík",
      "latlng": [
       65,
       -18
      ],
      "population": 334300,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EFTA",
        "name": "European Free Trade Association",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".is"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "is",
        "iso639_2": "isl",
        "name": "Icelandic",
        "nativeName": "Íslenska"
       }
      ],
      "translations": {
       "br": "Islândia",
       "de": "Island",
       "es": "Islandia",
       "fa": "ایسلند",
       "fr": "Islande",
       "hr": "Island",
       "it": "Islanda",
       "ja": "アイスランド",
       "nl": "IJsland",
       "pt": "Islândia"
      }
     },
     "name": "Islande",
     "phoneprefix": [
      354
     ]
    },
    {
     "code": "IND",
     "finance": {
      "currencies": [
       {
        "code": "INR",
        "name": "Indian rupee",
        "symbol": "₹"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ind.svg",
     "geo": {
      "area": 3287590,
      "borders": [
       "AFG",
       "BGD",
       "BTN",
       "MMR",
       "CHN",
       "NPL",
       "PAK",
       "LKA"
      ],
      "capital": "New Delhi",
      "latlng": [
       20,
       77
      ],
      "population": 1295210000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+05:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".in"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "hi",
        "iso639_2": "hin",
        "name": "Hindi",
        "nativeName": "हिन्दी"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Índia",
       "de": "Indien",
       "es": "India",
       "fa": "هند",
       "fr": "Inde",
       "hr": "Indija",
       "it": "India",
       "ja": "インド",
       "nl": "India",
       "pt": "Índia"
      }
     },
     "name": "Inde",
     "phoneprefix": [
      91
     ]
    },
    {
     "code": "IDN",
     "finance": {
      "currencies": [
       {
        "code": "IDR",
        "name": "Indonesian rupiah",
        "symbol": "Rp"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/idn.svg",
     "geo": {
      "area": 1904569,
      "borders": [
       "TLS",
       "MYS",
       "PNG"
      ],
      "capital": "Jakarta",
      "latlng": [
       -5,
       120
      ],
      "population": 258705000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+07:00",
       "UTC+08:00",
       "UTC+09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".id"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "id",
        "iso639_2": "ind",
        "name": "Indonesian",
        "nativeName": "Bahasa Indonesia"
       }
      ],
      "translations": {
       "br": "Indonésia",
       "de": "Indonesien",
       "es": "Indonesia",
       "fa": "اندونزی",
       "fr": "Indonésie",
       "hr": "Indonezija",
       "it": "Indonesia",
       "ja": "インドネシア",
       "nl": "Indonesië",
       "pt": "Indonésia"
      }
     },
     "name": "Indonésie",
     "phoneprefix": [
      62
     ]
    },
    {
     "code": "CIV",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/civ.svg",
     "geo": {
      "area": 322463,
      "borders": [
       "BFA",
       "GHA",
       "GIN",
       "LBR",
       "MLI"
      ],
      "capital": "Yamoussoukro",
      "latlng": [
       8,
       -5
      ],
      "population": 22671331,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ci"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Costa do Marfim",
       "de": "Elfenbeinküste",
       "es": "Costa de Marfil",
       "fa": "ساحل عاج",
       "fr": "Côte d'Ivoire",
       "hr": "Obala Bjelokosti",
       "it": "Costa D'Avorio",
       "ja": "コートジボワール",
       "nl": "Ivoorkust",
       "pt": "Costa do Marfim"
      }
     },
     "name": "Côte d'Ivoire",
     "phoneprefix": [
      225
     ]
    },
    {
     "code": "IRN",
     "finance": {
      "currencies": [
       {
        "code": "IRR",
        "name": "Iranian rial",
        "symbol": "﷼"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/irn.svg",
     "geo": {
      "area": 1648195,
      "borders": [
       "AFG",
       "ARM",
       "AZE",
       "IRQ",
       "PAK",
       "TUR",
       "TKM"
      ],
      "capital": "Tehran",
      "latlng": [
       32,
       53
      ],
      "population": 79369900,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+03:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ir"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fa",
        "iso639_2": "fas",
        "name": "Persian (Farsi)",
        "nativeName": "فارسی"
       }
      ],
      "translations": {
       "br": "Irã",
       "de": "Iran",
       "es": "Iran",
       "fa": "ایران",
       "fr": "Iran",
       "hr": "Iran",
       "it": null,
       "ja": "イラン・イスラム共和国",
       "nl": "Iran",
       "pt": "Irão"
      }
     },
     "name": "Iran",
     "phoneprefix": [
      98
     ]
    },
    {
     "code": "IRQ",
     "finance": {
      "currencies": [
       {
        "code": "IQD",
        "name": "Iraqi dinar",
        "symbol": "ع.د"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/irq.svg",
     "geo": {
      "area": 438317,
      "borders": [
       "IRN",
       "JOR",
       "KWT",
       "SAU",
       "SYR",
       "TUR"
      ],
      "capital": "Baghdad",
      "latlng": [
       33,
       44
      ],
      "population": 37883543,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".iq"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       },
       {
        "iso639_1": "ku",
        "iso639_2": "kur",
        "name": "Kurdish",
        "nativeName": "Kurdî"
       }
      ],
      "translations": {
       "br": "Iraque",
       "de": "Irak",
       "es": "Irak",
       "fa": "عراق",
       "fr": "Irak",
       "hr": "Irak",
       "it": "Iraq",
       "ja": "イラク",
       "nl": "Irak",
       "pt": "Iraque"
      }
     },
     "name": "Irak",
     "phoneprefix": [
      964
     ]
    },
    {
     "code": "IRL",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/irl.svg",
     "geo": {
      "area": 70273,
      "borders": [
       "GBR"
      ],
      "capital": "Dublin",
      "latlng": [
       53,
       -8
      ],
      "population": 6378000,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ie"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ga",
        "iso639_2": "gle",
        "name": "Irish",
        "nativeName": "Gaeilge"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Irlanda",
       "de": "Irland",
       "es": "Irlanda",
       "fa": "ایرلند",
       "fr": "Irlande",
       "hr": "Irska",
       "it": "Irlanda",
       "ja": "アイルランド",
       "nl": "Ierland",
       "pt": "Irlanda"
      }
     },
     "name": "Irlande",
     "phoneprefix": [
      353
     ]
    },
    {
     "code": "IMN",
     "finance": {
      "currencies": [
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       },
       {
        "code": "IMP[G]",
        "name": "Manx pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/imn.svg",
     "geo": {
      "area": 572,
      "borders": [],
      "capital": "Douglas",
      "latlng": [
       54.25,
       -4.5
      ],
      "population": 84497,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".im"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "gv",
        "iso639_2": "glv",
        "name": "Manx",
        "nativeName": "Gaelg"
       }
      ],
      "translations": {
       "br": "Ilha de Man",
       "de": "Insel Man",
       "es": "Isla de Man",
       "fa": "جزیره من",
       "fr": "Île de Man",
       "hr": "Otok Man",
       "it": "Isola di Man",
       "ja": "マン島",
       "nl": "Isle of Man",
       "pt": "Ilha de Man"
      }
     },
     "name": "Île de Man",
     "phoneprefix": [
      44
     ]
    },
    {
     "code": "ISR",
     "finance": {
      "currencies": [
       {
        "code": "ILS",
        "name": "Israeli new shekel",
        "symbol": "₪"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/isr.svg",
     "geo": {
      "area": 20770,
      "borders": [
       "EGY",
       "JOR",
       "LBN",
       "SYR"
      ],
      "capital": "Jerusalem",
      "latlng": [
       31.5,
       34.75
      ],
      "population": 8527400,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".il"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "he",
        "iso639_2": "heb",
        "name": "Hebrew (modern)",
        "nativeName": "עברית"
       },
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Israel",
       "de": "Israel",
       "es": "Israel",
       "fa": "اسرائیل",
       "fr": "Israël",
       "hr": "Izrael",
       "it": "Israele",
       "ja": "イスラエル",
       "nl": "Israël",
       "pt": "Israel"
      }
     },
     "name": "Israël",
     "phoneprefix": [
      972
     ]
    },
    {
     "code": "ITA",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ita.svg",
     "geo": {
      "area": 301336,
      "borders": [
       "AUT",
       "FRA",
       "SMR",
       "SVN",
       "CHE",
       "VAT"
      ],
      "capital": "Rome",
      "latlng": [
       42.83333333,
       12.83333333
      ],
      "population": 60665551,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".it"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "it",
        "iso639_2": "ita",
        "name": "Italian",
        "nativeName": "Italiano"
       }
      ],
      "translations": {
       "br": "Itália",
       "de": "Italien",
       "es": "Italia",
       "fa": "ایتالیا",
       "fr": "Italie",
       "hr": "Italija",
       "it": "Italia",
       "ja": "イタリア",
       "nl": "Italië",
       "pt": "Itália"
      }
     },
     "name": "Italie",
     "phoneprefix": [
      39
     ]
    },
    {
     "code": "JAM",
     "finance": {
      "currencies": [
       {
        "code": "JMD",
        "name": "Jamaican dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/jam.svg",
     "geo": {
      "area": 10991,
      "borders": [],
      "capital": "Kingston",
      "latlng": [
       18.25,
       -77.5
      ],
      "population": 2723246,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".jm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Jamaica",
       "de": "Jamaika",
       "es": "Jamaica",
       "fa": "جامائیکا",
       "fr": "Jamaïque",
       "hr": "Jamajka",
       "it": "Giamaica",
       "ja": "ジャマイカ",
       "nl": "Jamaica",
       "pt": "Jamaica"
      }
     },
     "name": "Jamaïque",
     "phoneprefix": [
      1876
     ]
    },
    {
     "code": "JPN",
     "finance": {
      "currencies": [
       {
        "code": "JPY",
        "name": "Japanese yen",
        "symbol": "¥"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/jpn.svg",
     "geo": {
      "area": 377930,
      "borders": [],
      "capital": "Tokyo",
      "latlng": [
       36,
       138
      ],
      "population": 126960000,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".jp"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ja",
        "iso639_2": "jpn",
        "name": "Japanese",
        "nativeName": "日本語 (にほんご)"
       }
      ],
      "translations": {
       "br": "Japão",
       "de": "Japan",
       "es": "Japón",
       "fa": "ژاپن",
       "fr": "Japon",
       "hr": "Japan",
       "it": "Giappone",
       "ja": "日本",
       "nl": "Japan",
       "pt": "Japão"
      }
     },
     "name": "Japon",
     "phoneprefix": [
      81
     ]
    },
    {
     "code": "JEY",
     "finance": {
      "currencies": [
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       },
       {
        "code": "JEP[G]",
        "name": "Jersey pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/jey.svg",
     "geo": {
      "area": 116,
      "borders": [],
      "capital": "Saint Helier",
      "latlng": [
       49.25,
       -2.16666666
      ],
      "population": 100800,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".je"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Jersey",
       "de": "Jersey",
       "es": "Jersey",
       "fa": "جرزی",
       "fr": "Jersey",
       "hr": "Jersey",
       "it": "Isola di Jersey",
       "ja": "ジャージー",
       "nl": "Jersey",
       "pt": "Jersey"
      }
     },
     "name": "Jersey",
     "phoneprefix": [
      44
     ]
    },
    {
     "code": "JOR",
     "finance": {
      "currencies": [
       {
        "code": "JOD",
        "name": "Jordanian dinar",
        "symbol": "د.ا"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/jor.svg",
     "geo": {
      "area": 89342,
      "borders": [
       "IRQ",
       "ISR",
       "SAU",
       "SYR"
      ],
      "capital": "Amman",
      "latlng": [
       31,
       36
      ],
      "population": 9531712,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".jo"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Jordânia",
       "de": "Jordanien",
       "es": "Jordania",
       "fa": "اردن",
       "fr": "Jordanie",
       "hr": "Jordan",
       "it": "Giordania",
       "ja": "ヨルダン",
       "nl": "Jordanië",
       "pt": "Jordânia"
      }
     },
     "name": "Jordanie",
     "phoneprefix": [
      962
     ]
    },
    {
     "code": "KAZ",
     "finance": {
      "currencies": [
       {
        "code": "KZT",
        "name": "Kazakhstani tenge",
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kaz.svg",
     "geo": {
      "area": 2724900,
      "borders": [
       "CHN",
       "KGZ",
       "RUS",
       "TKM",
       "UZB"
      ],
      "capital": "Astana",
      "latlng": [
       48,
       68
      ],
      "population": 17753200,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "EEU",
        "name": "Eurasian Economic Union",
        "otherAcronyms": [
         "EAEU"
        ],
        "otherNames": []
       }
      ],
      "subregion": "Central Asia",
      "timezones": [
       "UTC+05:00",
       "UTC+06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kz",
       ".қаз"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "kk",
        "iso639_2": "kaz",
        "name": "Kazakh",
        "nativeName": "қазақ тілі"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Cazaquistão",
       "de": "Kasachstan",
       "es": "Kazajistán",
       "fa": "قزاقستان",
       "fr": "Kazakhstan",
       "hr": "Kazahstan",
       "it": "Kazakistan",
       "ja": "カザフスタン",
       "nl": "Kazachstan",
       "pt": "Cazaquistão"
      }
     },
     "name": "Kazakhstan",
     "phoneprefix": [
      76,
      77
     ]
    },
    {
     "code": "KEN",
     "finance": {
      "currencies": [
       {
        "code": "KES",
        "name": "Kenyan shilling",
        "symbol": "Sh"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ken.svg",
     "geo": {
      "area": 580367,
      "borders": [
       "ETH",
       "SOM",
       "SSD",
       "TZA",
       "UGA"
      ],
      "capital": "Nairobi",
      "latlng": [
       1,
       38
      ],
      "population": 47251000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ke"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "sw",
        "iso639_2": "swa",
        "name": "Swahili",
        "nativeName": "Kiswahili"
       }
      ],
      "translations": {
       "br": "Quênia",
       "de": "Kenia",
       "es": "Kenia",
       "fa": "کنیا",
       "fr": "Kenya",
       "hr": "Kenija",
       "it": "Kenya",
       "ja": "ケニア",
       "nl": "Kenia",
       "pt": "Quénia"
      }
     },
     "name": "Kenya",
     "phoneprefix": [
      254
     ]
    },
    {
     "code": "KIR",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       },
       {
        "code": "(none)",
        "name": "Kiribati dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kir.svg",
     "geo": {
      "area": 811,
      "borders": [],
      "capital": "South Tarawa",
      "latlng": [
       1.41666666,
       173
      ],
      "population": 113400,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+12:00",
       "UTC+13:00",
       "UTC+14:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ki"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Kiribati",
       "de": "Kiribati",
       "es": "Kiribati",
       "fa": "کیریباتی",
       "fr": "Kiribati",
       "hr": "Kiribati",
       "it": "Kiribati",
       "ja": "キリバス",
       "nl": "Kiribati",
       "pt": "Quiribáti"
      }
     },
     "name": "Kiribati",
     "phoneprefix": [
      686
     ]
    },
    {
     "code": "KWT",
     "finance": {
      "currencies": [
       {
        "code": "KWD",
        "name": "Kuwaiti dinar",
        "symbol": "د.ك"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kwt.svg",
     "geo": {
      "area": 17818,
      "borders": [
       "IRN",
       "SAU"
      ],
      "capital": "Kuwait City",
      "latlng": [
       29.5,
       45.75
      ],
      "population": 4183658,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Kuwait",
       "de": "Kuwait",
       "es": "Kuwait",
       "fa": "کویت",
       "fr": "Koweït",
       "hr": "Kuvajt",
       "it": "Kuwait",
       "ja": "クウェート",
       "nl": "Koeweit",
       "pt": "Kuwait"
      }
     },
     "name": "Koweït",
     "phoneprefix": [
      965
     ]
    },
    {
     "code": "KGZ",
     "finance": {
      "currencies": [
       {
        "code": "KGS",
        "name": "Kyrgyzstani som",
        "symbol": "с"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kgz.svg",
     "geo": {
      "area": 199951,
      "borders": [
       "CHN",
       "KAZ",
       "TJK",
       "UZB"
      ],
      "capital": "Bishkek",
      "latlng": [
       41,
       75
      ],
      "population": 6047800,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "EEU",
        "name": "Eurasian Economic Union",
        "otherAcronyms": [
         "EAEU"
        ],
        "otherNames": []
       }
      ],
      "subregion": "Central Asia",
      "timezones": [
       "UTC+06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ky",
        "iso639_2": "kir",
        "name": "Kyrgyz",
        "nativeName": "Кыргызча"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Quirguistão",
       "de": "Kirgisistan",
       "es": "Kirguizistán",
       "fa": "قرقیزستان",
       "fr": "Kirghizistan",
       "hr": "Kirgistan",
       "it": "Kirghizistan",
       "ja": "キルギス",
       "nl": "Kirgizië",
       "pt": "Quirguizistão"
      }
     },
     "name": "Kirghizistan",
     "phoneprefix": [
      996
     ]
    },
    {
     "code": "LAO",
     "finance": {
      "currencies": [
       {
        "code": "LAK",
        "name": "Lao kip",
        "symbol": "₭"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lao.svg",
     "geo": {
      "area": 236800,
      "borders": [
       "MMR",
       "KHM",
       "CHN",
       "THA",
       "VNM"
      ],
      "capital": "Vientiane",
      "latlng": [
       18,
       105
      ],
      "population": 6492400,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+07:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".la"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "lo",
        "iso639_2": "lao",
        "name": "Lao",
        "nativeName": "ພາສາລາວ"
       }
      ],
      "translations": {
       "br": "Laos",
       "de": "Laos",
       "es": "Laos",
       "fa": "لائوس",
       "fr": "Laos",
       "hr": "Laos",
       "it": "Laos",
       "ja": "ラオス人民民主共和国",
       "nl": "Laos",
       "pt": "Laos"
      }
     },
     "name": "Laos",
     "phoneprefix": [
      856
     ]
    },
    {
     "code": "LVA",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lva.svg",
     "geo": {
      "area": 64559,
      "borders": [
       "BLR",
       "EST",
       "LTU",
       "RUS"
      ],
      "capital": "Riga",
      "latlng": [
       57,
       25
      ],
      "population": 1961600,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lv"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "lv",
        "iso639_2": "lav",
        "name": "Latvian",
        "nativeName": "latviešu valoda"
       }
      ],
      "translations": {
       "br": "Letônia",
       "de": "Lettland",
       "es": "Letonia",
       "fa": "لتونی",
       "fr": "Lettonie",
       "hr": "Latvija",
       "it": "Lettonia",
       "ja": "ラトビア",
       "nl": "Letland",
       "pt": "Letónia"
      }
     },
     "name": "Lettonie",
     "phoneprefix": [
      371
     ]
    },
    {
     "code": "LBN",
     "finance": {
      "currencies": [
       {
        "code": "LBP",
        "name": "Lebanese pound",
        "symbol": "ل.ل"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lbn.svg",
     "geo": {
      "area": 10452,
      "borders": [
       "ISR",
       "SYR"
      ],
      "capital": "Beirut",
      "latlng": [
       33.83333333,
       35.83333333
      ],
      "population": 5988000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lb"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Líbano",
       "de": "Libanon",
       "es": "Líbano",
       "fa": "لبنان",
       "fr": "Liban",
       "hr": "Libanon",
       "it": "Libano",
       "ja": "レバノン",
       "nl": "Libanon",
       "pt": "Líbano"
      }
     },
     "name": "Liban",
     "phoneprefix": [
      961
     ]
    },
    {
     "code": "LSO",
     "finance": {
      "currencies": [
       {
        "code": "LSL",
        "name": "Lesotho loti",
        "symbol": "L"
       },
       {
        "code": "ZAR",
        "name": "South African rand",
        "symbol": "R"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lso.svg",
     "geo": {
      "area": 30355,
      "borders": [
       "ZAF"
      ],
      "capital": "Maseru",
      "latlng": [
       -29.5,
       28.5
      ],
      "population": 1894194,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Southern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ls"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "st",
        "iso639_2": "sot",
        "name": "Southern Sotho",
        "nativeName": "Sesotho"
       }
      ],
      "translations": {
       "br": "Lesoto",
       "de": "Lesotho",
       "es": "Lesotho",
       "fa": "لسوتو",
       "fr": "Lesotho",
       "hr": "Lesoto",
       "it": "Lesotho",
       "ja": "レソト",
       "nl": "Lesotho",
       "pt": "Lesoto"
      }
     },
     "name": "Lesotho",
     "phoneprefix": [
      266
     ]
    },
    {
     "code": "LBR",
     "finance": {
      "currencies": [
       {
        "code": "LRD",
        "name": "Liberian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lbr.svg",
     "geo": {
      "area": 111369,
      "borders": [
       "GIN",
       "CIV",
       "SLE"
      ],
      "capital": "Monrovia",
      "latlng": [
       6.5,
       -9.5
      ],
      "population": 4615000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Libéria",
       "de": "Liberia",
       "es": "Liberia",
       "fa": "لیبریا",
       "fr": "Liberia",
       "hr": "Liberija",
       "it": "Liberia",
       "ja": "リベリア",
       "nl": "Liberia",
       "pt": "Libéria"
      }
     },
     "name": "Liberia",
     "phoneprefix": [
      231
     ]
    },
    {
     "code": "LBY",
     "finance": {
      "currencies": [
       {
        "code": "LYD",
        "name": "Libyan dinar",
        "symbol": "ل.د"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lby.svg",
     "geo": {
      "area": 1759540,
      "borders": [
       "DZA",
       "TCD",
       "EGY",
       "NER",
       "SDN",
       "TUN"
      ],
      "capital": "Tripoli",
      "latlng": [
       25,
       17
      ],
      "population": 6385000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ly"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Líbia",
       "de": "Libyen",
       "es": "Libia",
       "fa": "لیبی",
       "fr": "Libye",
       "hr": "Libija",
       "it": "Libia",
       "ja": "リビア",
       "nl": "Libië",
       "pt": "Líbia"
      }
     },
     "name": "Libye",
     "phoneprefix": [
      218
     ]
    },
    {
     "code": "LIE",
     "finance": {
      "currencies": [
       {
        "code": "CHF",
        "name": "Swiss franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lie.svg",
     "geo": {
      "area": 160,
      "borders": [
       "AUT",
       "CHE"
      ],
      "capital": "Vaduz",
      "latlng": [
       47.26666666,
       9.53333333
      ],
      "population": 37623,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EFTA",
        "name": "European Free Trade Association",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".li"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       }
      ],
      "translations": {
       "br": "Liechtenstein",
       "de": "Liechtenstein",
       "es": "Liechtenstein",
       "fa": "لیختن‌اشتاین",
       "fr": "Liechtenstein",
       "hr": "Lihtenštajn",
       "it": "Liechtenstein",
       "ja": "リヒテンシュタイン",
       "nl": "Liechtenstein",
       "pt": "Listenstaine"
      }
     },
     "name": "Liechtenstein",
     "phoneprefix": [
      423
     ]
    },
    {
     "code": "LTU",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ltu.svg",
     "geo": {
      "area": 65300,
      "borders": [
       "BLR",
       "LVA",
       "POL",
       "RUS"
      ],
      "capital": "Vilnius",
      "latlng": [
       56,
       24
      ],
      "population": 2872294,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "lt",
        "iso639_2": "lit",
        "name": "Lithuanian",
        "nativeName": "lietuvių kalba"
       }
      ],
      "translations": {
       "br": "Lituânia",
       "de": "Litauen",
       "es": "Lituania",
       "fa": "لیتوانی",
       "fr": "Lituanie",
       "hr": "Litva",
       "it": "Lituania",
       "ja": "リトアニア",
       "nl": "Litouwen",
       "pt": "Lituânia"
      }
     },
     "name": "Lituanie",
     "phoneprefix": [
      370
     ]
    },
    {
     "code": "LUX",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lux.svg",
     "geo": {
      "area": 2586,
      "borders": [
       "BEL",
       "FRA",
       "DEU"
      ],
      "capital": "Luxembourg",
      "latlng": [
       49.75,
       6.16666666
      ],
      "population": 576200,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       },
       {
        "iso639_1": "lb",
        "iso639_2": "ltz",
        "name": "Luxembourgish",
        "nativeName": "Lëtzebuergesch"
       }
      ],
      "translations": {
       "br": "Luxemburgo",
       "de": "Luxemburg",
       "es": "Luxemburgo",
       "fa": "لوکزامبورگ",
       "fr": "Luxembourg",
       "hr": "Luksemburg",
       "it": "Lussemburgo",
       "ja": "ルクセンブルク",
       "nl": "Luxemburg",
       "pt": "Luxemburgo"
      }
     },
     "name": "Luxembourg",
     "phoneprefix": [
      352
     ]
    },
    {
     "code": "MAC",
     "finance": {
      "currencies": [
       {
        "code": "MOP",
        "name": "Macanese pataca",
        "symbol": "P"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mac.svg",
     "geo": {
      "area": 30,
      "borders": [
       "CHN"
      ],
      "capital": "",
      "latlng": [
       22.16666666,
       113.55
      ],
      "population": 649100,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mo"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "zh",
        "iso639_2": "zho",
        "name": "Chinese",
        "nativeName": "中文 (Zhōngwén)"
       },
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Macau",
       "de": "Macao",
       "es": "Macao",
       "fa": "مکائو",
       "fr": "Macao",
       "hr": "Makao",
       "it": "Macao",
       "ja": "マカオ",
       "nl": "Macao",
       "pt": "Macau"
      }
     },
     "name": "Macao",
     "phoneprefix": [
      853
     ]
    },
    {
     "code": "MKD",
     "finance": {
      "currencies": [
       {
        "code": "MKD",
        "name": "Macedonian denar",
        "symbol": "ден"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mkd.svg",
     "geo": {
      "area": 25713,
      "borders": [
       "ALB",
       "BGR",
       "GRC",
       "KOS",
       "SRB"
      ],
      "capital": "Skopje",
      "latlng": [
       41.83333333,
       22
      ],
      "population": 2058539,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "mk",
        "iso639_2": "mkd",
        "name": "Macedonian",
        "nativeName": "македонски јазик"
       }
      ],
      "translations": {
       "br": "Macedônia",
       "de": "Mazedonien",
       "es": "Macedonia",
       "fa": "",
       "fr": "Macédoine",
       "hr": "Makedonija",
       "it": "Macedonia",
       "ja": "マケドニア旧ユーゴスラビア共和国",
       "nl": "Macedonië",
       "pt": "Macedónia"
      }
     },
     "name": "Macédoine",
     "phoneprefix": [
      389
     ]
    },
    {
     "code": "MDG",
     "finance": {
      "currencies": [
       {
        "code": "MGA",
        "name": "Malagasy ariary",
        "symbol": "Ar"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mdg.svg",
     "geo": {
      "area": 587041,
      "borders": [],
      "capital": "Antananarivo",
      "latlng": [
       -20,
       47
      ],
      "population": 22434363,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "mg",
        "iso639_2": "mlg",
        "name": "Malagasy",
        "nativeName": "fiteny malagasy"
       }
      ],
      "translations": {
       "br": "Madagascar",
       "de": "Madagaskar",
       "es": "Madagascar",
       "fa": "ماداگاسکار",
       "fr": "Madagascar",
       "hr": "Madagaskar",
       "it": "Madagascar",
       "ja": "マダガスカル",
       "nl": "Madagaskar",
       "pt": "Madagáscar"
      }
     },
     "name": "Madagascar",
     "phoneprefix": [
      261
     ]
    },
    {
     "code": "MWI",
     "finance": {
      "currencies": [
       {
        "code": "MWK",
        "name": "Malawian kwacha",
        "symbol": "MK"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mwi.svg",
     "geo": {
      "area": 118484,
      "borders": [
       "MOZ",
       "TZA",
       "ZMB"
      ],
      "capital": "Lilongwe",
      "latlng": [
       -13.5,
       34
      ],
      "population": 16832910,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ny",
        "iso639_2": "nya",
        "name": "Chichewa",
        "nativeName": "chiCheŵa"
       }
      ],
      "translations": {
       "br": "Malawi",
       "de": "Malawi",
       "es": "Malawi",
       "fa": "مالاوی",
       "fr": "Malawi",
       "hr": "Malavi",
       "it": "Malawi",
       "ja": "マラウイ",
       "nl": "Malawi",
       "pt": "Malávi"
      }
     },
     "name": "Malawi",
     "phoneprefix": [
      265
     ]
    },
    {
     "code": "MYS",
     "finance": {
      "currencies": [
       {
        "code": "MYR",
        "name": "Malaysian ringgit",
        "symbol": "RM"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mys.svg",
     "geo": {
      "area": 330803,
      "borders": [
       "BRN",
       "IDN",
       "THA"
      ],
      "capital": "Kuala Lumpur",
      "latlng": [
       2.5,
       112.5
      ],
      "population": 31405416,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".my"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": null,
        "iso639_2": "zsm",
        "name": "Malaysian",
        "nativeName": "بهاس مليسيا"
       }
      ],
      "translations": {
       "br": "Malásia",
       "de": "Malaysia",
       "es": "Malasia",
       "fa": "مالزی",
       "fr": "Malaisie",
       "hr": "Malezija",
       "it": "Malesia",
       "ja": "マレーシア",
       "nl": "Maleisië",
       "pt": "Malásia"
      }
     },
     "name": "Malaisie",
     "phoneprefix": [
      60
     ]
    },
    {
     "code": "MDV",
     "finance": {
      "currencies": [
       {
        "code": "MVR",
        "name": "Maldivian rufiyaa",
        "symbol": ".ރ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mdv.svg",
     "geo": {
      "area": 300,
      "borders": [],
      "capital": "Malé",
      "latlng": [
       3.25,
       73
      ],
      "population": 344023,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mv"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "dv",
        "iso639_2": "div",
        "name": "Divehi",
        "nativeName": "ދިވެހި"
       }
      ],
      "translations": {
       "br": "Maldivas",
       "de": "Malediven",
       "es": "Maldivas",
       "fa": "مالدیو",
       "fr": "Maldives",
       "hr": "Maldivi",
       "it": "Maldive",
       "ja": "モルディブ",
       "nl": "Maldiven",
       "pt": "Maldivas"
      }
     },
     "name": "Maldives",
     "phoneprefix": [
      960
     ]
    },
    {
     "code": "MLI",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mli.svg",
     "geo": {
      "area": 1240192,
      "borders": [
       "DZA",
       "BFA",
       "GIN",
       "CIV",
       "MRT",
       "NER",
       "SEN"
      ],
      "capital": "Bamako",
      "latlng": [
       17,
       -4
      ],
      "population": 18135000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ml"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Mali",
       "de": "Mali",
       "es": "Mali",
       "fa": "مالی",
       "fr": "Mali",
       "hr": "Mali",
       "it": "Mali",
       "ja": "マリ",
       "nl": "Mali",
       "pt": "Mali"
      }
     },
     "name": "Mali",
     "phoneprefix": [
      223
     ]
    },
    {
     "code": "MLT",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mlt.svg",
     "geo": {
      "area": 316,
      "borders": [],
      "capital": "Valletta",
      "latlng": [
       35.83333333,
       14.58333333
      ],
      "population": 425384,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "mt",
        "iso639_2": "mlt",
        "name": "Maltese",
        "nativeName": "Malti"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Malta",
       "de": "Malta",
       "es": "Malta",
       "fa": "مالت",
       "fr": "Malte",
       "hr": "Malta",
       "it": "Malta",
       "ja": "マルタ",
       "nl": "Malta",
       "pt": "Malta"
      }
     },
     "name": "Malte",
     "phoneprefix": [
      356
     ]
    },
    {
     "code": "MHL",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mhl.svg",
     "geo": {
      "area": 181,
      "borders": [],
      "capital": "Majuro",
      "latlng": [
       9,
       168
      ],
      "population": 54880,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mh"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "mh",
        "iso639_2": "mah",
        "name": "Marshallese",
        "nativeName": "Kajin M̧ajeļ"
       }
      ],
      "translations": {
       "br": "Ilhas Marshall",
       "de": "Marshallinseln",
       "es": "Islas Marshall",
       "fa": "جزایر مارشال",
       "fr": "Îles Marshall",
       "hr": "Maršalovi Otoci",
       "it": "Isole Marshall",
       "ja": "マーシャル諸島",
       "nl": "Marshalleilanden",
       "pt": "Ilhas Marshall"
      }
     },
     "name": "Îles Marshall",
     "phoneprefix": [
      692
     ]
    },
    {
     "code": "MTQ",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mtq.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "Fort-de-France",
      "latlng": [
       14.666667,
       -61
      ],
      "population": 378243,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mq"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Martinica",
       "de": "Martinique",
       "es": "Martinica",
       "fa": "مونتسرات",
       "fr": "Martinique",
       "hr": "Martinique",
       "it": "Martinica",
       "ja": "マルティニーク",
       "nl": "Martinique",
       "pt": "Martinica"
      }
     },
     "name": "Martinique",
     "phoneprefix": [
      596
     ]
    },
    {
     "code": "MRT",
     "finance": {
      "currencies": [
       {
        "code": "MRO",
        "name": "Mauritanian ouguiya",
        "symbol": "UM"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mrt.svg",
     "geo": {
      "area": 1030700,
      "borders": [
       "DZA",
       "MLI",
       "SEN",
       "ESH"
      ],
      "capital": "Nouakchott",
      "latlng": [
       20,
       -12
      ],
      "population": 3718678,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Mauritânia",
       "de": "Mauretanien",
       "es": "Mauritania",
       "fa": "موریتانی",
       "fr": "Mauritanie",
       "hr": "Mauritanija",
       "it": "Mauritania",
       "ja": "モーリタニア",
       "nl": "Mauritanië",
       "pt": "Mauritânia"
      }
     },
     "name": "Mauritanie",
     "phoneprefix": [
      222
     ]
    },
    {
     "code": "MUS",
     "finance": {
      "currencies": [
       {
        "code": "MUR",
        "name": "Mauritian rupee",
        "symbol": "₨"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mus.svg",
     "geo": {
      "area": 2040,
      "borders": [],
      "capital": "Port Louis",
      "latlng": [
       -20.28333333,
       57.55
      ],
      "population": 1262879,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Maurício",
       "de": "Mauritius",
       "es": "Mauricio",
       "fa": "موریس",
       "fr": "Île Maurice",
       "hr": "Mauricijus",
       "it": "Mauritius",
       "ja": "モーリシャス",
       "nl": "Mauritius",
       "pt": "Maurícia"
      }
     },
     "name": "Île Maurice",
     "phoneprefix": [
      230
     ]
    },
    {
     "code": "MYT",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/myt.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "Mamoudzou",
      "latlng": [
       -12.83333333,
       45.16666666
      ],
      "population": 226915,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".yt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Mayotte",
       "de": "Mayotte",
       "es": "Mayotte",
       "fa": "مایوت",
       "fr": "Mayotte",
       "hr": "Mayotte",
       "it": "Mayotte",
       "ja": "マヨット",
       "nl": "Mayotte",
       "pt": "Mayotte"
      }
     },
     "name": "Mayotte",
     "phoneprefix": [
      262
     ]
    },
    {
     "code": "MEX",
     "finance": {
      "currencies": [
       {
        "code": "MXN",
        "name": "Mexican peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mex.svg",
     "geo": {
      "area": 1964375,
      "borders": [
       "BLZ",
       "GTM",
       "USA"
      ],
      "capital": "Mexico City",
      "latlng": [
       23,
       -102
      ],
      "population": 122273473,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "PA",
        "name": "Pacific Alliance",
        "otherAcronyms": [],
        "otherNames": [
         "Alianza del Pacífico"
        ]
       },
       {
        "acronym": "NAFTA",
        "name": "North American Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": [
         "Tratado de Libre Comercio de América del Norte",
         "Accord de Libre-échange Nord-Américain"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-08:00",
       "UTC-07:00",
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mx"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "México",
       "de": "Mexiko",
       "es": "México",
       "fa": "مکزیک",
       "fr": "Mexique",
       "hr": "Meksiko",
       "it": "Messico",
       "ja": "メキシコ",
       "nl": "Mexico",
       "pt": "México"
      }
     },
     "name": "Mexique",
     "phoneprefix": [
      52
     ]
    },
    {
     "code": "FSM",
     "finance": {
      "currencies": [
       {
        "code": null,
        "name": "[D]",
        "symbol": "$"
       },
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/fsm.svg",
     "geo": {
      "area": 702,
      "borders": [],
      "capital": "Palikir",
      "latlng": [
       6.91666666,
       158.25
      ],
      "population": 102800,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+10:00",
       "UTC+11"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".fm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Micronésia",
       "de": "Mikronesien",
       "es": "Micronesia",
       "fa": "ایالات فدرال میکرونزی",
       "fr": "Micronésie",
       "hr": "Mikronezija",
       "it": "Micronesia",
       "ja": "ミクロネシア連邦",
       "nl": "Micronesië",
       "pt": "Micronésia"
      }
     },
     "name": "Micronésie",
     "phoneprefix": [
      691
     ]
    },
    {
     "code": "MDA",
     "finance": {
      "currencies": [
       {
        "code": "MDL",
        "name": "Moldovan leu",
        "symbol": "L"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mda.svg",
     "geo": {
      "area": 33846,
      "borders": [
       "ROU",
       "UKR"
      ],
      "capital": "Chișinău",
      "latlng": [
       47,
       29
      ],
      "population": 3553100,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".md"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ro",
        "iso639_2": "ron",
        "name": "Romanian",
        "nativeName": "Română"
       }
      ],
      "translations": {
       "br": "Moldávia",
       "de": "Moldawie",
       "es": "Moldavia",
       "fa": "مولداوی",
       "fr": "Moldavie",
       "hr": "Moldova",
       "it": "Moldavia",
       "ja": "モルドバ共和国",
       "nl": "Moldavië",
       "pt": "Moldávia"
      }
     },
     "name": "Moldavie",
     "phoneprefix": [
      373
     ]
    },
    {
     "code": "MCO",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mco.svg",
     "geo": {
      "area": 2.02,
      "borders": [
       "FRA"
      ],
      "capital": "Monaco",
      "latlng": [
       43.73333333,
       7.4
      ],
      "population": 38400,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Mônaco",
       "de": "Monaco",
       "es": "Mónaco",
       "fa": "موناکو",
       "fr": "Monaco",
       "hr": "Monako",
       "it": "Principato di Monaco",
       "ja": "モナコ",
       "nl": "Monaco",
       "pt": "Mónaco"
      }
     },
     "name": "Monaco",
     "phoneprefix": [
      377
     ]
    },
    {
     "code": "MNG",
     "finance": {
      "currencies": [
       {
        "code": "MNT",
        "name": "Mongolian tögrög",
        "symbol": "₮"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mng.svg",
     "geo": {
      "area": 1564110,
      "borders": [
       "CHN",
       "RUS"
      ],
      "capital": "Ulan Bator",
      "latlng": [
       46,
       105
      ],
      "population": 3093100,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+07:00",
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "mn",
        "iso639_2": "mon",
        "name": "Mongolian",
        "nativeName": "Монгол хэл"
       }
      ],
      "translations": {
       "br": "Mongólia",
       "de": "Mongolei",
       "es": "Mongolia",
       "fa": "مغولستان",
       "fr": "Mongolie",
       "hr": "Mongolija",
       "it": "Mongolia",
       "ja": "モンゴル",
       "nl": "Mongolië",
       "pt": "Mongólia"
      }
     },
     "name": "Mongolie",
     "phoneprefix": [
      976
     ]
    },
    {
     "code": "MNE",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mne.svg",
     "geo": {
      "area": 13812,
      "borders": [
       "ALB",
       "BIH",
       "HRV",
       "KOS",
       "SRB"
      ],
      "capital": "Podgorica",
      "latlng": [
       42.5,
       19.3
      ],
      "population": 621810,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".me"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sr",
        "iso639_2": "srp",
        "name": "Serbian",
        "nativeName": "српски језик"
       },
       {
        "iso639_1": "bs",
        "iso639_2": "bos",
        "name": "Bosnian",
        "nativeName": "bosanski jezik"
       },
       {
        "iso639_1": "sq",
        "iso639_2": "sqi",
        "name": "Albanian",
        "nativeName": "Shqip"
       },
       {
        "iso639_1": "hr",
        "iso639_2": "hrv",
        "name": "Croatian",
        "nativeName": "hrvatski jezik"
       }
      ],
      "translations": {
       "br": "Montenegro",
       "de": "Montenegro",
       "es": "Montenegro",
       "fa": "مونته‌نگرو",
       "fr": "Monténégro",
       "hr": "Crna Gora",
       "it": "Montenegro",
       "ja": "モンテネグロ",
       "nl": "Montenegro",
       "pt": "Montenegro"
      }
     },
     "name": "Monténégro",
     "phoneprefix": [
      382
     ]
    },
    {
     "code": "MSR",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/msr.svg",
     "geo": {
      "area": 102,
      "borders": [],
      "capital": "Plymouth",
      "latlng": [
       16.75,
       -62.2
      ],
      "population": 4922,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ms"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Montserrat",
       "de": "Montserrat",
       "es": "Montserrat",
       "fa": "مایوت",
       "fr": "Montserrat",
       "hr": "Montserrat",
       "it": "Montserrat",
       "ja": "モントセラト",
       "nl": "Montserrat",
       "pt": "Monserrate"
      }
     },
     "name": "Montserrat",
     "phoneprefix": [
      1664
     ]
    },
    {
     "code": "MAR",
     "finance": {
      "currencies": [
       {
        "code": "MAD",
        "name": "Moroccan dirham",
        "symbol": "د.م."
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mar.svg",
     "geo": {
      "area": 446550,
      "borders": [
       "DZA",
       "ESH",
       "ESP"
      ],
      "capital": "Rabat",
      "latlng": [
       32,
       -5
      ],
      "population": 33337529,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ma"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Marrocos",
       "de": "Marokko",
       "es": "Marruecos",
       "fa": "مراکش",
       "fr": "Maroc",
       "hr": "Maroko",
       "it": "Marocco",
       "ja": "モロッコ",
       "nl": "Marokko",
       "pt": "Marrocos"
      }
     },
     "name": "Maroc",
     "phoneprefix": [
      212
     ]
    },
    {
     "code": "MOZ",
     "finance": {
      "currencies": [
       {
        "code": "MZN",
        "name": "Mozambican metical",
        "symbol": "MT"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/moz.svg",
     "geo": {
      "area": 801590,
      "borders": [
       "MWI",
       "ZAF",
       "SWZ",
       "TZA",
       "ZMB",
       "ZWE"
      ],
      "capital": "Maputo",
      "latlng": [
       -18.25,
       35
      ],
      "population": 26423700,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Moçambique",
       "de": "Mosambik",
       "es": "Mozambique",
       "fa": "موزامبیک",
       "fr": "Mozambique",
       "hr": "Mozambik",
       "it": "Mozambico",
       "ja": "モザンビーク",
       "nl": "Mozambique",
       "pt": "Moçambique"
      }
     },
     "name": "Mozambique",
     "phoneprefix": [
      258
     ]
    },
    {
     "code": "MMR",
     "finance": {
      "currencies": [
       {
        "code": "MMK",
        "name": "Burmese kyat",
        "symbol": "Ks"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mmr.svg",
     "geo": {
      "area": 676578,
      "borders": [
       "BGD",
       "CHN",
       "IND",
       "LAO",
       "THA"
      ],
      "capital": "Naypyidaw",
      "latlng": [
       22,
       98
      ],
      "population": 51419420,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+06:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "my",
        "iso639_2": "mya",
        "name": "Burmese",
        "nativeName": "ဗမာစာ"
       }
      ],
      "translations": {
       "br": "Myanmar",
       "de": "Myanmar",
       "es": "Myanmar",
       "fa": "میانمار",
       "fr": "Myanmar",
       "hr": "Mijanmar",
       "it": "Birmania",
       "ja": "ミャンマー",
       "nl": "Myanmar",
       "pt": "Myanmar"
      }
     },
     "name": "Myanmar",
     "phoneprefix": [
      95
     ]
    },
    {
     "code": "NAM",
     "finance": {
      "currencies": [
       {
        "code": "NAD",
        "name": "Namibian dollar",
        "symbol": "$"
       },
       {
        "code": "ZAR",
        "name": "South African rand",
        "symbol": "R"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nam.svg",
     "geo": {
      "area": 825615,
      "borders": [
       "AGO",
       "BWA",
       "ZAF",
       "ZMB"
      ],
      "capital": "Windhoek",
      "latlng": [
       -22,
       17
      ],
      "population": 2324388,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Southern Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".na"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "af",
        "iso639_2": "afr",
        "name": "Afrikaans",
        "nativeName": "Afrikaans"
       }
      ],
      "translations": {
       "br": "Namíbia",
       "de": "Namibia",
       "es": "Namibia",
       "fa": "نامیبیا",
       "fr": "Namibie",
       "hr": "Namibija",
       "it": "Namibia",
       "ja": "ナミビア",
       "nl": "Namibië",
       "pt": "Namíbia"
      }
     },
     "name": "Namibie",
     "phoneprefix": [
      264
     ]
    },
    {
     "code": "NRU",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       },
       {
        "code": "(none)",
        "name": null,
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nru.svg",
     "geo": {
      "area": 21,
      "borders": [],
      "capital": "Yaren",
      "latlng": [
       -0.53333333,
       166.91666666
      ],
      "population": 10084,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".nr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "na",
        "iso639_2": "nau",
        "name": "Nauruan",
        "nativeName": "Dorerin Naoero"
       }
      ],
      "translations": {
       "br": "Nauru",
       "de": "Nauru",
       "es": "Nauru",
       "fa": "نائورو",
       "fr": "Nauru",
       "hr": "Nauru",
       "it": "Nauru",
       "ja": "ナウル",
       "nl": "Nauru",
       "pt": "Nauru"
      }
     },
     "name": "Nauru",
     "phoneprefix": [
      674
     ]
    },
    {
     "code": "NPL",
     "finance": {
      "currencies": [
       {
        "code": "NPR",
        "name": "Nepalese rupee",
        "symbol": "₨"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/npl.svg",
     "geo": {
      "area": 147181,
      "borders": [
       "CHN",
       "IND"
      ],
      "capital": "Kathmandu",
      "latlng": [
       28,
       84
      ],
      "population": 28431500,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+05:45"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".np"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ne",
        "iso639_2": "nep",
        "name": "Nepali",
        "nativeName": "नेपाली"
       }
      ],
      "translations": {
       "br": "Nepal",
       "de": "Népal",
       "es": "Nepal",
       "fa": "نپال",
       "fr": "Népal",
       "hr": "Nepal",
       "it": "Nepal",
       "ja": "ネパール",
       "nl": "Nepal",
       "pt": "Nepal"
      }
     },
     "name": "Népal",
     "phoneprefix": [
      977
     ]
    },
    {
     "code": "NLD",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nld.svg",
     "geo": {
      "area": 41850,
      "borders": [
       "BEL",
       "DEU"
      ],
      "capital": "Amsterdam",
      "latlng": [
       52.5,
       5.75
      ],
      "population": 17019800,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC-04:00",
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".nl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       }
      ],
      "translations": {
       "br": "Holanda",
       "de": "Niederlande",
       "es": "Países Bajos",
       "fa": "پادشاهی هلند",
       "fr": "Pays-Bas",
       "hr": "Nizozemska",
       "it": "Paesi Bassi",
       "ja": "オランダ",
       "nl": "Nederland",
       "pt": "Países Baixos"
      }
     },
     "name": "Pays-Bas",
     "phoneprefix": [
      31
     ]
    },
    {
     "code": "NCL",
     "finance": {
      "currencies": [
       {
        "code": "XPF",
        "name": "CFP franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ncl.svg",
     "geo": {
      "area": 18575,
      "borders": [],
      "capital": "Nouméa",
      "latlng": [
       -21.5,
       165.5
      ],
      "population": 268767,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Melanesia",
      "timezones": [
       "UTC+11:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".nc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Nova Caledônia",
       "de": "Neukaledonien",
       "es": "Nueva Caledonia",
       "fa": "کالدونیای جدید",
       "fr": "Nouvelle-Calédonie",
       "hr": "Nova Kaledonija",
       "it": "Nuova Caledonia",
       "ja": "ニューカレドニア",
       "nl": "Nieuw-Caledonië",
       "pt": "Nova Caledónia"
      }
     },
     "name": "Nouvelle-Calédonie",
     "phoneprefix": [
      687
     ]
    },
    {
     "code": "NZL",
     "finance": {
      "currencies": [
       {
        "code": "NZD",
        "name": "New Zealand dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nzl.svg",
     "geo": {
      "area": 270467,
      "borders": [],
      "capital": "Wellington",
      "latlng": [
       -41,
       174
      ],
      "population": 4697854,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Australia and New Zealand",
      "timezones": [
       "UTC-11:00",
       "UTC-10:00",
       "UTC+12:00",
       "UTC+12:45",
       "UTC+13:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".nz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "mi",
        "iso639_2": "mri",
        "name": "Māori",
        "nativeName": "te reo Māori"
       }
      ],
      "translations": {
       "br": "Nova Zelândia",
       "de": "Neuseeland",
       "es": "Nueva Zelanda",
       "fa": "نیوزیلند",
       "fr": "Nouvelle-Zélande",
       "hr": "Novi Zeland",
       "it": "Nuova Zelanda",
       "ja": "ニュージーランド",
       "nl": "Nieuw-Zeeland",
       "pt": "Nova Zelândia"
      }
     },
     "name": "Nouvelle-Zélande",
     "phoneprefix": [
      64
     ]
    },
    {
     "code": "NIC",
     "finance": {
      "currencies": [
       {
        "code": "NIO",
        "name": "Nicaraguan córdoba",
        "symbol": "C$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nic.svg",
     "geo": {
      "area": 130373,
      "borders": [
       "CRI",
       "HND"
      ],
      "capital": "Managua",
      "latlng": [
       13,
       -85
      ],
      "population": 6262703,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ni"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Nicarágua",
       "de": "Nicaragua",
       "es": "Nicaragua",
       "fa": "نیکاراگوئه",
       "fr": "Nicaragua",
       "hr": "Nikaragva",
       "it": "Nicaragua",
       "ja": "ニカラグア",
       "nl": "Nicaragua",
       "pt": "Nicarágua"
      }
     },
     "name": "Nicaragua",
     "phoneprefix": [
      505
     ]
    },
    {
     "code": "NER",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ner.svg",
     "geo": {
      "area": 1267000,
      "borders": [
       "DZA",
       "BEN",
       "BFA",
       "TCD",
       "LBY",
       "MLI",
       "NGA"
      ],
      "capital": "Niamey",
      "latlng": [
       16,
       8
      ],
      "population": 20715000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ne"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Níger",
       "de": "Niger",
       "es": "Níger",
       "fa": "نیجر",
       "fr": "Niger",
       "hr": "Niger",
       "it": "Niger",
       "ja": "ニジェール",
       "nl": "Niger",
       "pt": "Níger"
      }
     },
     "name": "Niger",
     "phoneprefix": [
      227
     ]
    },
    {
     "code": "NGA",
     "finance": {
      "currencies": [
       {
        "code": "NGN",
        "name": "Nigerian naira",
        "symbol": "₦"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nga.svg",
     "geo": {
      "area": 923768,
      "borders": [
       "BEN",
       "CMR",
       "TCD",
       "NER"
      ],
      "capital": "Abuja",
      "latlng": [
       10,
       8
      ],
      "population": 186988000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ng"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Nigéria",
       "de": "Nigeria",
       "es": "Nigeria",
       "fa": "نیجریه",
       "fr": "Nigéria",
       "hr": "Nigerija",
       "it": "Nigeria",
       "ja": "ナイジェリア",
       "nl": "Nigeria",
       "pt": "Nigéria"
      }
     },
     "name": "Nigéria",
     "phoneprefix": [
      234
     ]
    },
    {
     "code": "NIU",
     "finance": {
      "currencies": [
       {
        "code": "NZD",
        "name": "New Zealand dollar",
        "symbol": "$"
       },
       {
        "code": "(none)",
        "name": "Niue dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/niu.svg",
     "geo": {
      "area": 260,
      "borders": [],
      "capital": "Alofi",
      "latlng": [
       -19.03333333,
       -169.86666666
      ],
      "population": 1470,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC-11:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".nu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Niue",
       "de": "Niue",
       "es": "Niue",
       "fa": "نیووی",
       "fr": "Niue",
       "hr": "Niue",
       "it": "Niue",
       "ja": "ニウエ",
       "nl": "Niue",
       "pt": "Niue"
      }
     },
     "name": "Niue",
     "phoneprefix": [
      683
     ]
    },
    {
     "code": "NFK",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nfk.svg",
     "geo": {
      "area": 36,
      "borders": [],
      "capital": "Kingston",
      "latlng": [
       -29.03333333,
       167.95
      ],
      "population": 2302,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Australia and New Zealand",
      "timezones": [
       "UTC+11:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".nf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilha Norfolk",
       "de": "Norfolkinsel",
       "es": "Isla de Norfolk",
       "fa": "جزیره نورفک",
       "fr": "Île de Norfolk",
       "hr": "Otok Norfolk",
       "it": "Isola Norfolk",
       "ja": "ノーフォーク島",
       "nl": "Norfolkeiland",
       "pt": "Ilha Norfolk"
      }
     },
     "name": "Île de Norfolk",
     "phoneprefix": [
      672
     ]
    },
    {
     "code": "PRK",
     "finance": {
      "currencies": [
       {
        "code": "KPW",
        "name": "North Korean won",
        "symbol": "₩"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/prk.svg",
     "geo": {
      "area": 120538,
      "borders": [
       "CHN",
       "KOR",
       "RUS"
      ],
      "capital": "Pyongyang",
      "latlng": [
       40,
       127
      ],
      "population": 25281000,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kp"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ko",
        "iso639_2": "kor",
        "name": "Korean",
        "nativeName": "한국어"
       }
      ],
      "translations": {
       "br": "Coreia do Norte",
       "de": "Nordkorea",
       "es": "Corea del Norte",
       "fa": "کره جنوبی",
       "fr": "Corée du Nord",
       "hr": "Sjeverna Koreja",
       "it": "Corea del Nord",
       "ja": "朝鮮民主主義人民共和国",
       "nl": "Noord-Korea",
       "pt": "Coreia do Norte"
      }
     },
     "name": "Corée du Nord",
     "phoneprefix": [
      850
     ]
    },
    {
     "code": "MNP",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/mnp.svg",
     "geo": {
      "area": 464,
      "borders": [],
      "capital": "Saipan",
      "latlng": [
       15.2,
       145.75
      ],
      "population": 56940,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+10:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mp"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ch",
        "iso639_2": "cha",
        "name": "Chamorro",
        "nativeName": "Chamoru"
       }
      ],
      "translations": {
       "br": "Ilhas Marianas",
       "de": "Nördliche Marianen",
       "es": "Islas Marianas del Norte",
       "fa": "جزایر ماریانای شمالی",
       "fr": "Îles Mariannes du Nord",
       "hr": "Sjevernomarijanski otoci",
       "it": "Isole Marianne Settentrionali",
       "ja": "北マリアナ諸島",
       "nl": "Noordelijke Marianeneilanden",
       "pt": "Ilhas Marianas"
      }
     },
     "name": "Îles Mariannes du Nord",
     "phoneprefix": [
      1670
     ]
    },
    {
     "code": "NOR",
     "finance": {
      "currencies": [
       {
        "code": "NOK",
        "name": "Norwegian krone",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/nor.svg",
     "geo": {
      "area": 323802,
      "borders": [
       "FIN",
       "SWE",
       "RUS"
      ],
      "capital": "Oslo",
      "latlng": [
       62,
       10
      ],
      "population": 5223256,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EFTA",
        "name": "European Free Trade Association",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".no"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "no",
        "iso639_2": "nor",
        "name": "Norwegian",
        "nativeName": "Norsk"
       },
       {
        "iso639_1": "nb",
        "iso639_2": "nob",
        "name": "Norwegian Bokmål",
        "nativeName": "Norsk bokmål"
       },
       {
        "iso639_1": "nn",
        "iso639_2": "nno",
        "name": "Norwegian Nynorsk",
        "nativeName": "Norsk nynorsk"
       }
      ],
      "translations": {
       "br": "Noruega",
       "de": "Norwegen",
       "es": "Noruega",
       "fa": "نروژ",
       "fr": "Norvège",
       "hr": "Norveška",
       "it": "Norvegia",
       "ja": "ノルウェー",
       "nl": "Noorwegen",
       "pt": "Noruega"
      }
     },
     "name": "Norvège",
     "phoneprefix": [
      47
     ]
    },
    {
     "code": "OMN",
     "finance": {
      "currencies": [
       {
        "code": "OMR",
        "name": "Omani rial",
        "symbol": "ر.ع."
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/omn.svg",
     "geo": {
      "area": 309500,
      "borders": [
       "SAU",
       "ARE",
       "YEM"
      ],
      "capital": "Muscat",
      "latlng": [
       21,
       57
      ],
      "population": 4420133,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".om"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Omã",
       "de": "Oman",
       "es": "Omán",
       "fa": "عمان",
       "fr": "Oman",
       "hr": "Oman",
       "it": "oman",
       "ja": "オマーン",
       "nl": "Oman",
       "pt": "Omã"
      }
     },
     "name": "Oman",
     "phoneprefix": [
      968
     ]
    },
    {
     "code": "PAK",
     "finance": {
      "currencies": [
       {
        "code": "PKR",
        "name": "Pakistani rupee",
        "symbol": "₨"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pak.svg",
     "geo": {
      "area": 881912,
      "borders": [
       "AFG",
       "CHN",
       "IND",
       "IRN"
      ],
      "capital": "Islamabad",
      "latlng": [
       30,
       70
      ],
      "population": 194125062,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ur",
        "iso639_2": "urd",
        "name": "Urdu",
        "nativeName": "اردو"
       }
      ],
      "translations": {
       "br": "Paquistão",
       "de": "Pakistan",
       "es": "Pakistán",
       "fa": "پاکستان",
       "fr": "Pakistan",
       "hr": "Pakistan",
       "it": "Pakistan",
       "ja": "パキスタン",
       "nl": "Pakistan",
       "pt": "Paquistão"
      }
     },
     "name": "Pakistan",
     "phoneprefix": [
      92
     ]
    },
    {
     "code": "PLW",
     "finance": {
      "currencies": [
       {
        "code": "(none)",
        "name": "[E]",
        "symbol": "$"
       },
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/plw.svg",
     "geo": {
      "area": 459,
      "borders": [],
      "capital": "Ngerulmud",
      "latlng": [
       7.5,
       134.5
      ],
      "population": 17950,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Micronesia",
      "timezones": [
       "UTC+09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Palau",
       "de": "Palau",
       "es": "Palau",
       "fa": "پالائو",
       "fr": "Palaos",
       "hr": "Palau",
       "it": "Palau",
       "ja": "パラオ",
       "nl": "Palau",
       "pt": "Palau"
      }
     },
     "name": "Palaos",
     "phoneprefix": [
      680
     ]
    },
    {
     "code": "PSE",
     "finance": {
      "currencies": [
       {
        "code": "ILS",
        "name": "Israeli new sheqel",
        "symbol": "₪"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pse.svg",
     "geo": {
      "area": null,
      "borders": [
       "ISR",
       "EGY",
       "JOR"
      ],
      "capital": "Ramallah",
      "latlng": [
       31.9,
       35.2
      ],
      "population": 4682467,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ps"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Palestina",
       "de": "Palästina",
       "es": "Palestina",
       "fa": "فلسطین",
       "fr": "Palestine",
       "hr": "Palestina",
       "it": "Palestina",
       "ja": "パレスチナ",
       "nl": "Palestijnse gebieden",
       "pt": "Palestina"
      }
     },
     "name": "Palestine",
     "phoneprefix": [
      970
     ]
    },
    {
     "code": "PAN",
     "finance": {
      "currencies": [
       {
        "code": "PAB",
        "name": "Panamanian balboa",
        "symbol": "B/."
       },
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pan.svg",
     "geo": {
      "area": 75417,
      "borders": [
       "COL",
       "CRI"
      ],
      "capital": "Panama City",
      "latlng": [
       9,
       -80
      ],
      "population": 3814672,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CAIS",
        "name": "Central American Integration System",
        "otherAcronyms": [
         "SICA"
        ],
        "otherNames": [
         "Sistema de la Integración Centroamericana,"
        ]
       }
      ],
      "subregion": "Central America",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pa"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Panamá",
       "de": "Panama",
       "es": "Panamá",
       "fa": "پاناما",
       "fr": "Panama",
       "hr": "Panama",
       "it": "Panama",
       "ja": "パナマ",
       "nl": "Panama",
       "pt": "Panamá"
      }
     },
     "name": "Panama",
     "phoneprefix": [
      507
     ]
    },
    {
     "code": "PNG",
     "finance": {
      "currencies": [
       {
        "code": "PGK",
        "name": "Papua New Guinean kina",
        "symbol": "K"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/png.svg",
     "geo": {
      "area": 462840,
      "borders": [
       "IDN"
      ],
      "capital": "Port Moresby",
      "latlng": [
       -6,
       147
      ],
      "population": 8083700,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Melanesia",
      "timezones": [
       "UTC+10:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Papua Nova Guiné",
       "de": "Papua-Neuguinea",
       "es": "Papúa Nueva Guinea",
       "fa": "پاپوآ گینه نو",
       "fr": "Papouasie-Nouvelle-Guinée",
       "hr": "Papua Nova Gvineja",
       "it": "Papua Nuova Guinea",
       "ja": "パプアニューギニア",
       "nl": "Papoea-Nieuw-Guinea",
       "pt": "Papua Nova Guiné"
      }
     },
     "name": "Papouasie-Nouvelle-Guinée",
     "phoneprefix": [
      675
     ]
    },
    {
     "code": "PRY",
     "finance": {
      "currencies": [
       {
        "code": "PYG",
        "name": "Paraguayan guaraní",
        "symbol": "₲"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pry.svg",
     "geo": {
      "area": 406752,
      "borders": [
       "ARG",
       "BOL",
       "BRA"
      ],
      "capital": "Asunción",
      "latlng": [
       -23,
       -58
      ],
      "population": 6854536,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".py"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       },
       {
        "iso639_1": "gn",
        "iso639_2": "grn",
        "name": "Guaraní",
        "nativeName": "Avañe'ẽ"
       }
      ],
      "translations": {
       "br": "Paraguai",
       "de": "Paraguay",
       "es": "Paraguay",
       "fa": "پاراگوئه",
       "fr": "Paraguay",
       "hr": "Paragvaj",
       "it": "Paraguay",
       "ja": "パラグアイ",
       "nl": "Paraguay",
       "pt": "Paraguai"
      }
     },
     "name": "Paraguay",
     "phoneprefix": [
      595
     ]
    },
    {
     "code": "PER",
     "finance": {
      "currencies": [
       {
        "code": "PEN",
        "name": "Peruvian sol",
        "symbol": "S/."
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/per.svg",
     "geo": {
      "area": 1285216,
      "borders": [
       "BOL",
       "BRA",
       "CHL",
       "COL",
       "ECU"
      ],
      "capital": "Lima",
      "latlng": [
       -10,
       -76
      ],
      "population": 31488700,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "PA",
        "name": "Pacific Alliance",
        "otherAcronyms": [],
        "otherNames": [
         "Alianza del Pacífico"
        ]
       },
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pe"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Peru",
       "de": "Peru",
       "es": "Perú",
       "fa": "پرو",
       "fr": "Pérou",
       "hr": "Peru",
       "it": "Perù",
       "ja": "ペルー",
       "nl": "Peru",
       "pt": "Peru"
      }
     },
     "name": "Pérou",
     "phoneprefix": [
      51
     ]
    },
    {
     "code": "PHL",
     "finance": {
      "currencies": [
       {
        "code": "PHP",
        "name": "Philippine peso",
        "symbol": "₱"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/phl.svg",
     "geo": {
      "area": 342353,
      "borders": [],
      "capital": "Manila",
      "latlng": [
       13,
       122
      ],
      "population": 103279800,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ph"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Filipinas",
       "de": "Philippinen",
       "es": "Filipinas",
       "fa": "جزایر الندفیلیپین",
       "fr": "Philippines",
       "hr": "Filipini",
       "it": "Filippine",
       "ja": "フィリピン",
       "nl": "Filipijnen",
       "pt": "Filipinas"
      }
     },
     "name": "Philippines",
     "phoneprefix": [
      63
     ]
    },
    {
     "code": "PCN",
     "finance": {
      "currencies": [
       {
        "code": "NZD",
        "name": "New Zealand dollar",
        "symbol": "$"
       },
       {
        "code": null,
        "name": "Pitcairn Islands dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pcn.svg",
     "geo": {
      "area": 47,
      "borders": [],
      "capital": "Adamstown",
      "latlng": [
       -25.06666666,
       -130.1
      ],
      "population": 56,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC-08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Pitcairn",
       "de": "Pitcairn",
       "es": "Islas Pitcairn",
       "fa": "پیتکرن",
       "fr": "Îles Pitcairn",
       "hr": "Pitcairnovo otočje",
       "it": "Isole Pitcairn",
       "ja": "ピトケアン",
       "nl": "Pitcairneilanden",
       "pt": "Ilhas Picárnia"
      }
     },
     "name": "Îles Pitcairn",
     "phoneprefix": [
      64
     ]
    },
    {
     "code": "POL",
     "finance": {
      "currencies": [
       {
        "code": "PLN",
        "name": "Polish złoty",
        "symbol": "zł"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pol.svg",
     "geo": {
      "area": 312679,
      "borders": [
       "BLR",
       "CZE",
       "DEU",
       "LTU",
       "RUS",
       "SVK",
       "UKR"
      ],
      "capital": "Warsaw",
      "latlng": [
       52,
       20
      ],
      "population": 38437239,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pl",
        "iso639_2": "pol",
        "name": "Polish",
        "nativeName": "język polski"
       }
      ],
      "translations": {
       "br": "Polônia",
       "de": "Polen",
       "es": "Polonia",
       "fa": "لهستان",
       "fr": "Pologne",
       "hr": "Poljska",
       "it": "Polonia",
       "ja": "ポーランド",
       "nl": "Polen",
       "pt": "Polónia"
      }
     },
     "name": "Pologne",
     "phoneprefix": [
      48
     ]
    },
    {
     "code": "PRT",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/prt.svg",
     "geo": {
      "area": 92090,
      "borders": [
       "ESP"
      ],
      "capital": "Lisbon",
      "latlng": [
       39.5,
       -8
      ],
      "population": 10374822,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC-01:00",
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Portugal",
       "de": "Portugal",
       "es": "Portugal",
       "fa": "پرتغال",
       "fr": "Portugal",
       "hr": "Portugal",
       "it": "Portogallo",
       "ja": "ポルトガル",
       "nl": "Portugal",
       "pt": "Portugal"
      }
     },
     "name": "Portugal",
     "phoneprefix": [
      351
     ]
    },
    {
     "code": "PRI",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/pri.svg",
     "geo": {
      "area": 8870,
      "borders": [],
      "capital": "San Juan",
      "latlng": [
       18.25,
       -66.5
      ],
      "population": 3474182,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Porto Rico",
       "de": "Puerto Rico",
       "es": "Puerto Rico",
       "fa": "پورتو ریکو",
       "fr": "Porto Rico",
       "hr": "Portoriko",
       "it": "Porto Rico",
       "ja": "プエルトリコ",
       "nl": "Puerto Rico",
       "pt": "Porto Rico"
      }
     },
     "name": "Porto Rico",
     "phoneprefix": [
      1787,
      1939
     ]
    },
    {
     "code": "QAT",
     "finance": {
      "currencies": [
       {
        "code": "QAR",
        "name": "Qatari riyal",
        "symbol": "ر.ق"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/qat.svg",
     "geo": {
      "area": 11586,
      "borders": [
       "SAU"
      ],
      "capital": "Doha",
      "latlng": [
       25.5,
       51.25
      ],
      "population": 2587564,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".qa"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Catar",
       "de": "Katar",
       "es": "Catar",
       "fa": "قطر",
       "fr": "Qatar",
       "hr": "Katar",
       "it": "Qatar",
       "ja": "カタール",
       "nl": "Qatar",
       "pt": "Catar"
      }
     },
     "name": "Qatar",
     "phoneprefix": [
      974
     ]
    },
    {
     "code": "KOS",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kos.svg",
     "geo": {
      "area": 10908,
      "borders": [
       "ALB",
       "MKD",
       "MNE",
       "SRB"
      ],
      "capital": "Pristina",
      "latlng": [
       42.666667,
       21.166667
      ],
      "population": 1733842,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ""
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sq",
        "iso639_2": "sqi",
        "name": "Albanian",
        "nativeName": "Shqip"
       },
       {
        "iso639_1": "sr",
        "iso639_2": "srp",
        "name": "Serbian",
        "nativeName": "српски језик"
       }
      ],
      "translations": {
       "br": "Kosovo",
       "de": null,
       "es": "Kosovo",
       "fa": "کوزوو",
       "fr": null,
       "hr": "Kosovo",
       "it": null,
       "ja": null,
       "nl": null,
       "pt": "Kosovo"
      }
     },
     "name": "Republic of Kosovo",
     "phoneprefix": [
      383
     ]
    },
    {
     "code": "REU",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/reu.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "Saint-Denis",
      "latlng": [
       -21.15,
       55.5
      ],
      "population": 840974,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".re"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Reunião",
       "de": "Réunion",
       "es": "Reunión",
       "fa": "رئونیون",
       "fr": "Réunion",
       "hr": "Réunion",
       "it": "Riunione",
       "ja": "レユニオン",
       "nl": "Réunion",
       "pt": "Reunião"
      }
     },
     "name": "Réunion",
     "phoneprefix": [
      262
     ]
    },
    {
     "code": "ROU",
     "finance": {
      "currencies": [
       {
        "code": "RON",
        "name": "Romanian leu",
        "symbol": "lei"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/rou.svg",
     "geo": {
      "area": 238391,
      "borders": [
       "BGR",
       "HUN",
       "MDA",
       "SRB",
       "UKR"
      ],
      "capital": "Bucharest",
      "latlng": [
       46,
       25
      ],
      "population": 19861408,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ro"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ro",
        "iso639_2": "ron",
        "name": "Romanian",
        "nativeName": "Română"
       }
      ],
      "translations": {
       "br": "Romênia",
       "de": "Rumänien",
       "es": "Rumania",
       "fa": "رومانی",
       "fr": "Roumanie",
       "hr": "Rumunjska",
       "it": "Romania",
       "ja": "ルーマニア",
       "nl": "Roemenië",
       "pt": "Roménia"
      }
     },
     "name": "Roumanie",
     "phoneprefix": [
      40
     ]
    },
    {
     "code": "RUS",
     "finance": {
      "currencies": [
       {
        "code": "RUB",
        "name": "Russian ruble",
        "symbol": "₽"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/rus.svg",
     "geo": {
      "area": 17124442,
      "borders": [
       "AZE",
       "BLR",
       "CHN",
       "EST",
       "FIN",
       "GEO",
       "KAZ",
       "PRK",
       "LVA",
       "LTU",
       "MNG",
       "NOR",
       "POL",
       "UKR"
      ],
      "capital": "Moscow",
      "latlng": [
       60,
       100
      ],
      "population": 146599183,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EEU",
        "name": "Eurasian Economic Union",
        "otherAcronyms": [
         "EAEU"
        ],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+03:00",
       "UTC+04:00",
       "UTC+06:00",
       "UTC+07:00",
       "UTC+08:00",
       "UTC+09:00",
       "UTC+10:00",
       "UTC+11:00",
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ru"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Rússia",
       "de": "Russland",
       "es": "Rusia",
       "fa": "روسیه",
       "fr": "Russie",
       "hr": "Rusija",
       "it": "Russia",
       "ja": "ロシア連邦",
       "nl": "Rusland",
       "pt": "Rússia"
      }
     },
     "name": "Russie",
     "phoneprefix": [
      7
     ]
    },
    {
     "code": "RWA",
     "finance": {
      "currencies": [
       {
        "code": "RWF",
        "name": "Rwandan franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/rwa.svg",
     "geo": {
      "area": 26338,
      "borders": [
       "BDI",
       "COD",
       "TZA",
       "UGA"
      ],
      "capital": "Kigali",
      "latlng": [
       -2,
       30
      ],
      "population": 11553188,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".rw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "rw",
        "iso639_2": "kin",
        "name": "Kinyarwanda",
        "nativeName": "Ikinyarwanda"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Ruanda",
       "de": "Ruanda",
       "es": "Ruanda",
       "fa": "رواندا",
       "fr": "Rwanda",
       "hr": "Ruanda",
       "it": "Ruanda",
       "ja": "ルワンダ",
       "nl": "Rwanda",
       "pt": "Ruanda"
      }
     },
     "name": "Rwanda",
     "phoneprefix": [
      250
     ]
    },
    {
     "code": "BLM",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/blm.svg",
     "geo": {
      "area": 21,
      "borders": [],
      "capital": "Gustavia",
      "latlng": [
       18.5,
       -63.41666666
      ],
      "population": 9417,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".bl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "São Bartolomeu",
       "de": "Saint-Barthélemy",
       "es": "San Bartolomé",
       "fa": "سن-بارتلمی",
       "fr": "Saint-Barthélemy",
       "hr": "Saint Barthélemy",
       "it": "Antille Francesi",
       "ja": "サン・バルテルミー",
       "nl": "Saint Barthélemy",
       "pt": "São Bartolomeu"
      }
     },
     "name": "Saint-Barthélemy",
     "phoneprefix": [
      590
     ]
    },
    {
     "code": "SHN",
     "finance": {
      "currencies": [
       {
        "code": "SHP",
        "name": "Saint Helena pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/shn.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "Jamestown",
      "latlng": [
       -15.95,
       -5.7
      ],
      "population": 4255,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sh"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Santa Helena",
       "de": "Sankt Helena",
       "es": "Santa Helena",
       "fa": "سنت هلنا، اسنشن و تریستان دا کونا",
       "fr": "Sainte-Hélène",
       "hr": "Sveta Helena",
       "it": "Sant'Elena",
       "ja": "セントヘレナ・アセンションおよびトリスタンダクーニャ",
       "nl": "Sint-Helena",
       "pt": "Santa Helena"
      }
     },
     "name": "Sainte-Hélène",
     "phoneprefix": [
      290
     ]
    },
    {
     "code": "KNA",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kna.svg",
     "geo": {
      "area": 261,
      "borders": [],
      "capital": "Basseterre",
      "latlng": [
       17.33333333,
       -62.75
      ],
      "population": 46204,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "São Cristóvão e Neves",
       "de": "St. Kitts und Nevis",
       "es": "San Cristóbal y Nieves",
       "fa": "سنت کیتس و نویس",
       "fr": "Saint-Christophe-et-Niévès",
       "hr": "Sveti Kristof i Nevis",
       "it": "Saint Kitts e Nevis",
       "ja": "セントクリストファー・ネイビス",
       "nl": "Saint Kitts en Nevis",
       "pt": "São Cristóvão e Neves"
      }
     },
     "name": "Saint-Christophe-et-Niévès",
     "phoneprefix": [
      1869
     ]
    },
    {
     "code": "LCA",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lca.svg",
     "geo": {
      "area": 616,
      "borders": [],
      "capital": "Castries",
      "latlng": [
       13.88333333,
       -60.96666666
      ],
      "population": 186000,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Santa Lúcia",
       "de": "Saint Lucia",
       "es": "Santa Lucía",
       "fa": "سنت لوسیا",
       "fr": "Saint-Lucie",
       "hr": "Sveta Lucija",
       "it": "Santa Lucia",
       "ja": "セントルシア",
       "nl": "Saint Lucia",
       "pt": "Santa Lúcia"
      }
     },
     "name": "Saint-Lucie",
     "phoneprefix": [
      1758
     ]
    },
    {
     "code": "MAF",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/maf.svg",
     "geo": {
      "area": 53,
      "borders": [
       "SXM",
       "NLD"
      ],
      "capital": "Marigot",
      "latlng": [
       18.08333333,
       -63.95
      ],
      "population": 36979,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".mf",
       ".fr",
       ".gp"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       }
      ],
      "translations": {
       "br": "Saint Martin",
       "de": "Saint Martin",
       "es": "Saint Martin",
       "fa": "سینت مارتن",
       "fr": "Saint-Martin",
       "hr": "Sveti Martin",
       "it": "Saint Martin",
       "ja": "サン・マルタン（フランス領）",
       "nl": "Saint-Martin",
       "pt": "Ilha São Martinho"
      }
     },
     "name": "Saint-Martin",
     "phoneprefix": [
      590
     ]
    },
    {
     "code": "SPM",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/spm.svg",
     "geo": {
      "area": 242,
      "borders": [],
      "capital": "Saint-Pierre",
      "latlng": [
       46.83333333,
       -56.33333333
      ],
      "population": 6069,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Northern America",
      "timezones": [
       "UTC-03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".pm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Saint-Pierre e Miquelon",
       "de": "Saint-Pierre und Miquelon",
       "es": "San Pedro y Miquelón",
       "fa": "سن پیر و میکلن",
       "fr": "Saint-Pierre-et-Miquelon",
       "hr": "Sveti Petar i Mikelon",
       "it": "Saint-Pierre e Miquelon",
       "ja": "サンピエール島・ミクロン島",
       "nl": "Saint Pierre en Miquelon",
       "pt": "São Pedro e Miquelon"
      }
     },
     "name": "Saint-Pierre-et-Miquelon",
     "phoneprefix": [
      508
     ]
    },
    {
     "code": "VCT",
     "finance": {
      "currencies": [
       {
        "code": "XCD",
        "name": "East Caribbean dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/vct.svg",
     "geo": {
      "area": 389,
      "borders": [],
      "capital": "Kingstown",
      "latlng": [
       13.25,
       -61.2
      ],
      "population": 109991,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".vc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "São Vicente e Granadinas",
       "de": "Saint Vincent und die Grenadinen",
       "es": "San Vicente y Granadinas",
       "fa": "سنت وینسنت و گرنادین‌ها",
       "fr": "Saint-Vincent-et-les-Grenadines",
       "hr": "Sveti Vincent i Grenadini",
       "it": "Saint Vincent e Grenadine",
       "ja": "セントビンセントおよびグレナディーン諸島",
       "nl": "Saint Vincent en de Grenadines",
       "pt": "São Vicente e Granadinas"
      }
     },
     "name": "Saint-Vincent-et-les-Grenadines",
     "phoneprefix": [
      1784
     ]
    },
    {
     "code": "WSM",
     "finance": {
      "currencies": [
       {
        "code": "WST",
        "name": "Samoan tālā",
        "symbol": "T"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/wsm.svg",
     "geo": {
      "area": 2842,
      "borders": [],
      "capital": "Apia",
      "latlng": [
       -13.58333333,
       -172.33333333
      ],
      "population": 194899,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC+13:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ws"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sm",
        "iso639_2": "smo",
        "name": "Samoan",
        "nativeName": "gagana fa'a Samoa"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Samoa",
       "de": "Samoa",
       "es": "Samoa",
       "fa": "ساموآ",
       "fr": "Samoa",
       "hr": "Samoa",
       "it": "Samoa",
       "ja": "サモア",
       "nl": "Samoa",
       "pt": "Samoa"
      }
     },
     "name": "Samoa",
     "phoneprefix": [
      685
     ]
    },
    {
     "code": "SMR",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/smr.svg",
     "geo": {
      "area": 61,
      "borders": [
       "ITA"
      ],
      "capital": "City of San Marino",
      "latlng": [
       43.76666666,
       12.41666666
      ],
      "population": 33005,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "it",
        "iso639_2": "ita",
        "name": "Italian",
        "nativeName": "Italiano"
       }
      ],
      "translations": {
       "br": "San Marino",
       "de": "San Marino",
       "es": "San Marino",
       "fa": "سان مارینو",
       "fr": "Saint-Marin",
       "hr": "San Marino",
       "it": "San Marino",
       "ja": "サンマリノ",
       "nl": "San Marino",
       "pt": "São Marinho"
      }
     },
     "name": "Saint-Marin",
     "phoneprefix": [
      378
     ]
    },
    {
     "code": "STP",
     "finance": {
      "currencies": [
       {
        "code": "STD",
        "name": "São Tomé and Príncipe dobra",
        "symbol": "Db"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/stp.svg",
     "geo": {
      "area": 964,
      "borders": [],
      "capital": "São Tomé",
      "latlng": [
       1,
       7
      ],
      "population": 187356,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".st"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "São Tomé e Príncipe",
       "de": "São Tomé und Príncipe",
       "es": "Santo Tomé y Príncipe",
       "fa": "کواترو دو فرویرو",
       "fr": "Sao Tomé-et-Principe",
       "hr": "Sveti Toma i Princip",
       "it": "São Tomé e Príncipe",
       "ja": "サントメ・プリンシペ",
       "nl": "Sao Tomé en Principe",
       "pt": "São Tomé e Príncipe"
      }
     },
     "name": "Sao Tomé-et-Principe",
     "phoneprefix": [
      239
     ]
    },
    {
     "code": "SAU",
     "finance": {
      "currencies": [
       {
        "code": "SAR",
        "name": "Saudi riyal",
        "symbol": "ر.س"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sau.svg",
     "geo": {
      "area": 2149690,
      "borders": [
       "IRQ",
       "JOR",
       "KWT",
       "OMN",
       "QAT",
       "ARE",
       "YEM"
      ],
      "capital": "Riyadh",
      "latlng": [
       25,
       45
      ],
      "population": 32248200,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sa"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Arábia Saudita",
       "de": "Saudi-Arabien",
       "es": "Arabia Saudí",
       "fa": "عربستان سعودی",
       "fr": "Arabie Saoudite",
       "hr": "Saudijska Arabija",
       "it": "Arabia Saudita",
       "ja": "サウジアラビア",
       "nl": "Saoedi-Arabië",
       "pt": "Arábia Saudita"
      }
     },
     "name": "Arabie Saoudite",
     "phoneprefix": [
      966
     ]
    },
    {
     "code": "SEN",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sen.svg",
     "geo": {
      "area": 196722,
      "borders": [
       "GMB",
       "GIN",
       "GNB",
       "MLI",
       "MRT"
      ],
      "capital": "Dakar",
      "latlng": [
       14,
       -14
      ],
      "population": 14799859,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Senegal",
       "de": "Senegal",
       "es": "Senegal",
       "fa": "سنگال",
       "fr": "Sénégal",
       "hr": "Senegal",
       "it": "Senegal",
       "ja": "セネガル",
       "nl": "Senegal",
       "pt": "Senegal"
      }
     },
     "name": "Sénégal",
     "phoneprefix": [
      221
     ]
    },
    {
     "code": "SRB",
     "finance": {
      "currencies": [
       {
        "code": "RSD",
        "name": "Serbian dinar",
        "symbol": "дин."
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/srb.svg",
     "geo": {
      "area": 88361,
      "borders": [
       "BIH",
       "BGR",
       "HRV",
       "HUN",
       "KOS",
       "MKD",
       "MNE",
       "ROU"
      ],
      "capital": "Belgrade",
      "latlng": [
       44,
       21
      ],
      "population": 7076372,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "CEFTA",
        "name": "Central European Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".rs"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sr",
        "iso639_2": "srp",
        "name": "Serbian",
        "nativeName": "српски језик"
       }
      ],
      "translations": {
       "br": "Sérvia",
       "de": "Serbien",
       "es": "Serbia",
       "fa": "صربستان",
       "fr": "Serbie",
       "hr": "Srbija",
       "it": "Serbia",
       "ja": "セルビア",
       "nl": "Servië",
       "pt": "Sérvia"
      }
     },
     "name": "Serbie",
     "phoneprefix": [
      381
     ]
    },
    {
     "code": "SYC",
     "finance": {
      "currencies": [
       {
        "code": "SCR",
        "name": "Seychellois rupee",
        "symbol": "₨"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/syc.svg",
     "geo": {
      "area": 452,
      "borders": [],
      "capital": "Victoria",
      "latlng": [
       -4.58333333,
       55.66666666
      ],
      "population": 91400,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Seicheles",
       "de": "Seychellen",
       "es": "Seychelles",
       "fa": "سیشل",
       "fr": "Seychelles",
       "hr": "Sejšeli",
       "it": "Seychelles",
       "ja": "セーシェル",
       "nl": "Seychellen",
       "pt": "Seicheles"
      }
     },
     "name": "Seychelles",
     "phoneprefix": [
      248
     ]
    },
    {
     "code": "SLE",
     "finance": {
      "currencies": [
       {
        "code": "SLL",
        "name": "Sierra Leonean leone",
        "symbol": "Le"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sle.svg",
     "geo": {
      "area": 71740,
      "borders": [
       "GIN",
       "LBR"
      ],
      "capital": "Freetown",
      "latlng": [
       8.5,
       -11.5
      ],
      "population": 7075641,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Serra Leoa",
       "de": "Sierra Leone",
       "es": "Sierra Leone",
       "fa": "سیرالئون",
       "fr": "Sierra Leone",
       "hr": "Sijera Leone",
       "it": "Sierra Leone",
       "ja": "シエラレオネ",
       "nl": "Sierra Leone",
       "pt": "Serra Leoa"
      }
     },
     "name": "Sierra Leone",
     "phoneprefix": [
      232
     ]
    },
    {
     "code": "SGP",
     "finance": {
      "currencies": [
       {
        "code": "BND",
        "name": "Brunei dollar",
        "symbol": "$"
       },
       {
        "code": "SGD",
        "name": "Singapore dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sgp.svg",
     "geo": {
      "area": 710,
      "borders": [],
      "capital": "Singapore",
      "latlng": [
       1.36666666,
       103.8
      ],
      "population": 5535000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ms",
        "iso639_2": "msa",
        "name": "Malay",
        "nativeName": "bahasa Melayu"
       },
       {
        "iso639_1": "ta",
        "iso639_2": "tam",
        "name": "Tamil",
        "nativeName": "தமிழ்"
       },
       {
        "iso639_1": "zh",
        "iso639_2": "zho",
        "name": "Chinese",
        "nativeName": "中文 (Zhōngwén)"
       }
      ],
      "translations": {
       "br": "Singapura",
       "de": "Singapur",
       "es": "Singapur",
       "fa": "سنگاپور",
       "fr": "Singapour",
       "hr": "Singapur",
       "it": "Singapore",
       "ja": "シンガポール",
       "nl": "Singapore",
       "pt": "Singapura"
      }
     },
     "name": "Singapour",
     "phoneprefix": [
      65
     ]
    },
    {
     "code": "SXM",
     "finance": {
      "currencies": [
       {
        "code": "ANG",
        "name": "Netherlands Antillean guilder",
        "symbol": "ƒ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sxm.svg",
     "geo": {
      "area": 34,
      "borders": [
       "MAF"
      ],
      "capital": "Philipsburg",
      "latlng": [
       18.033333,
       -63.05
      ],
      "population": 38247,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sx"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Sint Maarten",
       "de": "Sint Maarten (niederl. Teil)",
       "es": null,
       "fa": "سینت مارتن",
       "fr": "Saint Martin (partie néerlandaise)",
       "hr": null,
       "it": "Saint Martin (parte olandese)",
       "ja": null,
       "nl": "Sint Maarten",
       "pt": "São Martinho"
      }
     },
     "name": "Saint Martin (partie néerlandaise)",
     "phoneprefix": [
      1721
     ]
    },
    {
     "code": "SVK",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/svk.svg",
     "geo": {
      "area": 49037,
      "borders": [
       "AUT",
       "CZE",
       "HUN",
       "POL",
       "UKR"
      ],
      "capital": "Bratislava",
      "latlng": [
       48.66666666,
       19.5
      ],
      "population": 5426252,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sk",
        "iso639_2": "slk",
        "name": "Slovak",
        "nativeName": "slovenčina"
       }
      ],
      "translations": {
       "br": "Eslováquia",
       "de": "Slowakei",
       "es": "República Eslovaca",
       "fa": "اسلواکی",
       "fr": "Slovaquie",
       "hr": "Slovačka",
       "it": "Slovacchia",
       "ja": "スロバキア",
       "nl": "Slowakije",
       "pt": "Eslováquia"
      }
     },
     "name": "Slovaquie",
     "phoneprefix": [
      421
     ]
    },
    {
     "code": "SVN",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/svn.svg",
     "geo": {
      "area": 20273,
      "borders": [
       "AUT",
       "HRV",
       "ITA",
       "HUN"
      ],
      "capital": "Ljubljana",
      "latlng": [
       46.11666666,
       14.81666666
      ],
      "population": 2064188,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".si"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sl",
        "iso639_2": "slv",
        "name": "Slovene",
        "nativeName": "slovenski jezik"
       }
      ],
      "translations": {
       "br": "Eslovênia",
       "de": "Slowenien",
       "es": "Eslovenia",
       "fa": "اسلوونی",
       "fr": "Slovénie",
       "hr": "Slovenija",
       "it": "Slovenia",
       "ja": "スロベニア",
       "nl": "Slovenië",
       "pt": "Eslovénia"
      }
     },
     "name": "Slovénie",
     "phoneprefix": [
      386
     ]
    },
    {
     "code": "SLB",
     "finance": {
      "currencies": [
       {
        "code": "SBD",
        "name": "Solomon Islands dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/slb.svg",
     "geo": {
      "area": 28896,
      "borders": [],
      "capital": "Honiara",
      "latlng": [
       -8,
       159
      ],
      "population": 642000,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Melanesia",
      "timezones": [
       "UTC+11:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sb"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Salomão",
       "de": "Salomonen",
       "es": "Islas Salomón",
       "fa": "جزایر سلیمان",
       "fr": "Îles Salomon",
       "hr": "Solomonski Otoci",
       "it": "Isole Salomone",
       "ja": "ソロモン諸島",
       "nl": "Salomonseilanden",
       "pt": "Ilhas Salomão"
      }
     },
     "name": "Îles Salomon",
     "phoneprefix": [
      677
     ]
    },
    {
     "code": "SOM",
     "finance": {
      "currencies": [
       {
        "code": "SOS",
        "name": "Somali shilling",
        "symbol": "Sh"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/som.svg",
     "geo": {
      "area": 637657,
      "borders": [
       "DJI",
       "ETH",
       "KEN"
      ],
      "capital": "Mogadishu",
      "latlng": [
       10,
       49
      ],
      "population": 11079000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".so"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "so",
        "iso639_2": "som",
        "name": "Somali",
        "nativeName": "Soomaaliga"
       },
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Somália",
       "de": "Somalia",
       "es": "Somalia",
       "fa": "سومالی",
       "fr": "Somalie",
       "hr": "Somalija",
       "it": "Somalia",
       "ja": "ソマリア",
       "nl": "Somalië",
       "pt": "Somália"
      }
     },
     "name": "Somalie",
     "phoneprefix": [
      252
     ]
    },
    {
     "code": "ZAF",
     "finance": {
      "currencies": [
       {
        "code": "ZAR",
        "name": "South African rand",
        "symbol": "R"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/zaf.svg",
     "geo": {
      "area": 1221037,
      "borders": [
       "BWA",
       "LSO",
       "MOZ",
       "NAM",
       "SWZ",
       "ZWE"
      ],
      "capital": "Pretoria",
      "latlng": [
       -29,
       24
      ],
      "population": 55653654,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Southern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".za"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "af",
        "iso639_2": "afr",
        "name": "Afrikaans",
        "nativeName": "Afrikaans"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "nr",
        "iso639_2": "nbl",
        "name": "Southern Ndebele",
        "nativeName": "isiNdebele"
       },
       {
        "iso639_1": "st",
        "iso639_2": "sot",
        "name": "Southern Sotho",
        "nativeName": "Sesotho"
       },
       {
        "iso639_1": "ss",
        "iso639_2": "ssw",
        "name": "Swati",
        "nativeName": "SiSwati"
       },
       {
        "iso639_1": "tn",
        "iso639_2": "tsn",
        "name": "Tswana",
        "nativeName": "Setswana"
       },
       {
        "iso639_1": "ts",
        "iso639_2": "tso",
        "name": "Tsonga",
        "nativeName": "Xitsonga"
       },
       {
        "iso639_1": "ve",
        "iso639_2": "ven",
        "name": "Venda",
        "nativeName": "Tshivenḓa"
       },
       {
        "iso639_1": "xh",
        "iso639_2": "xho",
        "name": "Xhosa",
        "nativeName": "isiXhosa"
       },
       {
        "iso639_1": "zu",
        "iso639_2": "zul",
        "name": "Zulu",
        "nativeName": "isiZulu"
       }
      ],
      "translations": {
       "br": "República Sul-Africana",
       "de": "Republik Südafrika",
       "es": "República de Sudáfrica",
       "fa": "آفریقای جنوبی",
       "fr": "Afrique du Sud",
       "hr": "Južnoafrička Republika",
       "it": "Sud Africa",
       "ja": "南アフリカ",
       "nl": "Zuid-Afrika",
       "pt": "República Sul-Africana"
      }
     },
     "name": "Afrique du Sud",
     "phoneprefix": [
      27
     ]
    },
    {
     "code": "SGS",
     "finance": {
      "currencies": [
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       },
       {
        "code": "(none)",
        "name": null,
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sgs.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "King Edward Point",
      "latlng": [
       -54.5,
       -37
      ],
      "population": 30,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".gs"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Geórgias do Sul e Sandwich do Sul",
       "de": "Südgeorgien und die Südlichen Sandwichinseln",
       "es": "Islas Georgias del Sur y Sandwich del Sur",
       "fa": "جزایر جورجیای جنوبی و ساندویچ جنوبی",
       "fr": "Géorgie du Sud-et-les Îles Sandwich du Sud",
       "hr": "Južna Georgija i otočje Južni Sandwich",
       "it": "Georgia del Sud e Isole Sandwich Meridionali",
       "ja": "サウスジョージア・サウスサンドウィッチ諸島",
       "nl": "Zuid-Georgia en Zuidelijke Sandwicheilanden",
       "pt": "Ilhas Geórgia do Sul e Sanduíche do Sul"
      }
     },
     "name": "Géorgie du Sud-et-les Îles Sandwich du Sud",
     "phoneprefix": [
      500
     ]
    },
    {
     "code": "KOR",
     "finance": {
      "currencies": [
       {
        "code": "KRW",
        "name": "South Korean won",
        "symbol": "₩"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/kor.svg",
     "geo": {
      "area": 100210,
      "borders": [
       "PRK"
      ],
      "capital": "Seoul",
      "latlng": [
       37,
       127.5
      ],
      "population": 50801405,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".kr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ko",
        "iso639_2": "kor",
        "name": "Korean",
        "nativeName": "한국어"
       }
      ],
      "translations": {
       "br": "Coreia do Sul",
       "de": "Südkorea",
       "es": "Corea del Sur",
       "fa": "کره شمالی",
       "fr": "Corée du Sud",
       "hr": "Južna Koreja",
       "it": "Corea del Sud",
       "ja": "大韓民国",
       "nl": "Zuid-Korea",
       "pt": "Coreia do Sul"
      }
     },
     "name": "Corée du Sud",
     "phoneprefix": [
      82
     ]
    },
    {
     "code": "SSD",
     "finance": {
      "currencies": [
       {
        "code": "SSP",
        "name": "South Sudanese pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ssd.svg",
     "geo": {
      "area": 619745,
      "borders": [
       "CAF",
       "COD",
       "ETH",
       "KEN",
       "SDN",
       "UGA"
      ],
      "capital": "Juba",
      "latlng": [
       7,
       30
      ],
      "population": 12131000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Middle Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ss"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Sudão do Sul",
       "de": "Südsudan",
       "es": "Sudán del Sur",
       "fa": "سودان جنوبی",
       "fr": "Soudan du Sud",
       "hr": "Južni Sudan",
       "it": "Sudan del sud",
       "ja": "南スーダン",
       "nl": "Zuid-Soedan",
       "pt": "Sudão do Sul"
      }
     },
     "name": "Soudan du Sud",
     "phoneprefix": [
      211
     ]
    },
    {
     "code": "ESP",
     "finance": {
      "currencies": [
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/esp.svg",
     "geo": {
      "area": 505992,
      "borders": [
       "AND",
       "FRA",
       "GIB",
       "PRT",
       "MAR"
      ],
      "capital": "Madrid",
      "latlng": [
       40,
       -4
      ],
      "population": 46438422,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Europe",
      "timezones": [
       "UTC",
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".es"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Espanha",
       "de": "Spanien",
       "es": "España",
       "fa": "اسپانیا",
       "fr": "Espagne",
       "hr": "Španjolska",
       "it": "Spagna",
       "ja": "スペイン",
       "nl": "Spanje",
       "pt": "Espanha"
      }
     },
     "name": "Espagne",
     "phoneprefix": [
      34
     ]
    },
    {
     "code": "LKA",
     "finance": {
      "currencies": [
       {
        "code": "LKR",
        "name": "Sri Lankan rupee",
        "symbol": "Rs"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/lka.svg",
     "geo": {
      "area": 65610,
      "borders": [
       "IND"
      ],
      "capital": "Colombo",
      "latlng": [
       7,
       81
      ],
      "population": 20966000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "SAARC",
        "name": "South Asian Association for Regional Cooperation",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Southern Asia",
      "timezones": [
       "UTC+05:30"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".lk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "si",
        "iso639_2": "sin",
        "name": "Sinhalese",
        "nativeName": "සිංහල"
       },
       {
        "iso639_1": "ta",
        "iso639_2": "tam",
        "name": "Tamil",
        "nativeName": "தமிழ்"
       }
      ],
      "translations": {
       "br": "Sri Lanka",
       "de": "Sri Lanka",
       "es": "Sri Lanka",
       "fa": "سری‌لانکا",
       "fr": "Sri Lanka",
       "hr": "Šri Lanka",
       "it": "Sri Lanka",
       "ja": "スリランカ",
       "nl": "Sri Lanka",
       "pt": "Sri Lanka"
      }
     },
     "name": "Sri Lanka",
     "phoneprefix": [
      94
     ]
    },
    {
     "code": "SDN",
     "finance": {
      "currencies": [
       {
        "code": "SDG",
        "name": "Sudanese pound",
        "symbol": "ج.س."
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sdn.svg",
     "geo": {
      "area": 1886068,
      "borders": [
       "CAF",
       "TCD",
       "EGY",
       "ERI",
       "ETH",
       "LBY",
       "SSD"
      ],
      "capital": "Khartoum",
      "latlng": [
       15,
       30
      ],
      "population": 39598700,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sd"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Sudão",
       "de": "Sudan",
       "es": "Sudán",
       "fa": "سودان",
       "fr": "Soudan",
       "hr": "Sudan",
       "it": "Sudan",
       "ja": "スーダン",
       "nl": "Soedan",
       "pt": "Sudão"
      }
     },
     "name": "Soudan",
     "phoneprefix": [
      249
     ]
    },
    {
     "code": "SUR",
     "finance": {
      "currencies": [
       {
        "code": "SRD",
        "name": "Surinamese dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sur.svg",
     "geo": {
      "area": 163820,
      "borders": [
       "BRA",
       "GUF",
       "FRA",
       "GUY"
      ],
      "capital": "Paramaribo",
      "latlng": [
       4,
       -56
      ],
      "population": 541638,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       },
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "nl",
        "iso639_2": "nld",
        "name": "Dutch",
        "nativeName": "Nederlands"
       }
      ],
      "translations": {
       "br": "Suriname",
       "de": "Suriname",
       "es": "Surinam",
       "fa": "سورینام",
       "fr": "Surinam",
       "hr": "Surinam",
       "it": "Suriname",
       "ja": "スリナム",
       "nl": "Suriname",
       "pt": "Suriname"
      }
     },
     "name": "Surinam",
     "phoneprefix": [
      597
     ]
    },
    {
     "code": "SJM",
     "finance": {
      "currencies": [
       {
        "code": "NOK",
        "name": "Norwegian krone",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/sjm.svg",
     "geo": {
      "area": null,
      "borders": [],
      "capital": "Longyearbyen",
      "latlng": [
       78,
       20
      ],
      "population": 2562,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sj"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "no",
        "iso639_2": "nor",
        "name": "Norwegian",
        "nativeName": "Norsk"
       }
      ],
      "translations": {
       "br": "Svalbard",
       "de": "Svalbard und Jan Mayen",
       "es": "Islas Svalbard y Jan Mayen",
       "fa": "سوالبارد و یان ماین",
       "fr": "Svalbard et Jan Mayen",
       "hr": "Svalbard i Jan Mayen",
       "it": "Svalbard e Jan Mayen",
       "ja": "スヴァールバル諸島およびヤンマイエン島",
       "nl": "Svalbard en Jan Mayen",
       "pt": "Svalbard"
      }
     },
     "name": "Svalbard et Jan Mayen",
     "phoneprefix": [
      4779
     ]
    },
    {
     "code": "SWZ",
     "finance": {
      "currencies": [
       {
        "code": "SZL",
        "name": "Swazi lilangeni",
        "symbol": "L"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/swz.svg",
     "geo": {
      "area": 17364,
      "borders": [
       "MOZ",
       "ZAF"
      ],
      "capital": "Lobamba",
      "latlng": [
       -26.5,
       31.5
      ],
      "population": 1132657,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Southern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "ss",
        "iso639_2": "ssw",
        "name": "Swati",
        "nativeName": "SiSwati"
       }
      ],
      "translations": {
       "br": "Suazilândia",
       "de": "Swasiland",
       "es": "Suazilandia",
       "fa": "سوازیلند",
       "fr": "Swaziland",
       "hr": "Svazi",
       "it": "Swaziland",
       "ja": "スワジランド",
       "nl": "Swaziland",
       "pt": "Suazilândia"
      }
     },
     "name": "Swaziland",
     "phoneprefix": [
      268
     ]
    },
    {
     "code": "SWE",
     "finance": {
      "currencies": [
       {
        "code": "SEK",
        "name": "Swedish krona",
        "symbol": "kr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/swe.svg",
     "geo": {
      "area": 450295,
      "borders": [
       "FIN",
       "NOR"
      ],
      "capital": "Stockholm",
      "latlng": [
       62,
       15
      ],
      "population": 9894888,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".se"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sv",
        "iso639_2": "swe",
        "name": "Swedish",
        "nativeName": "svenska"
       }
      ],
      "translations": {
       "br": "Suécia",
       "de": "Schweden",
       "es": "Suecia",
       "fa": "سوئد",
       "fr": "Suède",
       "hr": "Švedska",
       "it": "Svezia",
       "ja": "スウェーデン",
       "nl": "Zweden",
       "pt": "Suécia"
      }
     },
     "name": "Suède",
     "phoneprefix": [
      46
     ]
    },
    {
     "code": "CHE",
     "finance": {
      "currencies": [
       {
        "code": "CHF",
        "name": "Swiss franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/che.svg",
     "geo": {
      "area": 41284,
      "borders": [
       "AUT",
       "FRA",
       "ITA",
       "LIE",
       "DEU"
      ],
      "capital": "Bern",
      "latlng": [
       47,
       8
      ],
      "population": 8341600,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EFTA",
        "name": "European Free Trade Association",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Western Europe",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ch"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "de",
        "iso639_2": "deu",
        "name": "German",
        "nativeName": "Deutsch"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       },
       {
        "iso639_1": "it",
        "iso639_2": "ita",
        "name": "Italian",
        "nativeName": "Italiano"
       }
      ],
      "translations": {
       "br": "Suíça",
       "de": "Schweiz",
       "es": "Suiza",
       "fa": "سوئیس",
       "fr": "Suisse",
       "hr": "Švicarska",
       "it": "Svizzera",
       "ja": "スイス",
       "nl": "Zwitserland",
       "pt": "Suíça"
      }
     },
     "name": "Suisse",
     "phoneprefix": [
      41
     ]
    },
    {
     "code": "SYR",
     "finance": {
      "currencies": [
       {
        "code": "SYP",
        "name": "Syrian pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/syr.svg",
     "geo": {
      "area": 185180,
      "borders": [
       "IRQ",
       "ISR",
       "JOR",
       "LBN",
       "TUR"
      ],
      "capital": "Damascus",
      "latlng": [
       35,
       38
      ],
      "population": 18564000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".sy"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Síria",
       "de": "Syrien",
       "es": "Siria",
       "fa": "سوریه",
       "fr": "Syrie",
       "hr": "Sirija",
       "it": "Siria",
       "ja": "シリア・アラブ共和国",
       "nl": "Syrië",
       "pt": "Síria"
      }
     },
     "name": "Syrie",
     "phoneprefix": [
      963
     ]
    },
    {
     "code": "TWN",
     "finance": {
      "currencies": [
       {
        "code": "TWD",
        "name": "New Taiwan dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/twn.svg",
     "geo": {
      "area": 36193,
      "borders": [],
      "capital": "Taipei",
      "latlng": [
       23.5,
       121
      ],
      "population": 23503349,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Eastern Asia",
      "timezones": [
       "UTC+08:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "zh",
        "iso639_2": "zho",
        "name": "Chinese",
        "nativeName": "中文 (Zhōngwén)"
       }
      ],
      "translations": {
       "br": "Taiwan",
       "de": "Taiwan",
       "es": "Taiwán",
       "fa": "تایوان",
       "fr": "Taïwan",
       "hr": "Tajvan",
       "it": "Taiwan",
       "ja": "台湾（中華民国）",
       "nl": "Taiwan",
       "pt": "Taiwan"
      }
     },
     "name": "Taïwan",
     "phoneprefix": [
      886
     ]
    },
    {
     "code": "TJK",
     "finance": {
      "currencies": [
       {
        "code": "TJS",
        "name": "Tajikistani somoni",
        "symbol": "ЅМ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tjk.svg",
     "geo": {
      "area": 143100,
      "borders": [
       "AFG",
       "CHN",
       "KGZ",
       "UZB"
      ],
      "capital": "Dushanbe",
      "latlng": [
       39,
       71
      ],
      "population": 8593600,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Central Asia",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tj"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "tg",
        "iso639_2": "tgk",
        "name": "Tajik",
        "nativeName": "тоҷикӣ"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Tajiquistão",
       "de": "Tadschikistan",
       "es": "Tayikistán",
       "fa": "تاجیکستان",
       "fr": "Tadjikistan",
       "hr": "Tađikistan",
       "it": "Tagikistan",
       "ja": "タジキスタン",
       "nl": "Tadzjikistan",
       "pt": "Tajiquistão"
      }
     },
     "name": "Tadjikistan",
     "phoneprefix": [
      992
     ]
    },
    {
     "code": "TZA",
     "finance": {
      "currencies": [
       {
        "code": "TZS",
        "name": "Tanzanian shilling",
        "symbol": "Sh"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tza.svg",
     "geo": {
      "area": 945087,
      "borders": [
       "BDI",
       "COD",
       "KEN",
       "MWI",
       "MOZ",
       "RWA",
       "UGA",
       "ZMB"
      ],
      "capital": "Dodoma",
      "latlng": [
       -6,
       35
      ],
      "population": 55155000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "sw",
        "iso639_2": "swa",
        "name": "Swahili",
        "nativeName": "Kiswahili"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Tanzânia",
       "de": "Tansania",
       "es": "Tanzania",
       "fa": "تانزانیا",
       "fr": "Tanzanie",
       "hr": "Tanzanija",
       "it": "Tanzania",
       "ja": "タンザニア",
       "nl": "Tanzania",
       "pt": "Tanzânia"
      }
     },
     "name": "Tanzanie",
     "phoneprefix": [
      255
     ]
    },
    {
     "code": "THA",
     "finance": {
      "currencies": [
       {
        "code": "THB",
        "name": "Thai baht",
        "symbol": "฿"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tha.svg",
     "geo": {
      "area": 513120,
      "borders": [
       "MMR",
       "KHM",
       "LAO",
       "MYS"
      ],
      "capital": "Bangkok",
      "latlng": [
       15,
       100
      ],
      "population": 65327652,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+07:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".th"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "th",
        "iso639_2": "tha",
        "name": "Thai",
        "nativeName": "ไทย"
       }
      ],
      "translations": {
       "br": "Tailândia",
       "de": "Thailand",
       "es": "Tailandia",
       "fa": "تایلند",
       "fr": "Thaïlande",
       "hr": "Tajland",
       "it": "Tailandia",
       "ja": "タイ",
       "nl": "Thailand",
       "pt": "Tailândia"
      }
     },
     "name": "Thaïlande",
     "phoneprefix": [
      66
     ]
    },
    {
     "code": "TLS",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       },
       {
        "code": null,
        "name": null,
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tls.svg",
     "geo": {
      "area": 14874,
      "borders": [
       "IDN"
      ],
      "capital": "Dili",
      "latlng": [
       -8.83333333,
       125.91666666
      ],
      "population": 1167242,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+09:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tl"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "pt",
        "iso639_2": "por",
        "name": "Portuguese",
        "nativeName": "Português"
       }
      ],
      "translations": {
       "br": "Timor Leste",
       "de": "Timor-Leste",
       "es": "Timor Oriental",
       "fa": "تیمور شرقی",
       "fr": "Timor oriental",
       "hr": "Istočni Timor",
       "it": "Timor Est",
       "ja": "東ティモール",
       "nl": "Oost-Timor",
       "pt": "Timor Leste"
      }
     },
     "name": "Timor oriental",
     "phoneprefix": [
      670
     ]
    },
    {
     "code": "TGO",
     "finance": {
      "currencies": [
       {
        "code": "XOF",
        "name": "West African CFA franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tgo.svg",
     "geo": {
      "area": 56785,
      "borders": [
       "BEN",
       "BFA",
       "GHA"
      ],
      "capital": "Lomé",
      "latlng": [
       8,
       1.16666666
      ],
      "population": 7143000,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Western Africa",
      "timezones": [
       "UTC"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tg"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Togo",
       "de": "Togo",
       "es": "Togo",
       "fa": "توگو",
       "fr": "Togo",
       "hr": "Togo",
       "it": "Togo",
       "ja": "トーゴ",
       "nl": "Togo",
       "pt": "Togo"
      }
     },
     "name": "Togo",
     "phoneprefix": [
      228
     ]
    },
    {
     "code": "TKL",
     "finance": {
      "currencies": [
       {
        "code": "NZD",
        "name": "New Zealand dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tkl.svg",
     "geo": {
      "area": 12,
      "borders": [],
      "capital": "Fakaofo",
      "latlng": [
       -9,
       -172
      ],
      "population": 1411,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC+13:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Tokelau",
       "de": "Tokelau",
       "es": "Islas Tokelau",
       "fa": "توکلائو",
       "fr": "Tokelau",
       "hr": "Tokelau",
       "it": "Isole Tokelau",
       "ja": "トケラウ",
       "nl": "Tokelau",
       "pt": "Toquelau"
      }
     },
     "name": "Tokelau",
     "phoneprefix": [
      690
     ]
    },
    {
     "code": "TON",
     "finance": {
      "currencies": [
       {
        "code": "TOP",
        "name": "Tongan paʻanga",
        "symbol": "T$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ton.svg",
     "geo": {
      "area": 747,
      "borders": [],
      "capital": "Nuku'alofa",
      "latlng": [
       -20,
       -175
      ],
      "population": 103252,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC+13:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".to"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "to",
        "iso639_2": "ton",
        "name": "Tonga (Tonga Islands)",
        "nativeName": "faka Tonga"
       }
      ],
      "translations": {
       "br": "Tonga",
       "de": "Tonga",
       "es": "Tonga",
       "fa": "تونگا",
       "fr": "Tonga",
       "hr": "Tonga",
       "it": "Tonga",
       "ja": "トンガ",
       "nl": "Tonga",
       "pt": "Tonga"
      }
     },
     "name": "Tonga",
     "phoneprefix": [
      676
     ]
    },
    {
     "code": "TTO",
     "finance": {
      "currencies": [
       {
        "code": "TTD",
        "name": "Trinidad and Tobago dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tto.svg",
     "geo": {
      "area": 5130,
      "borders": [],
      "capital": "Port of Spain",
      "latlng": [
       11,
       -61
      ],
      "population": 1349667,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "CARICOM",
        "name": "Caribbean Community",
        "otherAcronyms": [],
        "otherNames": [
         "Comunidad del Caribe",
         "Communauté Caribéenne",
         "Caribische Gemeenschap"
        ]
       }
      ],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tt"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Trinidad e Tobago",
       "de": "Trinidad und Tobago",
       "es": "Trinidad y Tobago",
       "fa": "ترینیداد و توباگو",
       "fr": "Trinité et Tobago",
       "hr": "Trinidad i Tobago",
       "it": "Trinidad e Tobago",
       "ja": "トリニダード・トバゴ",
       "nl": "Trinidad en Tobago",
       "pt": "Trindade e Tobago"
      }
     },
     "name": "Trinité et Tobago",
     "phoneprefix": [
      1868
     ]
    },
    {
     "code": "TUN",
     "finance": {
      "currencies": [
       {
        "code": "TND",
        "name": "Tunisian dinar",
        "symbol": "د.ت"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tun.svg",
     "geo": {
      "area": 163610,
      "borders": [
       "DZA",
       "LBY"
      ],
      "capital": "Tunis",
      "latlng": [
       34,
       9
      ],
      "population": 11154400,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       },
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC+01:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Tunísia",
       "de": "Tunesien",
       "es": "Túnez",
       "fa": "تونس",
       "fr": "Tunisie",
       "hr": "Tunis",
       "it": "Tunisia",
       "ja": "チュニジア",
       "nl": "Tunesië",
       "pt": "Tunísia"
      }
     },
     "name": "Tunisie",
     "phoneprefix": [
      216
     ]
    },
    {
     "code": "TUR",
     "finance": {
      "currencies": [
       {
        "code": "TRY",
        "name": "Turkish lira",
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tur.svg",
     "geo": {
      "area": 783562,
      "borders": [
       "ARM",
       "AZE",
       "BGR",
       "GEO",
       "GRC",
       "IRN",
       "IRQ",
       "SYR"
      ],
      "capital": "Ankara",
      "latlng": [
       39,
       35
      ],
      "population": 78741053,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tr"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "tr",
        "iso639_2": "tur",
        "name": "Turkish",
        "nativeName": "Türkçe"
       }
      ],
      "translations": {
       "br": "Turquia",
       "de": "Türkei",
       "es": "Turquía",
       "fa": "ترکیه",
       "fr": "Turquie",
       "hr": "Turska",
       "it": "Turchia",
       "ja": "トルコ",
       "nl": "Turkije",
       "pt": "Turquia"
      }
     },
     "name": "Turquie",
     "phoneprefix": [
      90
     ]
    },
    {
     "code": "TKM",
     "finance": {
      "currencies": [
       {
        "code": "TMT",
        "name": "Turkmenistan manat",
        "symbol": "m"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tkm.svg",
     "geo": {
      "area": 488100,
      "borders": [
       "AFG",
       "IRN",
       "KAZ",
       "UZB"
      ],
      "capital": "Ashgabat",
      "latlng": [
       40,
       60
      ],
      "population": 4751120,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Central Asia",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "tk",
        "iso639_2": "tuk",
        "name": "Turkmen",
        "nativeName": "Türkmen"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Turcomenistão",
       "de": "Turkmenistan",
       "es": "Turkmenistán",
       "fa": "ترکمنستان",
       "fr": "Turkménistan",
       "hr": "Turkmenistan",
       "it": "Turkmenistan",
       "ja": "トルクメニスタン",
       "nl": "Turkmenistan",
       "pt": "Turquemenistão"
      }
     },
     "name": "Turkménistan",
     "phoneprefix": [
      993
     ]
    },
    {
     "code": "TCA",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tca.svg",
     "geo": {
      "area": 948,
      "borders": [],
      "capital": "Cockburn Town",
      "latlng": [
       21.75,
       -71.58333333
      ],
      "population": 31458,
      "region": "Americas",
      "regionalBlocs": [],
      "subregion": "Caribbean",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tc"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Ilhas Turcas e Caicos",
       "de": "Turks- und Caicosinseln",
       "es": "Islas Turks y Caicos",
       "fa": "جزایر تورکس و کایکوس",
       "fr": "Îles Turques-et-Caïques",
       "hr": "Otoci Turks i Caicos",
       "it": "Isole Turks e Caicos",
       "ja": "タークス・カイコス諸島",
       "nl": "Turks- en Caicoseilanden",
       "pt": "Ilhas Turcas e Caicos"
      }
     },
     "name": "Îles Turques-et-Caïques",
     "phoneprefix": [
      1649
     ]
    },
    {
     "code": "TUV",
     "finance": {
      "currencies": [
       {
        "code": "AUD",
        "name": "Australian dollar",
        "symbol": "$"
       },
       {
        "code": "TVD[G]",
        "name": "Tuvaluan dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/tuv.svg",
     "geo": {
      "area": 26,
      "borders": [],
      "capital": "Funafuti",
      "latlng": [
       -8,
       178
      ],
      "population": 10640,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".tv"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Tuvalu",
       "de": "Tuvalu",
       "es": "Tuvalu",
       "fa": "تووالو",
       "fr": "Tuvalu",
       "hr": "Tuvalu",
       "it": "Tuvalu",
       "ja": "ツバル",
       "nl": "Tuvalu",
       "pt": "Tuvalu"
      }
     },
     "name": "Tuvalu",
     "phoneprefix": [
      688
     ]
    },
    {
     "code": "UGA",
     "finance": {
      "currencies": [
       {
        "code": "UGX",
        "name": "Ugandan shilling",
        "symbol": "Sh"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/uga.svg",
     "geo": {
      "area": 241550,
      "borders": [
       "COD",
       "KEN",
       "RWA",
       "SSD",
       "TZA"
      ],
      "capital": "Kampala",
      "latlng": [
       1,
       32
      ],
      "population": 33860700,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ug"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "sw",
        "iso639_2": "swa",
        "name": "Swahili",
        "nativeName": "Kiswahili"
       }
      ],
      "translations": {
       "br": "Uganda",
       "de": "Uganda",
       "es": "Uganda",
       "fa": "اوگاندا",
       "fr": "Uganda",
       "hr": "Uganda",
       "it": "Uganda",
       "ja": "ウガンダ",
       "nl": "Oeganda",
       "pt": "Uganda"
      }
     },
     "name": "Uganda",
     "phoneprefix": [
      256
     ]
    },
    {
     "code": "UKR",
     "finance": {
      "currencies": [
       {
        "code": "UAH",
        "name": "Ukrainian hryvnia",
        "symbol": "₴"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ukr.svg",
     "geo": {
      "area": 603700,
      "borders": [
       "BLR",
       "HUN",
       "MDA",
       "POL",
       "ROU",
       "RUS",
       "SVK"
      ],
      "capital": "Kiev",
      "latlng": [
       49,
       32
      ],
      "population": 42692393,
      "region": "Europe",
      "regionalBlocs": [],
      "subregion": "Eastern Europe",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ua"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "uk",
        "iso639_2": "ukr",
        "name": "Ukrainian",
        "nativeName": "Українська"
       }
      ],
      "translations": {
       "br": "Ucrânia",
       "de": "Ukraine",
       "es": "Ucrania",
       "fa": "وکراین",
       "fr": "Ukraine",
       "hr": "Ukrajina",
       "it": "Ucraina",
       "ja": "ウクライナ",
       "nl": "Oekraïne",
       "pt": "Ucrânia"
      }
     },
     "name": "Ukraine",
     "phoneprefix": [
      380
     ]
    },
    {
     "code": "ARE",
     "finance": {
      "currencies": [
       {
        "code": "AED",
        "name": "United Arab Emirates dirham",
        "symbol": "د.إ"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/are.svg",
     "geo": {
      "area": 83600,
      "borders": [
       "OMN",
       "SAU"
      ],
      "capital": "Abu Dhabi",
      "latlng": [
       24,
       54
      ],
      "population": 9856000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+04"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ae"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Emirados árabes Unidos",
       "de": "Vereinigte Arabische Emirate",
       "es": "Emiratos Árabes Unidos",
       "fa": "امارات متحده عربی",
       "fr": "Émirats arabes unis",
       "hr": "Ujedinjeni Arapski Emirati",
       "it": "Emirati Arabi Uniti",
       "ja": "アラブ首長国連邦",
       "nl": "Verenigde Arabische Emiraten",
       "pt": "Emirados árabes Unidos"
      }
     },
     "name": "Émirats arabes unis",
     "phoneprefix": [
      971
     ]
    },
    {
     "code": "GBR",
     "finance": {
      "currencies": [
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/gbr.svg",
     "geo": {
      "area": 242900,
      "borders": [
       "IRL"
      ],
      "capital": "London",
      "latlng": [
       54,
       -2
      ],
      "population": 65110000,
      "region": "Europe",
      "regionalBlocs": [
       {
        "acronym": "EU",
        "name": "European Union",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "Northern Europe",
      "timezones": [
       "UTC-08:00",
       "UTC-05:00",
       "UTC-04:00",
       "UTC-03:00",
       "UTC-02:00",
       "UTC",
       "UTC+01:00",
       "UTC+02:00",
       "UTC+06:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".uk"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Reino Unido",
       "de": "Vereinigtes Königreich",
       "es": "Reino Unido",
       "fa": "بریتانیای کبیر و ایرلند شمالی",
       "fr": "Royaume-Uni",
       "hr": "Ujedinjeno Kraljevstvo",
       "it": "Regno Unito",
       "ja": "イギリス",
       "nl": "Verenigd Koninkrijk",
       "pt": "Reino Unido"
      }
     },
     "name": "Royaume-Uni",
     "phoneprefix": [
      44
     ]
    },
    {
     "code": "USA",
     "finance": {
      "currencies": [
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/usa.svg",
     "geo": {
      "area": 9629091,
      "borders": [
       "CAN",
       "MEX"
      ],
      "capital": "Washington, D.C.",
      "latlng": [
       38,
       -97
      ],
      "population": 323947000,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "NAFTA",
        "name": "North American Free Trade Agreement",
        "otherAcronyms": [],
        "otherNames": [
         "Tratado de Libre Comercio de América del Norte",
         "Accord de Libre-échange Nord-Américain"
        ]
       }
      ],
      "subregion": "Northern America",
      "timezones": [
       "UTC-12:00",
       "UTC-11:00",
       "UTC-10:00",
       "UTC-09:00",
       "UTC-08:00",
       "UTC-07:00",
       "UTC-06:00",
       "UTC-05:00",
       "UTC-04:00",
       "UTC+10:00",
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".us"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Estados Unidos",
       "de": "Vereinigte Staaten von Amerika",
       "es": "Estados Unidos",
       "fa": "ایالات متحده آمریکا",
       "fr": "États-Unis",
       "hr": "Sjedinjene Američke Države",
       "it": "Stati Uniti D'America",
       "ja": "アメリカ合衆国",
       "nl": "Verenigde Staten",
       "pt": "Estados Unidos"
      }
     },
     "name": "États-Unis",
     "phoneprefix": [
      1
     ]
    },
    {
     "code": "URY",
     "finance": {
      "currencies": [
       {
        "code": "UYU",
        "name": "Uruguayan peso",
        "symbol": "$"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ury.svg",
     "geo": {
      "area": 181034,
      "borders": [
       "ARG",
       "BRA"
      ],
      "capital": "Montevideo",
      "latlng": [
       -33,
       -56
      ],
      "population": 3480222,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".uy"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Uruguai",
       "de": "Uruguay",
       "es": "Uruguay",
       "fa": "اروگوئه",
       "fr": "Uruguay",
       "hr": "Urugvaj",
       "it": "Uruguay",
       "ja": "ウルグアイ",
       "nl": "Uruguay",
       "pt": "Uruguai"
      }
     },
     "name": "Uruguay",
     "phoneprefix": [
      598
     ]
    },
    {
     "code": "UZB",
     "finance": {
      "currencies": [
       {
        "code": "UZS",
        "name": "Uzbekistani so'm",
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/uzb.svg",
     "geo": {
      "area": 447400,
      "borders": [
       "AFG",
       "KAZ",
       "KGZ",
       "TJK",
       "TKM"
      ],
      "capital": "Tashkent",
      "latlng": [
       41,
       64
      ],
      "population": 31576400,
      "region": "Asia",
      "regionalBlocs": [],
      "subregion": "Central Asia",
      "timezones": [
       "UTC+05:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".uz"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "uz",
        "iso639_2": "uzb",
        "name": "Uzbek",
        "nativeName": "Oʻzbek"
       },
       {
        "iso639_1": "ru",
        "iso639_2": "rus",
        "name": "Russian",
        "nativeName": "Русский"
       }
      ],
      "translations": {
       "br": "Uzbequistão",
       "de": "Usbekistan",
       "es": "Uzbekistán",
       "fa": "ازبکستان",
       "fr": "Ouzbékistan",
       "hr": "Uzbekistan",
       "it": "Uzbekistan",
       "ja": "ウズベキスタン",
       "nl": "Oezbekistan",
       "pt": "Usbequistão"
      }
     },
     "name": "Ouzbékistan",
     "phoneprefix": [
      998
     ]
    },
    {
     "code": "VUT",
     "finance": {
      "currencies": [
       {
        "code": "VUV",
        "name": "Vanuatu vatu",
        "symbol": "Vt"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/vut.svg",
     "geo": {
      "area": 12189,
      "borders": [],
      "capital": "Port Vila",
      "latlng": [
       -16,
       167
      ],
      "population": 277500,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Melanesia",
      "timezones": [
       "UTC+11:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".vu"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "bi",
        "iso639_2": "bis",
        "name": "Bislama",
        "nativeName": "Bislama"
       },
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Vanuatu",
       "de": "Vanuatu",
       "es": "Vanuatu",
       "fa": "وانواتو",
       "fr": "Vanuatu",
       "hr": "Vanuatu",
       "it": "Vanuatu",
       "ja": "バヌアツ",
       "nl": "Vanuatu",
       "pt": "Vanuatu"
      }
     },
     "name": "Vanuatu",
     "phoneprefix": [
      678
     ]
    },
    {
     "code": "VEN",
     "finance": {
      "currencies": [
       {
        "code": "VEF",
        "name": "Venezuelan bolívar",
        "symbol": "Bs F"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/ven.svg",
     "geo": {
      "area": 916445,
      "borders": [
       "BRA",
       "COL",
       "GUY"
      ],
      "capital": "Caracas",
      "latlng": [
       8,
       -66
      ],
      "population": 31028700,
      "region": "Americas",
      "regionalBlocs": [
       {
        "acronym": "USAN",
        "name": "Union of South American Nations",
        "otherAcronyms": [
         "UNASUR",
         "UNASUL",
         "UZAN"
        ],
        "otherNames": [
         "Unión de Naciones Suramericanas",
         "União de Nações Sul-Americanas",
         "Unie van Zuid-Amerikaanse Naties",
         "South American Union"
        ]
       }
      ],
      "subregion": "South America",
      "timezones": [
       "UTC-04:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ve"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Venezuela",
       "de": "Venezuela",
       "es": "Venezuela",
       "fa": "ونزوئلا",
       "fr": "Venezuela",
       "hr": "Venezuela",
       "it": "Venezuela",
       "ja": "ベネズエラ・ボリバル共和国",
       "nl": "Venezuela",
       "pt": "Venezuela"
      }
     },
     "name": "Venezuela",
     "phoneprefix": [
      58
     ]
    },
    {
     "code": "VNM",
     "finance": {
      "currencies": [
       {
        "code": "VND",
        "name": "Vietnamese đồng",
        "symbol": "₫"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/vnm.svg",
     "geo": {
      "area": 331212,
      "borders": [
       "KHM",
       "CHN",
       "LAO"
      ],
      "capital": "Hanoi",
      "latlng": [
       16.16666666,
       107.83333333
      ],
      "population": 92700000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "ASEAN",
        "name": "Association of Southeast Asian Nations",
        "otherAcronyms": [],
        "otherNames": []
       }
      ],
      "subregion": "South-Eastern Asia",
      "timezones": [
       "UTC+07:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".vn"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "vi",
        "iso639_2": "vie",
        "name": "Vietnamese",
        "nativeName": "Tiếng Việt"
       }
      ],
      "translations": {
       "br": "Vietnã",
       "de": "Vietnam",
       "es": "Vietnam",
       "fa": "ویتنام",
       "fr": "Viêt Nam",
       "hr": "Vijetnam",
       "it": "Vietnam",
       "ja": "ベトナム",
       "nl": "Vietnam",
       "pt": "Vietname"
      }
     },
     "name": "Viêt Nam",
     "phoneprefix": [
      84
     ]
    },
    {
     "code": "WLF",
     "finance": {
      "currencies": [
       {
        "code": "XPF",
        "name": "CFP franc",
        "symbol": "Fr"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/wlf.svg",
     "geo": {
      "area": 142,
      "borders": [],
      "capital": "Mata-Utu",
      "latlng": [
       -13.3,
       -176.2
      ],
      "population": 11750,
      "region": "Oceania",
      "regionalBlocs": [],
      "subregion": "Polynesia",
      "timezones": [
       "UTC+12:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".wf"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "fr",
        "iso639_2": "fra",
        "name": "French",
        "nativeName": "français"
       }
      ],
      "translations": {
       "br": "Wallis e Futuna",
       "de": "Wallis und Futuna",
       "es": "Wallis y Futuna",
       "fa": "والیس و فوتونا",
       "fr": "Wallis-et-Futuna",
       "hr": "Wallis i Fortuna",
       "it": "Wallis e Futuna",
       "ja": "ウォリス・フツナ",
       "nl": "Wallis en Futuna",
       "pt": "Wallis e Futuna"
      }
     },
     "name": "Wallis-et-Futuna",
     "phoneprefix": [
      681
     ]
    },
    {
     "code": "ESH",
     "finance": {
      "currencies": [
       {
        "code": "MAD",
        "name": "Moroccan dirham",
        "symbol": "د.م."
       },
       {
        "code": "DZD",
        "name": "Algerian dinar",
        "symbol": "د.ج"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/esh.svg",
     "geo": {
      "area": 266000,
      "borders": [
       "DZA",
       "MRT",
       "MAR"
      ],
      "capital": "El Aaiún",
      "latlng": [
       24.5,
       -13
      ],
      "population": 510713,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Northern Africa",
      "timezones": [
       "UTC+00:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".eh"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "es",
        "iso639_2": "spa",
        "name": "Spanish",
        "nativeName": "Español"
       }
      ],
      "translations": {
       "br": "Saara Ocidental",
       "de": "Westsahara",
       "es": "Sahara Occidental",
       "fa": "جمهوری دموکراتیک عربی صحرا",
       "fr": "Sahara Occidental",
       "hr": "Zapadna Sahara",
       "it": "Sahara Occidentale",
       "ja": "西サハラ",
       "nl": "Westelijke Sahara",
       "pt": "Saara Ocidental"
      }
     },
     "name": "Sahara Occidental",
     "phoneprefix": [
      212
     ]
    },
    {
     "code": "YEM",
     "finance": {
      "currencies": [
       {
        "code": "YER",
        "name": "Yemeni rial",
        "symbol": "﷼"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/yem.svg",
     "geo": {
      "area": 527968,
      "borders": [
       "OMN",
       "SAU"
      ],
      "capital": "Sana'a",
      "latlng": [
       15,
       48
      ],
      "population": 27478000,
      "region": "Asia",
      "regionalBlocs": [
       {
        "acronym": "AL",
        "name": "Arab League",
        "otherAcronyms": [],
        "otherNames": [
         "جامعة الدول العربية",
         "Jāmiʻat ad-Duwal al-ʻArabīyah",
         "League of Arab States"
        ]
       }
      ],
      "subregion": "Western Asia",
      "timezones": [
       "UTC+03:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".ye"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "ar",
        "iso639_2": "ara",
        "name": "Arabic",
        "nativeName": "العربية"
       }
      ],
      "translations": {
       "br": "Iêmen",
       "de": "Jemen",
       "es": "Yemen",
       "fa": "یمن",
       "fr": "Yémen",
       "hr": "Jemen",
       "it": "Yemen",
       "ja": "イエメン",
       "nl": "Jemen",
       "pt": "Iémen"
      }
     },
     "name": "Yémen",
     "phoneprefix": [
      967
     ]
    },
    {
     "code": "ZMB",
     "finance": {
      "currencies": [
       {
        "code": "ZMW",
        "name": "Zambian kwacha",
        "symbol": "ZK"
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/zmb.svg",
     "geo": {
      "area": 752612,
      "borders": [
       "AGO",
       "BWA",
       "COD",
       "MWI",
       "MOZ",
       "NAM",
       "TZA",
       "ZWE"
      ],
      "capital": "Lusaka",
      "latlng": [
       -15,
       30
      ],
      "population": 15933883,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".zm"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       }
      ],
      "translations": {
       "br": "Zâmbia",
       "de": "Sambia",
       "es": "Zambia",
       "fa": "زامبیا",
       "fr": "Zambie",
       "hr": "Zambija",
       "it": "Zambia",
       "ja": "ザンビア",
       "nl": "Zambia",
       "pt": "Zâmbia"
      }
     },
     "name": "Zambie",
     "phoneprefix": [
      260
     ]
    },
    {
     "code": "ZWE",
     "finance": {
      "currencies": [
       {
        "code": "BWP",
        "name": "Botswana pula",
        "symbol": "P"
       },
       {
        "code": "GBP",
        "name": "British pound",
        "symbol": "£"
       },
       {
        "code": "CNY",
        "name": "Chinese yuan",
        "symbol": "¥"
       },
       {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€"
       },
       {
        "code": "INR",
        "name": "Indian rupee",
        "symbol": "₹"
       },
       {
        "code": "JPY",
        "name": "Japanese yen",
        "symbol": "¥"
       },
       {
        "code": "ZAR",
        "name": "South African rand",
        "symbol": "Rs"
       },
       {
        "code": "USD",
        "name": "United States dollar",
        "symbol": "$"
       },
       {
        "code": "(none)",
        "name": null,
        "symbol": null
       }
      ]
     },
     "flag": "https://wpcdn.silico.fr/flags/zwe.svg",
     "geo": {
      "area": 390757,
      "borders": [
       "BWA",
       "MOZ",
       "ZAF",
       "ZMB"
      ],
      "capital": "Harare",
      "latlng": [
       -20,
       30
      ],
      "population": 14240168,
      "region": "Africa",
      "regionalBlocs": [
       {
        "acronym": "AU",
        "name": "African Union",
        "otherAcronyms": [],
        "otherNames": [
         "الاتحاد الأفريقي",
         "Union africaine",
         "União Africana",
         "Unión Africana",
         "Umoja wa Afrika"
        ]
       }
      ],
      "subregion": "Eastern Africa",
      "timezones": [
       "UTC+02:00"
      ]
     },
     "internet": {
      "topLevelDomain": [
       ".zw"
      ]
     },
     "languages": {
      "spoken": [
       {
        "iso639_1": "en",
        "iso639_2": "eng",
        "name": "English",
        "nativeName": "English"
       },
       {
        "iso639_1": "sn",
        "iso639_2": "sna",
        "name": "Shona",
        "nativeName": "chiShona"
       },
       {
        "iso639_1": "nd",
        "iso639_2": "nde",
        "name": "Northern Ndebele",
        "nativeName": "isiNdebele"
       }
      ],
      "translations": {
       "br": "Zimbabwe",
       "de": "Simbabwe",
       "es": "Zimbabue",
       "fa": "زیمباوه",
       "fr": "Zimbabwe",
       "hr": "Zimbabve",
       "it": "Zimbabwe",
       "ja": "ジンバブエ",
       "nl": "Zimbabwe",
       "pt": "Zimbabué"
      }
     },
     "name": "Zimbabwe",
     "phoneprefix": [
      263
     ]
    }
   ]