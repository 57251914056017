<template>
    <div class="current-subscription">
        <CurrentSubscriptionLeft :current="current" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentSubscriptionLeft from './CurrentSubscriptionLeft'

export default {
    name: 'CurrentSubscriptionHeader',
    components: {
        CurrentSubscriptionLeft,
    },
    computed: {
        ...mapGetters('subscription', ['GET_CURRENT_SUBSCRIPTION']),
        current() {
            return this.GET_CURRENT_SUBSCRIPTION
        },
    },
}
</script>