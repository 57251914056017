<template>
    <div class="header-container">
        <Logo />
        <div class="slogan">{{ $t('slogan') }}</div>
    </div>
</template>

<script>
import {Logo} from './logo'
export default {
  components: {
    Logo
  },
};
</script>