<template>
  <div class="addresses-container">
    <Address 
      :user="user"
      :show-colon-after-label="showColonAfterLabel"
      :label-mode="labelMode"
      :label-location="labelLocation"
    />
  </div>
</template>

<script>
import Address from '../components/Address'

export default {
  name: 'Addresses',
  props: ["showColonAfterLabel", "labelMode", "labelLocation", "user"],
  components: {
    Address,
  },
  computed: {},
  methods: {},
  data() {
      return {}
  },
}
</script>

<style lang="scss" scoped>
.addresses-container {
  width: 60%;
  margin: auto;
}
</style>