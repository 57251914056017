const moduleRoute = [
    {
        path: "/administration",
        name: "administration",
        meta: { requiresAuth: true, transition: 'slide-fade', labelMode: "floating", requiresRoles: ['admin', 'superadmin'], unauthorizedRedirect: "/home" },
        components: {
            layout: null,
            content: () => import(/* webpackChunkName: "silico" */ "./views/administration")
        },
    },
]

const setOptions = {
    apply: (route, options) => {
        setOptions.layout(route, options)  
        setOptions.props(route, options)  
    },
    layout: (route, options) => {
        route.components.layout = options.components.layout
    },
    props: (route, options) => {
        // // console.log("props.options", route, options)
        route.props = {
            ...route.props,
            ...options.props
        }
    }
}
export default (router, options) => {
    options = {
        default: {
            components: {
                layout: () => import(/* webpackChunkName: "silico" */ "./layouts/default")
            },
            props: {
                layout: {
                    // title: 'Administration',
                    // card: true,
                }
            }
        },
        ...options,           
    }
    moduleRoute.forEach(route => {
        const routeOptions = options[route.name] || options.default
        setOptions.apply(route, routeOptions)
        router.addRoute(route)
    })
}