<template>
   <div class="subscription-container">
        <CurrentSubscriptionHeader />
       <SubscriptionChoice />
    </div> 
</template>

<script>
import { mapActions } from 'vuex'
import CurrentSubscriptionHeader from '../components/CurrentSubscriptionHeader'
import SubscriptionChoice from '../components/SubscriptionChoice'

export default {
    name: 'Subscription',
    components: {
        CurrentSubscriptionHeader,
        SubscriptionChoice
    },
    methods: {
        ...mapActions('subscription',['FETCH_ALL'])
    },
    beforeMount() {
        this.FETCH_ALL() 
    },
}
</script>