<template>
  <div class="country-container">
    <DxSelectBox
      :data-source="countries"
      :value="code"
      :search-enabled="false"
      :search-expr="['name', 'code']"
      :search-timeout="200"
      :min-search-length="1"
      :show-clear-button="showClearButton"
      :show-data-before-search="true"
      search-mode="contains"
      value-expr="code"
      display-expr="name"
      field-template="field"
      item-template="item"
      @valueChanged="onValueChanged"
      @selectionChanged="onSelectionChanged"
    >
      <template #field="{ data }">
        <Field :field-data="data" :placeholder="fieldPlaceholder"/>
      </template>
      <template #item="{ data }">
        <Item :item-data="data"/>
      </template>
    </DxSelectBox>
  </div>
</template>

<script>
import module_i18n from '../i18n'
// import DxForm, { DxItem } from 'devextreme-vue/form'
import DxSelectBox from 'devextreme-vue/select-box'
import Field from '../components/Field'
import Item from '../components/Item'

export default {
  name: 'Country',
  props: {
      scope: {
        type: String,
        default: () => (null)
      },
      code: {
        type: String,
        default: () => (null)
      },
      filter: {
        type: Function,
        default: () => (() => true)
      },
      placeholder: {
        type: String,
        default: () => (null)
      },
      showClearButton: {
        type: Boolean,
        default: () => (true)
      },
  },
  components: {
    // DxForm,
    // DxItem,
    DxSelectBox,
    Field,
    Item,
  },
  beforeMount() {
    let options = {}
    // if (this.code) params.code = this.code
    if (this.scope) options.params = { lang: this.i18n.locale }, options.params.scope = this.scope
    this.$store.dispatch('country/FETCH_LIST',options)
  },
  computed: {
    country() {
      return this.code && this.countries.length === 1 ? this.countries[0] : null
    },
    countries() {
      // return this.$store.state['country'].list
      return this.filter ? this.$store.state['country'].list.filter(this.filter) : this.$store.state['country'].list
    },
    fieldPlaceholder() {
      return this.placeholder && this.placeholder != "" ? this.placeholder : this.i18n.t('country.select-country')
    },
    i18n() {
      return this.$i18n ? this.$i18n : this.module_i18n
    }
  },
  methods: {
    onSelectionChanged(e) {
      this.selectedItem = {...e.selectedItem}
    },
    onValueChanged(e) {
      this.$emit('valueChanged',e,this.selectedItem)
    }
  },
  data() {
      return {
        module_i18n,
        selectedItem: null
      }
  },
}
</script>

<style lang="scss">
  .dx-overlay-content {
    &.dx-popup-normal {
      padding: 0;
    }
  }
</style>

<style lang="scss" scoped>
.country-container {
  
}
</style>