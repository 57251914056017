import * as bcrypt from 'bcryptjs';
const saltRounds = 10;

export const hash = (password, salt = null) => {
    salt = salt || bcrypt.genSaltSync(saltRounds);
    const hashPassword = bcrypt.hashSync(password, salt);
    return {
        salt,
        password: hashPassword
    }
}

export default {
    hash
}