<template>
  <div class="custom-item">
    <img
      v-if="fieldData"
      :src="fieldData.flag"
    >
    <DxTextBox
      :value="fieldData && fieldData.name"
      :read-only="true"
      :placeholder="placeholder"
      class="product-name"
    />
  </div>
</template>
<script>

import DxTextBox from 'devextreme-vue/text-box';

export default {
  components: {
    DxTextBox,
  },
  props: {
    placeholder: {
      type: String,
      default: () => ('')
    },
    fieldData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-item {
  display: flex;
  align-items: center;

  img {
    width: 24px;
    margin-left: 10px;
  }
}
</style>
