import devextremConfig from 'devextreme/core/config';
import csMessages from "devextreme/localization/messages/cs.json";
import deMessages from "devextreme/localization/messages/de.json";
import elMessages from "devextreme/localization/messages/el.json";
import enMessages from "devextreme/localization/messages/en.json";
import esMessages from "devextreme/localization/messages/es.json";
import fiMessages from "devextreme/localization/messages/fi.json";
import frMessages from "devextreme/localization/messages/fr.json";
import huMessages from "devextreme/localization/messages/hu.json";
import itMessages from "devextreme/localization/messages/it.json";
import jaMessages from "devextreme/localization/messages/ja.json";
import nlMessages from "devextreme/localization/messages/nl.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import ruMessages from "devextreme/localization/messages/ru.json";
import slMessages from "devextreme/localization/messages/sl.json";
import svMessages from "devextreme/localization/messages/sv.json";
import trMessages from "devextreme/localization/messages/tr.json";
import viMessages from "devextreme/localization/messages/vi.json";
import zhTwMessages from "devextreme/localization/messages/zh-tw.json";
import zhMessages from "devextreme/localization/messages/zh.json";
import { locale, loadMessages as devextremeLoadMessages } from "devextreme/localization";

// import "devextreme/localization/globalize/number";
// import "devextreme/localization/globalize/date";
// import "devextreme/localization/globalize/currency";
// import "devextreme/localization/globalize/message";

// import supplemental from "devextreme-cldr-data/supplemental.json";
// import frCldrData from "devextreme-cldr-data/fr.json";
// import deCldrData from "devextreme-cldr-data/de.json";
// import ruCldrData from "devextreme-cldr-data/ru.json"; 

// import Globalize from "devextreme/localization/globalize";

const loadMessages = () => {
    devextremeLoadMessages(csMessages);
    devextremeLoadMessages(deMessages);
    devextremeLoadMessages(elMessages);
    devextremeLoadMessages(enMessages);
    devextremeLoadMessages(esMessages);
    devextremeLoadMessages(fiMessages);
    devextremeLoadMessages(frMessages);
    devextremeLoadMessages(huMessages);
    devextremeLoadMessages(itMessages);
    devextremeLoadMessages(jaMessages);
    devextremeLoadMessages(nlMessages);
    devextremeLoadMessages(ptMessages);
    devextremeLoadMessages(ruMessages);
    devextremeLoadMessages(slMessages);
    devextremeLoadMessages(svMessages);
    devextremeLoadMessages(trMessages);
    devextremeLoadMessages(viMessages);
    devextremeLoadMessages(zhTwMessages);
    devextremeLoadMessages(zhMessages);
}

// const loadGlobalization = () => {
//     Globalize.load(
//         supplemental, deCldrData, ruCldrData, frCldrData
//     );
//     Globalize.loadMessages(csMessages);
//     Globalize.loadMessages(deMessages);
//     Globalize.loadMessages(elMessages);
//     Globalize.loadMessages(enMessages);
//     Globalize.loadMessages(esMessages);
//     Globalize.loadMessages(fiMessages);
//     Globalize.loadMessages(frMessages);
//     Globalize.loadMessages(huMessages);
//     Globalize.loadMessages(itMessages);
//     Globalize.loadMessages(jaMessages);
//     Globalize.loadMessages(nlMessages);
//     Globalize.loadMessages(ptMessages);
//     Globalize.loadMessages(ruMessages);
//     Globalize.loadMessages(slMessages);
//     Globalize.loadMessages(svMessages);
//     Globalize.loadMessages(trMessages);
//     Globalize.loadMessages(viMessages);
//     Globalize.loadMessages(zhTwMessages)
//     Globalize.loadMessages(zhMessages);
//     // Globalize.locale(navigator.language);
//     Globalize.locale('ru');
// }

export { 
    locale,
    loadMessages,
    devextremConfig,
    // loadGlobalization
}
export default { 
    locale,
    loadMessages,
    devextremConfig,
    // loadGlobalization
}