<template>
    <div class="subscription-choice">
        <div class="subscription-sub-title">{{ i18n.t('subscription.sub-title') }}</div>
        <div class="subscription-options">
            <Card v-for="(card, index) in cards" :key="`card-${index}`" :title="card.title" :list="card.list" :price="card.price" />
        </div>
    </div>  
</template>

<script>
import module_i18n from '../i18n'
import Card from './card'

export default {
    components: {
        Card,
    },
    data() {
        return {
            module_i18n,
        }
    },
    computed: {
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
        cards() {
            return this.$store.getters['subscription/GET_LIST']
        }
    }
}
</script>