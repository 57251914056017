export const SET_LOADING = "SET_LOADING";
export const SET_SENDINGS = "SET_SENDINGS";
export const SET_RECEIVINGS = "SET_RECEIVINGS";
export const SET_FILES = 'SET_FILES'
export const SET_ENVELOPE = 'SET_ENVELOPE'
export const SET_CIPHER = 'SET_CIPHER'
export const SET_DECIPHER = 'SET_DECIPHER'
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS'
export const GET_ENVELOPE = 'GET_ENVELOPE'
export const POST_ENVELOPE = 'POST_ENVELOPE'
export const UPDATE_RECEIVING = 'UPDATE_RECEIVING'
export const LAUNCH_CIPHER = 'LAUNCH_CIPHER'
export const LAUNCH_DECIPHER = 'LAUNCH_DECIPHER'
export const GET_CIPHER_STATUS = 'GET_CIPHER_STATUS'
export const GET_DECIPHER_STATUS = 'GET_DECIPHER_STATUS'
export const GET_CURRENT_FILE_SIZE_MONTH = 'GET_CURRENT_FILE_SIZE_MONTH'
export const GET_CURRENT_SENDING_FILE_SIZE = 'GET_CURRENT_SENDING_FILE_SIZE'
export const GET_REMAIN_FILE_SIZE = 'GET_REMAIN_FILE_SIZE'
export const FETCH_CIPHER_DECIPHER_STATUS = 'FETCH_CIPHER_DECIPHER_STATUS'
export const FETCH_SENDINGS = 'FETCH_SENDINGS'
export const FETCH_RECEIVINGS = 'FETCH_RECEIVINGS'
export const DELETE_SENDING = 'DELETE_SENDING'
export const RESET = 'RESET'
export const START_DOWNLOADING_TASK = 'START_DOWNLOADING_TASK'

import { Silico as API } from '@/SDK'
import { prepareResultRows, sum } from '@/utils';
import store from '@/store'
// import { interpret } from 'xstate';

API.client.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log('--- AXIOS REQUEST', config)
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
API.client.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('--- AXIOS RESPONSE', response)
    store.dispatch('authentication-basic/REFRESH')
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

const prepareSecureOptions = (state) => {
    const headers = {
        'Content-Type': 'application/json'
    }

    if (state.tokens && state.tokens.accessToken) headers['Authorization'] = `Bearer ${state.tokens.accessToken}`

    return {
        headers
    }
}

export default {
    namespaced: true,
    state: {
        loading: false,
        files: [],
        sendings: null,
        receivings: null,
        uploadStatus: null,
        envelope: null,
        cipher: null,
        decipher: null
    },
    mutations: {
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [SET_FILES]: (state, files) => state.files = files,
        [SET_ENVELOPE]: (state, envelope) => state.envelope = envelope,
        [SET_CIPHER]: (state, cipher) => state.cipher = cipher,
        [SET_DECIPHER]: (state, decipher) => state.decipher = decipher,
        [SET_UPLOAD_STATUS]: (state, uploadStatus) => state.uploadStatus = uploadStatus,
        [SET_SENDINGS]: (state, sendings) => state.sendings = sendings,
        [SET_RECEIVINGS]: (state, receivings) => state.receivings = receivings,
    },
    actions: {
        [RESET]: ({ commit, dispatch }) => {
            commit(SET_FILES, [])
            commit(SET_ENVELOPE  , null)
            commit(SET_CIPHER, null)
            commit(SET_DECIPHER, null)
            commit(SET_UPLOAD_STATUS,null)
            dispatch(FETCH_SENDINGS)
        },
        [GET_ENVELOPE]: ({ rootState }, envelopeUUID) => {
            // console.log("call GET_ENVELOPE", envelopeUUID)
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.getEnvelope(envelopeUUID, options).catch(error => console.log("ERROR sendings:",error))
        },
        [DELETE_SENDING]: ({ commit, rootState }, envelopeUUID) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)  

            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.deleteEnvelope(envelopeUUID, options)
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [POST_ENVELOPE]: ({ commit, rootState }, envelope) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)  
            commit(SET_ENVELOPE,envelope)

            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            envelope.files = envelope.files.map(file => {
                return {
                    name: file.name,
                    size: file.size,
                    type: file.type
                }
            })

            return API.submitSending(envelope, options)
                .then(async res => {
                    // if (res.success) await commit(SET_ADDRESS, res.data)
                    // console.log("RESPONSE submitSending", res)
                    if (res.success) await commit(SET_ENVELOPE,res.data)
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [UPDATE_RECEIVING]: ({ rootState, commit }, {sendingUUID, fileUUID}) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)  
            // console.log("LAUNCH_DECIPHER", sendingUUID, fileUUID)   
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.updateReceiving(sendingUUID, fileUUID, options)
                .then(async res => {
                    // if (res.success) await commit(SET_ADDRESS, res.data)
                    // console.log("RESPONSE updateReceiving", res)
                    // if (res.success) await commit(SET_CIPHER,res.data)
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [LAUNCH_CIPHER]: ({ state, rootState, commit }) => {
            commit(SET_LOADING, true)
            // console.log("LAUNCH_CIPHER", state.envelope.uuid)   
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.launchCipher(state.envelope.uuid, options)
                .then(async res => {
                    // console.log("RESPONSE launchCipher", res)
                    if (res.success && state.envelope) await commit(SET_CIPHER,res.data)
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                })
        },
        [LAUNCH_DECIPHER]: ({ rootState }, {sendingUUID, fileUUID}) => {
            // commit(SET_LOADING, true)
            // console.log("LAUNCH_DECIPHER", sendingUUID, fileUUID)   
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.launchDecipher(sendingUUID, fileUUID, options)
                .then(async res => {
                    // if (res.success) await commit(SET_ADDRESS, res.data)
                    // console.log("RESPONSE launchDecipher", res)
                    // if (res.success) await commit(SET_CIPHER,res.data)
                    return res
                })
                .finally(()=>{
                    // commit(SET_LOADING, false)
                })
        },
        [FETCH_CIPHER_DECIPHER_STATUS]: ({ state, rootState, commit }, actionUUID) => {
            // commit(SET_LOADING, true)
            // console.log("FETCH_CIPHER_DECIPHER_STATUS", actionUUID)   
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.getCipherStatus(actionUUID, options)
                .then(async res => {
                    // console.log("RESPONSE FETCH_CIPHER_DECIPHER_STATUS", res)
                    if (res.success && res.data.action === 'CIPHER' && state.envelope) await commit(SET_CIPHER,res.data)
                    return res
                })
                .finally(()=>{
                    // commit(SET_LOADING, false)
                })
        },
        [FETCH_SENDINGS]: ({ rootState, commit }) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            // console.log("FETCH_SENDINGS")   
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.getSendingList(options)
                .then(res => {
                    // console.log("FETCH_SENDINGS result",res)
                    if (res && res.data && res.success) {
                        // console.log("prepareResultRows",prepareResultRows(res.data))
                        return commit(SET_SENDINGS, prepareResultRows(res.data))
                    }
                    return commit(SET_SENDINGS, null)
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [FETCH_RECEIVINGS]: ({ rootState, commit }) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            // console.log("FETCH_RECEIVINGS")   
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.getReceivingList(options)
                .then(res => {
                    // console.log("FETCH_RECEIVINGS result",res)
                    if (res && res.data && res.success) {
                        return commit(SET_RECEIVINGS, prepareResultRows(res.data))
                    }
                    return commit(SET_RECEIVINGS, null)
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [START_DOWNLOADING_TASK]: async (context, row) => {
            // console.log("START_DOWNLOADING_TASK", context, row)

            const rootState = context.rootState
            let found = rootState.downloadMachines.find(machine => machine.context.sendingUUID === row.sendingUUID && machine.context.fileUUID === row.fileUUID)

            if (!found) {
                await context.commit('ADD_DOWNLOAD', {
                    ...row
                }, { root: true })
                // found = rootState.downloadMachines.find(machine => machine.context.sendingUUID === row.sendingUUID && machine.context.fileUUID === row.fileUUID)
            }



            // // console.log("found",found, found.state, found.send)
            // // console.log(interpret(found))

            
            // const options = prepareSecureOptions(this.$store.state['authentication-basic'])

            // let envelope = await API.getEnvelope(sendingUUID, options)
            // // console.log(envelope, fileUUID)

            // if (envelope.success) {
            //   const file = envelope.data.files.find(file => file.uuid === fileUUID)
                
            //   // console.log(file)

            //   if (file) {
            //     if (file.download_url && Array.isArray(file.download_url) && file.download_url.length === 1 && file.download_url[0].url) {
            //       file.download_url = new URL(file.download_url[0].url)
            //       const validityDate = file.download_url.searchParams.get('X-Amz-Date')
            //       const validityExpires = file.download_url.searchParams.get('X-Amz-Expires')
            //       // console.log("file.download_url", file.download_url, validityDate, validityExpires)
            //       return;
            //     }
            //   }
            // }
            
            // const launchDecipherResult = await API.launchDecipher(sendingUUID, fileUUID, options)

            // // console.log(launchDecipherResult) 
        }
    },
    getters: {
        [GET_CIPHER_STATUS]: (state) => state.cipher && state.cipher.status, 
        [GET_DECIPHER_STATUS]: (state) => state.decipher && state.decipher.status, 
        [GET_CURRENT_FILE_SIZE_MONTH]: (state) => sum((state.sendings || [])
                            .filter(sending => sending.send_on.getMonth() === (new Date().getMonth()))
                             .map(sending => sum((sending.files || []).map(file => file.size)))),
        [GET_CURRENT_SENDING_FILE_SIZE]: (state) => sum((state.files || []).map(file => file.size)),
        [GET_REMAIN_FILE_SIZE]: (state, getters, rootState) => 
                rootState.limits.max_file_size_per_month
                - getters.GET_CURRENT_FILE_SIZE_MONTH
                - getters.GET_CURRENT_SENDING_FILE_SIZE
    }
}