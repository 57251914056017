import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n"
import sendings from './sendings'
import {
    createMachine
} from 'xstate';
// import { useMachine } from 'xstate-vue2';
import {
    downloadMachine
} from './sendings/download-machine'
// import createPersistedState from "vuex-persistedstate";
// import {getPersistState} from '@/modules'

// const persistState = ()=>{
//     return {
//         key: "go2transfer",
//         paths: [
//             'authentication-basic',
//         ],
//         storage: getPersistState()
//     }
// }

const ADD_MENU = 'ADD_MENU'
const ADD_DOWNLOAD = 'ADD_DOWNLOAD'
const REMOVE_DOWNLOAD = 'REMOVE_DOWNLOAD'
const GET_MENU = 'GET_MENU'
// const GET_STATE_MACHINES = 'GET_STATE_MACHINES'
const SET_LOADING = 'SET_LOADING'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        _menu: [{
                text: 'menu.send-new-file',
                path: "/send",
                class: "menu-send",
            }, {
                text: 'menu.sended',
                path: '/sendings',
                class: 'menu-all-send',
            }, {
                text: 'menu.received',
                path: '/receiving',
                class: 'menu-all-receive',
            },
            // {
            //     text: 'menu.payment',
            //     path: '/payment',
            //     class: 'menu-payment',
            // }, 
            // {
            //     text: 'menu.settings',
            //     path: '/settings',
            //     class: 'menu-settings',
            // }, 
            {
                text: 'menu.profile',
                path: '/profile',
                class: 'menu-account',
            }, {
                text: 'administration.menu.admin',
                roles: ['admin', 'superadmin'],
                path: '/administration',
                class: 'menu-administration',
            }, {
                text: 'authentication-basic.logout.button',
                // onClick: async () => {
                //     const actions = {...Vuex.mapActions('authentication-basic',['LOGOUT'])}
                //     console.log(actions)
                //     await actions.LOGOUT()
                //     this.$router.push({ name: "home" }).catch()
                // },
                onClick: 'logout',
                class: 'menu-logout',
            }
        ],
        downloadMachines: [],
        limits: {
            max_file_upload: 1,
            max_file_size: 2 * 1024 * 1024 * 1024,
            max_file_retention: 3,
            max_file_size_per_month: 20 * 1024 * 1024 * 1024,
            // max_file_size_per_month: 1 * 1024 * 1024,
        }
    },
    actions: {},
    mutations: {
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [ADD_MENU]: (state, menu) => {
            if (!state._menu.find(menu)) state._menu.push(menu)
        },
        [ADD_DOWNLOAD]: (state, context) => {
            const currentDownloadMachine = {
                ...downloadMachine
            }
            currentDownloadMachine.context = {
                ...currentDownloadMachine.context,
                ...context
            }
            // console.log("currentDownloadMachine", currentDownloadMachine)
            return state.downloadMachines.push(createMachine(currentDownloadMachine))
        },
        [REMOVE_DOWNLOAD]: (state, context) => state.downloadMachines = [...state.downloadMachines.filter(machine => machine.context.sendingUUID !== context.sendingUUID && machine.context.fileUUID !== context.fileUUID)]
    },
    getters: {
        [GET_MENU]: (state, rootGetters) => {
            const isAuthenticated = rootGetters['authentication-basic/GET_LOGGED_IN']
            const userRoles = rootGetters['authentication-basic/GET_ROLES']

            if (isAuthenticated && userRoles) {
                const returnMenu = state._menu.filter(menu => menu.roles && Array.isArray(menu.roles) ? menu.roles.some(role => userRoles.some(userRole => userRole === role)) : true)
                return returnMenu.map(menu => {
                    let newMenu = {
                        ...menu
                    }
                    newMenu.text = i18n.t(newMenu.text)
                    // newMenu.onClick ? menu.
                    return newMenu
                })
            }

            return []
        },
        // [GET_STATE_MACHINES]: (state) => {
        //     if (state.downloadMachines && state.downloadMachines.length <= 0) return []
        //     return state.downloadMachines && state.downloadMachines.length > 0 && state.downloadMachines.map(machine => useMachine(machine))
        // }
    },
    modules: {
        sendings
    }
    // plugins: [createPersistedState(persistState())]
})