<template>
    <div>
        <div v-if="!sending.expired" class="cell-container">
            <div class="col-content" :key="`remaining-time-${rt}`">
                {{ getRemainingTime }}
            </div>
            <Progress class="col-progress-value" :radius="15" :strokeWidth="3" strokeColor="#434343" :value="(sending.send_duration-sending.remaining_time)*100/sending.send_duration">
                <div></div>
            </Progress>
        </div>
        <div v-else class="cell-container cell-expired">{{$i18n.t('stats.package.expired')}}</div>
    </div>
</template>

<script>
import { 
    dateDiff, 
    // dateDiffDay, 
    // dateDiffHour, 
    // dateDiffMinute, 
    // dateDiffSecond 
} from '@/SDK/Common';
import Progress from "easy-circular-progress";

export default {
    name: 'RowRemainingTime',
    props: ['sending'],
    components: {
        Progress,
    },
    data() {
        return {
            rt: null,
            interval: null
        }
    },
    beforeMount() {
        this.rt = dateDiff(new Date(), this.sending.end_date)
        if (!this.sending.expired) {
            this.interval = setInterval(() => {
                this.rt = dateDiff(new Date(), this.sending.end_date)
            }, 900);
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    computed: {
        getRemainingTime() {
            const rt = [
                `${this.rt.day}${this.$i18n.t('sendings.columns.remaining_time_dayUnit')}`,
                `${this.rt.hour.toString().padStart(2,'0')}h`,
                `${this.rt.min.toString().padStart(2,'0')}m`,
                `${this.rt.sec.toString().padStart(2,'0')}s`,
            ]
            return rt.join(' ')
        }
    }
}
</script>

<style lang="scss" scoped>
.cell-container {
    font-family: "LatoWebThin";
    font-size: 19px;
    justify-content: center;
    height: 36px;

    .col-progress-value {
        margin-left: 8px;
    }
}
</style>