const dataSources = require('./dataSources')

module.exports = {
    login(login, password) {
        return new Promise((resolve ,reject) => {
            console.log('[MOCK login]',login, password)
            setTimeout(() => {
                if (password == dataSources.user.password) resolve({ success: true, user: {...dataSources.user}, token: dataSources.token })
                else reject(new Error('Bad credntials'))
            },2000)
        })
    },

    logout(options) {
        return new Promise((resolve) => {
            console.log('[MOCK logout]',options)
            setTimeout(() => {
                resolve({ success: true, user: null, token: null })
            },2000)
        })
    },

    checkToken(token) {
        console.log("TODO : Vérification date d'expiration avec JWT (+ refreshToken)")
        console.log("checkToken",token)
        return !!token
    },

    profile: (options) => {
        return new Promise((resolve) => {
            console.log('[MOCK profile]',options)
            setTimeout(() => {
                resolve({ success: true, user: {...dataSources.user} })
            },2000)
        })
    },

    resetPassword(payload) {
        return new Promise((resolve ,reject) => {
            console.log('[MOCK resetPassword]',payload)
            setTimeout(() => {
                if (payload.resetToken && payload.newPassword && payload.confirmPassword && payload.newPassword === payload.confirmPassword) resolve({ success: true })
                else reject(new Error('Bad credntials'))
            },2000)
        }) 
    },

    updateAccount(user) {
        return new Promise((resolve) => {
            console.log('[MOCK updateAccount]',user)
            setTimeout(() => {
                resolve({ success: true, user: {...user} })
            },2000)
        })
    },
}