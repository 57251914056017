export const SET_LOADING = ' SET_LOADING'
export const SET_FILELIST = 'SET_FILELIST'
export const GET_FILELIST = 'GET_FILELIST'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        fileList: [],
    }),
    mutations:{
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [SET_FILELIST]: (state, fileList) => state.fileList = fileList,
    },
    actions:{
    },
    getters:{
        [GET_FILELIST]: state => state.fileList,
    },
}