import router from "@/router";
import store from "@/store";
import i18n, { updateLocaleMessage } from "@/i18n"
// import { registerAndGetStore } from 'vuex-stores'

const registerModule = (name, module, options) => {
  // // console.log("registerModule",name,module)
  if (module.store) {
    // // console.log("register Store",name,module)
    store.registerModule(name, module.store)
    // registerAndGetStore(store, {namespace:name, ...module.store}, { preserveState: module.preserveState && !!localStorage.getItem('go2transfer') })
  }

  if (module.i18n) {
    // // console.log("register i18n",name,module)
    updateLocaleMessage(module.i18n,i18n)
  }

  if (module.router) {
    // // console.log("register Router",name,module)
    module.router(router, options);
  }
};

export const registerModules = (modules, options) => {
  Object.keys(modules).forEach(moduleKey => {
    const module = modules[moduleKey];
    registerModule(moduleKey, module, options && options[moduleKey] ? options[moduleKey] : null);
  });
};
