export const SET_LOADING = "SET_LOADING";
export const SET_ADDRESS = "SET_ADDRESS";
export const GET_ADDRESS = "GET_ADDRESS";
export const IS_INCOMPLETE = "IS_INCOMPLETE";

export const FETCH_ALL = "FETCH_ALL";
export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const CREATE_OR_UPDATE_ADDRESS = "CREATE_OR_UPDATE_ADDRESS";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";

import { APIAddresses as API } from '../SDK'
// import SDK from '../SDK/Silico'

const prepareSecureOptions = (state) => {
    const headers = {
        'Content-Type': 'application/json'
    }

    if (state.tokens && state.tokens.accessToken) headers['Authorization'] = `Bearer ${state.tokens.accessToken}`

    return {
        headers
    }
}

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        address: {},
    }),
    mutations:{
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [SET_ADDRESS]: (state, address) => state.address = address,
    },
    actions:{
        //  EXEMPLE FETCH ALL DATA
        //  [FETCH_ALL]: ({dispatch}) => {
        //      // console.log("FETCH_ALL")
        //      Promise.all([dispatch('FETCH_ADDRESS'),dispatch('FETCH_CURRENT_SUBSCRIPTION')])
        //  },
        [FETCH_ADDRESS]: ({ commit, rootState }) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            
            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            return API.getAddress(options)
                .then(async res => {
                    if (res.success) await commit(SET_ADDRESS, res.data)
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [CREATE_OR_UPDATE_ADDRESS]: ({ state, dispatch }) => {
            if (state.address && state.address.detail && state.address.detail.adr_uuid) return dispatch(UPDATE_ADDRESS)
            return dispatch(CREATE_ADDRESS)
        },
        [CREATE_ADDRESS]: ({ state, rootState, commit, getters }) => {
            if (getters[IS_INCOMPLETE]) return Promise.resolve({ success: false, message: 'No Address'})

            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)

            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            // console.log("CREATE_ADDRESS", state.address, options)

            return API.createAddress(state.address, options)
                .then(async res => {
                    if (res.success) await commit(SET_ADDRESS, res.data)
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [UPDATE_ADDRESS]: ({ state, rootState, commit, getters }) => {
            if (getters[IS_INCOMPLETE]) return Promise.resolve({ success: false, message: 'No Address'})
            
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)

            const options = prepareSecureOptions(rootState['authentication-basic'] ? rootState['authentication-basic'] : {})

            // console.log("UPDATE_ADDRESS", state.address, options)

            return API.updateAddress(state.address, options)
                .then(async res => {
                    if (res.success) await commit(SET_ADDRESS, res.data)
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        }
    },
    getters:{
        [GET_ADDRESS]: state => state.address,
        [IS_INCOMPLETE]: state => {
            // console.log("IS_INCOMPLETE", state)

            if (!(state.address)) { console.log('address missing'); return true}
            // if (!(state.address.usr_id)) { console.log('usr_id missing'); return true}
            if (!(state.address.type)) { console.log('type missing'); return true}
            if (!(state.address.detail)) { console.log('detail missing'); return true}
            if (!(state.address.detail.adr_way_name)) { console.log('adr_way_name missing'); return true}
            if (!(state.address.detail.adr_postal_code)) { console.log('adr_postal_code missing'); return true}
            if (!(state.address.detail.adr_city)) { console.log('adr_city missing'); return true}
            if (!(state.address.detail.adr_cou_id)) { console.log('adr_cou_id missing'); return true}

            return false
        }
    },
}