<template>
    <div :class="classBox">
        <div class="title" @click="goTo()">{{title}}</div>
        <div class="items">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: () => ('sended')
        }
    },
    methods: {
        goTo() {
            this.$router.push({name: this.type === "sended" ? 'sendings' : 'receiving'})
        }
    },
    computed: {
        title() {
            return this.type === "sended" ? this.$i18n.t('stats.box.my_sent') : this.$i18n.t('stats.box.my_receives')
        },
        classBox() {
            let classBox = {
                "dx-card": true,
                "box": true,
            }

            classBox[this.type] = true

            return classBox
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    $backgroundColor: #D9DBDC;
    height: 257px;

    font-family: 'LatoWeb';

    &.sended {
        background: url(/images/packages-sended.svg)  $backgroundColor bottom 50px left -40px no-repeat;
    }

    &.received {
        background: url(/images/packages-received.svg)  $backgroundColor bottom 50px left -40px no-repeat;
    }

    .title {
        font-size: 21px;
        width: 100%;
        padding: 25px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .items {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}
</style>