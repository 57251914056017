export const SET_LOADING = "SET_LOADING";
export const SET_LIST = "SET_LIST";
export const GET_LIST = "GET_LIST";

export const FETCH_ALL = "FETCH_ALL";
export const FETCH_LIST = "FETCH_LIST";
export const SEARCH = "FETCH_LIST";

import { APICountry as API } from '../SDK'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        list: [],
    }),
    mutations:{
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [SET_LIST]: (state, list) => state.list = list,
    },
    actions:{
        [FETCH_LIST]: ({ commit }, payload) => {
            commit(SET_LOADING, true)
            return API.getCountryList(payload)
                .then(async res => {
                    if (res.success) await commit(SET_LIST, [...res.list])
                    return res
                })
                .finally(()=>{
                    commit(SET_LOADING, false)
                })
        },
    },
    getters:{
        [GET_LIST]: state => state.list,
    },
}