<template>
  <div :class="classContainer">
      <div :class="classCard">
        <h2 v-if="!isCreateMode" class="title">{{ $i18n.t('menu.profile') }}</h2>
        <CreateAccountLayout
          @initialized="onFormInitialized"
          :type="type"
          :form-data.sync="forwardFormData"
          :disabled="loading"
          :show-colon-after-label="showColonAfterLabel"
          :label-mode="labelMode"
          :label-location="labelLocation"
          :onFormValueChanged="onFormValueChanged"
        >
          <template v-slot:info>
            <dx-item
              cssClass="create-account-items"
              item-type="group"
              :col-count="2"
              :col-span="2"
            >
              <dx-item
                item-type="group"
                :caption="t('create-account.identity')"
              >
                <dx-item
                  data-field="firstname"
                  :is-required="true"
                  :label="{ text: t('create-account.firstname') }"
                />
                <dx-item
                  data-field="lastname"
                  :is-required="true"
                  :label="{ text: t('create-account.lastname') }"
                />
                <dx-item
                  data-field="company"
                  :is-required="false"
                  :label="{ text: t('create-account.company') }"
                />
              </dx-item>
              <dx-item
                item-type="group"
                :caption="t('create-account.account')"
              >
                <dx-item
                  data-field="mobile"
                  :is-required="true"
                  :label="{ text: t('create-account.mobile') }"
                />
                <dx-item
                  data-field="login"
                  :is-required="true"
                  :label="{ text: t(`authentication-basic.common.login`) }"
                  :editor-options="{ mode: 'email' }"
                >
                  <dx-required-rule :message="t('authentication-basic.common.email_is_required')" />
                  <dx-email-rule :message="t('authentication-basic.common.email_is_invalid')" />
                </dx-item>

                <dx-item item-type="group" :col-count="2">
                  <dx-button-item :button-options="changePasswordButtonOptions" v-if="!isCreateMode">
                    <!-- <dx-button-options
                      width="100%"
                      type="normal"
                      template="changePassword"
                      :use-submit-behavior="false"
                    >
                    </dx-button-options> -->
                  </dx-button-item> 
                  <dx-button-item :button-options="logoutButtonOptions" v-if="!isCreateMode">
                  </dx-button-item> 
                </dx-item>
                
                <dx-item
                  v-if="isCreateMode"
                  data-field="password"
                  :is-required="true"
                  :label="{ text: t('authentication-basic.common.password') }"
                  :editor-options="{ mode: 'password' }"
                >
                  <dx-required-rule :message="t('authentication-basic.common.password_is_required')" />
                  <dx-pattern-rule
                    :pattern="passwordPattern"
                    :message="t('authentication-basic.common.password-pattern')"
                  />
                </dx-item>
                <dx-item
                  v-if="isCreateMode"
                  data-field="confirmPassword"
                  :is-required="true"
                  :label="{ text: t('authentication-basic.create-account.confirm_password') }"
                  :editor-options="{ mode: 'password' }"
                >
                  <dx-required-rule :message="t('authentication-basic.common.password_is_required')" />
                  <dx-custom-rule
                    :message="t('authentication-basic.create-account.password_not_match')"
                    :validation-callback="confirmPassword"
                  />
                </dx-item>

              </dx-item>
            </dx-item>
          </template>

          <template v-slot:address>
            <dx-item v-if="!isCreateMode">
              <template #default>
                <Addresses :user="forwardFormData"/>
              </template>
            </dx-item>
          </template>

          <template v-slot:subscription>
            <dx-item v-if="!isCreateMode">
              <template #default>
                <Subscription :user="forwardFormData"/>
              </template>
            </dx-item>
          </template>

          <template v-slot:buttonSubmit>
            <dx-button-item>
              <dx-button-options
                width="100%"
                type="normal"
                template="createAccount"
                :use-submit-behavior="true"
              >
              </dx-button-options>
            </dx-button-item>
          </template>
        </CreateAccountLayout>
      </div>
  </div>
</template>

<script>
import { Subscription } from '@/modules/subscription'
import { Addresses } from '@/modules/addresses'
import { createAccountLayout as CreateAccountLayout } from '../../layouts'
import { formTypes } from '@/modules'
// import { i18n } from '@/modules/authentication-basic'
import {
  DxItem,
  DxButtonItem,
  DxButtonOptions,
  // ///////////////////
  // DxTabbedItem,
  // DxTabPanelOptions,
  // DxTab,
  ///////////////////
  DxCustomRule,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
} from 'devextreme-vue/form';
// import DxLoadIndicator from 'devextreme-vue/load-indicator';

export default {
    components: {
        CreateAccountLayout,
        Subscription,
        Addresses,
        // DxForm,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        // ///////////////////
        // DxTabbedItem,
        // DxTabPanelOptions,
        // DxTab,
        ///////////////////
        DxRequiredRule,
        DxCustomRule,
        DxEmailRule,
        DxPatternRule,
        // DxLoadIndicator
    },
    props: ['type','formData', 'loading', 'confirmPassword', "showColonAfterLabel", "labelMode", "labelLocation", "onFormValueChanged"],
    data() {
      return {
        // i18n,
        passwordPattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.* ).{8,16}$",
        logoutButtonOptions: { 
          onClick: () => this.logout(),
          width: "100%",
          type: "normal",
          template: "logOut",
          useSubmitBehavior: false,
        },
        changePasswordButtonOptions: { 
          onClick: this.changePasswordClick,
          width: "100%",
          type: "normal",
          template: "changePassword",
          useSubmitBehavior: false,
        }
      }
    },
    computed: {
      isCreateMode() {
        return this.type === formTypes.CREATE
      },
      classContainer() {
        return {
          "authentication-profile-container": !this.isCreateMode,
          "authentication-create-account-container": this.isCreateMode,
        }
      },
      classCard() {
        return {
          "dx-card": !this.isCreateMode,
          "profile": !this.isCreateMode
        } 
      },
      forwardFormData: {
        get() {
          return this.formData
        },
        set(value) {
          this.$emit('update:form-data',{...value})
        }
      }
    },
    methods: {
      changePasswordClick() {
        this.logout(true)
      },
      logout(forgot = false) {
        return this.$store.dispatch('authentication-basic/LOGOUT', forgot)
      },
      onFormInitialized(e) {
        this.$emit('initialized', e)
      },
      t(value) {
        // return this.i18n.t(value) == value ? this.$i18n.t(value) : this.i18n.t(value)
        return this.$i18n.t(value)
      },
    },
}
</script>s