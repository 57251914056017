<template>
  <div>
    <h2 class="content-block">Settings</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <Localechanger />
        <hr />
        <file-progress :progress="30" />
      </div>
    </div>
  </div>
</template>

<script>
import FileProgress from '@/components/file-progress'
import { Localechanger } from "@/modules/localechanger"

export default {
  components: {
    Localechanger,
    FileProgress
  },
  beforeMount() {
    // console.log("$i18n:",this.$i18n)
  },
}
</script>

<style lang="scss">
</style>