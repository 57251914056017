import { v4 as uuidv4 } from 'uuid';

export const address = {
    adr_id: 1,
    adr_uuid: uuidv4(),
    adr_way_num: '47',
    adr_way_ext: '',
    adr_way_type: 'impasse',
    adr_way_name: 'du Bergeron',
    adr_complement: '',
    adr_postal_code: '38290',
    adr_city: 'Frontonas',
    adr_cou_id: 'FRA'
}