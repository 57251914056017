<template>
    <div class="stats-box-container">
        <Box type="sended">
            <Package v-for="pack in packages.sended" :key="pack.type" :type="pack.type" :number="pack.number"/>
        </Box>
        <Box type="received">
            <Package v-for="pack in packages.received" :key="pack.type" :type="pack.type" :number="pack.number"/>
        </Box>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Box from "./box"
import Package, {PackageTypes, ExpeditionTypes } from "./package"
export default {
    components: {
        Box,
        Package
    },
    computed: {
        ...mapState('sendings',['sendings', 'receivings']),
        sentPackages() { return this.sendings || [] },
        receivedPackages() { return this.receivings || [] },     
        packages() {
          let packageCount = {
                sended: [],
                received: []
          }

          packageCount.sended.push({type:PackageTypes.UNREAD, number:this.countPackages(ExpeditionTypes.SENDED, PackageTypes.UNREAD)});
          packageCount.sended.push({type:PackageTypes.READED, number:this.countPackages(ExpeditionTypes.SENDED, PackageTypes.READED)});
          packageCount.sended.push({type:PackageTypes.EXPIRED, number:this.countPackages(ExpeditionTypes.SENDED, PackageTypes.EXPIRED)});

          packageCount.received.push({type:PackageTypes.UNREAD, number:this.countPackages(ExpeditionTypes.RECEIVED, PackageTypes.UNREAD)});

          return packageCount
        }
    },
    methods:{
        countPackages(expeditionType, packageType) {
          // console.log("countPackage",expeditionType, packageType)
          if(expeditionType === ExpeditionTypes.SENDED){
            switch (packageType) {
              case PackageTypes.UNREAD:
                return this.sentPackages.filter(pckg => pckg.statutes.length===0||pckg.statutes[0].files.length<pckg.files.length).length;  

              case PackageTypes.READED:
                return this.sentPackages.length - this.countPackages(ExpeditionTypes.SENDED, PackageTypes.UNREAD)

              case PackageTypes.EXPIRED:
                return this.sentPackages.filter(pckg => pckg.end_date<new Date()).length;
            
              default:
                break;
            }
          }else{
            return this.receivedPackages.filter(pckg => pckg.statutes.length===0||pckg.statutes[0].files.length<pckg.files.length).length;
          }
        },
      },
}
</script>

<style lang="scss" scoped>
.stats-box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.box.sended{
flex-grow: 2;

}

.box.received{
flex-grow: 1;

}

.box{
    margin: 20px 20px 0px 20px;
}


</style>