<template>
    <div class="file-list-container">
        <transition name="fade" mode="out-in">
            <img v-if="files.length <= 0" src="/images/upload.svg" />
            <div class="file-list" v-else>
            <FileList :data-source="files" :height="130" @row-removing="removingFile">
                <template v-slot:beforeGrid>
                    <div class="summary-container">
                        <div class="top-total">TOTAL <span class="count">{{totalCount}}</span></div>
                        <div class="top-total">/</div>
                        <div class="top-total">TOTAL SIZE <span class="count">{{displayTotalSize}}</span></div>
                    </div>
                </template>
                <dx-scrolling mode="virtual"/>
                <dx-editing
                :allow-updating="false"
                :allow-deleting="true"
                :allow-adding="false"
                mode="row"
                />
                <dx-column width="250" data-field="name" />
                <dx-column data-field="size" alignment="right" :calculate-cell-value="calculateSize" />
                <dx-column
                type="buttons"
                >
                <dx-button 
                    name="delete" 
                    hint="Delete"
                    icon="close"
                />
                </dx-column>
            </FileList>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { DxColumn, DxScrolling, DxEditing, DxButton } from "devextreme-vue/data-grid";
import { sizes } from '@/modules/utils'
import { math } from '@/modules/utils'
import FileList from '@/components/list'

export default {
    name:'fileListContainer',
    components: {
        FileList,
        DxColumn, 
        DxScrolling, 
        DxEditing, 
        DxButton,
    },
    computed: {
        ...mapState('sendings', ['files']),
        totalCount() {
            return this.files ? this.files.length : 0
        },
        totalSize() {
            return this.files && math.sum((this.files || []).map(file => file.size),0)
        },
        displayTotalSize() {
            return this.totalSize ? sizes.formatBytes(this.totalSize) : "Inconnu"
        }
    },
    methods: { 
        ...mapMutations('sendings', ['SET_FILES']),
        removingFile(event) {
            event.cancel = true;
            // console.log('removingFile',event)
            const filteredFiles = (this.files || []).filter(file => !(file.name === event.key.name && file.size === event.key.size))
            this.SET_FILES(filteredFiles)
            this.$emit('row-removing', event)
        },
        calculateSize(data) {
            return data && data.size && sizes.formatBytes(data.size,0)
        },
    },
}
</script>



<style lang="scss" scoped>
@import '@/override/themes/go2transfer/variables.scss';

.file-list-container {
  width: 85%;
  margin: auto;
  height: 220px;
  display: flex;
  justify-content: space-evenly;
  cursor: default;

  .file-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    .list {
      width: 80%;
      background: transparent;
      ::v-deep .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child {
          border-top:1px dashed #6a6a6a;

          
      }




    }

    &:before {
      content: url(/images/lock-file.svg);
      margin-top: 50px;
    }
  }

    .summary-container {
        display: flex;
        justify-content: right;
        align-items: center;

        .top-total {
            font-size: 11px;
            color: white;
            display: flex;
            justify-content: right;
            align-items: center;
            margin-bottom: 10px;
            
            .count {
                font-size: 23px;
                color: $go2transfer-red;
                margin-left: 5px;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }  

  > img {
    margin: auto;
    padding: 52px 120px;
    // cursor: pointer;
  }
    // &:hover{
    //     img{
    //         opacity:0.7;
    //     }
    // }

}



::v-deep {
    .dx-scrollable-wrapper {
        /* Scrollbar styles */
        ::-webkit-scrollbar {
            width: 4px;
            padding: 0 4px 0 4px;
            // height: 12px;
            background-color: #5e5e5e;
            border-radius: 8px;
        }

        ::-webkit-scrollbar-track {
            // border: 1px solid #5e5e5e;
            border: 0px solid transparent;
            border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background: #8a8a8a;  
            border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #8a8a8a;  
        }
    }

    .dx-datagrid {
        color: white;

        .dx-row > td {
            padding: 10px;
        }

        .dx-datagrid-content .dx-datagrid-table .dx-row > td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-edit.dx-command-edit-with-icons .dx-link {
        color: #707070;
        width: 30px;
            &:hover{
            color: #fff;   
            }
        }
    }    

    .dx-datagrid-rowsview .dx-datagrid-content {
        font-family: 'LatoWebThin';
    }
    
}

::v-deep{

    .dx-datagrid .dx-data-row{
        border-bottom: 0px solid;
       border-top: 1px dashed #6a6a6a !important;                      
    }

     .dx-datagrid .dx-row-lines > td{
        border-bottom: 0px solid !important;
        }

        .dx-scrollbar-horizontal.dx-scrollbar-hoverable{
            display:none !important;
        }

        .dx-master-detail-cell{
        
            padding: 0px 15px 10px 15px !important;
            .dx-form::before{
                display:none;
            }

            .dx-field-item{
                display: flex;
                align-items: center;

                    .dx-field-item-label-location-top{
                        padding: 2px 11px;
                    }
                     .dx-field-item-label-text,
                    .dx-field-item-content{
                        color:#fff;
                    }

            }
       

        }

        .dx-master-detail-row{
            border:none !important;
        }
        .dx-master-detail-cell{
            border:none !important;
        }

        .dx-datagrid-adaptive-more{
        color: rgba(85, 85, 85, 0.54);
        padding-right:30px;

            &:hover{
                 color: #fff;
            }
        }


        

  } 
</style>

