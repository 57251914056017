<template>
    <div :class="fileClass(file)" @mouseover="hover = true" @mouseleave="hover = false">
        <DxTooltip
            :wrapper-attr="tooltipAttributes"
            :target="`.file-status-${rowId}-${file.id}`"
            :position="'top'"
            ;shading="true"
            :visible="hover"
            :close-on-outside-click="false"
        >
            <div class="file-detail-container">
                <div class="file-details">
                    <div class="file-name">{{ file.name }}</div>
                    <div class="file-size">{{ formatBytes(file) }}</div>
                </div>
            </div>
        </DxTooltip>
    </div>
</template>

<script>
import { DxTooltip } from 'devextreme-vue/tooltip';
import { sizes } from '@/modules'
import { countDownloadedFileByStatutes, precentage } from '@/utils'

export default {
    components: {
        DxTooltip
    },
    props: {
        templateData: {
            type: Object,
            default: () => ({}),
        },
        file: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tooltipAttributes: { class: 'file-status' },
            hover: false
        }
    },
    computed: {
        realData() { return this.templateData },
        rowId() { return this.realData.id },
        recipients() { return this.realData.recipients },
        statutes() { return this.realData.statutes },
        nbRecipient() { return this.recipients.length },
    },
    methods: {
        formatBytes: (file) => sizes.formatBytes(file.size),
        percent: (file) => precentage(countDownloadedFileByStatutes(file.id,this.statutes),this.nbRecipient),
        fileClass(file) {
            const nbFilesDownloaded = countDownloadedFileByStatutes(file.id,this.statutes)
            const currentFileProgress = precentage(nbFilesDownloaded,this.recipients.length)
            const fileClass = { file: true, empty: currentFileProgress < 100, full: currentFileProgress > 99 }
            fileClass[`file-status-${this.rowId}-${file.id}`] = true
            return fileClass
        },
    },
}
</script>

<style lang="scss" scoped>
.file-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;   
    padding: 25px;    

    .file-details {
        font-size: 17px;
        text-align: left;
        
        .file-name {
            color: white;
            margin-bottom: 5px
        }

        .file-size {
            color: #6A6A6A;
            margin-top: 5px
        }
    }

    &::after {
        content: url(/images/file-eye.svg);
        margin-left: 80px;
    }
}
</style>