<template>
    <div :class="fileClass(file)" @mouseover="hover = true" @mouseleave="hover = false">
        <DxTooltip
            cssClass="file-detail"
            :wrapper-attr="tooltipAttributes"
            :target="`.file-detail-${recipientId}-${file.id}`"
            :position="'top'"
            ;shading="true"
            :visible="hover"
            :close-on-outside-click="false"
        >
            {{ $i18n.t('sendings.file-downloaded-at') }} : {{ fileDownloadedAt }}
        </DxTooltip>
    </div>
</template>

<script>
import { DxTooltip } from 'devextreme-vue/tooltip';

export default {
    components: {
        DxTooltip
    },
    props: {
        file: {
            type: Object,
            required: true
        },
        recipientId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            tooltipAttributes: { class: 'file-detail' },
            hover: false
        }
    },
    computed: {
        fileDownloadedAt() {
            return this.$moment(this.file.downloaded_at).format('YYYY/MM/DD') //this.$moment(this.file.downloaded_at).format('DD.MM.YYYY')
        }
    },
    methods: {
        fileClass(file) {
            const fileClass = {
                file: true,
                "file-downloaded": file.downloaded,
                "file-not-downloaded": !file.downloaded,
            }
            fileClass[`file-detail-${this.recipientId}-${file.id}`] = true
            return fileClass
        },
    },
}
</script>