<template>
  <div class="address-container">
    <div class="no-address" v-if="address === null">{{ i18n.t('addresses.no-address') }}</div>
    <dx-form 
      v-else
      :show-colon-after-label="showColonAfterLabel"
      :label-mode="labelMode"
      :label-location="labelLocation"
      :formData="address"
    >
      <dx-item item-type="group" :caption="i18n.t('addresses.address.title')">
        <dx-item
          data-field="detail.adr_uuid"
          :visible="false"
        />
        <dx-item cssClass="form-row" item-type="group" :col-count="6">
          <dx-item item-type="group" :col-count="2" :col-span="2">
            <dx-item
              :label="{ text: i18n.t('addresses.address.adr_way_num') }"
              data-field="detail.adr_way_num"
              :is-required="false"
            />
            <dx-item
              :label="{ text: i18n.t('addresses.address.adr_way_ext') }"
              data-field="detail.adr_way_ext"
              :is-required="false"
            />
          </dx-item>
          <dx-item item-type="group" :col-count="2" :col-span="4">
            <dx-item
              :label="{ text: i18n.t('addresses.address.adr_way_type') }"
              data-field="detail.adr_way_type"
              :is-required="false"
            />
            <dx-item
              :label="{ text: i18n.t('addresses.address.adr_way_name') }"
              data-field="detail.adr_way_name"
              :is-required="true"
            />
          </dx-item>
        </dx-item>
        <dx-item cssClass="form-row" item-type="group" :col-count="3">
          <dx-item item-type="group" :col-span="3">
            <dx-item
              :label="{ text: i18n.t('addresses.address.adr_complement') }"
              data-field="detail.adr_complement"
            />
          </dx-item>
        </dx-item>
        <dx-item cssClass="form-row" item-type="group" :col-count="4">
          <dx-item
            :label="{ text: i18n.t('addresses.address.adr_postal_code') }"
            data-field="detail.adr_postal_code"
            :is-required="true"
          />
          <dx-item item-type="group" :col-span="3">
            <dx-item
              :label="{ text: i18n.t('addresses.address.adr_city') }"
              data-field="detail.adr_city"
              :is-required="true"
            />
          </dx-item>
        </dx-item>
        <dx-item cssClass="form-row" item-type="group" :col-count="2">
          <dx-item item-type="group" :col-span="1">
            <dx-item data-field="detail.adr_cou_id">
              <template #default>
                <div class="country">
                  <Country 
                    :code="address && address.detail && address.detail.adr_cou_id"
                    @valueChanged="onCountryValueChanged"
                  />
                </div>
              </template>
            </dx-item>
          </dx-item>
        </dx-item>
      </dx-item>
    </dx-form>
  </div>
</template>

<script>
import module_i18n from '../i18n'
import { Country } from '@/modules/country'
import DxForm, { DxItem } from 'devextreme-vue/form'

export default {
  name: 'Address',
  props: ["index","showColonAfterLabel", "labelMode", "labelLocation", "user"],
  components: {
    DxForm,
    DxItem,
    Country
  },
  beforeMount() {
    // console.log("beforeMount addresses/FETCH_ADDRESS")
    this.$store.dispatch('addresses/FETCH_ADDRESS')
  },
  computed: {
    i18n() {
        return this.$i18n ? this.$i18n : this.module_i18n
    },
    address() {
      const address = this.$store.getters['addresses/GET_ADDRESS']
      address.type = address.type || 'FAC'
      return address
    },
  },
  methods: {
    onCountryValueChanged(e) {
      this.$store.commit('addresses/SET_ADDRESS',{
        ...this.address,
        detail: {
          ...this.address.detail,
          adr_cou_id: e.value
        }
      })
    }
  },
  data() {
      return {
        module_i18n,
      }
  },
}
</script>

<style lang="scss" scoped>
.address-container {
  .no-address {
    text-align: center;
    font-size: 15px;
    padding: 50px;
  }
}

::v-deep .form-row {
    padding: 10px 0 10px 0;
} 
</style>