import store from './store'
import router from './router'
import i18n from './i18n'
import locales from './locales'
import Subscription from './views/subscription'

export { 
    i18n,
    locales,
    store,
    router,
    Subscription,
}

export default {
    i18n,
    locales,
    store,
    router,
    Subscription,
    preserveState: false
}