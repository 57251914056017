import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'animate.css';

import Vue from "vue";

import App from "./App";
import store from './store'
import router from "./router";
import appInfo from "./app-info";
import i18n from './i18n'
import vueMoment from 'vue-moment'
import VueCompositionAPI from '@vue/composition-api'

// import authenticationBasic from '@/modules/authentication-basic'
// import subscription from '@/modules/subscription'
// import country from '@/modules/country'
// import administration from '@/modules/administration'
// import addresses from '@/modules/addresses'

import { registerModules, locale, loadMessages, devextremConfig, addresses, administration, country, subscription, authenticationBasic } from '@/modules'

import {modulesOptions} from '@/override'

registerModules({
  "authentication-basic": authenticationBasic,
  subscription,
  country,
  addresses,
  administration,
}, modulesOptions);

Vue.use(vueMoment)
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
  created: () => {
    loadMessages()
    // locale(navigator.language);
    // locale(navigator.language === 'fr-FR' ? 'fr' : 'en');
    locale('en');
    devextremConfig({ defaultCurrency: 'EUR' });

    // vueMoment.locale('fr')
  }
}).$mount("#app");
