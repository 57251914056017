<template>
    <div class="send-history">
        <div class="spacer"></div>
        <div class="spacer"></div>
        <div class="list-container">
            <List :data-source="latestFiveDownloads" :title="$i18n.t('stats.grid.last-downloads')">
                <dx-column data-field="recipients[0].mail" />
                <dx-column data-field="send_on" data-type="datetime" format="yyyy/MM/dd hh:mm a"/>
                <dx-column data-field="statutes" cssClass="col col-statutes" cell-template="statutesTemplate"/>
<!--                <dx-column data-field="files" />-->
            </List>
        </div>
        <div class="stats-container">
            <Stats />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DxColumn } from "devextreme-vue/data-grid";
import List from '@/components/list'
import Stats from './stats/stats'

export default {
    components: {
        List,
        Stats,
        DxColumn,
    },
    computed: {
        ...mapState('sendings',['sendings', 'receivings']),        
        latestFiveDownloads() {
            if (!(this.sendings && Array.isArray(this.sendings))) return []
            
            return this.sendings.filter(sending => sending.downloaded === true).slice(0,5)
     
        }
    },
    methods: {
        ...mapActions('sendings',['FETCH_SENDINGS','FETCH_RECEIVINGS']),
    },
    beforeMount() {
      this.FETCH_SENDINGS()
      this.FETCH_RECEIVINGS()
    }
}
</script>

<style lang="scss" scoped>
.send-history {
    border: none;
    height: 863px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:70px 0;
}
</style>