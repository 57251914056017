import appLayout from "@/layouts/side-nav-inner-toolbar"

export const modulesOptions = {
    "administration": {
      "default": {
        components: {
          layout: appLayout
        },
        props: {
          content: {
            card: false
          }
        }
      }
    }
}

export default { 
  modulesOptions 
}