import store from '@/store';
import axios from 'axios';
import moment from 'moment';
// import { send } from 'xstate'

function getDownloadUrl({sendingUUID, fileUUID}) {
    // console.log("getDownloadUrl", sendingUUID, fileUUID, store)

    return new Promise((resolve, reject) => {
        store.dispatch('sendings/GET_ENVELOPE',sendingUUID).then(response => {
            // console.log("sendings/GET_ENVELOPE",sendingUUID, response)
            const result = response.success && response.data

            const file = result.files.find(file => file.uuid === fileUUID)
            if (file) {
                if (file.download_url && Array.isArray(file.download_url) && file.download_url.length === 1 && file.download_url[0].url) {
                    file.download_url = new URL(file.download_url[0].url)
                    const validityDate = file.download_url.searchParams.get('X-Amz-Date')
                    const validityExpires = parseInt(file.download_url.searchParams.get('X-Amz-Expires'))

                    // const now = parseInt(new Date().getTime())
                    // const validityDateExpires = parseInt(moment(validityDate).toDate().getTime())+parseInt(validityExpires)
                    // const validityDateExpires = parseInt(moment(validityDate).toDate().getTime())
                    const isExpired = (new Date()-moment(validityDate).toDate()) > (validityExpires*1000)
                    // const isExpired = (new Date()-moment(validityDate).toDate()) > validityExpires

                    // console.group("file.download_url")
                    // // console.log("URL:",file.download_url)
                    // // console.log("validityDate:",validityDate)
                    // // console.log("validityDate (Date):",validityDateExpires)
                    // // console.log("validityExpires:",validityExpires)
                    // // console.log("Now:",now)
                    // // console.log("Diff:",now-validityDateExpires)
                    // // console.log('FromNow:',moment(validityDate).fromNow())
                    // // console.log("isExpired:",isExpired)
                    // console.groupEnd()

                    // // console.log("file.download_url", file.download_url, validityDate, validityExpires, new Date()-moment(validityDate).toDate(), isExpired)
                    if (!isExpired) resolve(file.download_url)
                    else reject('expired')
                    // resolve(file.download_url)
                }
                else reject('no url')
            }
            else reject('no file')
        })   
    })
}

function getStatus({actionUUID}) {
    // console.log("getStatus", actionUUID, sendingUUID, fileUUID, status)
    return new Promise((resolve, reject) => {
        store.dispatch('sendings/FETCH_CIPHER_DECIPHER_STATUS',actionUUID).then(response => {
            // console.log('sendings/FETCH_CIPHER_DECIPHER_STATUS',response)
            if (!response.success) return reject(response.message) 
            if (response.data.status !== 'ENDED') return reject(response.data)
            resolve(response.data)
        })
    })
}

function launchDecipher({sendingUUID, fileUUID}) {
    // console.log("launchDecipher", sendingUUID, fileUUID, status)
    return new Promise((resolve, reject) => {
        store.dispatch('sendings/LAUNCH_DECIPHER',{sendingUUID, fileUUID}).then(response => {
            if (response.success) resolve(response.data)
            else reject(response.message)
        })
    });
}

function updateKeys(context) {
    // context.sending.updateKey = new Date().getTime()
    context.file.updateKey = new Date().getTime()
}

function updateFileProgress(context, progressEvent) {
    // // console.log("updateFileProgress",sendingUUID, fileUUID, progressEvent)
    // const receiving = store.state.sendings.receivings.find(rec => rec.uuid === context.sendingUUID)
    // if (receiving) {
    //     const file = receiving.files.find(file => file.uuid === context.fileUUID)
    //     if (file) {
    //         file.progress = file.progress || 0
    //         file.progress = progressEvent.loaded<file.progress ? file.progress : progressEvent.loaded
    //         file.total = progressEvent.total

    //         const newFiles = receiving.files.map(f => {
    //             if (f.uuid === file.uuid) return file
    //             return f
    //         })
            
    //         receiving.files = [...newFiles]
    //     }
    // }  

    context.file.progress = context.file.progress || 0
    context.file.progress = progressEvent.loaded<context.file.progress ? context.file.progress : progressEvent.loaded
    context.file.total = progressEvent.total

    const newFiles = context.sending.files.map(f => {
        if (f.uuid === context.file.uuid) return context.file
        return f
    })
    context.sending.files = [...newFiles]

    updateKeys(context)
}

function updateReceiving({sendingUUID, fileUUID}) {
    return new Promise((resolve, reject) => {
        store.dispatch('sendings/UPDATE_RECEIVING',{sendingUUID, fileUUID}).then(response => {
            if (response.success) resolve(response.data)
            else reject(response.message)
        })
    });
}

function downloadFile(context) {
    // console.log("downloadFile",context, onDownloadProgress)
    const { downloadURL } = context
    if (!downloadURL) return Promise.reject('url invalid')
    return new Promise((resolve) => {
        axios.get(downloadURL, { 
            responseType: "blob",
            onDownloadProgress: (progressEvent) => updateFileProgress(context, progressEvent)
        })
        .then(resolve)
    })
}

function decipherUpdate(context) {
    // console.log("DECIPHER_UPDATE action", context)
    // const receiving = store.state.sendings.receivings.find(rec => rec.uuid === context.sendingUUID)
    // if (receiving) {
    //     const file = receiving.files.find(file => file.uuid === context.fileUUID)
    //     if (file) {
    //         file.decipherStatus = context.status

    //         const newFiles = receiving.files.map(f => {
    //             if (f.uuid === file.uuid) return file
    //             return f
    //         })

    //         receiving.files = [...newFiles]

    //         // // console.log(receiving.files)
    //     }
    // }  

    context.file.decipherStatus = context.status
    
    const newFiles = context.sending.files.map(f => {
        if (f.uuid === context.file.uuid) return context.file
        return f
    })
    context.sending.files = [...newFiles]

    // console.log("*************************decipherUpdate***********************")
    // console.log(context.status)
    // console.log("************************************************")
}

export const downloadMachine = {
    id: 'download',
    context: {
        status: 'NONE',
        progressEvent: {}
    },
    initial: 'getDownloadUrl',
    states: {
        idle: {
            invoke: {
                src: () => Promise.resolve(),
                onDone: {
                    target: 'getDownloadUrl',
                },
            }
        },
        decipher: {
            invoke: {
                src: launchDecipher,
                onDone: {
                    target: 'getStatus',
                    actions: (context, action) => {
                        // console.log("decipher.onDone", action)
                        context.actionUUID = action.data && action.data.uuid
                        // console.log("**********************decipher onDone**************************")
                        // console.log(context)
                        // console.log("************************************************")
                    }
                },
                onError: {
                    target: 'end',
                }
            }
        },
        sleepStatus: {
            invoke: {
                src: () => {
                    return new Promise(resolve => setTimeout(resolve, 2000))
                },
                onDone: {
                    target: 'getStatus'
                }
            }
        },
        getStatus: {
            invoke: {
                src: getStatus,
                onDone: {
                    target: 'getDownloadUrl',
                    actions: (context, status) => {
                        if (status.success) context.status = status.data.status
                        decipherUpdate(context)
                        // console.log("************************getStatus onDone************************")
                        // console.log(context)
                        // console.log("************************************************")
                    }
                },
                onError: {
                    target: 'sleepStatus',
                    actions: (context, response) => {
                        if (response.data && response.data.status) context.status = response.data.status
                        decipherUpdate(context)
                        // console.log("*************************getStatus onError***********************")
                        // console.log(context,response)
                        // console.log("************************************************")
                    }
                }
            },
        },
        getDownloadUrl: {
            invoke: {
                src: getDownloadUrl,
                onDone: {
                    target: 'download',
                    actions: (context, downloadURL) => {
                        context.downloadURL = downloadURL.data
                        // console.log("*********************getDownloadUrl onDone***************************")
                        // console.log(context)
                        // console.log("************************************************")
                    }
                },
                onError: {
                    target: 'decipher',
                    actions: (context) => {
                        // console.log("ERROR", error.data)
                        context.downloadURL = null
                        // console.log("***********************getDownloadUrl onError*************************")
                        // console.log(context)
                        // console.log("************************************************")
                    }
                }
            }
        },
        download: {
            invoke: {
                src: (context) => downloadFile(context),
                onDone: {
                    target: 'localDownloaded',
                    actions: (context, event) => {
                        console.log('localDownloaded',event.data)
                        context.fileBLOB = event.data.data //new Blob([event.data.data])
                        // console.log("download onDone", context, event)
                    }
                },
                onError: {
                    target: 'end',
                    actions: (context, event) => {
                        console.warn("download onError", context, event)
                    }
                }
            },
        },
        localDownloaded: {
            invoke: {
                src: (context) => {
                    // console.log("localDownloaded", context, event)
                    return new Promise(resolve => {
                        // var fileURL = window.URL.createObjectURL(context.fileBLOB);
                        // var fileLink = document.createElement('a');
                    
                        // fileLink.href = fileURL;
                        // fileLink.setAttribute('download', context.fileNAME);
                        // document.body.appendChild(fileLink);
                    
                        // fileLink.click();

                        // // console.log("*************************fileBLOB***********************")
                        // // console.log(context.fileBLOB)
                        // // console.log("************************************************")

                        // const receiving = store.state.sendings.receivings.find(rec => rec.uuid === context.sendingUUID)
                        // if (receiving) {
                        //     const file = receiving.files.find(file => file.uuid === context.fileUUID)
                        //     if (file) {
                        //         file.fileBLOB = context.fileBLOB

                        //         const newFiles = receiving.files.map(f => {
                        //             if (f.uuid === file.uuid) return file
                        //             return f
                        //         })

                        //         receiving.files = [...newFiles]
                        //     }
                        // }    

                        context.file.fileBLOB = context.fileBLOB
    
                        const newFiles = context.sending.files.map(f => {
                            if (f.uuid === context.file.uuid) return context.file
                            return f
                        })
                        context.sending.files = [...newFiles]

                        setTimeout(resolve,2000)
                    })
                },
                onDone: {
                    target: 'waitForSaveOnDisk',
                    actions: (context) => {
                        // const receiving = store.state.sendings.receivings.find(rec => rec.uuid === context.sendingUUID)
                        // if (receiving) {
                        //     const file = receiving.files.find(file => file.uuid === context.fileUUID)
                        //     if (file) {
                        //         file.updateKey = new Date().getTime()

                        //         const newFiles = receiving.files.map(f => {
                        //             if (f.uuid === file.uuid) return file
                        //             return f
                        //         })

                        //         receiving.files = [...newFiles]
                        //     }
                        // }

                        context.file.updateKey = new Date().getTime()
    
                        const newFiles = context.sending.files.map(f => {
                            if (f.uuid === context.file.uuid) return context.file
                            return f
                        })
                        context.sending.files = [...newFiles]
                    }
                }
            }
        },
        waitForSaveOnDisk: {
            on: {
                SAVE_ON_DISK: {
                    target: 'end',
                }
            }
        },
        end: {
            invoke: {
                src: async (context) => {
                    await updateReceiving(context)
                    // // console.log("*************************end***********************")
                    // // console.log(context)
                    // // console.log("************************************************")
                    store.commit('REMOVE_DOWNLOAD', context)
                }
            }            
        }
    }
}