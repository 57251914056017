export const SET_LOADING = "SET_LOADING";
export const SET_USERS = "SET_USERS";
export const GET_USERS = "GET_USERS";

export const FETCH_ALL = "FETCH_ALL";
export const FETCH_USERS = "FETCH_USERS";
export const UPDATE_USER = "UPDATE_USER";

import { APIAdministration as API } from '../SDK'

const prepareSecureOptions = (state) => {
    const headers = {
        'Content-Type': 'application/json'
    }

    if (state.tokens && state.tokens.accessToken) headers['Authorization'] = `Bearer ${state.tokens.accessToken}`

    return {
        headers
    }
}

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        users: [],
    }),
    mutations:{
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [SET_USERS]: (state, users) => state.users = users,
    },
    actions:{
        //  EXEMPLE FETCH ALL DATA
        //  [FETCH_ALL]: ({dispatch}) => {
        //      // console.log("FETCH_ALL")
        //      Promise.all([dispatch('FETCH_USERS'),dispatch('FETCH_CURRENT_SUBSCRIPTION')])
        //  },
        [FETCH_USERS]: ({ rootState, commit }) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            const options = prepareSecureOptions(rootState['authentication-basic'])
            return API.getUsers(options)
                .then(async res => {
                    if (res.success) await commit(SET_USERS, [...res.data])
                    return res
                })
                .finally(() => {
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [UPDATE_USER]: async ({ rootState, commit }, user) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            const options = prepareSecureOptions(rootState['authentication-basic'])
            return await API.updateUser(user,options)
                .finally(() => {
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        }
    },
    getters:{
        [GET_USERS]: state => state.users,
    },
}