import Mock from './Mock'
import { AuthenticationBasic } from './Silico'
import Config from '@/config.json'

var APIUsers = Mock;
if (Config && Config["authentication-basic"] && Config["authentication-basic"].endpoint) 
{    
    AuthenticationBasic.create({
        ...Config["authentication-basic"]   
    })
    APIUsers = AuthenticationBasic
}

export { APIUsers }

export default {
    APIUsers
}