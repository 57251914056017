<template>
  <div class="custom-item">
    <img :src="itemData.flag">
    <div class="product-name">
      {{ itemData.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-item {
  display: flex;
  align-items: center;

  img {
    width: 24px;
    margin-right: 10px;
  }
}
</style>
