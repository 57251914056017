export const GET_LOGGED_IN = "GET_LOGGED_IN";
export const GET_HAS_RT = "GET_HAS_RT";
export const GET_USER = "GET_USER";
export const GET_ROLES = "GET_ROLES";


export default {
    [GET_LOGGED_IN]: state => !!(state.tokens && state.tokens.accessToken),
    [GET_USER]: state => state.user,
    [GET_ROLES]: state => state.user && state.user.other && state.user.other.roles,
    [GET_HAS_RT]: state => !!(state.tokens && state.tokens.refreshToken),
}