<template>
    <div class="uploading-header">
        <div class="header-picture" is="transition-group" name="fade" mode="out-in">                
            <img v-if="showCube" key="cube" :class="bigCubeClass" src="/images/big-cube.svg"/>
            <img v-if="showStatus" :key="`uploadStatus-${uploadStatus}`" :class="bigCubeStatusClass" width="128" :src="`/images/${uploadStatus}.svg`" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'UploadingHeader',
    computed: {
        ...mapState('sendings', ['uploadStatus']),
        cipherStatus() {
            return this.$store.getters['sendings/GET_CIPHER_STATUS']
        },
        showCube() {
            return (
                (!this.uploadStatus && this.cipherStatus === null)
                ||
                (this.uploadStatus && this.cipherStatus !== null)
            )
        },
        showStatus() {
            return (
                (this.uploadStatus && this.cipherStatus === null)
                ||
                (this.uploadStatus && this.cipherStatus === 'ENDED')
            )
        },
        bigCubeClass() {
            return {
                'big-cube': true,
                'big-cube-cipher': this.cipherStatus && this.cipherStatus === 'ENDED',
            }
        },
        bigCubeStatusClass() {
            return {
                'big-cube': true,
                'big-cube-top': true //this.cipherStatus && this.cipherStatus === 'ENDED',
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.uploading-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    .big-cube {
        margin: auto;
        &-cipher {
            transition: filter 4s;
            filter: blur(10px);
        }

        &-top { 
            position: absolute;
            z-index: 1;
            transform: translate(25%, 50%);
        }
    }

    .header-picture {
        width: 190px; 
        height:248px;
        display: flex;
    }
}
</style>
