export const sendings = [{
    id: 1,
    send_on: new Date('2022-03-01').getTime(),
    send_duration: 30,
    subject: "Fichiers 3D",
    mail: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 2,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    }]
},{
    id: 2,
    send_on: new Date('2022-02-08').getTime(),    
    send_duration: 12,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 2,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 3,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 3,
    send_on: new Date('2022-01-01').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Photo bâtiments et autres documents",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 2,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 3,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 4,
    send_on: new Date('2022-02-08').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 5,
    send_on: new Date('2022-02-25').getTime(),    
    send_duration: 12,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 2,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    },
    {
        recipient_id: 3,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 5,
    send_on: new Date('2022-01-07').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 5,
    send_on: new Date('2022-02-14').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 5,
    send_on: new Date('2021-12-08').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 5,
    send_on: new Date('2022-01-14').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()},{id: 2, downloaded_at: new Date().getTime()}]
    }],
},{
    id: 5,
    send_on: new Date('2022-01-18').getTime(),    
    send_duration: 30,
    mail: "lorem ipsum indolor all the fact lorem ipsume lorem ipsum indolor all the fact lorem ipsume",
    subject: "Hello World!",
    recipients: [{
        id: 1,
        name: "Sébastien MADANI",
        mail: "seb@silico.fr"
    },{
        id: 2,
        name: "Michel FAGES",
        mail: "mif@silico.fr"
    },{
        id: 3,
        name: "Julien CHENAVAS",
        mail: "juc@silico.fr"
    }],
    files: [{
        id: 1,
        name: "mesfichiers3D.zip",
        size: 15000000
    },{
        id: 2,
        name: "autresfichiers3D.zip",
        size: 13000000
    }],
    statutes: [{
        recipient_id: 1,
        files: [{id: 1, downloaded_at: new Date().getTime()}]
    }],
},]