import Mock from './Mock'
import Addresses from './Silico'
import Config from '@/config.json'

var APIAddresses = Mock;
if (Config && Config["addresses"] && Config["addresses"].endpoint) 
{    
    Addresses.create({
        ...Config["addresses"]   
    })
    APIAddresses = Addresses
}

export { APIAddresses }

export default {
    APIAddresses
}