export const addDate = (d, nb) => {
    // additionne nb jours à une date
    var d1 = d.getTime(), d2 = new Date();
    d1 += 24*3600*1000*nb
    d2.setTime(d1)
    return d2
}

export const dateDiff = (date1, date2) => {
    var diff = {}                           // Initialisation du retour
    var tmp = date2 - date1;
 
    tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                    // Extraction du nombre de secondes
 
    tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                    // Extraction du nombre de minutes
 
    tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
    diff.hour = tmp % 24;                   // Extraction du nombre d'heures
     
    tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
    diff.day = tmp;
     
    return diff;
}

export const dateDiffDay = (date1, date2) => {
    return dateDiff(date1, date2).day
}

export const dateDiffHour = (date1, date2) => {
    return dateDiff(date1, date2).hour
}

export const dateDiffMinute = (date1, date2) => {
    return dateDiff(date1, date2).min
}

export const dateDiffSecond = (date1, date2) => {
    return dateDiff(date1, date2).sec
}