<template>
  <div class="filters">
      <div :class="filterClass('all')" @click="selectFilter" id="all">{{$i18n.t('filters.all')}}</div>
      <div :class="filterClass('current')" @click="selectFilter" id="current">{{$i18n.t('filters.current')}}</div>
      <div :class="filterClass('ended')" @click="selectFilter" id="ended">{{$i18n.t('filters.ended')}}</div>
      <div :class="filterClass('open')" @click="selectFilter" id="open">{{$i18n.t('filters.open')}}</div>
      <div :class="filterClass('non-open')" @click="selectFilter" id="non-open">{{$i18n.t('filters.non-open')}}</div>
    </div>  
</template>

<script>
export default {
  name: "Filters",
  data() {
    return {
      selectedFilter: 'all'
    }
  },
  methods: {
    selectFilter(e) {
      this.selectedFilter = e.target.id;
      ["selectedFilterChanged","selected-filter-changed"].forEach(valueChanged => this.$emit(valueChanged, this.selectedFilter));
    },
    filterClass(id) {
      return {
        filter: true,
        "filter-selected": this.selectedFilter === id
      }
    }
  },
}
</script>
