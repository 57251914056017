var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sendings-container"},[_c('div',{staticClass:"content-block data-grid-container"},[_c('div',{staticClass:"dx-card responsive-paddings"},[_c('DxDataGrid',{staticClass:"sendings-master-grid",attrs:{"dataSource":_vm.sendings,"pager":{ showNavigationButtons: true },"paging":{ enable: true, pageSize: 4 },"show-row-lines":false,"show-column-lines":false,"show-borders":false,"load-panel":{ enable: false},"word-wrap-enabled":true},on:{"initialized":_vm.onSendingsInitialized,"toolbar-preparing":_vm.onToolbarPreparing,"row-prepared":_vm.onRowPrepared,"cell-prepared":_vm.onCellPrepared,"row-expanding":_vm.onRowExpanding},scopedSlots:_vm._u([{key:"titleTemplate",fn:function(){return [_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$i18n.t('menu.sended')))])])]},proxy:true},{key:"filtersTemplate",fn:function(){return [_c('Filters',{on:{"selected-filter-changed":_vm.onSelectedFilterChanged}})]},proxy:true},{key:"refreshTemplate",fn:function(){return [_c('DxButton',{attrs:{"icon":"refresh"},on:{"click":_vm.refreshDataGrid}})]},proxy:true},{key:"actionsTemplate",fn:function(ref){
var data = ref.data;
return [_c('DxButton',{staticClass:"actions",attrs:{"disabled":(data.data.downloaded || data.data.expired),"icon":"trash"},on:{"click":function($event){return _vm.deleteSending($event, data)}}})]}},{key:"defaultTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"cell-container"},[_c('div',{staticClass:"col-content"},[_vm._v(_vm._s(data.value))])])]}},{key:"mailTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"mail-template"},[_c('RowMailTemplate',{attrs:{"template-data":data}})],1)]}},{key:"statutesTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"statutes-template"},[_c('RowStatusTemplate',{attrs:{"template-data":data}})],1)]}},{key:"remainingTimeTemplate",fn:function(ref){
var data = ref.data;
return [_c('row-remaining-time',{attrs:{"sending":data.data}})]}},{key:"masterDetailTemplate",fn:function(ref){
var data = ref.data;
return [_c('RowDetailTemplate',{attrs:{"template-data":data}})]}}])},[_c('DxSearchPanel',{attrs:{"visible":true,"width":340,"placeholder":((_vm.$i18n.t('common.search')) + "...")}}),_c('DxColumn',{attrs:{"data-field":"send_on","type":"datetime","cssClass":"col col-send-on","caption":_vm.$i18n.t('sendings.columns.send_on'),"format":"yyyy/MM/dd","width":"120","allow-sorting":true}}),_c('DxColumn',{attrs:{"data-field":"subject","width":"250","cssClass":"col col-subject","caption":_vm.$i18n.t('sendings.columns.subject')}}),_c('DxColumn',{attrs:{"data-field":"mail","width":"70","cssClass":"col col-mail","alignment":"center","caption":_vm.$i18n.t('sendings.columns.mail'),"cell-template":"mailTemplate"}}),_c('DxColumn',{attrs:{"data-field":"recipients.length","width":"160","alignment":"left","cssClass":"col col-recipients","caption":_vm.$i18n.t('sendings.columns.recipients'),"cell-template":"defaultTemplate"}}),_c('DxColumn',{attrs:{"data-field":"files.length","width":"150","alignment":"left","cssClass":"col col-files","caption":_vm.$i18n.t('sendings.columns.files'),"cell-template":"defaultTemplate"}}),_c('DxColumn',{attrs:{"data-field":"statutes","cssClass":"col col-statutes","caption":_vm.$i18n.t('sendings.columns.statutes'),"cell-template":"statutesTemplate"}}),_c('DxColumn',{attrs:{"data-field":"remaining_time","width":"220","cssClass":"col col-remaining_time","caption":_vm.$i18n.t('sendings.columns.remaining_time'),"cell-template":"remainingTimeTemplate"}}),_c('DxColumn',{attrs:{"caption":"Actions","alignment":"right","width":"90","cell-template":"actionsTemplate"}}),_c('DxMasterDetail',{attrs:{"enabled":true,"template":"masterDetailTemplate"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }