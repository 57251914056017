var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"receivings-container"},[_c('div',{staticClass:"content-block"},[_c('div',{staticClass:"dx-card responsive-paddings"},[_c('DxDataGrid',{attrs:{"data-source":_vm.receivings,"___data-row-template":"dataRowTemplate","load-panel":{ enable: false}},on:{"toolbar-preparing":_vm.onToolbarPreparing,"initialized":_vm.onReceivingsInitialized},scopedSlots:_vm._u([{key:"titleTemplate",fn:function(){return [_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$i18n.t('menu.received')))])])]},proxy:true},{key:"refreshTemplate",fn:function(){return [_c('DxButton',{attrs:{"icon":"refresh"},on:{"click":_vm.refreshDataGrid}})]},proxy:true},{key:"fileNameTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"file-template file-name-template"},_vm._l((data.data.files),function(file){return _c('div',{key:("file-name-" + (file.uuid)),staticClass:"file-name"},[_vm._v(_vm._s(file.name))])}),0)]}},{key:"fileSizeTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"file-template file-size-template"},_vm._l((data.data.files),function(file){return _c('div',{key:("file-size-" + (file.uuid)),staticClass:"file-size"},[_vm._v(_vm._s(_vm.formatBytes(file.size)))])}),0)]}},{key:"fileDownloadTemplate",fn:function(ref){
var cellData = ref.data;
return [(!_vm.data.data.expired)?_c('FileDownloadTemplate',{key:("download-cell-" + (cellData.rowIndex) + "-" + (cellData.data.updateKey)),attrs:{"cell-data":cellData}}):_vm._e()]}},{key:"internalFileDownloadTemplate",fn:function(ref){
var data = ref.data;
return [(!data.data.expired)?_c('div',{staticClass:"file-template file-download-template"},_vm._l((data.data.files),function(file){return _c('div',{key:("file-download-" + (file.uuid)),staticClass:"btn-download",attrs:{"title":("download-envelope-" + (data.data.uuid) + "-file-" + (file.uuid))},on:{"click":function($event){return _vm.onDownloadClick(data.data.uuid,file, data.data)}}},[(!file.stateValue)?_c('img',{key:("file-download-img-" + (file.uuid) + "-" + (file.stateValue)),attrs:{"src":"/images/download.svg"}}):(['idle', 'decipher', 'sleepStatus', 'getStatus', 'getDownloadUrl'].indexOf(file.stateValue) >= 0)?_c('BreedingRhombusSpinner',{key:("spinner-" + (file.uuid) + "-" + (file.stateValue)),attrs:{"animation-duration":1000,"size":20,"color":"black"}}):(file.stateValue === 'download')?_c('DxProgressBar',{key:("file-download-" + (file.uuid) + "-" + (file.stateValue)),attrs:{"show-status":false,"min":0,"max":file.total,"value":file.progress,"width":"100%"}}):(file.stateValue === 'localDownloaded')?_c('img',{key:("file-downloaded-img-" + (file.uuid) + "-" + (file.stateValue)),attrs:{"src":"/images/checked.svg","width":"25"}}):_c('img',{key:("file-save-img-" + (file.uuid) + "-" + (file.stateValue)),attrs:{"src":"/images/save.svg","width":"25"}})],1)}),0):_c('div',{staticClass:"file-template file-download-template"},_vm._l((data.data.files),function(file){return _c('div',{key:("file-download-" + (file.uuid)),staticClass:"btn-download",attrs:{"title":("download-envelope-" + (data.data.uuid) + "-file-" + (file.uuid))}},[_c('img',{attrs:{"src":"/images/error.svg"}})])}),0)]}},{key:"mailTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"mail-template"},[_c('RowMailTemplate',{attrs:{"template-data":data}})],1)]}},{key:"remainingTimeTemplate",fn:function(ref){
var data = ref.data;
return [_c('row-remaining-time',{attrs:{"sending":data.data}})]}}])},[_c('dx-paging',{attrs:{"page-size":4}}),_c('dx-pager',{attrs:{"show-page-size-selector":false,"show-info":false}}),_c('dx-sorting'),_c('DxColumn',{attrs:{"data-field":"snd_sender_email","cssClass":"col col-sender","alignment":"left","caption":_vm.$i18n.t('receivings.columns.sender')}}),_c('DxColumn',{attrs:{"data-field":"mail","width":"100","cssClass":"col col-mail","alignment":"center","caption":_vm.$i18n.t('receivings.columns.mail'),"cell-template":"mailTemplate"}}),_c('DxColumn',{attrs:{"data-field":"send_on","_type":"datetime","alignment":"left","cssClass":"col col-send-on","caption":_vm.$i18n.t('receivings.columns.send_on'),"format":"yyyy/MM/dd","width":"120","allow_sorting":true}}),_c('DxColumn',{attrs:{"alignment":"left","width":"390","cssClass":"col col-filename","caption":_vm.$i18n.t('receivings.columns.filename'),"cell-template":"fileNameTemplate"}}),_c('DxColumn',{attrs:{"alignment":"left","width":"190","cssClass":"col col-filesize","caption":_vm.$i18n.t('receivings.columns.filesize'),"cell-template":"fileSizeTemplate"}}),_c('DxColumn',{attrs:{"alignment":"left","width":"150","cssClass":"col col-download","caption":_vm.$i18n.t('receivings.columns.download'),"cell-template":"internalFileDownloadTemplate"}}),_c('DxColumn',{attrs:{"data-field":"remaining_time","alignment":"right","width":"220","cssClass":"col col-remaining_time","caption":_vm.$i18n.t('sendings.columns.remaining_time'),"cell-template":"remainingTimeTemplate"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }