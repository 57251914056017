const axios = require('axios')

const defaultConfig = {
    baseURL: null,
    URI: {
        users: '/users',
        updateUser: '/user/update'
    }
}

function processResponse(response) {
    return response.data
}

const Administration = {
    client: axios.create(),
    create: (options) => {
        if (options && options.endpoint) Administration.client.defaults.baseURL = options.endpoint
    },
    async getUsers(options) {
        return await Administration.client.get(defaultConfig.URI.users,options).then(resp => processResponse(resp))
    },

    async updateUser(user,options) {
        return await Administration.client.put(defaultConfig.URI.updateUser,user,options).then(resp => processResponse(resp))
    }
}

module.exports = Administration