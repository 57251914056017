export const ADMINCOMPONENTS = {
    USERS: 'users',
    LOCALE: 'locale'
}

export const listKeysFromObject = (objSrc) => {
    // console.log("listKeysFromObject Entries",Object.entries(objSrc))
     return Object.keys(objSrc).map(key => {
        // let internalKey = key
        if (typeof objSrc[key] !== 'string') {
            listKeysFromObject(objSrc[key])
        }

        return Object.entries(objSrc)
     })
}