export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOADING = "SET_LOADING";
export const SET_PENDINGREFRESH = "SET_PENDINGREFRESH";

import {
    APIUsers as API
} from '../SDK'
import {
    securedLocalStorage
} from '../../index'

const SESSIONTIMOUT = 60

export default {
    [SET_LOADING]: (state, loading) => state.loading = loading,
    [SET_PENDINGREFRESH]: (state, prendingRefresh) => state.prendingRefresh = prendingRefresh,
    [SET_USER]: (state, user) => {
        // console.trace("SET_USER", user)
        state.user = user
    },
    [SET_TOKEN]: (state, tokens) => {
        // console.trace("SET_TOKEN", token)
        if (JSON.stringify(state.tokens) !== JSON.stringify(tokens)) {
            state.tokens = tokens

            if (tokens) {
                const decodedToken = API.decodeToken(state.tokens.accessToken)
                clearInterval(state.sessionInterval)
                state.sessionInterval = null
                clearTimeout(state.sessionTimeout)
                state.sessionExpiration = null
                state.sessionTimeout = setTimeout(() => {
                    // console.log(`--------------- token will expire in ${SESSIONTIMOUT} seconds`)
                    state.sessionExpiration = SESSIONTIMOUT
                    state.sessionInterval = setInterval(async () => {
                        state.sessionExpiration = Math.floor(API.timeBeforeExpiration(decodedToken.exp))

                        // console.log(state.sessionExpiration)
                        if (state.sessionExpiration <= 0) {
                            clearInterval(state.sessionInterval)
                            state.sessionInterval = null
                            clearTimeout(state.sessionTimeout)
                            state.sessionTimeout = null
                            state.sessionExpiration = null
                            state.tokens.refreshToken = null
                            await securedLocalStorage.setItem('authentication-basic', {
                                tokens: state.tokens
                            })
                        }
                    }, 500);
                }, (API.timeBeforeExpiration(decodedToken.exp)*1000) - (SESSIONTIMOUT*1000)) 
            }
            else {
                clearInterval(state.sessionInterval)
                state.sessionInterval = null
                clearTimeout(state.sessionTimeout)
                state.sessionTimeout = null
                state.sessionExpiration = null
            }
        }     
    }
}