<template>
    <div class="datagrid-container">
        <DxDataGrid
            class="datagrid"
            :data-source="dataSource"
            :columns="columns"
            :remote-operations="false"
            :key-expr="keyExpr"
            :allow-column-reordering="true"
            :row-alternation-enabled="true"
            :show-borders="false"
            @toolbar-preparing="onToolbarPreparing"
            @saving="onSaving"
            @saved="onSaved"
        >
            <template #titleTemplate>
            <div class="title-container">
                <div class="title">{{ title }}</div>
            </div>
            </template>

            <slot slot:columns />

            <DxEditing
                :allow-updating="allowUpdating"
                :allow-adding="allowAdding"
                :allow-deleting="allowDeleting"
                :mode="editMode"
            />
            <DxScrolling mode="infinite"/>
            <DxSorting mode="none"/>
            <DxGroupPanel :visible="showGroupPanel"/>
            <DxSearchPanel
                :visible="showSearchPanel"
                :width="340"
                :placeholder="`${$i18n.t('common.search')}...`"
            />
            <DxGrouping :auto-expand-all="false"/>
            <DxPager
                :allowed-page-sizes="allowedPageSizes"
                :show-page-size-selector="showPageSizeSelector"
                :show-navigation-buttons="showNavigationButtons"
            />
            <DxPaging :page-size="pageSizes"/>
        </DxDataGrid>
    </div>
</template>

<script>
import {
  DxDataGrid,
  DxPager,
  DxPaging,
  DxEditing,
  DxScrolling,
  DxSorting,
  DxSearchPanel,
  DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';

export default {
    props: {
        title: {
            type: String,
            default: () => ("")
        },
        dataSource: {
            type: Array,
            default: () => ([])
        },
        columns: {
            type: Array,
            default: () => (null)
        },
        keyExpr: {
            type: String,
            default: () => (null)
        },
        allowDeleting: {
            type: Boolean,
            default: () => (false)
        },
        allowUpdating: {
            type: Boolean,
            default: () => (false)
        },
        allowAdding: {
            type: Boolean,
            default: () => (false)
        },
        allowedPageSizes: {
            type: [String, Array],
            default: () => (null)
        },
        showNavigationButtons: {
            type: Boolean,
            default: () => (true)
        },
        showPageSizeSelector: {
            type: Boolean,
            default: () => (false)
        },
        showSearchPanel: {
            type: Boolean,
            default: () => (true)
        },
        showGroupPanel: {
            type: Boolean,
            default: () => (true)
        },
        pageSizes: {
            type: Number,
            default: () => (10)
        },
        editMode: {
            type: String,
            default: () => ("form")
        }
    },
    components: {
        DxDataGrid,
        DxPager,
        DxPaging,
        DxEditing,
        DxScrolling,
        DxSorting,
        DxSearchPanel,
        DxGroupPanel,
        DxGrouping,
    },
    methods: {
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                location: "before",
                template: "titleTemplate"
            })
            // e.toolbarOptions.items.push({
            //     location: "after",
            //     template: "filtersTemplate"
            // })
        },
        onSaving(e) {
            this.$emit('saving',e)
        },
        onSaved(e) {
            this.$emit('saved',e)
        }
    },
}
</script>

<style lang="scss" scoped>
.datagrid-container {
    height: 100%;
    background-color: red;

    .datagrid {
        height: 100%;
    }
}
</style>