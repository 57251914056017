<template>
    <Common 
        title="USERS" 
        key-expr="usr_uuid" 
        :data-source="users" 
        :allow-updating="true" 
        :allow-adding="true" 
        :allow-deleting="true" 
        :columns="columns" 
        @saving="onSaving"
    />
        <!-- <slot name="columns" v-if="loaded">
            <DxColumn data-field="usr_uuid" :visible="false" :allow-editing="false" />
            <DxColumn data-field="usr_active" caption="Active" />
            <DxColumn data-field="usr_email" caption="email" />
            <DxColumn data-field="usr_first_name" caption="first name" />
            <DxColumn data-field="usr_last_name" caption="last name" />
            <DxColumn data-field="usr_mobile_phone" caption="mobile phone" />
            <DxColumn data-field="usr_company" caption="company" />
            <DxColumn data-field="usr_activation_token" caption="activation token" />
            <DxColumn data-field="usr_delete_date" data-type="date" caption="delete date" />
        </slot> -->
    <!-- </Common> -->
</template>

<script>
import { mapActions } from 'vuex'
import Common from './common.vue'

// import { DxColumn } from 'devextreme-vue/data-grid';

export default {
    components: {
        Common,
        // DxColumn
    },
    data() {
        return {
            columns: [{
                dataField: "usr_uuid",
                caption: "UUID",
                visible: false,
                allowEditing: false
            },{
                dataField: "usr_active",
                caption: "Active"
            },{
                dataField: "usr_email",
                caption: "Email"
            },{
                dataField: "usr_first_name",
                caption: "First Name"
            },{
                dataField: "usr_last_name",
                caption: "Last Name"
            },{
                dataField: "usr_mobile_phone",
                caption: "Mobile Phone"
            },{
                dataField: "usr_company",
                caption: "Company"
            }/*,{
                dataField: "address.adr_uuid",
                caption: "Address",
                visible: false,
                allowEditing: false
            }*/,{
                dataField: "usr_activation_token",
                caption: "Activation Token"
            },{
                dataField: "usr_delete_date",
                caption: "Deleted Date",
                dataType: "datetime",
                allowEditing: false
            }]
        }
    },
    async beforeMount() {
        await this.$store.dispatch('administration/FETCH_USERS')
    },
    computed: {
        users() {
            return this.$store.getters['administration/GET_USERS']
        }
    },
    methods: {
        ...mapActions('administration', ['UPDATE_USER']),
        async onSaving(e) {
            e.cancel = false

            const changes = e.changes && e.changes.length > 0 && e.changes[0]

            if (changes) {
                let user = this.users.find(usr => usr.usr_uuid === changes.key)

                // console.log("user", this.users, changes.key, user)

                if (user) {
                    user = {
                        ...user,
                        ...changes.data
                    }

                    e.promise = this.UPDATE_USER(user).then(respUpdate => {
                        if (respUpdate.success) {
                            return respUpdate
                        }
                        throw `[${respUpdate.code}] - ${respUpdate.message}`
                    })
                }
            }            
        },
    },
}
</script>