// const dataSources = require('../Mock/dataSources')
const {
    addDate,
    dateDiffDay,
    // dateDiffHour,
    // dateDiffMinute,
    // dateDiffSecond,
} = require('../Common')
// const { v4: uuidv4 } = require('uuid');
const axios = require('axios')

const defaultConfig = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    URI: {
        envelope: '/envelope',
        sendings: '/sendings',
        receivings: '/receivings',
        launchCipher: '/launchcipher',
        launchDecipher: '/launchdecipher',
    }
}

function processResponse(response) {
    // console.log("processResponse",response)
    // console.log(response.status >= 200 )
    return {
        success: (response.status >= 200 && response.status < 300 && response.data && response.data.success),
        message: (response.status >= 200 && response.status < 300) ? response.data.message : 'InternalError',
        code: (response.status >= 200 && response.status < 300) && response.data.code,
        data: (response.status >= 200 && response.status < 300) && response.data.data
    }
}

const API = {
    client: axios.create(),
    create: (options) => {
        // console.log("CREATE")
        if (options && options.endpoint) API.client.defaults.baseURL = options.endpoint
        else API.client.defaults.baseURL = defaultConfig.baseURL
    },
    async getEnvelope(envelopeUUID, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL getEnvelope", envelopeUUID, options, API.client.defaults)
        const response = await API.client.get(`${defaultConfig.URI.envelope}/${envelopeUUID}`, options).then(processResponse)
        // console.log("getEnvelope",response)
        return response
    },
    async deleteEnvelope(envelopeUUID, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL getEnvelope", envelopeUUID, options, API.client.defaults)
        const response = await API.client.delete(`${defaultConfig.URI.envelope}/${envelopeUUID}`, options).then(processResponse)
        // console.log("getEnvelope",response)
        return response
    },
    manageSendingReceivingResponse(data) {
        data.send_on = new Date(data.send_on)
        data.end_date = addDate(data.send_on, data.send_duration + 1)
        data.remaining_time = dateDiffDay(new Date(), data.end_date)

        return data
    },
    getSendingList(options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL getSendingList", options, API.client.defaults)

        return API.client.get(defaultConfig.URI.sendings, options).then(processResponse).then(response => {
            // console.log(defaultConfig.URI.sendings, response)
            if (response.success && response.data) {
                response.data = response.data.map(this.manageSendingReceivingResponse)
            }
            return response
        })
    },
    getReceivingList(options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL getReceivingList", options, API.client.defaults)
        return API.client.get(defaultConfig.URI.receivings, options).then(processResponse).then(response => {
            // console.log(defaultConfig.URI.receivings, response)
            if (response.success && response.data) {
                response.data = response.data.map(this.manageSendingReceivingResponse)
            }
            return response
        })
    },

    async updateReceiving(envelopeUUID, fileUUID, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL updateReceiving", envelopeUUID, fileUUID, options, API.client.defaults)
        const response = await API.client.post(`${defaultConfig.URI.receivings}`, {
            sending_uuid: envelopeUUID,
            file_uuid: fileUUID
        }, options).then(processResponse)
        // console.log("updateReceiving",response)
        return response
    },

    async submitSending(envelope, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL submitSending", envelope, options, API.client.defaults)
        const response = await API.client.post(defaultConfig.URI.sendings, envelope, options).then(processResponse)
        // console.log("submitSending",response)
        return response
    },

    async launchCipher(envelopeUUID, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL launchCipher", envelopeUUID, options, API.client.defaults)
        const response = await API.client.post(`${defaultConfig.URI.launchCipher}/${envelopeUUID}`, {}, options).then(processResponse)
        // console.log("launchCipher",response)
        return response
    },

    async launchDecipher(envelopeUUID, fileUUID, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL launchDecipher", envelopeUUID, fileUUID, options, API.client.defaults)
        const response = await API.client.post(`${defaultConfig.URI.launchDecipher}/${envelopeUUID}`, {
            fileUUID
        }, options).then(processResponse)
        // console.log("launchDecipher",response)
        return response
    },

    async getCipherStatus(envelopeUUID, options) {
        API.client.defaults.baseURL = defaultConfig.baseURL
        // console.log("CALL getSendingStatus", envelopeUUID, options, API.client.defaults)
        const response = await API.client.get(`${defaultConfig.URI.sendings}/${envelopeUUID}`, options).then(processResponse).catch(processResponse)
        // console.log("getSendingStatus",response)
        return response
    },
}

module.exports = API