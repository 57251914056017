const dataSources = require('./dataSources')

module.exports = {
    async getCountryList(payload) {
        // console.log("[MOCK getCountryList]",payload)
        if (payload && payload.code) return await Promise.resolve({success: true, list: dataSources.list.filter(country => country.code === payload.code)})
        
        return await Promise.resolve({success: true, list: dataSources.list})
        
    },
}