<template>
    <div class="form-send-container">
        <transition name="fade" mode="out-in">
            <Form v-if="!readyToUpload">
                <slot />
            </Form>
            <Uploading v-else />
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Form from './form'
import Uploading from './uploading'

export default {
    components: {
        Form,
        Uploading,
    },
    computed: {
        ...mapState('sendings', ['envelope']),
        readyToUpload() {
            return this.envelope && this.envelope.uuid
        }
    },
}
</script>

<style lang="scss" scoped>
.form-send-container {
    width: 100%;
    // height: max-content;
    //height: 846px;
    margin: auto;
    margin-top: 16px;
    padding-bottom: 35px;

}


</style>