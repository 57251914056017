const axios = require('axios')
import VueJwtDecode from 'vue-jwt-decode'
const { hash } = require('./utils')
const defaultConfig = {
    baseURL: null,
    URI: {
        login: '/auth/login',
        refresh: '/auth/refresh',
        logout: '/auth/logout',
        create: '/auth/create',
        confirm: '/auth/confirm',
        forgotPassword: '/auth/forgotPassword',
        resetPassword: '/auth/resetPassword',
        profile: '/profile',
    }
}

function processResponse(response) {
    return response.data
}

const AuthenticationBasic = {
    client: axios.create(),
    create: (options) => {
        if (options && options.endpoint) AuthenticationBasic.client.defaults.baseURL = options.endpoint
    },
    decodeToken: (token) => VueJwtDecode.decode(token),
    timeBeforeExpiration: (expiration) => expiration - (new Date().getTime()/1000),
    isExpired: (expiration) => (new Date().getTime()/1000) > expiration,
    login: async (login, password) => {
        return await AuthenticationBasic.client.post(defaultConfig.URI.login, {
            username: login,
            password
        }).then(resp => processResponse(resp))
    },
    refresh: async (options) => {
        return await AuthenticationBasic.client.post(defaultConfig.URI.refresh, {}, options).then(resp => processResponse(resp))
    },
    logout: async (options) => {
        return await AuthenticationBasic.client.post(defaultConfig.URI.logout, {}, options).then(resp => processResponse(resp))
    },   
    checkToken: (token, isRefresh = false) => {
        const decodedToken = AuthenticationBasic.decodeToken(token)
        console.log("decodedToken",isRefresh,decodedToken)
        const isExpired = AuthenticationBasic.isExpired(decodedToken.exp)
        return isExpired
    },
    profile: async (options) => {
        return await AuthenticationBasic.client.get(defaultConfig.URI.profile, options).then(resp => processResponse(resp))
    },
    forgotPassword: async (payload, options) => {
        return await AuthenticationBasic.client.post(defaultConfig.URI.forgotPassword, payload, options).then(resp => processResponse(resp))
    },
    resetPassword: async (payload, options) => {
        if (payload.resetToken && payload.newPassword && payload.confirmPassword && payload.newPassword === payload.confirmPassword) {
            const { password, salt } = hash(payload.newPassword)
            const resetPasswordPayload = {
                resetToken: payload.resetToken,
                password,
                salt
            }

            // const data = JSON.stringify(resetPasswordPayload)
            return await AuthenticationBasic.client.post(defaultConfig.URI.resetPassword, resetPasswordPayload, options).then(resp => processResponse(resp))
        }

        return { success: false, code: '', message: "Invalid password" }


        // return new Promise((resolve, reject) => {
        //     console.log('[MOCK resetPassword]', payload, options)
        //     setTimeout(() => {
        //         if (payload.resetToken && payload.newPassword && payload.confirmPassword && payload.newPassword === payload.confirmPassword) resolve({
        //             success: true
        //         })
        //         else reject(new Error('Bad credntials'))
        //     }, 2000)
        // })
    },

    createAccount: async (createdUser, options) => {
        // const data = JSON.stringify(user)
        
        // return await AuthenticationBasic.client.post(defaultConfig.URI.create, data, options).then(resp => processResponse(resp))

        if (createdUser.password && createdUser.confirmPassword && createdUser.password === createdUser.confirmPassword) {
            const { password, salt } = hash(createdUser.password)
            const user = {
                ...createdUser,
                salt,
                password,
            }
            delete user.confirmPassword
            // console.log("createAccount", user, options)
            const data = JSON.stringify(user)
            return await AuthenticationBasic.client.post(defaultConfig.URI.create, data, options).then(resp => processResponse(resp))
        }

        return { success: false, code: '', message: "Invalid password" }
    },

    confirmAccount: async (confirmationToken, options) => {
        // console.log(confirmationToken, options)
        if (confirmationToken) {
            const data = JSON.stringify({confirmationToken}) 
            return await AuthenticationBasic.client.post(defaultConfig.URI.confirm, data, options).then(resp => processResponse(resp))
        }
        return { success: false, code: '', message: "Invalid token" }
    },

    updateAccount: async (user, options) => {
        const data = JSON.stringify(user)
        
        return await AuthenticationBasic.client.put(defaultConfig.URI.profile, data, options).then(resp => processResponse(resp))
    },
}

export { AuthenticationBasic }

export default {AuthenticationBasic  }

// module.exports = AuthenticationBasic