<template>
  <div class="sendings-container">
    <div class="content-block data-grid-container">
      <div class="dx-card responsive-paddings">
        <!-- <DxButton text="REFRESH" @click="refreshToken" /> -->
        <!-- <DxScrollView
            :scroll-by-content="true"
            :scroll-by-thumb="true"
            show-scrollbar="onScroll"
        > -->
          <DxDataGrid
            class="sendings-master-grid"
            :dataSource="sendings"
            :pager="{ showNavigationButtons: true }"
            :paging="{ enable: true, pageSize: 4 }"
            :show-row-lines="false"
            :show-column-lines="false"
            :show-borders="false"
            :load-panel="{ enable: false}"
            @initialized="onSendingsInitialized"
            @toolbar-preparing="onToolbarPreparing"
            @row-prepared="onRowPrepared"
            @cell-prepared="onCellPrepared"
            @row-expanding="onRowExpanding"
            :word-wrap-enabled="true"
          >
            <!-- <DxLoadPanel :enabled="true"/> -->
            <template #titleTemplate>
              <div class="title-container">
                <div class="title">{{ $i18n.t('menu.sended') }}</div>
              </div>
            </template>

            <template #filtersTemplate>
              <Filters @selected-filter-changed="onSelectedFilterChanged"/>
            </template>

            <template #refreshTemplate>
              <DxButton
                icon="refresh"
                @click="refreshDataGrid"
              />
            </template>

            <DxSearchPanel
              :visible="true"
              :width="340"
              :placeholder="`${$i18n.t('common.search')}...`"
            />
            
            <DxColumn
              data-field="send_on"
              type="datetime"
              cssClass="col col-send-on"
              :caption="$i18n.t('sendings.columns.send_on')"
              format="yyyy/MM/dd"
              width="120"
              :allow-sorting="true"
            />
            <DxColumn data-field="subject" width="250" cssClass="col col-subject" :caption="$i18n.t('sendings.columns.subject')"/>

            <DxColumn data-field="mail" width="70" cssClass="col col-mail" alignment="center" :caption="$i18n.t('sendings.columns.mail')" cell-template="mailTemplate"/>
            <DxColumn data-field="recipients.length" width="160" alignment="left" cssClass="col col-recipients" :caption="$i18n.t('sendings.columns.recipients')" cell-template="defaultTemplate" />    
            <DxColumn data-field="files.length" width="150" alignment="left" cssClass="col col-files" :caption="$i18n.t('sendings.columns.files')" cell-template="defaultTemplate" />
            <DxColumn data-field="statutes" cssClass="col col-statutes" :caption="$i18n.t('sendings.columns.statutes')" cell-template="statutesTemplate" />
            <!-- <DxColumn data-field="status-text" caption="status" /> -->

            <DxColumn data-field="remaining_time" width="220" cssClass="col col-remaining_time" :caption="$i18n.t('sendings.columns.remaining_time')" cell-template="remainingTimeTemplate"/>

            <DxColumn caption="Actions" alignment="right" width="90" cell-template="actionsTemplate">
            </DxColumn>

            <template #actionsTemplate="{ data }">
              <DxButton class="actions" :disabled="(data.data.downloaded || data.data.expired)" icon="trash" @click="deleteSending($event, data)" />
            </template>

            <template #defaultTemplate="{ data }">
              <div class="cell-container">
                <!-- <div v-if="data.column.dataField === 'mail'" style="display:none">{{data.value}}</div>
                <div v-else class="col-content">{{data.value}}</div> -->
                <div class="col-content">{{data.value}}</div>
              </div>
            </template>

            <template #mailTemplate="{ data }">
              <div class="mail-template">
                <RowMailTemplate :template-data="data" />
              </div>
            </template>

            <!-- <template #filesTemplate="{ data }">
              <div class="cell-container">
                <div class="col-content">{{data.value}}</div>
              </div>
            </template> -->

            <template #statutesTemplate="{ data }">
              <div class="statutes-template">
                <RowStatusTemplate :template-data="data" />
              </div>
            </template>

            <template #remainingTimeTemplate="{ data }">
              <row-remaining-time :sending="data.data" />
              <!-- <div v-if="!data.data.expired" class="cell-container">
                <div class="col-content">
                  {{ getRemainingTime(data.data)}}
                </div>
                <Progress class="col-progress-value" :radius="15" :strokeWidth="3" strokeColor="#434343" :value="(data.data.send_duration-data.data.remaining_time)*100/data.data.send_duration">
                  <div></div>
                </Progress>
              </div>
              <div v-else class="cell-container cell-expired">{{$i18n.t('stats.package.expired')}}</div> -->
            </template>

            <!-- https://supportcenter.devexpress.com/ticket/details/t459862/how-to-animate-expanding-collapsing-a-master-row -->
            <DxMasterDetail
              :enabled="true"
              template="masterDetailTemplate"
            />

            <template #masterDetailTemplate="{ data }">
              <RowDetailTemplate :template-data="data" />
            </template>

          </DxDataGrid>
        <!-- </DxScrollView> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { Mock as API } from '../SDK'
// import { Silico as API } from '../SDK'
// import { prepareResultRows } from '@/utils';
import { DxButton } from 'devextreme-vue/button';
import Filters from '@/components/grid/filters'
// import Progress from "easy-circular-progress";
import RowMailTemplate from '@/components/sendings/RowMailTemplate.vue';
import RowDetailTemplate from '@/components/sendings/RowDetailTemplate.vue';
import RowStatusTemplate from '@/components/sendings/RowStatusTemplate.vue';
import RowRemainingTime from '@/components/sendings/RowRemainingTime.vue';
// import { DxScrollView } from 'devextreme-vue/scroll-view';
// import CustomStore from 'devextreme/data/custom_store';
// import DataSource from 'devextreme/data/data_source';

import {
  DxDataGrid,
  DxColumn,
  // DxButton,
  // DxToolbar,
  // DxItem,
  DxSearchPanel,
  DxMasterDetail,
} from 'devextreme-vue/data-grid'

import { mapActions, mapState } from 'vuex';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
// import { dateDiffDay, dateDiffHour, dateDiffMinute, dateDiffSecond } from '../SDK/Common';

// import { DxButton } from 'devextreme-vue/button'

// const prepareSecureOptions = (state) => {
//     const headers = {
//         'Content-Type': 'application/json'
//     }

//     if (state.tokens && state.tokens.accessToken) headers['Authorization'] = `Bearer ${state.tokens.accessToken}`

//     return {
//         headers
//     }
// }

export default {
  name: "Sendings",
  components: {
    Filters,
    // DxScrollView,
    DxDataGrid,
    DxColumn,
    DxButton,
    // DxToolbar,
    // DxItem,
    DxSearchPanel,
    DxMasterDetail,
    // Progress,
    RowMailTemplate,
    RowDetailTemplate,
    RowStatusTemplate,
    RowRemainingTime,
  },
  data() {
    return {
      datagrid: null,
      // sendings: null,
    }
  },
  computed: {
    ...mapState('sendings', ['files','sendings']),
    filterValue: {
      get() {
        return this.datagrid.filter()
      },
      set(value) {
        this.datagrid.filter(value)
      }
    },
    customDialogOptions() {
      return {
        showTitle: false,
        messageHtml: this.$i18n.t('sendings.delete-confirm-message'), 
        buttons: [{
          text: this.$i18n.t('send.yes'),
          elementAttr: {
              class: "btn-yes"
          },
          onClick: () => (true)
        },
        {
          text: this.$i18n.t('send.no'),
          elementAttr: {
              class: "btn-no"
          },
          onClick: () => (false)
        },]
      }
    },
    // sendings() {
    //   return this.$store.state.sendings.sendings;
    // }
    // files() {
    //   return this.sendings && this.sendings.files
    // },
    // recipients() {
    //   return this.sendings && this.sendings.recipients
    // },
    // statutes() {
    //   return this.sendings && this.sendings.statutes
    // },
    // calculateStatutes() {
    //   return this.sendings && areFilesDownloadedByStatutesAndRecipients(this.sendings.files, this.sendings.statutes, this.sendings.recipients)
    // }
  },
  methods: {
    ...mapActions('sendings', ['FETCH_SENDINGS','DELETE_SENDING']),
    ...mapActions('authentication-basic', ['REFRESH']),
    refreshDataGrid() {
      this.FETCH_SENDINGS()
    },
    refreshToken() {
      this.REFRESH()
    },
    deleteSending(event, data) {
      console.log(this.customDialogOptions)
      const confirmDialog = custom(this.customDialogOptions).show()
      console.log(this.confirmDialog)
      confirmDialog.done(async (dialogResult) => {
          if (dialogResult) {
            const result = await this.DELETE_SENDING(data.data.uuid)
            if (result.success) notify('delete success','success')
            else notify(result.message,'error')
            console.log("deleteSending", result)
            this.refreshDataGrid()
          }
      });
    },
    // getRemainingTime(data) {
    //   return `
    //     ${dateDiffDay(new Date(), data.end_date)}${this.$i18n.t('sendings.columns.remaining_time_dayUnit')}
    //     ${dateDiffHour(new Date(), data.end_date)}h
    //     ${dateDiffMinute(new Date(), data.end_date)}m
    //     ${dateDiffSecond(new Date(), data.end_date)}s
    //   `
    // },
    onSendingsInitialized(event) {
      // const self = this
      this.datagrid = event.component

      this.refreshDataGrid();

      // // console.log("onSendingsInitialized", this.sendings, this.datagrid) 

      // this.sendings = new DataSource({
      //   key: 'id',
      //   load() {    
      //     const options = prepareSecureOptions(self.$store.state['authentication-basic'])
      //     return API.getSendingList(options)
      //       .then(res => {
      //           if (res && res.data && res.success) {
      //             res.data = prepareResultRows(res.data)
      //             return res
      //           }
      //           return []
      //       })
      //       .then((res) => ({
      //         data: res.data,
      //         totalCount: 100,//res.data.length,
      //         summary: null,
      //         groupCount: null,
      //       }))
      //       .catch((e) => { 
      //         throw e //new Error('Data Loading Error'); 
      //       });
      //   },
      // });
    },
    onRowExpanding(event) {
      event.component.collapseAll(-1)
    },
    onRowPrepared(row) {
      if (row.rowType === "header") row.rowElement.classList.add("go2transfer-datagrid-header-row")
      if (row.rowType == "data" || row.rowType == "detail") {
        if (row.rowType == "data") row.rowElement.classList.add("go2transfer-datagrid-data-row")
        if (row.rowType == "detail") row.rowElement.classList.add("go2transfer-datagrid-detail-row")

        if (row.isExpanded) row.rowElement.classList.add("go2transfer-datagrid-data-row-expanded");
        else row.rowElement.classList.remove("go2transfer-datagrid-data-row-expanded");

        if (row.data.expired) row.rowElement.classList.add("row-expired");
        if (!row.data.expired && row.data.downloaded) row.rowElement.classList.add("row-downloaded");
      }         
    },
    onCellPrepared(cell) {
      if (cell.rowType === "header") cell.cellElement.classList.add("go2transfer-datagrid-header-column")
      if (cell.rowType == "data") cell.cellElement.classList.add("go2transfer-datagrid-data-column")
    },
    // onExpandClick(e, data) {
    //   // console.log(this.test)
    //   const expand = this.sendings.find(sending => sending.id===data.data.id)
    //   if (expand) expand.expanded = !expand.expanded
    // },
    onSelectedFilterChanged(filter) {
      console.log('TODO - onSelectedFilterChanged:',filter)
      if (filter === 'all') return this.datagrid.clearFilter()
      if (filter === 'current') {
        this.filterValue = ['remaining_time','>',0]
      }
      if (filter === 'open') {
        this.filterValue = ['downloaded','=',true]
      }
      if (filter === 'non-open') {
        this.filterValue = ['downloaded','=',false]
      }
      if (filter === 'ended') {
        this.filterValue = ['remaining_time','<=',0]
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "titleTemplate"
      })
      e.toolbarOptions.items.push({
        location: "after",
        template: "filtersTemplate"
      })
      e.toolbarOptions.items.push({
        location: "after",
        template: "refreshTemplate"
      })
    },
  },
}
</script>

<style lang="scss" scope>
.sendings-container {
  height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: center;

  .content-block {
    .dx-card {
      height: 80vh;
      background-color: #fffffffa !important;

      .dx-datagrid{
        background-color: transparent !important;
      }
      .dx-datagrid-header-panel{
        background-color: transparent !important;
      }
    } 

    .dx-datagrid-search-panel{
      background-color: transparent;
    }
    
  }

  .dx-datagrid-header-panel {
    .title:before {
      content: url(/images/sendings.svg);
    }
  }

  
 .dx-command-expand{
      padding: 0 4px 0 4px !important;
    
         &:hover{
          cursor:pointer;
          background: #f7f7f7;
          .dx-datagrid-group-closed, .dx-datagrid-group-opened{
             // color: #eee !important;
              //background:#414143;
              border-radius: 25px;
              color:#414143 !important;
    
              }

              &~ td{
                 background: #f7f7f7;
              }

        }

    }


  .dx-datagrid-rowsview .dx-datagrid-content {
    .cell-expired {
      color: #8b8b8b;
    }

    .col {
      &.col-remaining_time {
        .col-progress-value {
          margin-left: 8px;
        }

        .cell-container {
          font-family: "LatoWebThin";
          font-size: 19px;
          justify-content: center;
          height: 36px;
        }
      }

      .cell-container {
        display: flex;
        align-items: center;

        &.cell-expired { 
          font-size: 17px;
        }

        .col-content{
          &:before {
            margin-right: 8px;
          }
        }
      }

      &-send-on {
        color: #8b8b8b;
        font-weight: bold;
      }

      &-recipients {    
        font-size: 19px;
        .col-content:before {
          content: url(/images/recipients.svg);
          height: 19px;
        }
      }

      &-files {      
        font-size: 19px;
        .col-content:before {
          content: url(/images/files.svg);
          height: 19px;
        }
      }
    }


  }

  .expand-template .dx-button {
    background-color: transparent;
    height: 36px;

    &.dx-state-focused,
    &.dx-state-hover,
    &.dx-state-active {
      background-color: transparent;
    }
  }

  .row {
    &-expired {
      background-color: #e2e2e241;
    }

    &-downloaded {
      background-color: #30ff9e13;
    }
  }

  .actions {
    &.dx-state-disabled,
    &.dx-state-hover,
    &.dx-state-active {
      background: transparent;
    }
  }

}


</style>