const kilo = 1024
export const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const sizes = {
    formatBytes: (bytes, dm = 2) => { 
        // // console.log("formatBytes",bytes, dm)
        if (bytes === 0) return '0 Bytes';
        dm = dm < 0 ? 0 : dm
        const i = Math.floor(Math.log(bytes) / Math.log(kilo));
        return `${parseFloat((bytes / Math.pow(kilo, i)).toFixed(dm))} ${unit[i]}`
    }
}