<template>
    <div class="file-template file-download-template">
        <FileDownloadButton v-for="file in sending.files" :key="`file-download-container-${cellData.rowIndex}-${sendingUuid}-${file.uuid}-${sending.updateKey}`" :row-index="cellData.rowIndex" :sending="sending" :file="file"/>
    </div>
</template>

<script>
import FileDownloadButton from './fileDownloadButton.vue'
export default {
    name: 'FileDownloadTemplate',
    components: {
        FileDownloadButton,
    },
    props: {
        cellData: {
            type: Object,
            required: true
        },
    },
    computed: {
        sending() {
            return this.cellData.data
        },
        sendingUuid() {
            return this.sending.uuid
        }
    }
}
</script>

<style lang="scss" scoped>

</style>