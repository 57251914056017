<template>
    <div class="uploading-container">
        <div class="uploading-container-top">
            <UploadingHeader />


            <div class="uploading-content">

                <UploadingTitle />
                <UploadingSubTitle />
                
                <transition-group name="fade" mode="out-in">
                    <div v-show="cipherStatus !== 'ENDED'" key="uploading-files" class="uploading-files">
                        <FileList key="FileList" :data-source="filesUploadProgress" height="150">
                            <dx-scrolling mode="virtual"/>

                            <dx-column data-field="name" />
                            <!-- <dx-column data-field="loaded" ___cell-template="loaded-cell-template" :calculate-cell-value="calculateLoadedSize" /> -->
                            <dx-column data-field="size" alignment="right" :calculate-cell-value="calculateSize" />
                        </FileList>
                        <DxProgressBar
                            v-if="!uploadStatus && progress <= progressTotal"
                            key="DxProgressBar"
                            :show-status="true"
                            :status-format="formatProgress"
                            :min="0"
                            :max="progressTotal"
                            :value="progress"
                            width="90%"
                        />
                    </div>
                </transition-group>
            </div>
        </div>

        <div class="uploading-container-bottom">
            <div class="uploading-footer">
                <transition name="fade" mode="out-in">
                    <DxButton 
                        v-show="cipherStatus || uploadStatus === 'error'"
                        width="60%"
                        :text="$i18n.t('sendings.go-back')"
                        icon="chevronprev"
                        @click="goBack"
                    />
                </transition>
            </div>     
        </div>     
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FileList from '@/components/list'
import UploadingHeader from './uploading/uploadingHeader'
import UploadingTitle from './uploading/uploadingTitle'
import UploadingSubTitle from './uploading/uploadingSubTitle'
import { DxColumn, DxScrolling } from "devextreme-vue/data-grid";
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { DxButton } from "devextreme-vue/button";
import { sizes } from '@/modules/utils'
import { math } from '@/modules/utils'
import axios from 'axios'

export default {
    name: 'Uploading',
    components: {
        FileList,
        UploadingHeader,
        UploadingTitle,
        UploadingSubTitle,
        DxColumn, DxScrolling,
        DxProgressBar,
        DxButton
    },
    data() {
        return {
            filesUploadProgress: [],
        }
    },
    computed: {
        ...mapState('sendings', ['envelope','files','uploadStatus']),
        ...mapGetters('sendings', ['GET_CIPHER_STATUS']),
        cipherStatus() {
            return this.GET_CIPHER_STATUS
        },
        progress() {
            return math.sum(this.filesUploadProgress.map(fup => fup.loaded),0)
        },
        progressTotal() {
            return math.sum(this.filesUploadProgress.map(fup => fup.size),0)
        },
        filesEnvelope() {
            return this.envelope && this.envelope.files
        },
        mixedFiles() {
            return this.filesEnvelope.map(file => {
                const srcFile = this.files.find(f => f.name === file.name)
                // const srcFileProgress = this.filesUploadProgress && this.filesUploadProgress.find(f => f.name === file.name)
                return {
                    uuid: file.uuid,
                    file: srcFile,
                    upload_url: file.upload_url
                }
            })
        }
    },
    methods: {
        ...mapActions('sendings', ['LAUNCH_CIPHER', 'FETCH_CIPHER_DECIPHER_STATUS', 'RESET']),
        formatProgress(ratio, value) { 
            return `Progress: ${sizes.formatBytes(value,0)} / ${sizes.formatBytes(this.progressTotal,0)}`
        },
        calculateSize(data) {
            return data && data.size && data.loaded && `${sizes.formatBytes(data.loaded,0)} / ${sizes.formatBytes(data.size,0)}`
        },
        calculateLoadedSize(data) {
            return data && data.loaded && sizes.formatBytes(data.loaded,0)
        },
        uploadFiles() {
            return Promise.all(this.mixedFiles.map(file => {
                console.log("uploadFile", file)

                const currFile = this.filesUploadProgress.find(fup => fup.name === file.file.name)

                return axios.put(file.upload_url, file.file, {
                    headers: {
                        'Content-Type': file.file.type
                    },
                    onUploadProgress: (progressEvent) => {
                        if (currFile && currFile.loaded < progressEvent.loaded) currFile.loaded = progressEvent.loaded
                    }
                })
            })).then(responses => {
                // console.log(responses)
                if (responses.some((response) => response.status !== 200)) {
                    return { success: false, message: 'Some upload has error' }
                }

                return { success: true, message: 'All files are uploaded' }
            })
        },
        async launchCipher() {
            await this.LAUNCH_CIPHER()

            this.getCipherStatus()
        },
        async getCipherStatus() {
            if (this.envelope) {
                const cipherStatus = await this.FETCH_CIPHER_DECIPHER_STATUS(this.$store.state.sendings.cipher.uuid)
            // console.log("cipherStatus",cipherStatus)
                if (cipherStatus.success) {
                    if (this.cipherStatus && this.cipherStatus !== 'ENDED') setTimeout(this.getCipherStatus, 2000)
                }
            }
        },
        goBack() {
            this.$store.dispatch('sendings/RESET')
        },
        async startUpload() {
            this.$store.commit('sendings/SET_UPLOAD_STATUS',null)
            this.$store.commit('sendings/SET_CIPHER',null)
            this.filesUploadProgress = []
            this.filesUploadProgress = [...this.filesEnvelope.map(fe => { 
                return {
                    loaded: 0,
                    ...fe
                }
            })]
            
            const uploadResult = await this.uploadFiles()
            this.$store.commit('sendings/SET_UPLOAD_STATUS',uploadResult.success ? 'success' : 'error')

            if (uploadResult.success) setTimeout(this.launchCipher, 1800)
        }
    },
    beforeMount() {
        // console.log("topps")
        // setTimeout(() => { 
        if (this.envelope && this.envelope.uuid && !this.cipherStatus) this.startUpload()
        // }, 1000)
    },
}
</script>

<style lang="scss" scoped>
@import '@/override/themes/go2transfer/variables.scss';
.uploading-container {
    width: 100%;
    height: 712px;
    transition:0.2s ease;
    display: flex;
    flex-direction: column;    
    justify-content: space-between;

    .uploading-content {
        text-align: center;
        color: white;
        font-family: "LatoWebThin";
        // width: 90%;
        // margin: auto;

        .uploading-files {
          margin: 20px;

          ::v-deep .dx-progressbar {
            margin: auto;

            .dx-progressbar-status {
                color: $go2transfer-red;
            }

            .dx-progressbar-container {
                background-color: #8a8a8a;
                
                .dx-progressbar-range {
                    background-color: $go2transfer-red;   
                }
            }


            }

          
            .list{
                background:transparent !important;

                     ::v-deep .dx-datagrid .dx-row-lines > td{
                        border-bottom: 1px dotted #6f6f6f !important;
                        padding: 1px 3px;
                        font-family: "LatoWebThin";
                        font-size: 15px;
                        color: #ddd;
                    }
                    ::v-deep .dx-datagrid .dx-data-row{
                        border-bottom: 0px solid !important;
                    }
            }

        }
    }   

    .uploading-footer {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 30px;
        .dx-button {
            background-color: #262626;
            color: white;

            ::v-deep .dx-icon {
                color: white;
            }

            &:hover{
                background: rgb(24, 24, 24);
            }
        }
    }
}

::v-deep {
    .dx-datagrid {
        color: white;
    }
    .dx-scrollable-wrapper {
        /* Scrollbar styles */
        ::-webkit-scrollbar {
            width: 4px;
            padding: 0 4px 0 4px;
            // height: 12px;
            background-color: #5e5e5e;
            border-radius: 8px;
        }

        ::-webkit-scrollbar-track {
            // border: 1px solid #5e5e5e;
            border: 0px solid transparent;
            border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb {
            background: #8a8a8a;  
            border-radius: 8px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #8a8a8a;  
        }
    }
}
</style>