export const SET_LOADING = "SET_LOADING";
export const SET_LIST = "SET_LIST";
export const GET_LIST = "GET_LIST";

export const SET_CURRENT = "SET_CURRENT";
export const GET_CURRENT_SUBSCRIPTION = "GET_CURRENT_SUBSCRIPTION";

export const FETCH_ALL = "FETCH_ALL";
export const FETCH_LIST = "FETCH_LIST";
export const FETCH_CURRENT_SUBSCRIPTION = "FETCH_CURRENT_SUBSCRIPTION";
export const UPDATE_CURRENT_SUBSCRIPTION = "UPDATE_CURRENT_SUBSCRIPTION";

import SDK from '../SDK/Mock'

export default {
    namespaced: true,
    state: () => ({
        loading: false,
        list: [],
        current: {
            option: '',
            startDate: null,
            renewDate: null,
        }
    }),
    mutations:{
        [SET_LOADING]: (state, loading) => state.loading = loading,
        [SET_LIST]: (state, list) => state.list = list,
        [SET_CURRENT]: (state, current) => state.current = current,
    },
    actions:{
        [UPDATE_CURRENT_SUBSCRIPTION]: ({commit, rootState}, payload) => {
            // console.log("UPDATE_CURRENT_SUBSCRIPTION",payload)
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            return SDK.updateSubscriptionCurrent(payload)
                .then(async res => {
                    // console.log("UPDATE_CURRENT_SUBSCRIPTION",res)
                    if (res.success) await commit(SET_CURRENT, {...res.current})
                    return res
                })
                .finally(() => {
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [FETCH_ALL]: ({dispatch}) => {
            Promise.all([dispatch('FETCH_LIST'),dispatch('FETCH_CURRENT_SUBSCRIPTION')])
        },
        [FETCH_CURRENT_SUBSCRIPTION]: ({ rootState, commit }) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            return SDK.getSubscriptionCurrent()
                .then(async res => {
                    if (res.success) await commit(SET_CURRENT, {...res.current})
                    return res
                }).finally(() => {
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
        [FETCH_LIST]: ({ rootState, commit }) => {
            if (rootState) commit(SET_LOADING, true, { root: true })
            commit(SET_LOADING, true)
            return SDK.getSubscriptionList()
                .then(async res => {
                    if (res.success) await commit(SET_LIST, [...res.list])
                    return res
                }).finally(() => {
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, { root: true })
                })
        },
    },
    getters:{
        [GET_LIST]: state => state.list,
        [GET_CURRENT_SUBSCRIPTION]: state => state.current,
    },
}