import router from '@/router'
import {
    SET_USER,
    SET_TOKEN,
    SET_LOADING,
    SET_PENDINGREFRESH,
} from './mutations'
import {
    APIUsers as API
} from '../SDK'
import {
    securedLocalStorage
} from '../../index'

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH = "REFRESH";
export const CHECKTOKEN = "CHECKTOKEN";
export const FETCHPROFILE = "FETCHPROFILE";
export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const RESETPASSWORD = "RESETPASSWORD";
export const CHANGEPASSWORD = "CHANGEPASSWORD";
export const CREATEACCOUNT = "CREATEACCOUNT";
export const CONFIRMACCOUNT = "CONFIRMACCOUNT";
export const UPDATEACCOUNT = "UPDATEACCOUNT";

const prepareSecureOptions = (state) => {
    const headers = {
        'Content-Type': 'application/json'
    }

    if (state.tokens && state.tokens.accessToken) headers['Authorization'] = `Bearer ${state.tokens.accessToken}`
    if (state.tokens && state.tokens.refreshToken) headers['x-refresh-token'] = `${state.tokens.refreshToken}`

    return {
        headers
    }
}

export default {
    [LOGIN]: ({
        commit,
        rootState
    }, payload) => {
        if (rootState) commit(SET_LOADING, true, {
            root: true
        })
        commit(SET_LOADING, true)

        // console.log("LOGIN API.login",payload)
        return API.login(payload.login, payload.password)
            .then(async res => {
                // console.log("LOGIN API.login RESULT",res)
                if (res.success) {
                    await commit(SET_USER, res.data)
                    await commit(SET_TOKEN, res.tokens)

                    await securedLocalStorage.setItem('authentication-basic', {
                        user: res.data,
                        tokens: res.tokens
                    })
                }
                return res
            })
            .finally(() => {
                commit(SET_LOADING, false)
                if (rootState) commit(SET_LOADING, false, {
                    root: true
                })
            })
    },
    [REFRESH]: ({
        state,
        dispatch,
        commit,
        rootState
    }) => {
        if (!state.pendingRefresh) {
            commit(SET_PENDINGREFRESH, true)
            if (rootState) commit(SET_LOADING, false, {
                root: true
            })
            commit(SET_LOADING, false)
    
            const options = prepareSecureOptions(state)
            // console.log("LOGIN API.login",payload)
            return API.refresh(options)
                .then(async res => {
                    // console.log("REFRESH API.refresh RESULT",res)
                    if (res.success) {
                        await commit(SET_TOKEN, res.tokens)
    
                        await securedLocalStorage.setItem('authentication-basic', {
                            tokens: res.tokens
                        })
                    }
                    return res
                })
                .catch(() => {
                    // console.log("ERROR REFRESH:", error)
                    return dispatch(LOGOUT)
                })
                .finally(() => {
                    commit(SET_PENDINGREFRESH, false)
                    commit(SET_LOADING, false)
                    if (rootState) commit(SET_LOADING, false, {
                        root: true
                    })
                })
        }
    },
    [LOGOUT]: ({
        state,
        commit,
        rootState
    }, forgot = false) => {
        // console.log("[LOGOUT]")
        if (rootState) commit(SET_LOADING, true, {
            root: true
        })
        commit(SET_LOADING, true)
        const options = prepareSecureOptions(state)
        // console.log(options)
        return API.logout(options)
            .then(async res => {
                if (res.success) {
                    await commit(SET_USER, null)
                    await commit(SET_TOKEN, null)
                    securedLocalStorage.setItem('authentication-basic', null)
                }
                return res
            })
            .finally(() => {
                commit(SET_LOADING, false)
                if (rootState) commit(SET_LOADING, false, {
                    root: true
                })
                if (forgot) {
                    router.push({
                        name: "forgot-password"
                    }).catch(() => {})
                } else router.push({
                    name: "login"
                }).catch(() => { 
                    // console.log('LOGOUT router error', err) 
                })
            })

    },
    [CHECKTOKEN]: async ({
        state,
        commit,
        dispatch
    }) => {
        // console.log("CHECKTOKEN BEFORE",state.tokens.accessToken)

        if (!(state.tokens && state.tokens.accessToken)) {
            const data = await securedLocalStorage.getItem('authentication-basic')
            // console.log("CHECKTOKEN READ LOCALSTORAGE", data, data && data.token)
            if (data && data.tokens) {
                commit(SET_USER, {
                    ...data.user
                })
                commit(SET_TOKEN, data.tokens)
            }

            // // console.log("CHECKTOKEN AFTER READ LOCALSTORAGE", getters['GET_USER'], state.tokens.accessToken)
        }

        // // console.log("CHECKTOKEN AFTER",state.tokens.accessToken, state)

        const tokenIsPresent = (state.tokens && state.tokens.accessToken && state.tokens.refreshToken) ? true : false
        let isAccessTokenExpired = true
        // let isRefreshTokenExpired = true

        if (tokenIsPresent) {
            isAccessTokenExpired = await API.checkToken(state.tokens.accessToken)
            // isRefreshTokenExpired = await API.checkToken(state.tokens.refreshToken, true)
        }

        // console.log("isAccessTokenExpired:", isAccessTokenExpired, "isRefreshTokenExpired:", isRefreshTokenExpired)

        if (isAccessTokenExpired && tokenIsPresent) {
            await dispatch(LOGOUT)
        }

        return Promise.resolve({
            success: !isAccessTokenExpired,
            token: state.tokens && state.tokens.accessToken
        })
    },
    [FETCHPROFILE]: ({
        state,
        commit,
        rootState
    }) => {
        if (rootState) commit(SET_LOADING, true, {
            root: true
        })
        commit(SET_LOADING, true)
        const options = prepareSecureOptions(state)
        return API.profile(options)
            .then(async res => {
                if (res.success) {
                    await commit(SET_USER, res.data)

                    const currentStorage = securedLocalStorage.getItem('authentication-basic');
                    securedLocalStorage.setItem('authentication-basic', {
                        ...currentStorage,
                        user: res.data
                    })
                }
                return res
            })
            .finally(() => {
                commit(SET_LOADING, false)
                if (rootState) commit(SET_LOADING, false, {
                    root: true
                })
            })
    },
    [FORGOTPASSWORD]: (context, payload) => {
        try {
            // Send request
            return API.forgotPassword(payload)
        } catch {
            return {
                success: false,
                message: "Failed call forgot password"
            };
        }
    },
    [RESETPASSWORD]: (context, payload) => {
        try {
            // Send request
            return API.resetPassword(payload)
        } catch {
            return {
                success: false,
                message: "Failed to reset password"
            };
        }
    },
    [CHANGEPASSWORD]: (context, payload) => {
        const {
            email,
            recoveryCode
        } = payload;
        try {
            // Send request
            console.log('TODO - CHANGEPASSWORD', email, recoveryCode);

            return {
                success: true
            };
        } catch {
            return {
                success: false,
                message: "Failed to change password"
            }
        }
    },
    [CREATEACCOUNT]: ({
        state,
        rootState,
        commit
    }) => {
        if (rootState) commit(SET_LOADING, true, {
            root: true
        })
        commit(SET_LOADING, true)
        const options = prepareSecureOptions(state)

        // Send request
        return API.createAccount(state.user, options)
            .then(async res => {
                if (res.success) {
                    await commit(SET_USER, res.data)

                    const currentStorage = securedLocalStorage.getItem('authentication-basic');
                    securedLocalStorage.setItem('authentication-basic', {
                        ...currentStorage,
                        user: res.data
                    })
                }
                return res
            })
            .finally(() => {
                commit(SET_LOADING, false)
                if (rootState) commit(SET_LOADING, false, {
                    root: true
                })
            })
    },
    [CONFIRMACCOUNT]: ({
        state,
        rootState,
        commit
    }, confirmationToken) => {
        if (rootState) commit(SET_LOADING, true, {
            root: true
        })
        commit(SET_LOADING, true)
        const options = prepareSecureOptions(state)

        // Send request
        return API.confirmAccount(confirmationToken, options)
            .then(async res => {
                // console.log('CONFIRMACCOUNT response', res)
                return res
            })
            .finally(() => {
                commit(SET_LOADING, false)
                if (rootState) commit(SET_LOADING, false, {
                    root: true
                })
            })
    },
    [UPDATEACCOUNT]: ({
        state,
        rootState,
        commit
    }) => {
        if (rootState) commit(SET_LOADING, true, {
            root: true
        })
        commit(SET_LOADING, true)
        const options = prepareSecureOptions(state)
        // Send request
        // console.log("UPDATEACCOUNT", state.user, options);
        return API.updateAccount(state.user, options)
            .then(async res => {
                if (res.success) {
                    await commit(SET_USER, res.data)

                    const currentStorage = securedLocalStorage.getItem('authentication-basic');
                    securedLocalStorage.setItem('authentication-basic', {
                        ...currentStorage,
                        user: res.data
                    })
                }
                return res
            })
            .finally(() => {
                commit(SET_LOADING, false)
                if (rootState) commit(SET_LOADING, false, {
                    root: true
                })
            })
    },
}