<template>
    <div class="uploading-subtitle">
        <transition name="fade" mode="out-in">
            <div class="subtitle-content" :key="subtitle" :style="subTitleStyle">{{ subtitle }}</div>
            <!-- <span v-show="!cipherStatus && uploadStatus" :key="`subtitle-uploadStatus-${uploadStatus}`">{{$i18n.t(`sendings.subtitle.upload-${uploadStatus}`)}}</span>
                    <span v-show="uploadStatus === 'success' && cipherStatus" :key="`subtitle-uploadStatus-${uploadStatus}-cipherStatus-${cipherStatus}`">{{$i18n.t(`sendings.subtitle.cipher-${cipherStatus}`)}}</span>                    
                    <span v-show="!uploadStatus || uploadStatus === 'error' || !cipherStatus" :key="`subtitle-uploadStatus-blank`" style="color: transparent;">{{ '-' }}</span>            -->
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'UploadingSubTitle',
    computed: {
        ...mapState('sendings', ['uploadStatus']),
        cipherStatus() {
            return this.$store.getters['sendings/GET_CIPHER_STATUS']
        },
        subTitleStyle() {
            if (!this.uploadStatus || this.uploadStatus === 'error' || !this.cipherStatus) return 'color: transparent;'
            return ''
        },
        subtitle() {
            if (!this.cipherStatus && !this.uploadStatus) return this.$i18n.t(`sendings.subtitle.upload-${this.uploadStatus}`)+'...'
            if (this.cipherStatus && this.uploadStatus === 'success') return this.$i18n.t(`sendings.subtitle.cipher-${this.cipherStatus}`)
            if (!this.uploadStatus || this.uploadStatus === 'error' || !this.cipherStatus) return `-`

            return ''
        },
    }
}
</script>

<style lang="scss" scoped>

</style>