<template>
    <div id="send-package" class="dx-card send-package">
        <FormSend>
            <FileUploader />
        </FormSend>
    </div>
</template>

<script>
import FileUploader from './fileUploader'
import FormSend from './formSend'
export default {
    components: {
        FileUploader,
        FormSend
    },
}
</script>

<style lang="scss" scoped>
@import '@/override/themes/go2transfer/variables.scss';
.send-package {
    background-color: $go2transfer-silver-grey-dark;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    //height: 863px;
    border-radius:8px;
}
</style>