<template>
  <div class="locale-changer">
    <select v-model="i18n.locale" @change="onSelectValueChanged">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
    </select>
    <!-- <div class="country">
      <Country scope="languages" :filter="filter" @valueChanged="onValueChanged" :show-clear-button="false" />
    </div> -->
  </div>
</template>

<script>
import module_i18n from '../i18n'
// import {Country} from '@/modules'
export default {
  name: 'locale-changer',
  components: {
    // Country
  },
  data () {
    return { 
      module_i18n,
      code: null,
      langs: ['en', 'fr'] 
    }
  },
  computed: {
    i18n() {
      return this.$i18n ? this.$i18n : this.module_i18n
    },
    // locale() {
    //   const locale = sessionStorage.getItem('locale');
    //   return locale != null && locale != "undefined" ? locale : this.i18n.locale;
    // }
  },
  methods: {
    // setLocale(locale) {
    //   sessionStorage.setItem('locale', locale);
    // },
    onSelectValueChanged(e) {
      // this.setLocale(e.target.value);
      ["valueChanged","value-changed"].forEach(valueChanged => this.$emit(valueChanged, e.target.value));
    },
    // onValueChanged(e,country) {
    //   this.i18n.locale = country.languages.spoken[0].iso639_1
    // },
    filter(country) {
      // if (this.langs.includes(country.languages.spoken[0].iso639_1)) // console.log("filter",country)
      return this.langs.includes(country.languages.spoken[0].iso639_1)
    }
  }
}
</script>

<style lang="scss" scoped>
.country {
  width: 300px;
}
</style>