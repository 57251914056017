import Mock from './Mock'
import Silico from './Silico'
import {
    SENDING_STATUTES
} from './Common'

export {
    Mock,
    Silico,
    SENDING_STATUTES
}

export default {
    Mock,
    Silico,
    SENDING_STATUTES
}