export const list = [{
    title: 'basic',
    list: [{
        value: '100 Mo / fichier',
        active: true
    },{
        value: '50 Go / mois',
        active: true
    },{
        value: 'Assistance technique',
        active: false
    }],
    price: "free"
},{
    title: 'standard',
    list: [{
        value: '250 Mo / fichier',
        active: true
    },{
        value: '500 Go / mois',
        active: true
    },{
        value: 'Assistance technique',
        active: true
    }],
    price: "5€ / month"
},{
    title: 'premium',
    list: [{
        value: 'Taille de fichier illimité',
        active: true
    },{
        value: 'Transfert illimité',
        active: true
    },{
        value: 'Assistance technique',
        active: true
    }],
    price: "12€ / month"
}]

// const currentOption = {
//     option: 'basic',
//     startDate: new Date("01/01/2021").toISOString(),
//     renewDate: null,
// }

// const currentOption = {
//     option: 'standard',
//     startDate: new Date("01/01/2021").toISOString(),
//     renewDate: new Date("01/01/2022").toISOString(),
// }

const currentOption = {
    user: "5b8ccac0-4250-4b26-9fca-804e3a41de49",
    option: 'premium',
    startDate: new Date("01/01/2021").toISOString(),
    renewDate: new Date("01/01/2022").toISOString(),
}

export const current = {
    ...currentOption
}