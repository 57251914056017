<template>
    <dx-scroll-view height="100%" width="100%" class="with-footer common-layout">
        <div :class="cssClass">
          <!-- <div class="header">
            <div class="title">{{title}}</div>
            <div class="description">{{description}}</div>
          </div> -->
          <slot />
        </div>
    </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  },
  props: {
    title: String,
    description: String,
    card: {
      type: Boolean,
      default: () => (false)
    }
  },
  computed: {
      cssClass() {
        let cssClass = {
          "administration-layout-container": true,
          "dx-card": this.card,
          "dx-card-content": this.card
        }

        // console.log('TODO: optimize class')

        return cssClass
      },
  }
};
</script>