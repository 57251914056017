<template>
    <div class="file-uploader-container">
        <div class="file-uploader-container">
            <Uploader />
        </div>
    </div>
</template>

<script>
import Uploader from './uploader';

export default {
    components: {
        Uploader
    }
}
</script>

<style lang="scss">
.file-list-container {
    text-align: center;

   
}

// .file-uploader-container{
//     cursor:pointer;
// }

 
</style>

