const moduleRoute = [{
        path: "/login",
        name: "login",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade',
            labelMode: "floating"
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/login-form")
        },
        props: {
            layout: {
                title: "Sign In"
            },
        }
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade',
            labelMode: "floating"
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/forgot-password-form")
        },
        props: {
            layout: {
                title: "Reset Password",
                description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
            }
        }
    },
    {
        path: "/forgot-password-confirm",
        name: "forgot-password-confirm",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade'
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/forgot-password-confirm")
        },
        props: {
            layout: {
                title: "Reset Password Confirmation",
            }
        }
    },
    {
        path: "/create-account",
        name: "create-account",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade',
            labelMode: "floating"
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/create-account-form")
        },
        props: {
            layout: {
                title: "Sign Up"
            }
        }
    },
    {
        path: "/create-account-confirm",
        name: "create-account-confirm",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade'
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/create-account-confirm")
        },
        props: {
            layout: {
                title: "Create Account Confirmation",
            }
        }
    },
    {
        path: "/confirm/:confirmationToken",
        name: "create-account-confirm-email",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade'
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/create-account-confirm-email")
        },
        props: {
            layout: {
                title: "Create Account Confirmation",
            }
        }
    },
    {
        path: "/reset-password/:resetToken",
        name: "reset-password",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade',
            labelMode: "floating"
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/reset-password-form")
        },
        // props: {
        //     layout: {
        //         title: "reset-password.title",
        //         description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
        //     }
        // }
    },
    {
        path: "/change-password/:recoveryCode",
        name: "change-password",
        meta: {
            requiresAuth: false,
            transition: 'slide-fade'
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/change-password-form")
        },
        props: {
            layout: {
                title: "Change Password"
            }
        }
    },
    {
        path: "/profile",
        name: "profile",
        meta: {
            requiresAuth: true,
            labelMode: "floating"
        },
        components: {
            layout: null,
            content: () => import( /* webpackChunkName: "silico" */ "./views/create-account-form")
        },
        props: {
            content: {
                type: 1
            },
            layout: {
                title: "Profile"
            }
        }
    },
]

const setOptions = {
    apply: (route, options) => {
        setOptions.layout(route, options)
        setOptions.props(route, options)
    },
    layout: (route, options) => {
        route.components.layout = options.components.layout
    },
    props: (route, options) => {
        // // console.log("props.options", route, options)
        route.props = {
            ...route.props,
            ...options.props
        }
    }
}

const checkRouteToken = async (router) => {
    const checkedToken = await router.app.$store.dispatch('authentication-basic/CHECKTOKEN');
    // console.log("checkRouteToken",checkedToken)
    return checkedToken
}

export default (router, options) => {
    // console.trace("auth", router, options)
    options = {
        default: {
            components: {
                layout: () => import( /* webpackChunkName: "silico" */ "./layouts/single-card")
            }
        },
        ...options,
    }
    moduleRoute.forEach(route => {
        const routeOptions = options[route.name] || options.default

        setOptions.apply(route, routeOptions)
        router.addRoute(route)
    })

    const beforeEach = async (to, from, next) => {
        // console.trace("beforeEach authentication-basic")
        const tokenExpiration = await checkRouteToken(router)

        const isAuthenticated = tokenExpiration.success && router.app.$store.getters['authentication-basic/GET_LOGGED_IN']
        const userRoles = router.app.$store.getters['authentication-basic/GET_ROLES']

        // console.log("beforeEach isAuthenticated/userRoles",isAuthenticated, userRoles)

        if (to.name === "login" && isAuthenticated) {
            next({
                name: "home"
            });
            return;
        }

        if (to.matched.some(record => {
                let requiresAuth = record.meta.requiresAuth
                if (userRoles && Array.isArray(userRoles)) {
                    let requiresRoles = record.meta.requiresRoles
                    if (requiresRoles && Array.isArray(requiresRoles)) {
                        const authorizedRole = requiresRoles.some(requiredRole => userRoles.some(userRole => userRole === requiredRole))
                        return requiresAuth && authorizedRole
                    }
                }

                // console.log("requiresAuth", requiresAuth)

                return requiresAuth
            })) {
            if (!isAuthenticated) {
                next({
                    name: "login",
                    query: {
                        redirect: to.fullPath
                    }
                });
                return;
            } else {
                next();
                return;
            }
        } else {
            if (to.meta.unauthorizedRedirect) {
                next({
                    path: to.meta.unauthorizedRedirect
                })
            }
            next();
            return;
        }
    }

    router.beforeEach((to, from, next) => {
        beforeEach(to, from, next)
    })
}