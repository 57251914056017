<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer common-layout">
     <div :class="cssClass">
      <header>
        <!-- <div class="top"></div> -->
        <img class="top" src="/images/top.webp" />
        <slot name="header"/>
      </header>
      <main>
        <slot/>
      </main>  
      <div v-if="displayLeftRight" class="left-right-container">
        <div class="left">
            <slot name="left"/> 
        </div>
        <div class="right">
            <slot name="right"/> 
        </div>
      </div>    
    </div>
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  },
  computed: {
      cssClass() {
        let cssClass = {
          "authentication-layout-container": true,
          "dx-card": true,
          "dx-card-content": true
        }

        // console.log('TODO: optimize class')

        return cssClass
      },
      displayLeftRight() {
          return this.hasLeftSlot && this.hasRightSlot
      },
      hasHeaderSlot() {
          return !!this.$slots.header
      },
      hasLeftSlot() {
          return !!this.$slots.left
      },
      hasRightSlot() {
          return !!this.$slots.right
      }
  }
};
</script>

<style lang="scss">
.top {
    //background: url(/images/top.webp) cover;  
    width: 101%;
    height: auto;
    max-height: 50px;
    object-fit: fill;
    position: relative;
    left: -12px;
}

.left-right-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .left {
        border-right: dotted 0.5px #7e7e7e;
    }

    .left, .right {
        height: 50%;
        width: 50%;
        padding: 50px;
    }
}
</style>
