<template>
    <div :class="classPackageContainer">
        <div :class="classPackage"></div>
        <div class="number">{{number}}</div>
        <div class="title">{{title}}</div>
    </div>
</template>

<script>
export const PackageTypes = {
    UNREAD: 'unread',
    READED: 'readed',
    EXPIRED: 'expired'
}

export const ExpeditionTypes = {
    SENDED: 'sended',
    RECEIVED: 'received'
}

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        number: {
            type: Number,
            required: true
        }
    },
    computed: {
        title() {
            return this.$i18n.t(`stats.package.${this.type}`)
        },
        classPackageContainer() {
            let classPackageContainer = {
                "package-container": true,
            }

            classPackageContainer[`package-container-${this.type}`] = true

            return classPackageContainer
        },
        classPackage() {
            let classPackage = {
                "package": true,
            }

            classPackage[`package-${this.type}`] = true

            return classPackage
        }
    }
}
</script>

<style lang="scss" scoped>
.package {
    &-unread {
        &:before {
            content: url(/images/package-unread.svg);
        }
    }

    &-readed {
        margin-top: -26px;
        &:before {
            content: url(/images/package-readed.svg);
        }
    }

    &-expired {
        &:before {
            content: url(/images/package-expired.svg);
        }
    }
}

.package-container {
    font-family: 'LatoWeb';
    text-align: center;
    padding: 15px 34px 0 34px;
    
    &:first-child {
        margin-left: 100px;
    }

    &:last-child {
        margin-right: 50px;
    }

    &-unread {
        color: black;
    }

    &-readed {
        color: white;
    }

    &-expired {
        color: #AAAAAA;
    }

    .number {
        font-size: 39px;
        line-height: 30px;
        padding-top: 16px;
        font-family: LatowebBold;
        padding-bottom: 4px;
    }

    .title{
       font-size: 17px;
        line-height: 18px;
    }
}
</style>