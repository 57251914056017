<template>
    <div class="send-container">
        <!-- <DropZone :full-parent="true" @dropped-files="onDropedFiles"/> -->
        <div class="send-left-layout">
            <LeftContainer />
        </div>
        <div class="send-right-layout">
            <RightContainer />
        </div>
    </div>
</template>

<script>
import { custom } from 'devextreme/ui/dialog';
import { mapState } from 'vuex' 
// import { DropZone } from '@/components/send/dropZone'
import LeftContainer from '@/components/send/left'
import RightContainer from '@/components/send/right'
export default {
    components: {
        // DropZone,
        LeftContainer,
        RightContainer
    },
    data() {
        return {
            confirmIsShowing: false
        }
    },
    methods: {
        onDropedFiles(files) {
            let newFiles = [...this.$store.state.sendings.files, ...files]
            this.$store.commit('sendings/SET_FILES',[...newFiles])
        }
    },
    computed: {
        ...mapState('sendings',['envelope', 'files','uploadStatus']),
        cipherStatus() {
            return this.$store.getters['sendings/GET_CIPHER_STATUS']
        },
        customDialogOptions() {
            return {
                showTitle: false,
                messageHtml: this.$i18n.t('send.cancel-confirm-message'), 
                buttons: [{
                    text: this.$i18n.t('send.yes'),
                    elementAttr: {
                        class: "btn-yes"
                    },
                    onClick: () => (true)
                },
                {
                    text: this.$i18n.t('send.no'),
                    elementAttr: {
                        class: "btn-no"
                    },
                    onClick: () => (false)
                },]
            }
        },
    },  
    beforeRouteLeave(to, from, next) {
        if (this.uploadStatus === 'success' && this.cipherStatus) {
            this.$store.dispatch('sendings/RESET')
            next(to)
        }
        if (!this.confirmIsShowing) {
            if (this.envelope || this.files.length) {
                this.confirmIsShowing = true
                const result = custom(this.customDialogOptions).show()
                result.done(async (dialogResult) => {
                    this.confirmIsShowing = false
                    if (dialogResult) {
                        this.$store.dispatch('sendings/RESET')
                        next(to) 
                    }
                });
            }
            else next()
        }
    }
}
</script>

<style lang="scss" scoped>
.send-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    
    // padding-top: 100px;

    // .dx-card {
    //     height: 100%;
    // }

    .send-left-layout,
    .send-right-layout {
        min-height: 863px;
        max-height: 863px;
    }

    .send-left-layout {
        // width: 30vw;
        // height: 70vh;
        min-width: 630px;
        max-width: 630px;  
        padding:30px 20px 20px 30px;      
    }

    .send-right-layout {
        // width: 30vw;
        // height: 70vh;

        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 930px;
    }
}


@media screen and (max-width: 1450px) {
  .send-container {
    flex-wrap: wrap;
  }
} 


</style>