const dataSources = require('./dataSources')

module.exports = {
    getAddress() {
        return new Promise((resolve) => {
            // console.log('[MOCK getAddress]')
            resolve({ success: true, address: {...dataSources.address}})
        })
    },

    updateAddress(payload) {
        return new Promise((resolve) => {
            // console.log('[MOCK updateAddress]',payload)
            setTimeout(() => {
                resolve({ success: true, address: {...payload}})
            }, 2000)
        })
    },
}