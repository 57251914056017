import store from './store'
import router from './router'
import i18n from './i18n'
import locales from './locales'
import Logout from './views/logout'
import { APIUsers } from './SDK'
import { formTypes, createStates } from './types'

export { 
    i18n,
    locales,
    store,
    router,
    Logout,
    APIUsers,
    formTypes, 
    createStates,
}

export default {
    i18n,
    locales,
    store,
    router,
    preserveState: false,
    Logout,
    APIUsers,
    formTypes, 
    createStates,
}