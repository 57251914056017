import "./themes/go2transfer/go2transfer.scss"

// import { layouts as commonLayouts } from './common'
import { layouts as authenticationBasicLayouts , slots as authenticationBasicSlots , /*forms,*/ modulesOptions as authenticationBasicOptions } from './authentication'
import { modulesOptions as administrationOptions } from './administration'

const modulesOptions = {
    // ...commonLayouts,
    ...authenticationBasicOptions,
    ...administrationOptions
}

const layouts = {
    ...authenticationBasicLayouts
}

const slots = {
    ...authenticationBasicSlots   
}

export { layouts, slots, /*forms,*/ modulesOptions }

export default { layouts, slots, /*forms,*/ modulesOptions }