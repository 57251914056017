<template>
    <div class="btn-download file-download-button-container" :key="`file-download-button-${file.uuid}`" :title="`download-envelope-${sending.uuid}-file-${file.uuid}`" @click="onDownloadClick">
        <img v-if="showDownloadButton" :key="`file-download-img-${file.uuid}-${taskStatus}`" src="/images/download.svg"/>
        <BreedingRhombusSpinner
            v-else-if="showLoading"
            :key="`spinner-${file.uuid}-${taskStatus}`"
            :animation-duration="1000"
            :size="20"
            color="black"
        />
        <DxProgressBar 
            v-else-if="showProgressBar"
            :key="`file-download-${file.uuid}-${taskStatus}`"
            :show-status="false"
            :min="0"
            :max="file.total"
            :value="file.progress"
            width="100%"
        />
        <img v-else-if="showDownloaded" :key="`file-downloaded-img-${file.uuid}-${taskStatus}`" :src="`/images/checked.svg`" width="25" />
        <img v-else :key="`file-save-img-${file.uuid}-${taskStatus}`" :src="`/images/save.svg`" width="25" />
    </div>
</template>

<script>
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { BreedingRhombusSpinner } from 'epic-spinners'
import { mapActions, mapState } from 'vuex';
import { useMachine } from 'xstate-vue2'

export default {
    name: 'FileDownloadButton',
    components: {
        DxProgressBar,
        BreedingRhombusSpinner,
    }, 
    props: {
        rowIndex: {
            type: Number,
            required: true
        },
        sending: {
            type: Object,
            required: true
        },
        file: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            downloadMachine: null,
            taskStatus: null,
        }
    },
    computed: {
        ...mapState(['downloadMachines']),
        showDownloadButton() {
            return this.taskStatus === null
        },
        showLoading() {
            return ['idle', 'decipher', 'sleepStatus', 'getStatus', 'getDownloadUrl'].indexOf(this.taskStatus) >= 0
        },
        showProgressBar() {
            return this.taskStatus === 'download'
        },
        showDownloaded() {
            return this.taskStatus === 'localDownloaded'
        },
        fileUUID() {
            return this.file.uuid
        },
        sendingUUID() {
            return this.sending.uuid
        },
    },
    methods: {
        ...mapActions('sendings', ['START_DOWNLOADING_TASK']),
        async onDownloadClick() {
            // // console.log(file)
            if (!this.file.fileBLOB) {
                delete this.file.progress
                delete this.file.total
                delete this.file.decipherStatus

                const fileNAME = this.file.name
                this.file.decipherStatus = 'NONE'
                await this.START_DOWNLOADING_TASK({ sending: this.sending, sendingUUID: this.sendingUUID, file: this.file, fileUUID: this.fileUUID, fileNAME }) 

                this.downloadMachine = this.downloadMachines && this.downloadMachines.find(machine => machine.context.sendingUUID === this.sendingUUID && machine.context.fileUUID === this.fileUUID) || null
                if (this.downloadMachine) {
                    this.downloadMachine = useMachine(this.downloadMachine)
                    this.downloadMachine.service.onTransition((state) => {
                        this.taskStatus = state.value
                    })
                }
            }
            else {
                var fileURL = window.URL.createObjectURL(this.file.fileBLOB);
                var fileLink = document.createElement('a');
            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.file.name);
                document.body.appendChild(fileLink);
            
                fileLink.click();

                setTimeout(() => {
                    if (this.downloadMachine) {
                        // console.log(this.downloadMachine)
                        this.downloadMachine.send("SAVE_ON_DISK")
                        this.downloadMachine = null
                    }
                }, 2000)
            }   
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/override/themes/go2transfer/variables.scss';

.file-download-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    min-height: 20px;   

    & img {
        cursor: pointer;
    }

    & .dx-progressbar-container {
        background-color: #ff4e4e57;
        border-radius: 2px;

        & .dx-progressbar-animating-container {
            background-image: linear-gradient(-90deg,transparent 5%,$go2transfer-red,transparent 95%);
        }

        & .dx-progressbar-range {
            background-color: $go2transfer-red;
            border-radius: 2px;
        }
    }

    & .breeding-rhombus-spinner {
        transform: rotate(45deg) translateY(-20px);
    }
}
</style>