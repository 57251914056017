const axios = require('axios')

// const client = axios.create({
//     baseURL: 'https://api.silico.fr/country'
// })
// 'https://api.silico.fr/country'

const Country = {
    client: axios.create(),
    create: (options) => {
        if (options && options.endpoint) Country.client.defaults.baseURL = options.endpoint
    },
    async getCountryList(options) {
        // console.log("[PROD getCountryList]",options)
        options = options || {}
        if (!options.params) {
            options.params = {
                scope: 'default'
            }  
        }
        return await Country.client.get('/', options).then(res => ({success: true, list: res.data}))
    },
}

module.exports = Country