import Mock from './Mock'
import Country from './Silico'
import Config from '@/config.json'

var APICountry = Mock;
if (Config && Config["country"] && Config["country"].endpoint) 
{    
    Country.create({
        ...Config["country"]   
    })
    APICountry = Country
}

export { APICountry }

export default {
    APICountry
}

// export { Mock, Silico }

// export default {
//     Mock, Silico
// }