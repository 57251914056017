const dataSources = require('./dataSources')
const { addDate, dateDiffDay } = require('../Common')
const { v4: uuidv4 } = require('uuid');
const axios = require('axios')

const API = {
    getSendingList() {
        return new Promise((resolve) => {
            // console.log('[MOCK getSendingList]')
            const response = { success: true, data: [...dataSources.sendings].map(sending => {
                sending.send_on = new Date(sending.send_on)
                sending.end_date = addDate(sending.send_on,sending.send_duration)
                sending.remaining_time = dateDiffDay(new Date(),sending.end_date)   
                return sending
            })}
            resolve(response)
        })
    },

    submitSending(envelope) {
        return new Promise((resolve) => {
            // console.log('[MOCK submitSending]')
            const response = { 
                success: true,
                data: {
                    uuid: uuidv4(), 
                    ...envelope, 
                    recipients: [...envelope.recipients.map(rec => {
                        rec.uuid = uuidv4()
                        return rec
                    })],
                    files: [...envelope.files.map(file => {
                        file.uuid = uuidv4()
                        file.upload_url = "https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
                        file.download_url = "https://go2transfer-public.s3.eu-west-3.amazonaws.com/envelopes/out/af666837-0e90-7be5-6813-1d7d4cc15b45?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIA4A5KJWTJE7QHZH7Q%2F20220217%2Feu-west-3%2Fs3%2Faws4_request\u0026X-Amz-Date=20220217T210708Z\u0026X-Amz-Expires=14400\u0026X-Amz-SignedHeaders=host\u0026response-content-disposition=attachment%3B%20filename%20%3Dhugo.txt\u0026X-Amz-Signature=aee6aa8b72a0c6bda5365cba6f6adc000cf53be2169f8f98192dec7f7591e8b7"

                        return file
                    })]
                }
            }
            resolve(response)
        })
    }, 

    uploadFiles(envelope) {
        return Promise.all(envelope.files.map(file => API.uploadFile(file)))   
    },

    uploadFile(file) {
        return axios.post(file.upload_url,file)
    }
}

module.exports = API