const axios = require('axios')
const defaultConfig = {
    baseURL: null,
    URI: {
        address: '/address',
    }
}

function processResponse(response) {
    return response.data
}

const Addresses = {
    client: axios.create(),
    create: (options) => {
        if (options && options.endpoint) Addresses.client.defaults.baseURL = options.endpoint
    },
    async getAddress(options) {
        return await Addresses.client.get(defaultConfig.URI.address,options).then(resp => processResponse(resp))
    },

    async updateAddress(address, options) {
        return await Addresses.client.put(defaultConfig.URI.address, address, options).then(resp => processResponse(resp))
    },

    async createAddress(address, options) {
        return await Addresses.client.post(defaultConfig.URI.address, address, options).then(resp => processResponse(resp))
    },
}

module.exports = Addresses