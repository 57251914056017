import { REVERSED_SENDING_STATUTES } from "../../SDK/Common/constantes" 

export const sum = arr => arr.reduce((pv, cv) => pv + cv,0)

export const isFileDownloaded = (downloadedFileId,files) => files && files.some(file => file.id === downloadedFileId)

export const isFileDownloadedByStatusAndRecipientIdAndFileId = (status, recipientId, fileId) => status.recipient_id === recipientId && isFileDownloaded(fileId,status.files)

export const isFileDownloadedByStatutesAndRecipientIdAndFileId = (statutes, recipientId, fileId) => (statutes && statutes.filter(status => isFileDownloadedByStatusAndRecipientIdAndFileId(status,recipientId,fileId)).length > 0)

export const findDownloadedFileByStatutesAndRecipientIdAndFileId = (statutes, recipientId, fileId) => statutes.find(status => status.recipient_id === recipientId && status.files.find(file => file.id === fileId))

export const getFileDownloadedAt = (statutes, recipientId, fileId) => { 
    const foundFile = findDownloadedFileByStatutesAndRecipientIdAndFileId(statutes, recipientId, fileId)
    const downloadedFile = foundFile && foundFile.files && foundFile.files.find(file => file.id === fileId)
    return downloadedFile && downloadedFile.downloaded_at
}

export const areFilesDownloadedByStatutesAndRecipientId = (files, statutes, recipientId) => files && files.map(file => ({ ...file, downloaded_at: getFileDownloadedAt(statutes,recipientId,file.id), downloaded: isFileDownloadedByStatutesAndRecipientIdAndFileId(statutes,recipientId,file.id)}))

export const areFilesDownloadedByStatutesAndRecipients = (files, statutes, recipients) => recipients && recipients.map(recipient => ({ ...recipient, files: areFilesDownloadedByStatutesAndRecipientId(files, statutes, recipient.id) }))

export const areAllFilesDownloaded = (files, statutes, recipients) => (recipients.length === statutes.length && sum(statutes.map(status => status.files.length))/statutes.length === files.length)

export const areAllFilesDownloadedData = (files, statutes, recipients) => ({downloaded: areAllFilesDownloaded(files, statutes, recipients)})

export const nbDownloadedFileByStatus = (fileId, status) => status.files.filter(f => f.id === fileId).length

export const countDownloadedFileByStatutes = (fileId, statutes) => sum(statutes.map(status => nbDownloadedFileByStatus(fileId, status)))

export const precentage = (num,per) => Math.floor((num*100)/per)


export const prepareResultRows = (rows) => rows.map(sending => ( {expanded: false, expired: sending.remaining_time<=0, ...areAllFilesDownloadedData(sending.files, sending.statutes, sending.recipients), ...sending, "status-text": REVERSED_SENDING_STATUTES[sending.status] } ))