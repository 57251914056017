<template>
  <div class="receivings-container">
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <DxDataGrid 
          :data-source="receivings"
          ___data-row-template="dataRowTemplate"
          :load-panel="{ enable: false}"
          @toolbar-preparing="onToolbarPreparing"
          @initialized="onReceivingsInitialized"
        >
          <dx-paging :page-size="4" />
          <dx-pager :show-page-size-selector="false" :show-info="false" />
          <dx-sorting />

          <template #titleTemplate>
            <div class="title-container">
              <div class="title">{{ $i18n.t('menu.received') }}</div>
            </div>
          </template>

          <template #refreshTemplate>
            <DxButton
              icon="refresh"
              @click="refreshDataGrid"
            />
          </template>

          <DxColumn data-field="snd_sender_email" cssClass="col col-sender" alignment="left" :caption="$i18n.t('receivings.columns.sender')"/>
          <DxColumn data-field="mail" width="100" cssClass="col col-mail" alignment="center" :caption="$i18n.t('receivings.columns.mail')" cell-template="mailTemplate"/>
          <DxColumn
              data-field="send_on"
              _type="datetime"
              alignment="left"
              cssClass="col col-send-on"
              :caption="$i18n.t('receivings.columns.send_on')"
              format="yyyy/MM/dd"
              width="120"
              :allow_sorting="true"
          />
          <DxColumn alignment="left" width="390" cssClass="col col-filename" :caption="$i18n.t('receivings.columns.filename')" cell-template="fileNameTemplate"/>
          <DxColumn alignment="left" width="190" cssClass="col col-filesize" :caption="$i18n.t('receivings.columns.filesize')" cell-template="fileSizeTemplate"/>
          <DxColumn alignment="left" width="150" cssClass="col col-download" :caption="$i18n.t('receivings.columns.download')" cell-template="internalFileDownloadTemplate"/>
          <DxColumn data-field="remaining_time" alignment="right" width="220" cssClass="col col-remaining_time" :caption="$i18n.t('sendings.columns.remaining_time')" cell-template="remainingTimeTemplate"/>

          <template #fileNameTemplate="{ data }">
            <div class="file-template file-name-template">
              <div v-for="file in data.data.files" :key="`file-name-${file.uuid}`" class="file-name">{{ file.name }}</div>
            </div>
          </template>

          <template #fileSizeTemplate="{ data }">
            <div class="file-template file-size-template">
              <div v-for="file in data.data.files" :key="`file-size-${file.uuid}`" class="file-size">{{ formatBytes(file.size) }}</div>
            </div>
          </template>

          <template #fileDownloadTemplate="{ data: cellData }">
            <FileDownloadTemplate v-if="!data.data.expired" :key="`download-cell-${cellData.rowIndex}-${cellData.data.updateKey}`" :cell-data="cellData" /> 
          </template>

          <template #internalFileDownloadTemplate="{ data }">
            <div v-if="!data.data.expired" class="file-template file-download-template">
                <!-- <div v-for="file in data.data.files" :key="`file-download-${file.uuid}`" class="btn-download" :title="`download-envelope-${data.data.uuid}-file-${file.uuid}`" @click="onDownloadClick(data.data.uuid,file, data.data)">
                    <img v-if="!file.progress && !file.decipherStatus" :key="`file-download-img-${file.uuid}-${getTaskStatus(data.data.uuid, file.uuid)}`" src="/images/download.svg"/>
                    <BreedingRhombusSpinner
                        v-else-if="!file.progress && file.decipherStatus && file.decipherStatus !== 'ENDED'"
                        :key="`spinner-${file.uuid}-${getTaskStatus(data.data.uuid, file.uuid)}`"
                        :animation-duration="1000"
                        :size="20"
                        color="black"
                    />
                    <DxProgressBar 
                      v-else-if="file.progress !== file.total"
                      :key="`file-download-${file.uuid}-${getTaskStatus(data.data.uuid, file.uuid)}`"
                      :show-status="false"
                      :min="0"
                      :max="file.total"
                      :value="file.progress"
                      width="100%"
                    />
                    <img v-else-if="getTaskStatus(data.data.uuid, file.uuid) === 'localDownloaded'" :key="`file-downloaded-img-${file.uuid}-${getTaskStatus(data.data.uuid, file.uuid)}`" :src="`/images/checked.svg`" width="25" />
                    <img v-else :key="`file-save-img-${file.uuid}-${getTaskStatus(data.data.uuid, file.uuid)}`" :src="`/images/save.svg`" width="25" />
                </div> -->
                <div v-for="file in data.data.files" :key="`file-download-${file.uuid}`" class="btn-download" :title="`download-envelope-${data.data.uuid}-file-${file.uuid}`" @click="onDownloadClick(data.data.uuid,file, data.data)">
                    <img v-if="!file.stateValue" :key="`file-download-img-${file.uuid}-${file.stateValue}`" src="/images/download.svg"/>
                    <BreedingRhombusSpinner
                        v-else-if="['idle', 'decipher', 'sleepStatus', 'getStatus', 'getDownloadUrl'].indexOf(file.stateValue) >= 0"
                        :key="`spinner-${file.uuid}-${file.stateValue}`"
                        :animation-duration="1000"
                        :size="20"
                        color="black"
                    />
                    <DxProgressBar 
                      v-else-if="file.stateValue === 'download'"
                      :key="`file-download-${file.uuid}-${file.stateValue}`"
                      :show-status="false"
                      :min="0"
                      :max="file.total"
                      :value="file.progress"
                      width="100%"
                    />
                    <img v-else-if="file.stateValue === 'localDownloaded'" :key="`file-downloaded-img-${file.uuid}-${file.stateValue}`" :src="`/images/checked.svg`" width="25" />
                    <img v-else :key="`file-save-img-${file.uuid}-${file.stateValue}`" :src="`/images/save.svg`" width="25" />
                </div>
            </div>
            <div v-else class="file-template file-download-template">
              <div v-for="file in data.data.files" :key="`file-download-${file.uuid}`" class="btn-download" :title="`download-envelope-${data.data.uuid}-file-${file.uuid}`">
                <img src="/images/error.svg"/>
              </div>
            </div>
          </template>

          <template #mailTemplate="{ data }">
            <div class="mail-template">
              <RowMailTemplate :template-data="data" />
            </div>
          </template>

          <template #remainingTimeTemplate="{ data }">
            <row-remaining-time :sending="data.data" />
            <!-- <div v-if="!data.data.expired" class="cell-container">
              <div class="col-content">{{ data.data.remaining_time}}j</div>
              <Progress class="col-progress-value" :radius="15" :strokeWidth="3" strokeColor="#434343" :value="(data.data.send_duration-data.data.remaining_time)*100/data.data.send_duration">
                <div></div>
              </Progress>
            </div>
            <div v-else class="cell-container cell-expired">{{$i18n.t('stats.package.expired')}}</div> -->
          </template>

        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { BreedingRhombusSpinner } from 'epic-spinners'
// import { interpret } from "xstate";
// import { Silico as API } from '../SDK'
import { sizes } from '@/modules'
// import DataSource from 'devextreme/data/data_source';
import {
  DxDataGrid,
  DxColumn,
  // DxToolbar,
  // DxItem,
  // DxSearchPanel,
  // DxMasterDetail,
  DxPager,
  DxPaging,
  DxSorting,
} from 'devextreme-vue/data-grid'
import { DxButton } from 'devextreme-vue/button';
import RowMailTemplate from '@/components/sendings/RowMailTemplate.vue';
import FileDownloadTemplate from '../components/receivings/fileDownloadTemplate.vue';
import RowRemainingTime from '../components/sendings/RowRemainingTime.vue';
import { mapActions, mapMutations, mapState } from 'vuex'
// import { useInterpret } from 'xstate-vue2'
import { interpret } from 'xstate'

export default {
  name: 'Receiving',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxPager,
    DxPaging,
    DxSorting,
    // DxToolbar,
    // DxItem,
    DxProgressBar,
    BreedingRhombusSpinner,
    RowMailTemplate,
    FileDownloadTemplate,
    RowRemainingTime,
  },
  data() {
    return {
      datagrid: null,
    }
  },
  computed: {
    ...mapState(['downloadMachines']),
    ...mapState('sendings', ['receivings']),
    // tasks() {
    //     return this.downloadMachines ? this.downloadMachines.map(machine => {
    //       const m = useMachine(machine)
    //       m.service.onTransition((state) => {
    //         // console.log("=====================================>",file,state.value)
    //       })          
    //       return m
    //     }) : []
    // },
  },
  beforeDestroy() {
    if (this.downloadMachines && this.downloadMachines.length > 0) this.downloadMachines.forEach(downloadMachine => this.REMOVE_DOWNLOAD(downloadMachine._context))
    
  },
  methods: {
    ...mapActions('sendings', ['FETCH_RECEIVINGS','START_DOWNLOADING_TASK']),
    ...mapMutations(['REMOVE_DOWNLOAD']),
    refreshDataGrid() {
      this.FETCH_RECEIVINGS()
    },
    sortByDate(data) {
      console.log(data)
      return 1
      // return new Date(data.send_on)
    },
    // getTask(sendingUUID, fileUUID) {
    //   return this.tasks.find(task => task.state.value.context.sendingUUID === sendingUUID && task.state.value.context.fileUUID === fileUUID)
    // },
    // getTaskStatus(sendingUUID, fileUUID) {
    //   const task = this.getTask(sendingUUID, fileUUID)
    //   return task && task.service && task.service._state && task.service._state.value ? task.service._state.value : 'null'
    // },
    async onDownloadClick(sendingUUID, file, sending) {
      // // console.log(file)
      const fileUUID = file.uuid

      if (!file.fileBLOB) {
        delete file.progress
        delete file.total
        delete file.decipherStatus

        const fileNAME = file.name
        file.decipherStatus = 'NONE'
        await this.START_DOWNLOADING_TASK({ sendingUUID, fileUUID, fileNAME, file, sending }) 
        if(this.downloadMachines) {
          this.downloadMachines.find(machine => {
            if (machine._context.sendingUUID === sendingUUID && machine._context.fileUUID === fileUUID) {
              file.machine = interpret(machine) //useInterpret(machine)
              file.total = 0
              file.progress = 0

              file.machine.onTransition((state) => {
                file.stateValue = state.value
                const newFiles = sending.files.map(f => {
                    if (f.uuid === file.uuid) return file
                    return f
                })

                sending.files = [...newFiles]
              }).start()

              return true
            }
            return false
          })
        }
      }
      else {
        var fileURL = window.URL.createObjectURL(file.fileBLOB);
        var fileLink = document.createElement('a');
    
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file.name);
        document.body.appendChild(fileLink);
    
        fileLink.click();

        setTimeout(() => {
          if (file.machine) file.machine.send("SAVE_ON_DISK")
          // const task = this.getTask(sendingUUID,fileUUID)
          // if (task) {
          //   // console.log(task)
          //   task.send("SAVE_ON_DISK")
          // }
        }, 2000)
      }   
    },
    formatBytes: (value) => sizes.formatBytes(value),
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: "titleTemplate"
      })
      e.toolbarOptions.items.push({
        location: "after",
        template: "refreshTemplate"
      })
    },
    onReceivingsInitialized(event) {
      this.datagrid = event.component

      this.refreshDataGrid()
    },
  },
}
</script>

<style lang="scss" scope>
@import '@/override/themes/go2transfer/variables.scss';

.receivings-container {
  height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: center;

  .content-block {
    .dx-card {
      height: 80vh;
      background-color: #fffffffa !important;

      .dx-datagrid{
        background-color: transparent !important;
      }
      .dx-datagrid-header-panel{
        background-color: transparent !important;
      }
    } 

    .col-filename{
      padding:15px 0;

    }
    .col-filesize{
      padding:15px 0;
    }
    .col-download{
      padding:15px 0;
    }

  }

  .dx-datagrid-header-panel {
    .title:before {
      content: url(/images/receivings.svg);
    }
  }

  .dx-datagrid-rowsview .dx-datagrid-content {
    .col {
      &.col-remaining_time {
        border: none;
        
        .col-progress-value {
          margin-left: 8px;
        }

        .cell-container {
          font-family: "LatoWebThin";
          font-size: 19px;
          display: flex;
          justify-content: center;
          align-items: center;
          // height: 36px;
        }
      }
    }
  }

  .file-template {
    .btn-download {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; 
      padding:5px 16px 5px 10px;   
      height: 35px; 

      & img {
          cursor: pointer;
          max-height: 20px;
          transition: 0.2s ease;
          position: relative;
          left: 0px; 
      }

      &:hover{
         background: #f0f0f0;

         img{
          left: 3px;         
          transition: 0.2s ease;
         }
      }

      & .dx-progressbar-container {
          background-color: #ff4e4e57;
          border-radius: 2px;

          & .dx-progressbar-animating-container {
              background-image: linear-gradient(-90deg,transparent 5%,$go2transfer-red,transparent 95%);
          }

          & .dx-progressbar-range {
              background-color: $go2transfer-red;
              border-radius: 2px;
          }
      }

      & .breeding-rhombus-spinner {
          transform: rotate(45deg) translateY(-20px);
          top: 13px;
          left: -14px;
      } 
        &:nth-child(even){
            border-top:1px dashed #777;
            border-bottom:1px dashed #777;
            }
        &:last-child{
            border-bottom:0px;
            }

    }

    .file-name{
      padding:5px 16px 5px 10px;
      height: 35px;
        &:nth-child(even){
            border-top:1px dashed #777;
            border-bottom:1px dashed #777;
            }
        &:last-child{
            border-bottom:0px;
            }

    }
    .file-size{
      padding:5px 16px 5px 10px;
      height: 35px;
        &:nth-child(even){
            border-top:1px dashed #777;
            border-bottom:1px dashed #777;
            }
        &:last-child{
            border-bottom:0px;
            }
 
    }

    & div {
      margin: 0;

    }
  }
}
</style>