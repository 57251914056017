import store from './store'
// import router from './router'
import i18n from './i18n'
import locales from './locales'
import Country from './views/country'
import { APICountry } from './SDK'

export { 
    i18n,
    locales,
    store,
    // router,
    Country,
    APICountry,
}

export default {
    i18n,
    locales,
    store,
    // router,
    Country,
    preserveState: false,
    APICountry,
}