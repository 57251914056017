<template>
    <Common 
        v-if="columns.length > 1"
        title="LOCALE"
        edit-mode="batch"
        key-expr="key"
        :allow-updating="true" 
        :data-source="localeMessages"
        :columns="columns"
        @saving="onSaving"
    />
</template>

<script>
const dot = require('dot-object');
import { updateLocaleMessage } from '@/i18n'
import Common from './common.vue'

export default {
    components: {
        Common,
    },
    computed: {
        columns() {
            let columns = [{
                dataField: 'key',
                allowEditing: false
            }]

            Object.keys(this.$i18n.messages).forEach(locale => {
                columns.push({dataField: locale})  
            })

            return columns
        },
        localeMessages() {
            let localeMessages = []
            let keys = []

            let tempoLocale = []
            Object.keys(this.$i18n.messages).forEach(locale => {
                const dottedLocale = dot.dot(this.$i18n.messages[locale])

                // // console.log("dottedLocale", Object.keys(dottedLocale).length, dottedLocale)

                keys = [...Object.keys(dottedLocale)]

                let data = {}
                data = {...dottedLocale}
                data.locale = locale
                tempoLocale.push(data)
            })

            // // console.log(keys)

            keys.forEach(key => {
                let result = {}
                result.key = key
                tempoLocale.forEach(tempo => {
                    result[tempo.locale] = tempo[key]
                })
                localeMessages.push(result)
            })

            // // console.log(keys,localeMessages, tempoLocale)

            return localeMessages.sort((a,b) => a.key < b.key ? -1 : a.key > b.key ? 1 : 0)         
        },
        reversedLocaleMessage() {
            let reversedLocaleMessage = {
                messages: {}
            }
            const localeMessages = [...this.localeMessages]
            // const keys = localeMessages.map(localeMessage => localeMessage.key)

            localeMessages.forEach(localeMessage => {
                Object.keys(localeMessage).forEach(key => {
                    if (key !== 'key') {
                        reversedLocaleMessage.messages[key] = reversedLocaleMessage.messages[key] || {}
                        reversedLocaleMessage.messages[key][localeMessage['key']] = localeMessage[key]
                    }
                })  
            })

            // Object.keys(reversedLocaleMessage.messages).forEach(key => {
            //     reversedLocaleMessage.messages[key] = dot.object(reversedLocaleMessage.messages[key])   
            // })

            return reversedLocaleMessage
        }
    },
    methods: {
        async onSaving(e) {
            // console.log("onSaving",e)
            e.cancel = false

            const changes = e.changes && e.changes.length > 0 && [...e.changes]

            if (changes) {
                const reversedLocaleMessage = {...this.reversedLocaleMessage}

                changes.forEach(change => {
                    let key = change.key
                    let data = null
                    let locale = null
                    Object.keys(change.data).forEach(loc => {
                        locale = loc
                        data = change.data[loc]
                    })

                    if (data && locale && key) {
                        reversedLocaleMessage.messages[locale][key] = data
                    }
                })

                Object.keys(reversedLocaleMessage.messages).forEach(key => {
                    reversedLocaleMessage.messages[key] = dot.object(reversedLocaleMessage.messages[key])   
                })

                updateLocaleMessage(reversedLocaleMessage,this.$i18n)
            }            
        },
    },
}
</script>