<template>
    <div class="uploading-title">
    <!-- <div class="uploading-title" is="transition-group" name="fade" mode="out-in"> -->
        <transition name="fade" mode="out-in">
            <div class="title-content" :key="title">
                {{ title }} 
                <BreedingRhombusSpinner
                    v-if="(!cipherStatus && !uploadStatus) || (uploadStatus && cipherStatus === 'STARTED')"
                    :key="`spinner-${title}`"
                    :animation-duration="1000"
                    :size="32"
                    color="black"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { BreedingRhombusSpinner } from 'epic-spinners'

export default {
    name: 'UploadingTitle',
    components: {
        BreedingRhombusSpinner
    },
    computed: {
        ...mapState('sendings', ['uploadStatus']),
        cipherStatus() {
            return this.$store.getters['sendings/GET_CIPHER_STATUS']
        },
        title() {
            if (!this.cipherStatus && !this.uploadStatus ) return `${this.$i18n.t('sendings.title.upload-in-progress')}...`
            if (!this.cipherStatus && this.uploadStatus ) return this.$i18n.t(`sendings.title.upload-${this.uploadStatus}`)
            if (this.uploadStatus && this.cipherStatus) return `${this.$i18n.t(`sendings.cipher-${this.cipherStatus}`)}${this.cipherStatus === 'STARTED' ? '...' : ''}`

            return "UNKNOW"
        },
    }
}
</script>

<style lang="scss" scoped>
.uploading-title {
    font-size: 31px;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .title-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .breeding-rhombus-spinner {
        margin-left: 25px;
    }
}
</style>