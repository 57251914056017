<template>
  <Common class="reset-password">
    <template v-slot:left>
        <Logo />
    </template>
    <template v-slot:right>
      <div class="reset-password-container">
          <div class="title">{{ t('authentication-basic.reset-password.title') }}</div>      
          <slot/>
      </div>
    </template>
  </Common>
</template>

<script>
import Common from "./common";
import Logo from './default/logo'
export default {
  components: {
    Common,
    Logo
  },
  data() {
    return {}
  },
  methods: {
    t(value) {
      return this.$i18n.t(value)
    },
  }
};
</script>