<template>
    <div class="card">
        <div class="card-title">{{ title && title != '' ? i18n.t(`subscription.options.${title}`) : '' }}</div>
        <div class="card-list">
            <ul>
                <li :class="item.active ? 'active' : ''" v-for="(item, index) in list" :key='index'>
                    {{ item.value }}
                </li>
            </ul>
        </div>
        <div class="card-price">{{ price }}</div>
        <DxButton
            v-if="!me"
            :element-attr="{
                class: 'card-button'
            }"
            :text="i18n.t('subscription.change-option')"
            width="100%"
            @click="onOptionChanged"
        />
    </div>
</template>

<script>
import module_i18n from '../i18n'
import DxButton from 'devextreme-vue/button'

export default {
    components: {
        DxButton,
    },
    props: ['title','list','price'],
    data() {
        return {
            module_i18n,
        }
    },
    computed: {
        me() {
            return this.current.option === this.title
        },
        loading() {
            if (this.showLoader)
                return this.$store.state['subscription'].loading

            return false
        },
        current() {
            return this.$store.getters['subscription/GET_CURRENT_SUBSCRIPTION']
        },
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        }
    },
    methods: {
        onOptionChanged(){
            this.showLoader = true
            this.$store.dispatch('subscription/UPDATE_CURRENT_SUBSCRIPTION',{
                option: this.title
            })   
        }
    },
}
</script>