<template>
  <div class="list">
    <h2 v-if="title" class="content-block">{{ title }}</h2>

    <slot name="beforeGrid"></slot>

    <dx-data-grid
      :data-source="dataSource"
      :focused-row-index="0"
      :show-borders="false"
      :show-column-headers="false"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :show-row-lines="true"
      :height="height"
      @row-removing="removingRow"
    >
      <dx-paging :enabled="paging" :page-size="5" />
      <dx-pager v-if="paging" :show-page-size-selector="false" :show-info="false" />
      <dx-filter-row :visible="false" />

      <slot />

      <template #status-cell-template="{ data }">
          <div :class="`file-is-downloaded file-is-downloaded-${data.row.data.status}`">&nbsp;</div>
      </template>

      <template #statutesTemplate="{ data }">
        <div class="statutes-template">
          <RowStatusTemplate :template-data="data" />
        </div>
      </template>

      <template #loaded-cell-template="{ data }">
          <div class="loaded-cell">
              <DxProgressBar
                  v-if="data.row.data.loaded <= data.row.data.size"
                  :show-status="false"
                  :min="0"
                  :max="data.row.data.size"
                  :value="data.row.data.loaded"
                  width="100%"
              />
              <!-- <div v-if="data.row.data.loaded <= data.row.data.size">{{data.row.data.loaded}} / {{data.row.data.size}}</div> -->
              <img v-else :src="`/images/success.svg`" width="20" />
          </div>
      </template>

    </dx-data-grid>

  </div>
</template>

<script>
import DxDataGrid, {
  // DxColumn,
  DxFilterRow,
  // DxScrolling,
  // DxLookup,
  DxPager,
  DxPaging
} from "devextreme-vue/data-grid";
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import RowStatusTemplate from '@/components/sendings/RowStatusTemplate.vue';

export default {
  props: {
    dataSource: {
      required: true
    },
    title: {
      type: String,
      default: () => (null)
    },
    height: {
      default: () => (null)
    },
    paging: {
      type: Boolean,
      default: () => (true)
    }
  },
  methods: {
    removingRow(event) {
      // console.log('removingRow',event)
      this.$emit('row-removing', event)
    }
  },
   
  components: {
    DxDataGrid,
    // DxColumn,
    DxFilterRow,
    // DxScrolling,
    // DxLookup,
    DxPager,
    DxPaging,
    DxProgressBar,
    RowStatusTemplate,
  }
};
</script>

<style lang="scss" scoped>
@import 'src/override/themes/go2transfer/variables.scss';

.list{
  margin: 20px 20px 0 0px;
  background: linear-gradient(25deg, rgba(244,244,244,1) 0%, rgba(244,244,244,0.9023810207676821) 76%, rgba(244,244,244,0) 90%);
  border-radius: 4px;
  padding: 8px;

  h2.content-block {
      font-family: 'LatoWeb';
      text-transform: uppercase;
      font-size: 20px;
      margin-left:15px;
  }

  ::v-deep {
    .dx-datagrid {
      font-family: 'LatoWeb';
      background-color: transparent;
      padding-bottom: 10px;
    }
  }

  .file-is-downloaded {
      &:before {
          padding-right: 10px;
      }

      &-checked:before {
          content: url(/images/checked.svg);
      }

      &-unchecked:before {
          content: url(/images/checked.svg);
      }

      &-error:before {
          content: url(/images/checked.svg);
      }
  }

  .dx-datagrid .dx-row > td{
    font-size:16px;
  }

}
</style>
