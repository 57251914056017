<template>
  <div id="root">
    <div :class="cssClasses">
      <transition name="fade" mode="out-in">
        <Loader 
          v-if="loading"
          :animation-duration="2000"
          :size="65"
          color="#5f5f5f"
        />
      </transition>
      <router-view
        name="layout"
        :title="title"
        :is-x-small="screen.isXSmall"
        :is-large="screen.isLarge"
      >        
        <div class="header">
          <router-view name="header" />
        </div>
        <div class="content">
          <!-- <transition name="fade" mode="out-in">    -->
            <!-- <background-task v-if="tasks.length > 0" :key="`background-task-${tasks.length}`"/> -->
            <!-- <background-task key="background-task"/> -->
          <!-- </transition> -->

          <!-- <transition :name="$route.meta.transition || ''"> -->
          <transition name="fade" mode="out-in">
            <router-view name="content" />
          </transition>

        </div>
        <!-- <template #footer>
          <app-footer />
        </template> -->
        <!-- <pre>Message: {{message}}</pre> -->
      </router-view>
      <dx-popup
        title="Session expiration"
        :show-close-button="false"
        :visible="sessionExpirationValue !== ''"
        :width="350"
        :height="200"
        @hidden="onHide"
      >
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="stayConnectedButtonOptions"
        />
        <div class="expiration session">Your session will expire in {{ sessionExpirationValue }} seconds.</div>
        <!-- <DxButton text="Stay connected" @click="refreshToken" /> -->
      </dx-popup>      
    </div>
  </div>
</template>

<script>
// import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
// import AppFooter from "@/components/app-footer";
import { getScreenSizeInfo, subscribe, unsubscribe } from "@/utils";
import Loader from "@/components/loader";
// import BackgroundTask from './components/receivings/backgroundTask.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
// import { useMachine } from 'xstate-vue2'
// import { useInterpret } from 'xstate-vue2'
// import { EventSourcePolyfill } from 'event-source-polyfill';

// function getScreenSizeInfo() {
//   const screenSizes = sizes();

//   return {
//     isXSmall: screenSizes["screen-x-small"],
//     isLarge: screenSizes["screen-large"],
//     cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
//   };
// }

export default {
  name: "app",
  components: {
    // AppFooter,
    Loader,
    // DxButton,
    DxPopup,
    DxToolbarItem,
    // BackgroundTask,
  },
  data() {
    return {
      title: this.$appInfo.title,
      screen: getScreenSizeInfo(),
      stayConnectedButtonOptions: {
        text: 'Extend the session',
        onClick: this.refreshToken
      },
      // message: ''
    };
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('authentication-basic',['sessionExpiration']),
    ...mapGetters('authentication-basic',['GET_HAS_RT','GET_USER']),
    sessionExpirationValue() {
      return this.sessionExpiration ? this.sessionExpiration : ''
    },
    // tasks() {
    //     return this.downloadMachines ? this.downloadMachines.map(machine => useInterpret(machine,{},(state) => {
    //       // console.log("machine state", state.value)
    //     })) : []
    // },
    cssClasses() {
      return ["app"].concat(this.screen.cssClasses);
    }
  },
  methods: {
    ...mapActions('authentication-basic',['LOGOUT','REFRESH']),
    refreshToken() {
      this.REFRESH()
    },
    onHide() {
      if (!this.GET_HAS_RT || !this.GET_USER) this.LOGOUT()
    },
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    },
    async onClose(event) {
      event.preventDefault();
      event.returnValue = '';
    }
  },
  created() {
    window.addEventListener('beforeunload', this.onClose)
  },
  mounted() {
    subscribe(this.screenSizeChanged);

    // const self = this
    // setTimeout(() => {
    //   const options = {
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state['authentication-basic'].token}`,
    //     },
    //   }

    //   const es = new EventSourcePolyfill(`${process.env.VUE_APP_BACKEND_URL}/sse`, options);
    //   es.onerror = es.onopen = es.onmessage = function (event) {
    //     // // console.log(event.type + ': ' + event.data);
    //     self.message = event.data
    //   };

    // }, 2000)
  },

  beforeDestroy() {
    unsubscribe(this.screenSizeChanged);
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

.app {
  @import "@/themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
</style>
