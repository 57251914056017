<template>
  <div id="uploader-container" ref="uploader-container" class="widget-container flex-box uploader-container">
    <div
      id="dropzone-external"
      ref="dropzone-external"
      class="flex-box"
      :class="dropZoneClass"
    >
      <div
        id="dropzone-text"
        v-if="textVisible"
      >
        <div class="upload-button">
          <DxButton icon="plus" styling-mode="text" />
        </div>
        <div class="upload-text">
          <div>{{ $i18n.t('file-uploader.uploader.upload-file') }}</div>
          <!-- <div>{{ $i18n.t('file-uploader.uploader.upload-file-bis') }}</div> -->
          <div>-</div>
          <div>{{ $i18n.t('file-uploader.uploader.upload-remaining-size') }} : 
            <span :class="remainigSizeClass"><b>{{ displayRemainFileSize }}</b></span>
          </div>
        </div>
      </div>
    </div>
    <DxFileUploader
      id="file-uploader"
      ref="file-uploader"
      :disabled="remainFileSize<0"
      :dialog-trigger="dropZone"
      :drop-zone="dropZone"
      :multiple="uploadMultipleFiles"
      :max-file-size="maxFileSize"
      :allowed-file-extensions="allowedFileExtensions"
      :chunk-size="5120"
      upload-mode="useButtons"
      :visible="false"
      @drop-zone-enter="onDropZoneEnter"
      @drop-zone-leave="onDropZoneLeave"
      @value-changed="onValueChanged"
    />

    <file-list-container @row-removing="removingFile" />

  </div>
</template>
<script>
import { sizes } from '@/modules/utils'
import fileListContainer from './fileListContainer'
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxButton } from 'devextreme-vue/button';
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    DxFileUploader,
    DxButton,
    fileListContainer,
  },
  data() {
    return {
      isDropZoneActive: false,
      textVisible: true,
      // allowedFileExtensions: ['.jpg', '.jpeg', '.gif', '.png', '.pdf'],
      allowedFileExtensions: [],
    };
  },
  computed: {
    ...mapState('sendings', ['files','sendings']),
    ...mapState(['limits']),
    ...mapGetters('sendings', ['GET_CURRENT_FILE_SIZE_MONTH', 'GET_CURRENT_SENDING_FILE_SIZE', 'GET_REMAIN_FILE_SIZE']),
    maxFileSize() {
      return this.limits && this.limits.max_file_size_upload
    },
    maxFileSizePerMonth() {
      return this.limits && this.limits.max_file_size_per_month
    },
    displayMaxFileSizePerMonth() {
      return sizes.formatBytes(this.maxFileSizePerMonth)
    },
    currentFileSizeMonth() {
      return this.GET_CURRENT_FILE_SIZE_MONTH                
    },
    displayCurrentFileSizeMonth() {
      return sizes.formatBytes(this.currentFileSizeMonth)
    },
    currentSendingTotalSize() {
        return this.GET_CURRENT_SENDING_FILE_SIZE
    },
    displayCurrentSendingTotalSize() {
        return this.currentSendingTotalSize ? sizes.formatBytes(this.currentSendingTotalSize) : "Inconnu"
    },
    remainFileSize() {
      return this.GET_REMAIN_FILE_SIZE
    },
    displayRemainFileSize() {
        return (this.remainFileSize ? `${this.remainFileSize < 0 ? '-' : ''}${sizes.formatBytes(Math.abs(this.remainFileSize))}` : "Inconnu")
    },
    remainigSizeClass() {
      // console.log(this.remainFileSize,(2*(this.maxFileSizePerMonth/3)),this.remainFileSize > (2*(this.maxFileSizePerMonth/3)))
      return {
        "remaining-size": true,
        "remaining-green": this.remainFileSize >= (1*(this.maxFileSizePerMonth/3)),
        "remaining-orange": this.remainFileSize < (1*(this.maxFileSizePerMonth/3)),
        "remaining-red": this.remainFileSize <= 0,
      }
    },
    uploadMultipleFiles() {
      return this.limits && this.limits.max_file_upload && this.limits.max_file_upload > 1
    },
    dropZone() {
      return '#dropzone-external'
      // return this.files && this.files.length <= 0 ? '#uploader-container' : '#dropzone-external'
      // return this.files && this.files.length <= 0 ? '#send-package' : '#dropzone-external'
    },
    dropZoneClass() {
      return {
        "dx-theme-border-color": !this.isDropZoneActive,
        "dx-theme-accent-as-border-color": this.isDropZoneActive,
        "dropzone-active": this.isDropZoneActive,
      }
    },
  },
  methods: {
    ...mapMutations('sendings', ['SET_FILES']),
    onValueChanged(e) {
      this.isDropZoneActive = false
      let uniqueFiles = []
      e.value.sort((a,b) => {
          if (a.name<b.name) return -1
          if (a.name>b.name) return 1
          return 0
      }).forEach(file => {
          const found = uniqueFiles.find(f => f.name === file.name && f.size === file.size)
          if (!found) uniqueFiles.push(file)
      })

      this.SET_FILES(uniqueFiles)
    },
    removingFile() {
      this.$refs["file-uploader"].instance.option('value', [...this.files])
    },
    onDropZoneEnter() {
      // console.log("onDropZoneEnter",e.dropZoneElement.id)
      // if (e.dropZoneElement.id === this.dropZone.replace('#','')) {
        this.isDropZoneActive = true;
      // }
    },
    onDropZoneLeave() {
      // console.log("onDropZoneLeave",e)
      // if (e.dropZoneElement.id === this.dropZone.replace('#','')) {
        this.isDropZoneActive = false;
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/override/themes/go2transfer/variables.scss';

#dropzone-external {
  width: 100%;
  height: 140px;
   //background-color: #2f2e2e;
  background-color:#333;
  background-image: url(/images/upload-bg.svg);
  background-size: cover;
  box-shadow: #2828287d 0px 3px 6px inset, #2424248a 0px -3px 7px inset;
  border: solid 1px #404040 !important;
  // padding: 10px;
  // margin: 16px;

    &:hover{
      background-color: #6f6e6e;
      transition:0.3s;
      cursor:pointer;

      .upload-text div{
        color:#ffffffd4;
        transition:0.3s;
      }
  }
}

// #dropzone-external {
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100vh;
//   width: 100vw;
//   z-index: 99;
// }

#dropzone-external > * {
  pointer-events: none;
}

#dropzone-external.dropzone-active {
  border-style: solid;

  // ADDED
  // background-color: #363636f6;
  background-color: #6f6e6e;
  transition:0.3s;
  border-width: 2px;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 55%;

  & > .upload-text div {
      color: #ffffff82;
      font-weight: 100;
      font-family: "LatoWeblight";
      text-transform: uppercase;
      // opacity: 0.5;
  }
}

#upload-progress {
  display: flex;
  margin-top: 10px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::v-deep {
  .upload-button .dx-button {
    background-color: $go2transfer-red;
    height: 50%;

    .dx-icon {
      color: white;
    }
  }
}

.remaining {
  &-size {
    font-family: "LatoWeb";
  }

  &-green {
    color: $go2transfer-dark-green;
  }

  &-orange {
    color: $go2transfer-dark-orange;
  }

  &-red {
    color: $go2transfer-red;
  }
}

</style>
