import Send from "./views/send";
import Tasks from "./views/tasks";
import Settings from './views/settings';
import Payment from './views/payment';
import Receiving from './views/receiving';
import Sendings from './views/sendings';
// import Profile from "./views/profile";
import Vue from "vue";
import Router from "vue-router";

import defaultLayout from "./layouts/side-nav-inner-toolbar";
// import defaultLayout from "./layouts/side-nav-outer-toolbar";
// import simpleLayout from "./layouts/single-card";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/send"
    },
    {
      path: "/home",
      name: "home",
      redirect: "/send"
    },
    {
      path: "/recovery",
      redirect: "/send"
    },
    {
      path: "*",
      redirect: "/send"
    },
    {
      path: "/send",
      name: "send",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Send
      }
    },
    // {
    //   path: "/profile",
    //   name: "profile",
    //   meta: { requiresAuth: true },
    //   components: {
    //     layout: defaultLayout,
    //     content: Profile
    //   }
    // },
    {
      path: "/tasks",
      name: "tasks",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Tasks
      }
    }
  , {
      path: "/sendings",
      name: "sendings",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Sendings
      }
    }, {
      path: "/receiving",
      name: "receiving",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Receiving
      }
    }, {
      path: "/payment",
      name: "payment",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Payment
      }
    }, {
      path: "/settings",
      name: "settings",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Settings
      }
    }]
});

export default router;
