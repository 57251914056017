<template>
  <div class="files">
    <RowStatusFileTemplate v-for="file in files" :key="`file-${rowId}-${file.id}`" :file="file" :template-data="realData" />
  </div>
</template>

<script>
import RowStatusFileTemplate from '@/components/sendings/RowStatusFileTemplate'

export default {
  name: 'RowStatusTemplate',
  props: {
    templateData: {
        type: Object,
        default: () => ({}),
    },
  },
  components: {
    RowStatusFileTemplate
  },
  computed: {
    realData() { return this.templateData.data },
    rowId() { return this.realData.id },
    files() { return this.realData.files },
  },
}
</script>

<style lang="scss" scoped>
.files {
  display: flex;
  justify-content: left;
  align-items: center;

  .file{
    // &:before {
      margin-right: 10px;
    // }

    &.empty {
      content: url(/images/file.svg);        
    }

    &.full {
      content: url(/images/file-full.svg);
    }
  }
}
</style>