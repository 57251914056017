import Mock from './Mock'
import Administration from './Silico'
import Config from '@/config.json'

var APIAdministration = Mock;
if (Config && Config["administration"] && Config["administration"].endpoint) 
{    
    Administration.create({
        ...Config["administration"]   
    })
    APIAdministration = Administration
}

export { APIAdministration }

export default {
    APIAdministration
}