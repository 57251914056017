const dataSources = require('./dataSources')

module.exports = {
    updateSubscriptionCurrent(payload) {
        return new Promise((resolve) => {
            // console.log('[MOCK updateSubscriptionCurrent]',payload)
            setTimeout(() => {
                resolve({ success: true, current: {
                    // ...dataSources.current,
                    option: payload.option,
                    startDate: new Date('2022-01-01').toISOString(),
                    renewDate: payload.option === 'basic' ? null : new Date('2023-01-01').toISOString()
                }}) 
            }, 2000) 
        })
    },

    getSubscriptionCurrent() {
        return new Promise((resolve) => {
            // console.log('[MOCK getSubscriptionCurrent]')
            resolve({ success: true, current: {...dataSources.current}})
        })
    },

    getSubscriptionList() {
        return new Promise((resolve) => {
            // console.log('[MOCK getSubscriptionList]')
            resolve({ success: true, list: [...dataSources.list]})
        })
    },
}