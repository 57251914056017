<template>
    <div class="logout">
        <dx-button :text="i18n.t('authentication-basic.logout.button')" @click="onButtonClick"/> 
    </div>
</template>

<script>
import module_i18n from '../i18n'
import DxButton from 'devextreme-vue/button'
export default {
    props: ["click"],
    components: {
        DxButton
    },
    data() {
        return {
            module_i18n,
        }
    },
    computed: {
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
    },
    methods: {
        onButtonClick(e) {
            if (this.click) return this.click(e)
            return this.logout()
        },
        async logout() {
            await this.$store.dispatch('authentication-basic/LOGOUT')
            this.$router.push({ name: "home" }).catch()
        }
    }
}
</script>