<template>
    <div class="overlay">
          <div class="overlay__inner">
            <div class="overlay__content">
                <BreedingRhombusSpinner 
                    :animation-duration="animationDuration"
                    :size="size"
                    :color="color"
                />
            </div>
          </div>
      </div>
</template>

<script>
import { BreedingRhombusSpinner } from 'epic-spinners'

export default {
    props: {
       animationDuration: {
           type: Number,
           default: () => (2000)
       },
       size: {
           type: Number,
           default: () => (65)
       },
       color: {
           type: String,
           default: () => ("black")
       },
    },
    components: { 
        BreedingRhombusSpinner
    },
    data() {
       return {
           style: {

           }
       }
    },
}
</script>

<style lang="scss" scoped>
@import '@/override/themes/go2transfer/variables.scss';

.overlay {
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(255, 255, 255, .5);
    z-index: 99;

    .overlay__inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        .overlay__content {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);

            // ::v-deep {
            //     .spinner-inner {
            //         &:before {
            //             border-color: $go2transfer-silver-grey;
            //         }
            //     }
            //     .hexagon {
            //         background-color: $go2transfer-silver-grey;
            //         &:before {
            //             border-bottom-color: $go2transfer-silver-grey;
            //         }
            //         &:after {
            //             border-top-color: $go2transfer-silver-grey;
            //         }
            //     }
            // }
        }
    }
}
</style>